import { fork } from 'redux-saga/effects';

import EnquiriesGateway from 'api/Enquiries';
import { RootSagaReturnType } from 'sagas/types';

import watchDeleteEnquiry from './deleteEnquiry';
import watchGetEnquiries from './getEnquiries';
import watchGetEnquiryDetails from './getEnquiryDetails';
import watchResolveEnquiry from './resolveEnquiry';

export default (api: EnquiriesGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetEnquiries, api);
        yield fork(watchResolveEnquiry, api);
        yield fork(watchDeleteEnquiry, api);
        yield fork(watchGetEnquiryDetails, api);
    }

    return {
        rootSaga,
    };
};
