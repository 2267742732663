import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import ProductsGateway from 'api/Products';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { GetProductsPayload } from 'redux/slices/products/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetProducts(api: ProductsGateway): SagaWatcherReturnType {
    yield takeEvery('products/productsGetProductsAttempt', handleGetProducts, api);
}

function* handleGetProducts(api: ProductsGateway, data: GetProductsPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getProducts], {
        authToken,
        index: data.payload.index,
        searchQuery: data.payload.searchQuery ? data.payload.searchQuery : undefined,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.productsGetProductsFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.productsGetProductsSuccess(response.data));
    }
}
