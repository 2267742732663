import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { UpdateProcessOrderPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchUpdateProcessOrder(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderUpdateProcessOrderAttempt', handleUpdateProcessOrder, api);
}
function* handleUpdateProcessOrder(api: OrderGateway, data: UpdateProcessOrderPayload) {
    const authToken = yield* select((state) => state.auth.authToken);

    const { orderId, projectName, projectDueDate } = data.payload;

    const response = yield* call([api, api.updateProcessOrder], {
        authToken,
        orderId,
        projectName,
        projectDueDate,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderUpdateProcessOrderFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderUpdateProcessOrderSuccess(response.data));
        yield put(Actions.orderGetOrderDetailsAttempt({ id: orderId }));
        toast.success('Process Order updated');
    }
}
