import React from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import Text from 'components/atoms/Text';

import { IAddress } from 'entities/address';
import { ICompanyDetails } from 'entities/company';
import { ICustomer } from 'entities/customer';
import { ISupplierDetails } from 'entities/supplier';

interface PurchaseOrderDetailsProps {
    companyDetails: Omit<ICompanyDetails, 'id'>,
    supplierDetails: Partial<ISupplierDetails>;
    deliveryCompanyName?: string;
    deliveryAddress: IAddress;
    preparedBy: string;
    purchaseOrderNo: string;
    poDate: string;
    createdAt: string;
}

const PurchaseOrderDetails: React.FC<PurchaseOrderDetailsProps> = (props: PurchaseOrderDetailsProps) => {
    const {
        companyDetails,
        supplierDetails,
        deliveryCompanyName,
        deliveryAddress,
        preparedBy,
        purchaseOrderNo,
        poDate,
        createdAt,
    } = props;

    return (
        <DetailsContainer>
            <div id='top'>
                <FlexContainer>
                    <Text style={{ fontWeight: 'bold' }}>Saltycustoms</Text>
                    <Text>{`${companyDetails?.name},`}</Text>
                    <Text>{`${companyDetails?.address.street1},`}</Text>
                    {companyDetails?.address.street2 && <Text>{`${companyDetails?.address.street2},`}</Text>}
                    <Text>{`${companyDetails?.address.postcode}, ${companyDetails?.address.city}, ${companyDetails?.address.state},`}</Text>
                    <Text>{companyDetails?.address.country}</Text>
                    <Text>{`Tel: ${companyDetails.phoneNumber}`}</Text>
                </FlexContainer>
                <FlexContainer style={{ gap: '1rem', alignItems: 'flex-end' }}>
                    <div>
                        <Text style={{ fontWeight: 'bold' }}>Date:</Text>
                        <Text>{dayjs(poDate).format('D MMM YYYY')}</Text>
                    </div>
                    <div>
                        <Text style={{ fontWeight: 'bold' }}>PO No:</Text>
                        <Text>{purchaseOrderNo}</Text>
                    </div>
                    <div>
                        <Text style={{ fontWeight: 'bold' }}>Supplier:</Text>
                        <Text>{supplierDetails?.supplierName}</Text>
                    </div>
                </FlexContainer>
            </div>
            <div id='bottom'>
                <FlexContainer>
                    <Text style={{ fontWeight: 'normal', fontSize: '2rem', marginBottom: '1rem' }}>Supplier</Text>
                    <Text style={{ fontWeight: 'bold' }}>{supplierDetails?.contactPersonName}</Text>
                    <Text>{`${supplierDetails?.supplierName},`}</Text>
                    <Text>{`${supplierDetails?.supplierAddress?.street1},`}</Text>
                    {supplierDetails?.supplierAddress?.street2 && <Text>{`${supplierDetails?.supplierAddress?.street2},`}</Text>}
                    <Text>{`${supplierDetails?.supplierAddress?.postcode}, ${supplierDetails?.supplierAddress?.city}, ${supplierDetails?.supplierAddress?.state},`}</Text>
                    <Text>{supplierDetails?.supplierAddress?.country}</Text>
                    <Text>{`Tel: ${supplierDetails.contactPersonNumber}`}</Text>
                    <FlexContainer style={{ marginTop: '3rem' }}>
                        <Text style={{ fontWeight: 'normal', fontSize: '2rem', marginBottom: '1rem' }}>Prepared by</Text>
                        <Text>{`${preparedBy} (${dayjs(createdAt).format('D MMM YYYY hh:mmA')})`}</Text>
                    </FlexContainer>
                </FlexContainer>
                <FlexContainer>
                    <Text style={{ fontWeight: 'normal', fontSize: '2rem', marginBottom: '1rem' }}>Ship To</Text>
                    <Text style={{ fontWeight: 'bold' }}>{deliveryCompanyName}</Text>
                    <Text>{`${deliveryAddress.street1},`}</Text>
                    {deliveryAddress.street2 && <Text>{`${deliveryAddress.street2},`}</Text>}
                    <Text>{`${deliveryAddress.postcode}, ${deliveryAddress.city}, ${deliveryAddress.state},`}</Text>
                    <Text>{deliveryAddress.country}</Text>
                </FlexContainer>
            </div>
        </DetailsContainer>
    );
};
const DetailsContainer = styled.div`
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin-block: 2rem;

    > div {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 2.5rem 3.25rem;
        gap: 20px;
    }

    #top {
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid ${props => props.theme.colors.border};
    }

    #bottom {
        flex-direction: row;
        justify-content: space-between;
    }

`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;

    > div {
        width: 20rem;
        display: flex;
        justify-content: space-between;
    }
`;

export default PurchaseOrderDetails;
