import { fork } from 'redux-saga/effects';

import PurchaseOrderGateway from 'api/PurchaseOrders';
import { RootSagaReturnType } from 'sagas/types';

import watchCreatePurchaseOrder from './createPurchaseOrder';
import watchDeletePurchaseOrder from './deletePurchaseOrder';
import watchDownloadPurchaseOrder from './downloadPurchaseOrder';
import watchGetPurchaseOrderDetails from './getPurchaseOrderDetails';
import watchGetPurchaseOrders from './getPurchaseOrders';
import watchSendPurchaseOrder from './sendPurchaseOrder';
import watchUpdateEmailVariables from './updateEmailVariables';
import watchUpdatePurchaseOrder from './updatePurchaseOrder';
import watchViewPurchaseOrderEmail from './viewPuchaseOrderEmail';

export default (api: PurchaseOrderGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetPurchaseOrders, api);
        yield fork(watchGetPurchaseOrderDetails, api);
        yield fork(watchCreatePurchaseOrder, api);
        yield fork(watchDeletePurchaseOrder, api);
        yield fork(watchDownloadPurchaseOrder, api);
        yield fork(watchSendPurchaseOrder, api);
        yield fork(watchUpdatePurchaseOrder, api);
        yield fork(watchUpdateEmailVariables, api);
        yield fork(watchViewPurchaseOrderEmail, api);
    }

    return {
        rootSaga,
    };
};
