import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import DropdownButton from 'components/atoms/DropdownButton';
import Input from 'components/atoms/Input';

import { IAddress } from 'entities/address';
import CountriesLov from 'lov/CountriesAndStates.json';

import { StatusFilterOption } from '../TableFilters';

interface FullAddressInputProps {
    address?: Partial<IAddress>;

    onAddressChanged: (add: Partial<IAddress>) => void;

    mandatory?: boolean;

    error?: string | null;
}

const FullAddressInput = (props: FullAddressInputProps): JSX.Element => {
    const { address, onAddressChanged, mandatory = false, error } = props;

    const currentAddress = address || {};

    const allCountries = CountriesLov.map((item) => ({
        label: item.name,
        value: item.name,
    }));

    let allStates: StatusFilterOption[] = [];

    if (address?.country) {
        const selectedCountry = CountriesLov.find((item) => item.name === address.country);

        if (selectedCountry) {
            allStates = selectedCountry.states.map((item) => ({
                label: item.name,
                value: item.name,
            }));
        }
    }

    const [filteredCountries, setFilteredCountries] = useState(allCountries);
    const [filteredStates, setFilteredStates] = useState(allStates);

    useEffect(() => {
        if (currentAddress.country) {
            const selectedCountry = CountriesLov.find((item) => item.name === currentAddress.country);

            if (selectedCountry) {
                setFilteredStates(selectedCountry.states.map((item) => ({
                    label: item.name,
                    value: item.name,
                })));
            }
        }
    }, [currentAddress.country]);

    const onSearchCountry = (query: string) => {
        const lowercasedQuery = query.toLowerCase();
        const filtered = allCountries.filter((country) => country.label.toLowerCase().includes(lowercasedQuery));
        setFilteredCountries(filtered);
    };

    const onSearchStates = (query: string) => {
        const lowercasedQuery = query.toLowerCase();
        const filtered = allStates.filter((state) => state.label.toLowerCase().includes(lowercasedQuery));
        setFilteredStates(filtered);
    };

    const setStreet1 = (street1: string) => {
        onAddressChanged({ ...currentAddress, street1 });
    };

    const setStreet2 = (street2: string) => {
        onAddressChanged({ ...currentAddress, street2 });
    };

    const setPostcode = (postcode: string) => {
        onAddressChanged({ ...currentAddress, postcode });
    };

    const setCity = (city: string) => {
        onAddressChanged({ ...currentAddress, city });
    };

    const setSelectedState = (state: string) => {
        onAddressChanged({ ...currentAddress, state });
    };

    const setCountry = (country: string) => {
        onAddressChanged({ ...currentAddress, country });
    };

    return (
        <Container>
            <Input
                error={!!error && !address?.street1}
                header='Street 1'
                value={address?.street1}
                onChange={(e) => setStreet1(e.target.value)}
                required={mandatory}
            />

            <Input
                header='Street 2'
                value={address?.street2}
                onChange={(e) => setStreet2(e.target.value)}
            />

            <InputRow>
                <Input
                    error={!!error && !address?.city}
                    header='City'
                    value={address?.city}
                    onChange={(e) => setCity(e.target.value)}
                    required={mandatory}
                />
                <Input
                    error={!!error && !address?.postcode}
                    header='Postcode'
                    value={address?.postcode}
                    onChange={(e) => setPostcode(e.target.value)}
                    required={mandatory}
                />
            </InputRow>

            <InputRow>
                <DropdownButton
                    error={!!error && !address?.country}
                    label='Country'
                    value={address?.country}
                    searchable
                    options={filteredCountries || allCountries}
                    onSelect={(selectedCountry) => setCountry(selectedCountry as string)}
                    mandatory={mandatory}
                    onSearch={onSearchCountry}
                />
                <DropdownButton
                    error={!!error && !address?.state}
                    label='State'
                    disabled={allStates.length <= 0}
                    value={address?.state}
                    searchable
                    options={filteredStates || allStates}
                    onSelect={(state) => setSelectedState(state as string)}
                    mandatory={mandatory}
                    onSearch={onSearchStates}
                />
            </InputRow>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    gap: 20px;
`;

const InputRow = styled.div`
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 1%;
`;

export default FullAddressInput;
