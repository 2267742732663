import React from 'react';

import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Modal from 'components/atoms/Modal';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import theme from 'styles/theme';

interface ConfirmationModalProps {
    isOpen: boolean;

    title: string;
    text: string | React.ReactNode;
    icon?: React.ReactNode;

    cancelText: string;
    confirmText: string;

    onCancel: () => void;
    onConfirm: () => void;

    loading?: boolean;
    error?: string;

    confirmButtonVariant?: ButtonVariant;
}

const ConfirmationModal = (props: ConfirmationModalProps): JSX.Element => {
    const {
        isOpen, title, text, icon, cancelText, confirmText, onCancel, onConfirm, loading, error, confirmButtonVariant,
    } = props;

    return (
        <Modal
            isModalOpen={isOpen}
            title={title}
            onClosed={onCancel}
            icon={icon}
        >
            <Container>
                <ModalText>{text}</ModalText>

                {error && <ErrorText variant={TextVariant.error}>{error}</ErrorText>}
            </Container>

            <ButtonRow>
                <Button label={cancelText} onClick={onCancel} variant={ButtonVariant.primary} disabled={loading} />
                <Button
                    label={confirmText}
                    onClick={onConfirm}
                    variant={confirmButtonVariant ?? ButtonVariant.primary}
                    loading={loading}
                />
            </ButtonRow>
        </Modal>
    );
};

const ModalText = styled(Text)`
    font-size: 16px;
    color: ${theme.fontColor.primary};
    text-align: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ErrorText = styled(Text)`
    margin-top: 12px;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 12px;

    margin-top: 20px;
`;

export default ConfirmationModal;
