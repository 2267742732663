import { fork } from 'redux-saga/effects';

import ProductsGateway from 'api/Products';
import { RootSagaReturnType } from 'sagas/types';

import watchGetPrintMethod from './getPrintMethod';
import watchGetPrintMethodPricing from './getPrintMethodPricing';
import watchGetPrintMethodVariant from './getPrintMethodVariant';
import watchGetProductPricing from './getProductPricing';
import watchGetProducts from './getProducts';
import watchGetProductVariant from './getProductVariant';
import watchGetShippingCost from './getShippingCost';
import watchUploadProductsFiles from './uploadProductsFiles';

export default (api: ProductsGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchUploadProductsFiles, api);
        yield fork(watchGetProducts, api);
        yield fork(watchGetProductVariant, api);
        yield fork(watchGetProductPricing, api);
        yield fork(watchGetPrintMethod, api);
        yield fork(watchGetPrintMethodVariant, api);
        yield fork(watchGetPrintMethodPricing, api);
        yield fork(watchGetShippingCost, api);
    }
    return {
        rootSaga,
    };
};
