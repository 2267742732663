import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import { GatewayResponseStatus } from 'api/types/types';
import UserManagementGateway from 'api/UserManagement';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import Selectors from 'redux/slices/userManagement/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchUserManagement(api: UserManagementGateway): SagaWatcherReturnType {
    yield takeEvery('userManagement/userManagementGetUserManagementAttempt', handleGetUserManagement, api);
}
function* handleGetUserManagement(api: UserManagementGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const userManagementFilter = yield* select(Selectors.getUserManagementFilters);

    const response = yield* call([api, api.getUserManagement], {
        authToken,
        index: userManagementFilter.currentPage,
        searchQuery: userManagementFilter.searchQuery,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.userManagementGetUserManagementFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.userManagementGetUserManagementSuccess(response.data));
    }
}
