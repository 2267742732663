import config from 'config';

import AuthGateway from './Auth';
import CompanyGateway from './Company';
import CustomerGateway from './Customer';
import EnquiriesGateway from './Enquiries';
import HomeGateway from './Home';
import OrderGateway from './Order';
import ProductsGateway from './Products';
import PurchaseOrderGateway from './PurchaseOrders';
import QuotationGateway from './Quotations';
import ReportGateway from './Report';
import SupplierGateway from './Supplier';
import UserManagementGateway from './UserManagement';

const baseUrl = config.baseUrl as string;

const auth = new AuthGateway(baseUrl);
const home = new HomeGateway(baseUrl);
const order = new OrderGateway(baseUrl);
const quotations = new QuotationGateway(baseUrl);
const suppliers = new SupplierGateway(baseUrl);
const enquiries = new EnquiriesGateway(baseUrl);
const customers = new CustomerGateway(baseUrl);
const purchaseOrders = new PurchaseOrderGateway(baseUrl);
const userManagement = new UserManagementGateway(baseUrl);
const products = new ProductsGateway(baseUrl);
const company = new CompanyGateway(baseUrl);
const report = new ReportGateway(baseUrl);

export default {
    auth,
    home,
    order,
    quotations,
    suppliers,
    enquiries,
    customers,
    purchaseOrders,
    userManagement,
    products,
    company,
    report,
};
