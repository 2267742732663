import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import { GatewayResponseStatus } from 'api/types/types';
import UserManagementGateway from 'api/UserManagement';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import Selectors from 'redux/slices/userManagement/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

import Utils from 'lib/Utils';
import { AuthRoleEnum } from 'entities/auth';

export default function* watchEditUserManagement(api: UserManagementGateway): SagaWatcherReturnType {
    yield takeEvery('userManagement/userManagementEditUserManagementAttempt', handleEditUserManagement, api);
}

function* handleEditUserManagement(api: UserManagementGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const userManagementParams = yield* select(Selectors.getUserManagementDetails);

    if (!userManagementParams || !userManagementParams.id || !userManagementParams.fullName
        || !userManagementParams.role || !userManagementParams.email) {
        yield put(Actions.userManagementEditUserManagementFailure('Please enter all fields before submitting.'));
        return;
    }

    if (userManagementParams.email && !Utils.Validators.validateEmail(userManagementParams.email)) {
        yield put(Actions.userManagementEditUserManagementFailure('Invalid email address. Please enter a valid email address.'));
        return;
    }

    const response = yield* call([api, api.editUserManagement], {
        authToken,
        id: userManagementParams.id,
        role: userManagementParams.role,
        email: userManagementParams.email,
        supplierId: userManagementParams.role === AuthRoleEnum.Supplier ? userManagementParams.supplierId : undefined,
        fullName: userManagementParams.fullName,
        password: userManagementParams.password ? userManagementParams.password : undefined,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.userManagementEditUserManagementFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.userManagementEditUserManagementSuccess());

        toast.success('User updated.');

        yield put(Actions.userManagementGetUserManagementDetailsAttempt({ id: userManagementParams.id }));
    }
}
