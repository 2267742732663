import React from 'react';

import styled from 'styled-components';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import theme from 'styles/theme';

import { BadgeVariant } from './variants/BadgeVariant';
import ErrorMessage from './ErrorMessage';
import LoadingIndicator from './LoadingIndicator';
import StatusBadge from './StatusBadge';

export interface IHeaderArray {
    header: 'Actions' | string;
    field: string;

    width?: number; // to set custom width on a table column
    hidden?: boolean; // set to true if you don't want this column to appear on the table
}

export interface IDataArray {
    id: string;
    onClick?: () => void;

    cellData: {
        [key: string]: string | number | JSX.Element;
    }
}

export interface IActionsArray {
    id: string; // for react list key
    icon: React.ReactNode;
    onClick: (itemId: string) => void;

    shouldHide?: (item: IDataArray) => boolean;
    resolve?: (item: IDataArray) => boolean | void;
}

type TableProps = {
    headerArrays: IHeaderArray[];
    dataArray: IDataArray[];
    actions?: IActionsArray[];

    loading?: boolean;
    error?: string;
    style?: React.CSSProperties;
    disableActions?: boolean;
};

const statusColumn = ['Status', 'other_Status', 'status'];

const Table: React.FC<TableProps> = (props: TableProps) => {
    const { headerArrays, dataArray, actions, loading, error, style, disableActions } = props;

    if (loading) {
        return (
            <LoadingContainer style={style}>
                <LoadingIndicator />
            </LoadingContainer>
        );
    }

    if (error) {
        return (
            <LoadingContainer style={style}>
                <ErrorMessage error={error} />
            </LoadingContainer>
        );
    }

    const boldText = (field: string) => {
        if (field === 'supplierName' || field === 'name' || field === 'fullName'
        || field === 'companyName'
        ) {
            return { fontWeight: 'bold', color: '#295543' };
        }
        return {};
    };

    const renderTableContents = () => {
        if (dataArray && dataArray.length) {
            return dataArray.map((item) => {
                return (
                    <TableRow key={item.id}>
                        {headerArrays.map((header, idx) => {
                            if (header.hidden) return null;
                            if (disableActions && idx === headerArrays.length - 1) return null;

                            const cellData = item.cellData[header.field];

                            if (statusColumn && statusColumn.includes(header.field)) {
                                if (React.isValidElement(cellData)) {
                                    return cellData;
                                }

                                if (cellData as string | number in BadgeVariant && cellData) {
                                    return (
                                        <TableCells key={`${item.id}-${cellData}`} title={cellData.toString()}>
                                            <StatusBadge variant={cellData as BadgeVariant} />
                                        </TableCells>

                                    );
                                }

                                return (
                                    <TableCells key={`${item.id}-${cellData}`} title={cellData ? cellData.toString() : ''}>
                                        {cellData}
                                    </TableCells>
                                );
                            }

                            if (header.header === 'Actions' && actions && !disableActions) {
                                return (
                                    <TableCells
                                        key={`${item.id}-${cellData}`}
                                        title={cellData?.toString()}
                                        width={header.width}

                                    >
                                        {renderActions(item)}
                                    </TableCells>
                                );
                            }

                            return (
                                <TableCells
                                    key={`${item.id}-${cellData}-${header.field}`}
                                    title={cellData?.toString()}
                                    width={header.width}
                                    cursor={item.onClick && 'pointer'}
                                    onClick={item.onClick}
                                    style={boldText(header.field)}
                                >
                                    {cellData}
                                </TableCells>
                            );
                        })}
                    </TableRow>
                );
            });
        }

        return (
            <EmptyContainer>
                <TableCells>
                    No data found. Try updating your filters.
                </TableCells>
            </EmptyContainer>
        );
    };

    const renderActions = (item: IDataArray) => {
        if (actions) {
            return (
                <span>
                    {actions.map((action) => {
                        if (action.shouldHide && action.shouldHide(item)) {
                            return (
                                <IconContainer
                                    key={`${item.id}-changeIcon`}
                                    type='button'
                                >
                                    <CheckIcon color='#C5D8D1' />
                                </IconContainer>
                            );
                        }

                        if (React.isValidElement(action.icon)) {
                            return (
                                <IconContainer
                                    key={`${item.id}-${action.id}`}
                                    type='button'
                                    onClick={() => action.onClick(item.id as string)}
                                >
                                    {action.icon}
                                </IconContainer>
                            );
                        }

                        return null;
                    })}
                </span>
            );
        }
        return null;
    };

    return (
        <TableContainer style={style}>
            <TableHead>
                <tr>
                    {
                        headerArrays.map((header) => {
                            if (header.hidden) return null;

                            if (disableActions && header.header === 'Actions') {
                                return null;
                            }
                            return <TableHeadCell key={header.header} width={header.width}>{header.header}</TableHeadCell>;
                        })
                    }
                </tr>
            </TableHead>
            <TableBody>
                {renderTableContents()}
            </TableBody>
        </TableContainer>
    );
};

export const TableContainer = styled.table`
    width: 100%;
    height: 100%;
    border-radius: 15px;

    table-layout: fixed;
    border-collapse: collapse;

    margin: auto;

    background-color: white;
`;

export const TableHead = styled.thead`
    width: 100%;
    display: table;
    table-layout: fixed;

    border-bottom: 1px solid #42564E33;
`;

export const TableBody = styled.tbody`
    // width: calc(100% - 15px); 
    // margin-block: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 660px;
    display: block;

    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &::-webkit-scrollbar {
        width: 7px;
        
    }

    &::-webkit-scrollbar-track {
        background-color: #42564E33;
        border-radius: 5.5px;
        height: 600px;
        margin-right: 100px;
    }

    &::-webkit-scrollbar-thumb {
        background: #295543;
        border-radius: 5.5px;
    }

    &:hover {

        tr:last-child td:first-child { 
            border-bottom-left-radius: 15px; 
        }
        tr:last-child td:last-child { 
            border-bottom-right-radius: 15px; 
        }
        
    
    }
    tr:last-child { 
        border-bottom: 0.5px solid transparent;
    }
`;

export const TableRow = styled.tr`
    display: table;
    table-layout: fixed;
    width: 100%;
    border-bottom: 0.5px solid #EDEDED;

    &:hover {
        background-color: #EDEDED;
    }
`;

export const TableHeadCell = styled.th<{ width?: number }>`
    font-family: ${theme.fonts.primary};
    font-size: 18px;
    font-weight: 700;
    color: ${theme.fontColor.primary};
    
    padding: 20px;
`;

export const TableCells = styled.td<{ cursor?: string }>`
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    font-weight: 500;
    color: ${theme.fontColor.tertiary};

    overflow: hidden;
    text-overflow: ellipsis;

    cursor: ${props => (props.cursor ? props.cursor : 'inherit')};

    padding: 18px 14px;
`;

export const IconContainer = styled.button`
    border: none;
    background-color: transparent;
    margin-right: 5px;
`;

const LoadingContainer = styled.div`
    width: 100%;
    height: 300px;
    border-radius: 15px;

    margin: auto;
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const EmptyContainer = styled.tr`
    height: 150px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export default Table;
