import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import ConfirmDeleteModal from 'containers/enquiries/components/ConfirmDeleteModal';
import ConfirmResolveModal from 'containers/enquiries/components/ConfirmResolveModal';
import EnquirySubjectAndMessage from 'containers/enquiries/components/EnquirySubjectAndMessage';
import Table, { IActionsArray, IDataArray, IHeaderArray } from 'components/atoms/Table';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import EnquiriesSelectors from 'redux/slices/enquiries/Selectors';

import NavActions from 'lib/NavActions';
import { AuthRoleEnum } from 'entities/auth';
import { EnquiryStatusEnum, EnquiryTypeEnum } from 'entities/enquiries';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

const LatestEnquiriesTable: React.FC = () => {
    const userInfo = useAppSelector(AuthSelectors.getUserInfo);
    const loading = useAppSelector(EnquiriesSelectors.getEnquiriesAttempting);
    const error = useAppSelector(EnquiriesSelectors.getEnquiriesError);
    const { data: enquiries } = useAppSelector(EnquiriesSelectors.getEnquiries);

    const [idToDeleteOrResolve, setIdToDeleteOrResolve] = useState<string | undefined>(undefined);
    const [typeToDeleteOrResolve, setTypeToDeleteOrResolve] = useState<EnquiryTypeEnum | undefined>(undefined);
    const [subjectToDeleteOrResolve, setSubjectToDeleteOrResolve] = useState<string | undefined>(undefined);

    const dispatch = useAppDispatch();

    const getEnquiries = () => {
        dispatch(Actions.enquiriesGetEnquiriesAttempt());
    };

    useEffect(() => {
        getEnquiries();
    }, []);

    const tableHeaders: IHeaderArray[] = [
        { header: 'id', field: 'id', hidden: true },
        { header: 'Date & Time', field: 'createdAt' },
        { header: 'Type', field: 'type', width: 150 },
        { header: 'Name', field: 'name' },
        { header: 'Message', field: 'message', width: 360 },
        { header: 'Status', field: 'status' },
        { header: 'Actions', field: 'action', width: 120 },
    ];

    const tableData: IDataArray[] = enquiries.map((item) => ({
        id: item.id,
        onClick: () => NavActions.navToEnquiryDetails(item.id, item.type),
        cellData: {
            createdAt: dayjs(item.createdAt).format('DD/MM/YYYY HH:mm'),
            type: item.type === EnquiryTypeEnum.HomePage ? 'Home Page' : 'Design Services',
            name: item.name,
            message: <EnquirySubjectAndMessage subject={item.subject} message={item.message} />,
            status: EnquiryStatusEnum[item.status],
        },
    }));

    const tableActions: IActionsArray[] = [
        {
            id: 'resolve',
            icon: <CheckIcon />,
            shouldHide: (item: IDataArray) => {
                if (item.cellData.status === EnquiryStatusEnum[EnquiryStatusEnum.Resolved]) return true;
                return false;
            },
            onClick: (enquiryId: string) => {
                dispatch(Actions.enquiriesSetResolveConfirmationModalIsOpen(true));

                const selectedEnquiry = enquiries.find((item) => item.id === enquiryId);
                if (selectedEnquiry) {
                    setIdToDeleteOrResolve(enquiryId);
                    setTypeToDeleteOrResolve(selectedEnquiry.type);
                    setSubjectToDeleteOrResolve(selectedEnquiry.subject);
                }
            },
        },
        {
            id: 'delete',
            icon: <DeleteIcon />,
            onClick: (enquiryId: string) => {
                dispatch(Actions.enquiriesSetDeleteConfirmationModalIsOpen(true));

                const selectedEnquiry = enquiries.find((item) => item.id === enquiryId);
                if (selectedEnquiry) {
                    setIdToDeleteOrResolve(enquiryId);
                    setTypeToDeleteOrResolve(selectedEnquiry.type);
                    setSubjectToDeleteOrResolve(selectedEnquiry.subject);
                }
            },
        }];

    return (
        <>
            <Table
                headerArrays={tableHeaders}
                dataArray={tableData}
                actions={tableActions}
                style={{ marginTop: 32, marginBottom: 32 }}
                loading={loading}
                error={error}
                disableActions={userInfo?.role === AuthRoleEnum.Executive}
            />
            <ConfirmResolveModal
                enquiryId={idToDeleteOrResolve}
                enquirySubject={subjectToDeleteOrResolve}
                enquiryType={typeToDeleteOrResolve}
            />
            <ConfirmDeleteModal
                enquiryId={idToDeleteOrResolve}
                enquirySubject={subjectToDeleteOrResolve}
                enquiryType={typeToDeleteOrResolve}
            />
        </>
    );
};

export default LatestEnquiriesTable;
