import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import ReportGateway from 'api/Report';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/slices/report/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetReportDetails(api: ReportGateway): SagaWatcherReturnType {
    yield takeEvery('report/reportGetReportDetailsAttempt', handleGetReportDetails, api);
}

function* handleGetReportDetails(api: ReportGateway) {
    const authToken = yield* select((state) => state.auth.authToken);

    const reportFilters = yield* select(Selectors.getReportFilters);

    if (!reportFilters.startDate || !reportFilters.endDate) {
        return;
    }
    const response = yield* call([api, api.getReportDetails], {
        authToken,
        startDate: reportFilters.startDate,
        endDate: reportFilters.endDate,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.reportGetReportDetailsFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.reportGetReportDetailsSuccess(response.data));
    }
}
