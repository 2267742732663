import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import { GatewayResponseStatus } from 'api/types/types';
import UserManagementGateway from 'api/UserManagement';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import Selectors from 'redux/slices/userManagement/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { AuthRoleEnum } from 'entities/auth';

export default function* watchCreateUserManagement(api: UserManagementGateway): SagaWatcherReturnType {
    yield takeEvery('userManagement/userManagementCreateUserManagementAttempt', handleCreateUserManagement, api);
}

function* handleCreateUserManagement(api: UserManagementGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const userManagementParams = yield* select(Selectors.getUserManagementDetails);

    if (!userManagementParams || !userManagementParams.fullName || !userManagementParams.email
        || !userManagementParams.role || !userManagementParams.password) {
        yield put(Actions.userManagementCreateUserManagementFailure('Please enter all fields before submitting.'));
        return;
    }

    if (!Utils.Validators.validateEmail(userManagementParams.email)) {
        yield put(Actions.userManagementCreateUserManagementFailure('Invalid email address. Please enter a valid email address.'));
        return;
    }

    if (userManagementParams.role === AuthRoleEnum.Supplier) {
        yield put(Actions.userManagementSetUserManagementDetails({ supplierId: userManagementParams.supplierId }));
    }

    const response = yield* call([api, api.createUserManagement], {
        authToken,
        fullName: userManagementParams.fullName,
        email: userManagementParams.email,
        password: userManagementParams.password,
        role: userManagementParams.role ?? AuthRoleEnum.Supplier,
        supplierId: userManagementParams.supplierId,

    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.userManagementCreateUserManagementFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.userManagementCreateUserManagementSuccess());

        toast.success('User created.');

        NavActions.navToUserManagement();
    }
}
