import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { GetReportChartDataResponse, GetReportDetailsResponse, OrderOverviewData } from 'api/ReportBase';

import { ReportReduxState, SetReportFilters } from './type';

const initialState: ReportReduxState = {
    actions: {
        getReportDetails: false,
        getReportOrderList: false,
        getReportChartData: false,
    },
    filters: {
        startDate: dayjs().subtract(30, 'days').startOf('day').toISOString(),
        endDate: dayjs().endOf('day').toISOString(),

        index: 0,
        companyAccountId: undefined,
    },
    reportDetails: {
        totalOrdersInfo: {
            total: 0,
            percentageChangeFromPrevMonth: 0,
        },
        totalOrderSalesInfo: {
            total: 0,
            percentageChangeFromPrevMonth: 0,
        },
        orderOverviewData: {
            maxIndex: 0,
            index: 0,
            totalRecords: 0,
            data: [],
        },
        orderStatusTotalInfo: {
            Paid: 0,
            Unpaid: 0,
            Pending: 0,
            Delivered: 0,
            Shipped: 0,
            InProduction: 0,
            Packing: 0,
            Canceled: 0,
        },
        chartData: 0,
    },
    reportOrderList: {
        maxIndex: 0,
        index: 0,
        totalRecords: 0,
        data: [],
    },
    reportChartData: {
        weeklyData: [],
        overallAvgOrderFulfillmentTime: 0,
        overallAvgSourceTime: 0,
        overallAvgProductionTime: 0,
        overallAvgDeliveryTime: 0,
    },
    error: {
        getReportDetails: '',
        getReportOrderList: '',
        getReportChartData: '',
    },
};

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        reportGetReportDetailsAttempt: (state) => {
            state.actions.getReportDetails = true;
            state.error.getReportDetails = '';
        },
        reportGetReportDetailsSuccess: (state, action: PayloadAction<GetReportDetailsResponse>) => {
            state.actions.getReportDetails = false;
            state.reportDetails = action.payload;
        },
        reportGetReportDetailsFailure: (state, action: PayloadAction<string>) => {
            state.actions.getReportDetails = false;
            state.error.getReportDetails = action.payload;
        },

        reportSetFilters: (state, action: SetReportFilters) => {
            const { startDate, endDate, companyAccountId, index } = action.payload;

            if (index !== undefined) {
                state.filters.index = index;
            }

            if (startDate) {
                state.filters.startDate = startDate;
                state.filters.endDate = undefined;
            }
            if (endDate) {
                state.filters.endDate = endDate;
            }

            if (companyAccountId !== undefined) {
                if (companyAccountId.length > 0) {
                    state.filters.companyAccountId = companyAccountId;
                    state.filters.index = 0;
                } else {
                    state.filters.companyAccountId = undefined;
                    state.filters.index = 0;
                }
            }
        },
        reportResetReportFilters: (state) => {
            state.filters = initialState.filters;
        },

        reportGetReportOrderListAttempt: (state) => {
            state.actions.getReportOrderList = true;
            state.error.getReportOrderList = '';
        },
        reportGetReportOrderListSuccess: (state, action: PayloadAction<OrderOverviewData>) => {
            state.actions.getReportOrderList = false;
            state.reportOrderList = action.payload;
        },
        reportGetReportOrderListFailure: (state, action: PayloadAction<string>) => {
            state.actions.getReportOrderList = false;
            state.error.getReportOrderList = action.payload;
        },
        reportGetReportChartDataAttempt: (state) => {
            state.actions.getReportChartData = true;
            state.error.getReportChartData = '';
        },
        reportGetReportChartDataSuccess: (state, action: PayloadAction<GetReportChartDataResponse>) => {
            state.actions.getReportChartData = false;
            state.reportChartData = action.payload;
        },
        reportGetReportChartDataFailure: (state, action: PayloadAction<string>) => {
            state.actions.getReportChartData = false;
            state.error.getReportChartData = action.payload;
        },

    },
});

export type ReportState = typeof initialState;

export default {
    actions: reportSlice.actions,
    reducers: reportSlice.reducer,
};
