import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import AuthGateway from 'api/Auth';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { ChangePasswordParamsPayload } from 'redux/slices/auth/types';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';

export default function* watchChangePassword(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authChangePasswordAttempt', handleChangePassword, api);
}

function* handleChangePassword(api: AuthGateway, data: ChangePasswordParamsPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.changePassword], {
        authToken,
        currentPassword: data.payload.currentPassword,
        newPassword: data.payload.newPassword,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.authChangePasswordFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            toast.success('Password changed! Please login again.');
            yield put(Actions.authChangePasswordSuccess());
            yield put(Actions.authLogout());
            NavActions.navResetToLogin();
        } else {
            yield put(Actions.authChangePasswordFailure('Something went wrong. Please try again.'));
        }
    }
}
