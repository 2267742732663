import React from 'react';

import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';

interface AddShippingCostModalProps {
    isOpen: boolean;
    onCancel: () => void;

    shippingCost: string;
    onShippingCostChange: (value: string) => void;

    onSubmit: () => void;
}

const AddShippingCostModal: React.FC<AddShippingCostModalProps> = (props: AddShippingCostModalProps) => {
    const { isOpen, onCancel, shippingCost, onShippingCostChange, onSubmit } = props;

    return (
        <Modal
            isModalOpen={isOpen}
            onClosed={onCancel}
            title='Add Shipping Cost'
        >
            <Container>
                <Input
                    header='Cost'
                    value={shippingCost}
                    onChange={(e) => onShippingCostChange(e.target.value)}
                />
                <ButtonContainer>
                    <div>
                        <Button label='Discard' variant={ButtonVariant.primary} onClick={onCancel} />
                        <Button label='Add Cost' variant={ButtonVariant.secondary} onClick={onSubmit} />
                    </div>
                </ButtonContainer>
            </Container>
        </Modal>
    );
};

const Container = styled.div`
    width: 30rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-block: 1.5rem;
    width: 100%;

    > div {
        display: flex;
        gap: 1rem;
        width: 100%;
    }
`;

export default AddShippingCostModal;
