import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetPrintMethodApiResponse, GetProductsApiResponse, GetShippingCostResponse } from 'api/ProductsBase';

import { IPrintMethodVariant, IPrintMethodVariantType, IProductDetails, IProductPricing, IProductVariant } from 'entities/products';

import {
    GetPrintMethodPayload,
    GetPrintMethodPricingPayload,
    GetPrintMethodVariantPayload,
    GetProductPricingPayload,
    GetProductsPayload,
    GetProductVariantPayload,
    GetShippingCostPayload,
    ProductsReduxState,
    SetVariantTypePayload,
    UploadProductsFilesPayload,
} from './types';

const initialState: ProductsReduxState = {
    actions: {
        uploadProductsFiles: false,
        getProducts: false,
        getProductVariant: false,
        getProductPricing: false,
        addingProduct: false,
        getPrintMethod: false,
        getPrintMethodVariant: false,
        getPrintMethodPricing: false,
        getShippingCost: false,
    },
    products: {
        index: 0,
        maxIndex: 0,
        totalRecords: 0,
        data: [],
    },
    productVariant: [],
    productPricing: [],
    productVariantType: {
        type: [],
        fit: [],
        sleeve: [],
        style: [],
        color: [],
    },
    printMethodList: {
        index: 0,
        maxIndex: 0,
        totalRecords: 0,
        data: [],
    },
    printMethodVariant: [],
    printMethodPricing: [],
    printMethodVariantType: [{
        block: [],
        colorCount: [],
    }],
    selectedProducts: [],
    discountPercent: '',
    discountFixed: '',
    discount: '0.00',
    shippingCost: '0.00',
    taxPrice: '0.00',
    totalWeight: 0,
    isShippingCostEdited: false,
    isTaxApplied: true,
    error: {
        uploadProductsFiles: '',
        getProducts: '',
        getProductVariant: '',
        getProductPricing: '',
        addingProduct: '',
        getPrintMethod: '',
        getPrintMethodVariant: '',
        getPrintMethodPricing: '',
        getShippingCost: '',
    },
};

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        productResetAddItemInput: (state) => {
            state.productVariantType = initialState.productVariantType;
            state.productVariant = initialState.productVariant;
            state.productPricing = initialState.productPricing;
            state.printMethodVariantType = initialState.printMethodVariantType;
            state.printMethodVariant = initialState.printMethodVariant;
            state.printMethodPricing = initialState.printMethodPricing;
        },
        productResetProductSelector: (state) => {
            state.selectedProducts = initialState.selectedProducts;
            state.discountPercent = initialState.discountPercent;
            state.discountFixed = initialState.discountFixed;
            state.discount = initialState.discount;
            state.shippingCost = initialState.shippingCost;
            state.taxPrice = initialState.taxPrice;
            state.totalWeight = initialState.totalWeight;
            state.isShippingCostEdited = initialState.isShippingCostEdited;
            state.isTaxApplied = initialState.isTaxApplied;
        },
        productsUploadProductsFilesAttempt: (state, _action: UploadProductsFilesPayload) => {
            state.actions.uploadProductsFiles = true;
            state.error.uploadProductsFiles = '';
        },
        productsUploadProductsFilesSuccess: (state) => {
            state.actions.uploadProductsFiles = false;
            state.error.uploadProductsFiles = '';
        },
        productsUploadProductsFilesFailure: (state, action: PayloadAction<string>) => {
            state.actions.uploadProductsFiles = false;
            state.error.uploadProductsFiles = action.payload || 'Something went wrong. Please try again.';
        },
        productsGetProductsAttempt: (state, _action: GetProductsPayload) => {
            state.actions.getProducts = true;
            state.error.getProducts = '';
        },
        productsGetProductsSuccess: (state, action: PayloadAction<GetProductsApiResponse>) => {
            state.actions.getProducts = false;
            state.error.getProducts = '';
            state.products = action.payload;
        },
        productsGetProductsFailure: (state, action: PayloadAction<string>) => {
            state.actions.getProducts = false;
            state.error.getProducts = action.payload || 'Something went wrong. Please try again.';
        },
        productsGetProductVariantAttempt: (state, _action: GetProductVariantPayload) => {
            state.actions.getProductVariant = true;
            state.error.getProductVariant = '';
        },
        productsGetProductVariantSuccess: (state, action: PayloadAction<IProductVariant[]>) => {
            state.actions.getProductVariant = false;
            state.error.getProductVariant = '';
            state.productVariant = action.payload;
        },
        productsGetProductVariantFailure: (state, action: PayloadAction<string>) => {
            state.actions.getProductVariant = false;
            state.error.getProductVariant = action.payload || 'Something went wrong. Please try again.';
        },
        productSetProductVariantType: (state, action: SetVariantTypePayload) => {
            state.productVariantType = action.payload;
        },
        productsGetProductPricingAttempt: (state, _action: GetProductPricingPayload) => {
            state.actions.getProductPricing = true;
            state.error.getProductPricing = '';
        },
        productsGetProductPricingSuccess: (state, action: PayloadAction<IProductPricing[]>) => {
            state.actions.getProductPricing = false;
            state.error.getProductPricing = '';
            state.productPricing = action.payload.sort((a, b) => a.minimumQuantity - b.minimumQuantity);
        },
        productsGetProductPricingFailure: (state, action: PayloadAction<string>) => {
            state.actions.getProductPricing = false;
            state.error.getProductPricing = action.payload || 'Something went wrong. Please try again.';
        },
        // ! ----------------------------------
        productsGetPrintMethodAttempt: (state, _action: GetPrintMethodPayload) => {
            state.actions.getPrintMethod = true;
            state.error.getPrintMethod = '';
        },
        productsGetPrintMethodSuccess: (state, action: PayloadAction<GetPrintMethodApiResponse>) => {
            state.actions.getPrintMethod = false;
            state.error.getPrintMethod = '';
            state.printMethodList = action.payload;
        },
        productsGetPrintMethodFailure: (state, action: PayloadAction<string>) => {
            state.actions.getPrintMethod = false;
            state.error.getPrintMethod = action.payload || 'Something went wrong. Please try again.';
        },
        productsGetPrintMethodVariantAttempt: (state, _action: GetPrintMethodVariantPayload) => {
            state.actions.getPrintMethodVariant = true;
            state.error.getPrintMethodVariant = '';
        },
        productsGetPrintMethodVariantSuccess: (state, action: PayloadAction<{ data: IPrintMethodVariant[], index: number }>) => {
            state.actions.getPrintMethodVariant = false;
            state.error.getPrintMethodVariant = '';
            state.printMethodVariant[action.payload.index] = action.payload.data;
        },
        productsGetPrintMethodVariantFailure: (state, action: PayloadAction<string>) => {
            state.actions.getPrintMethodVariant = false;
            state.error.getPrintMethodVariant = action.payload || 'Something went wrong. Please try again.';
        },
        productsRemovePrintMethodVariant: (state, action: PayloadAction<{ index: number }>) => {
            state.printMethodVariant = state.printMethodVariant.filter((item, i) => i !== action.payload.index);
        },
        productsSetPrintMethodVariantType: (state, action: PayloadAction<{ data: IPrintMethodVariantType, index: number }>) => {
            state.printMethodVariantType[action.payload.index] = action.payload.data;
        },
        productsRemovePrintMethodVariantType: (state, action: PayloadAction<{ index: number }>) => {
            state.printMethodVariantType = state.printMethodVariantType.filter((item, i) => i !== action.payload.index);
        },
        productsGetPrintMethodPricingAttempt: (state, _action: GetPrintMethodPricingPayload) => {
            state.actions.getPrintMethodPricing = true;
            state.error.getPrintMethodPricing = '';
        },
        productsGetPrintMethodPricingSuccess: (state, action: PayloadAction<{ data: IProductPricing[], index: number }>) => {
            state.actions.getPrintMethodPricing = false;
            state.error.getPrintMethodPricing = '';
            state.printMethodPricing[action.payload.index] = action.payload.data.sort((a, b) => a.minimumQuantity - b.minimumQuantity);
        },
        productsGetPrintMethodPricingFailure: (state, action: PayloadAction<string>) => {
            state.actions.getPrintMethodPricing = false;
            state.error.getPrintMethodPricing = action.payload || 'Something went wrong. Please try again.';
        },
        productRemovePrintMethodPricing: (state, action: PayloadAction<{ index: number }>) => {
            state.printMethodPricing = state.printMethodPricing.filter((item, i) => i !== action.payload.index);
        },
        // ! ----------------------------------------------
        productsGetShippingCostAttempt: (state, _action: GetShippingCostPayload) => {
            state.actions.getShippingCost = true;
            state.error.getShippingCost = '';
        },
        productsGetShippingCostSuccess: (state, action: PayloadAction<GetShippingCostResponse>) => {
            state.actions.getShippingCost = false;
            if (action) {
                state.shippingCost = action.payload.price.toFixed(2);
            }
        },
        productsGetShippingCostFailure: (state, action: PayloadAction<string>) => {
            state.actions.getShippingCost = false;
            state.error.getShippingCost = action.payload || 'Something went wrong. Please try again.';
        },
        // ! ----------------------------------------------
        productSetSelectedProducts: (state, action: PayloadAction<IProductDetails>) => {
            state.selectedProducts.push(action.payload);
        },
        productEditSelectedProducts: (state, action: PayloadAction<{ data: IProductDetails, index: number }>) => {
            state.selectedProducts[action.payload.index] = action.payload.data;
        },
        productReplaceSelectedProducts: (state, action: PayloadAction<IProductDetails[]>) => {
            state.selectedProducts = action.payload;
        },
        productResetSelectedProducts: (state) => {
            state.selectedProducts = initialState.selectedProducts;
        },
        productRemoveSelectedProducts: (state, action: PayloadAction<{ index: number }>) => {
            const removedProduct = state.selectedProducts[action.payload.index];
            state.selectedProducts = state.selectedProducts.filter((product) => product !== removedProduct);
        },
        productSetDiscountPercent: (state, action: PayloadAction<string | undefined>) => {
            state.discountPercent = action.payload;
        },
        productSetDiscountFixed: (state, action: PayloadAction<string | undefined>) => {
            state.discountFixed = action.payload;
        },
        productSetDiscountPrice: (state, action: PayloadAction<string>) => {
            state.discount = action.payload;
        },
        productSetShippingCost: (state, action: PayloadAction<string>) => {
            state.shippingCost = action.payload;
        },
        productSetTaxPrice: (state, action: PayloadAction<string>) => {
            state.taxPrice = action.payload;
        },
        productSetTotalWeight: (state, action: PayloadAction<number>) => {
            state.totalWeight = action.payload;
        },
        productSetIsShippingCostEdited: (state, action: PayloadAction<boolean>) => {
            state.isShippingCostEdited = action.payload;
        },
        productSetIsTaxApplied: (state, action: PayloadAction<boolean>) => {
            state.isTaxApplied = action.payload;
        },
    },
});

export type ProductState = typeof initialState;

export default {
    actions: productsSlice.actions,
    reducers: productsSlice.reducer,
};
