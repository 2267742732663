import React, { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import config from 'config';

import ConfirmDeleteModal from 'containers/enquiries/components/ConfirmDeleteModal';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import ConfirmationModal from 'components/molecules/ConfirmationModal';

import { GetAllShipmentTrackingParams, GetAllShipmentTrackingResponse } from 'api/OrderBase';
import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import OrderSelectors from 'redux/slices/order/Selectors';
import products from 'sagas/products';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { AuthRoleEnum } from 'entities/auth';
import { IOrder, IShipmentTrackingDetails, IsPartiallyPaidEnum, OrderPaymentMethodEnum } from 'entities/order';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import TabContainer from './TabContainer';
import UploadPaymentProofModal from './UploadPaymentProofModal';

interface ComponentProps {
    orderDetails: IOrder | null;
    allShipmentTracking: GetAllShipmentTrackingResponse;
    onUploadCustomerPoClicked: (file: File) => void;
    onDeleteCustomerPoClicked: (url: string) => void;
    onUploadPaymentClicked: (file: File, paymentType: IsPartiallyPaidEnum, paymentAmount: number) => void;
    onDeletePaymentClicked: (paymentProofId: string) => void;
    onAddTrackingClicked: () => void;
    onUploadCustomerPoLoading: boolean;
    onUploadPaymentLoading: boolean;
}

const PaymentDetails: React.FC<ComponentProps> = (props: ComponentProps) => {
    const {
        orderDetails,
        allShipmentTracking,
        onUploadCustomerPoClicked,
        onDeleteCustomerPoClicked,
        onUploadPaymentClicked,
        onDeletePaymentClicked,
        onAddTrackingClicked,
        onUploadCustomerPoLoading,
        onUploadPaymentLoading,
    } = props;

    const params = useParams();
    const orderId = params.id;

    const dispatch = useAppDispatch();

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);
    const deleteShipmentTrackingModalIsOpen = useAppSelector(OrderSelectors.deleteShipmentTrackingModalIsOpen);

    const deleteShipmentTrackingLoading = useAppSelector(OrderSelectors.orderDeleteShipmentTrackingAttempting);

    const exportDeliveryOrderLoading = useAppSelector(OrderSelectors.orderExportDeliveryOrderAttempting);
    const exportCommercialInvoiceLoading = useAppSelector(OrderSelectors.orderExportCommercialInvoiceAttempting);
    const orderUploadPaymentProofAttempting = useAppSelector(OrderSelectors.orderUploadPaymentProofAttempting);
    const orderDeletePaymentProofAttempting = useAppSelector(OrderSelectors.orderDeletePaymentProofAttempting);

    const uploadPaymentProofModalIsOpen = useAppSelector(OrderSelectors.uploadPaymentProofModalIsOpen);
    const deletePaymentProofModalIsOpen = useAppSelector(OrderSelectors.deletePaymentProofModalIsOpen);

    const [trackingIdToDelete, setTrackingIdToDelete] = useState<string | null>(null);
    const [trackingNumber, setTrackingNumber] = useState<string>('');

    const [exportDeliveryOrderLoadingId, setExportDeliveryOrderLoadingId] = useState<string | null>(null);
    const [exportCommercialInvoiceLoadingId, setExportCommercialInvoiceLoadingId] = useState<string | null>(null);

    const hiddenFileInputForPo = useRef<HTMLInputElement>(null);
    const hiddenFileInputForPayment = useRef<HTMLInputElement>(null);

    const [paymentProofId, setPaymentProofId] = useState<string>('');

    const [isPartiallyPaid, setIsPartiallyPaid] = useState<IsPartiallyPaidEnum>(IsPartiallyPaidEnum.false);
    const [paymentAmount, setPaymentAmount] = useState<number>(0);
    const [uploadPaymentProofModalError, setUploadPaymentProofModalError] = useState<string>('');

    useEffect(() => {
        if (!uploadPaymentProofModalIsOpen) {
            setUploadPaymentProofModalError('');
            setPaymentAmount(0);
        }
    }, [uploadPaymentProofModalIsOpen]);

    const onUploadClientPoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (hiddenFileInputForPo.current) {
            hiddenFileInputForPo.current.click();
        }
    };

    const onUploadClientPoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileUploaded = event.target.files && event.target.files[0];
        // const fileData = fileUploaded ? URL.createObjectURL(fileUploaded) : null;
        onUploadCustomerPoClicked(fileUploaded as File);

        event.target.value = '';
    };

    const onUploadPaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileUploaded = event.target.files && event.target.files[0];
        onUploadPaymentClicked(fileUploaded as File, isPartiallyPaid, paymentAmount);

        event.target.value = '';
    };

    const onDeleteTrackingClicked = (tracking: IShipmentTrackingDetails) => {
        dispatch(Actions.orderSetDeleteShipmentTrackingModalIsOpen(true));

        setTrackingIdToDelete(tracking.id);
        setTrackingNumber(tracking.trackingNo);
    };

    const onCancelDeleteTracking = () => {
        dispatch(Actions.orderSetDeleteShipmentTrackingModalIsOpen(false));
    };

    const onConfirmDeleteTracking = () => {
        dispatch(Actions.orderDeleteShipmentTrackingAttempt({ orderId: orderId as string, id: trackingIdToDelete as string }));
    };

    const onConfirmDeletePayment = () => {
        if (paymentProofId === '') return;
        onDeletePaymentClicked(paymentProofId);
    };

    const onPaymentTypeFull = () => {
        setIsPartiallyPaid(IsPartiallyPaidEnum.false);

        if (hiddenFileInputForPayment.current) {
            hiddenFileInputForPayment.current.click();
        }
    };

    const onPaymentTypePartial = () => {
        setIsPartiallyPaid(IsPartiallyPaidEnum.true);

        if (paymentAmount === 0) {
            setUploadPaymentProofModalError('Payment amount is required');
            return;
        }
        if (hiddenFileInputForPayment.current) {
            hiddenFileInputForPayment.current.click();
        }
    };

    const onExportDeliveryOrder = (trackingId: string) => {
        if (!trackingId) toast.error('No tracking id found');
        setExportDeliveryOrderLoadingId(trackingId);
        dispatch(Actions.orderExportDeliveryOrderAttempt({ orderId: orderId as string, trackingId }));
    };

    const onExportCommercialInvoice = (trackingId: string) => {
        if (!trackingId) toast.error('No tracking id found');
        setExportCommercialInvoiceLoadingId(trackingId);
        dispatch(Actions.orderExportCommercialInvoiceAttempt({ orderId: orderId as string, trackingId }));
    };

    const renderPurchaseOrder = () => {
        if (!orderDetails) return null;

        if (orderDetails.receivedPurchaseOrderUrl) {
            const poUrl = `${config.baseUrl}/${orderDetails.receivedPurchaseOrderUrl.split('/').slice(1).join('/')}`;
            return (
                <TabContent>
                    <Text variant={TextVariant.h2}>
                        {'Customer\'s Purchase Order'}
                    </Text>
                    <div>{'Customer\'s purchase order received.'}</div>
                    <ButtonContainer>
                        <Button
                            style={{ width: '300px' }}
                            label='Download purchase order'
                            variant={ButtonVariant.secondary}
                            loading={onUploadPaymentLoading}
                            onClick={() => {
                                window.open(
                                    poUrl,
                                    '_blank',
                                    'noopener, noreferrer',
                                );
                            }}
                        />
                        <Text>{orderDetails.receivedPurchaseOrderUrl.split('/').pop()}</Text>
                        {userInfo?.role === AuthRoleEnum.Supplier ? null : (
                            <ActionIconsContainer
                                type='button'
                                onClick={() => onDeleteCustomerPoClicked(orderDetails.receivedPurchaseOrderUrl ?? '')}
                            >
                                <DeleteIcon height={20} />
                            </ActionIconsContainer>
                        )}
                    </ButtonContainer>
                </TabContent>
            );
        }
        return (
            <TabContent>
                <Text variant={TextVariant.h2}>
                    {'Customer\'s Purchase Order'}
                </Text>
                <div>No customers purchase order yet.</div>
                {userInfo?.role === AuthRoleEnum.Supplier ? null : (
                    <Button
                        style={{ width: '300px' }}
                        variant={ButtonVariant.secondary}
                        label='Upload purchase order'
                        loading={onUploadCustomerPoLoading}
                        onClick={onUploadClientPoClick}
                    />
                )}
                <input
                    type='file'
                    onChange={onUploadClientPoChange}
                    ref={hiddenFileInputForPo}
                    style={{ display: 'none' }} // Make the file input element invisible
                />
            </TabContent>
        );
    };

    const renderPayment = () => {
        if (!orderDetails) return null;
        let paymentTitle = '';
        if (orderDetails.paymentMethod === OrderPaymentMethodEnum.CreditCard) paymentTitle = 'Credit Card';
        if (orderDetails.paymentMethod === OrderPaymentMethodEnum.OnlineBanking) paymentTitle = 'Online Banking';

        if (orderDetails.isPaidWithStripePaymentGateway) {
            return (
                <TabContent>
                    <Text variant={TextVariant.h2}>
                        {`Payments (${paymentTitle})`}
                    </Text>
                    <div>This order is paid by using Stripe.</div>
                </TabContent>
            );
        }

        if (orderDetails.paymentProof && orderDetails.paymentProof.length > 0) {
            return (
                <TabContent>
                    <Text variant={TextVariant.h2}>
                        {`Payments (${paymentTitle})`}
                    </Text>
                    <ButtonContainer>
                        <ul>
                            {
                                orderDetails.paymentProof.map((proof) => (
                                    <li key={proof._id}>
                                        <Text style={{ lineHeight: '16px', marginTop: '10px' }}>
                                            {`${Utils.Formatter.formatCurrency(orderDetails.currency)}${proof.paymentAmount}  - `}
                                        </Text>
                                        <a
                                            href={`${config.baseUrl}/${proof.paymentProofMediaUrl.split('/').slice(1).join('/')}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {proof.paymentProofMediaUrl.split('/').pop()}
                                        </a>
                                        {userInfo?.role === AuthRoleEnum.Supplier ? null : (
                                            <ActionIconsContainer
                                                style={{ marginLeft: '20px' }}
                                                type='button'
                                                onClick={() => {
                                                    dispatch(Actions.orderSetDeletePaymentProofModalIsOpen(true));
                                                    setPaymentProofId(proof._id);
                                                }}
                                            >
                                                <DeleteIcon height={20} />
                                            </ActionIconsContainer>
                                        )}
                                    </li>
                                ))
                            }
                        </ul>
                    </ButtonContainer>
                    {userInfo?.role === AuthRoleEnum.Supplier ? null : (
                        <Button
                            style={{ width: '300px' }}
                            variant={ButtonVariant.secondary}
                            label='Upload payment proof'
                            loading={onUploadPaymentLoading}
                            onClick={() => {
                                dispatch(Actions.orderSetUploadPaymentProofModalIsOpen(true));
                            }}
                        />
                    )}
                    <input
                        type='file'
                        onChange={onUploadPaymentChange}
                        ref={hiddenFileInputForPayment}
                        style={{ display: 'none' }}
                    />
                    <ConfirmationModal
                        isOpen={deletePaymentProofModalIsOpen}
                        icon={<ConfirmationIcon />}
                        onCancel={() => dispatch(Actions.orderSetDeletePaymentProofModalIsOpen(false))}
                        onConfirm={onConfirmDeletePayment}
                        title='Delete this Payment Proof?'
                        text={'If removed, the payment status will remain as paid unless you manually convert them back to "unpaid" through the action button'}
                        confirmText='Confirm'
                        cancelText='Cancel'
                        confirmButtonVariant={ButtonVariant.warning}
                        loading={orderDeletePaymentProofAttempting}
                    />
                    <UploadPaymentProofModal
                        isOpen={uploadPaymentProofModalIsOpen}
                        onCancel={() => dispatch(Actions.orderSetUploadPaymentProofModalIsOpen(false))}
                        onUploadPartialPayment={onPaymentTypePartial}
                        onUploadFullPayment={onPaymentTypeFull}
                        paymentAmount={paymentAmount}
                        setPaymentAmount={setPaymentAmount}
                        title='Upload Payment Proof'
                        text='Please select payment type'
                        confirmText='Upload'
                        cancelText='Cancel'
                        confirmButtonVariant={ButtonVariant.default}
                        loading={orderUploadPaymentProofAttempting}
                        error={uploadPaymentProofModalError}
                    />
                </TabContent>
            );
        }
        return (
            <TabContent>
                <Text variant={TextVariant.h2}>
                    {`Payments (${paymentTitle})`}
                </Text>
                <div>This order is pending for payment.</div>
                {userInfo?.role === AuthRoleEnum.Supplier ? null : (
                    <Button
                        style={{ width: '300px' }}
                        variant={ButtonVariant.secondary}
                        label='Upload payment proof'
                        loading={onUploadPaymentLoading}
                        onClick={() => {
                            dispatch(Actions.orderSetUploadPaymentProofModalIsOpen(true));
                        }}
                    />
                )}
                <input
                    type='file'
                    onChange={onUploadPaymentChange}
                    ref={hiddenFileInputForPayment}
                    style={{ display: 'none' }}
                />
                <UploadPaymentProofModal
                    isOpen={uploadPaymentProofModalIsOpen}
                    onCancel={() => dispatch(Actions.orderSetUploadPaymentProofModalIsOpen(false))}
                    onUploadPartialPayment={onPaymentTypePartial}
                    onUploadFullPayment={onPaymentTypeFull}
                    paymentAmount={paymentAmount}
                    setPaymentAmount={setPaymentAmount}
                    title='Upload Payment Proof'
                    text='Please select payment type'
                    confirmText='Upload'
                    cancelText='Cancel'
                    confirmButtonVariant={ButtonVariant.default}
                    loading={orderUploadPaymentProofAttempting}
                    error={uploadPaymentProofModalError}
                />
            </TabContent>
        );
    };

    const renderTrackingActions = (tracking: IShipmentTrackingDetails) => {
        if (!orderId) return null;
        if (userInfo?.role === AuthRoleEnum.Supplier) return null;
        return (
            <ButtonContainer>
                <ActionIconsContainer
                    type='button'
                    onClick={() => NavActions.navToAddShipmentTracking(orderId, tracking.id)}
                >
                    <EditIcon height={20} />
                </ActionIconsContainer>
                <ActionIconsContainer
                    type='button'
                    onClick={() => onDeleteTrackingClicked(tracking)}
                >
                    <DeleteIcon height={20} />
                </ActionIconsContainer>
            </ButtonContainer>
        );
    };
    const renderTracking = () => {
        if (allShipmentTracking && allShipmentTracking.length > 0) {
            return (
                <TabContent>
                    <Text variant={TextVariant.h2}>
                        Tracking
                    </Text>
                    <TrackingTable>
                        <thead>
                            <tr>
                                <th style={{ width: '30%' }}>Products</th>
                                <th>Tracking Number</th>
                                <th>Shipping Vendor</th>
                                <th>Downloads</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allShipmentTracking.map((tracking) => (
                                    <tr key={tracking.id}>
                                        <td>
                                            <ul>
                                                {
                                                    tracking.products.map((product) => (
                                                        <li key={product.productId}>
                                                            {product.productName}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </td>
                                        <td>
                                            <div>
                                                {tracking.trackingNo}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {tracking.shippingVendor}
                                            </div>
                                        </td>
                                        <td>
                                            <DownloadContainer>
                                                <Button
                                                    style={{ fontSize: '12px', width: '120px', height: '30px', whiteSpace: 'nowrap' }}
                                                    label='Download DO'
                                                    variant={ButtonVariant.secondary}
                                                    onClick={() => onExportDeliveryOrder(tracking.id)}
                                                    loading={exportDeliveryOrderLoading && exportDeliveryOrderLoadingId === tracking.id}
                                                />
                                                <Button
                                                    style={{ fontSize: '12px', width: '120px', height: '30px', whiteSpace: 'nowrap' }}
                                                    label='Download CI'
                                                    variant={ButtonVariant.secondary}
                                                    onClick={() => onExportCommercialInvoice(tracking.id)}
                                                    loading={exportCommercialInvoiceLoading && exportCommercialInvoiceLoadingId === tracking.id}
                                                />
                                            </DownloadContainer>
                                        </td>
                                        <td>{renderTrackingActions(tracking)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </TrackingTable>
                    {userInfo?.role === AuthRoleEnum.Supplier ? null : (
                        <Button
                            style={{ width: '300px' }}
                            variant={ButtonVariant.secondary}
                            label='Add tracking'
                            onClick={onAddTrackingClicked}
                        />
                    )}
                    <ConfirmationModal
                        isOpen={deleteShipmentTrackingModalIsOpen}
                        icon={<ConfirmationIcon />}
                        onCancel={onCancelDeleteTracking}
                        onConfirm={onConfirmDeleteTracking}
                        title='Delete this Tracking?'
                        text={`"${trackingNumber}"`}
                        confirmText='Confirm'
                        cancelText='Cancel'
                        confirmButtonVariant={ButtonVariant.warning}
                        loading={deleteShipmentTrackingLoading}
                    />
                </TabContent>
            );
        }
        return (
            <TabContent>
                <Text variant={TextVariant.h2}>
                    Tracking
                </Text>
                <div>There is no tracking for this order yet.</div>
                {userInfo?.role === AuthRoleEnum.Supplier ? null : (
                    <Button
                        style={{ width: '300px' }}
                        variant={ButtonVariant.secondary}
                        label='Add tracking'
                        onClick={onAddTrackingClicked}
                    />
                )}
            </TabContent>
        );
    };

    const tabs = [
        { label: 'Customer\'s Purchase Order', content: renderPurchaseOrder() },
        { label: 'Payment', content: renderPayment() },
        { label: 'Tracking', content: renderTracking() },
    ];

    return (
        <TabContainer tabs={tabs} />
    );
};

const TabContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

const ActionIconsContainer = styled.button`
    border: none;
    background-color: transparent;
`;

const DownloadContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const TrackingTable = styled.table`
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    th {
        text-align: center;
    }

    th, td {
        padding: 1rem;
        border: 1px solid #42564E33;

        > div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

export default PaymentDetails;
