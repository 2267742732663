export enum ButtonVariant {
    default = 'default',
    link = 'link',
    menu = 'menu',
    primary = 'primary',
    secondary = 'secondary',
    warning = 'warning',
}

export default {
    ButtonVariant,
};
