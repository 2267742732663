export enum IProductType {
    NORMAL = 1,
}

export enum EVariantType {
    FABRIC = 'fabric',
    FIT = 'fit',
    SLEEVE = 'sleeve',
    STYLE = 'style',
    COLOR = 'color',
}

export enum EPrintSide {
    Front = 1,
    Back = 2,
    Left = 3,
    Right = 4,
}

export enum EPrintMethod {
    SIDE = 'side',
    PRINTMETHOD = 'printMethod',
    BLOCK = 'block',
    COLORCOUNT = 'colorCount',
}

export enum EDiscountType {
    PERCENTAGE = 'percentage',
    FIXED = 'fixed',
}

export enum ShippingVendorEnum {
    FedEx = 'FedEx',
    Ninjavan = 'Ninjavan',
}

export interface IMinumumQuantity {
    size: string;
    quantity: number;
}

export interface IProduct {
    id: string;
    name: string;
    productType: IProductType;
}

export interface IProductVariant {
    id: string; // variant id
    variantCode: string;
    productId: string;
    fit?: string;
    type?: string;
    sleeve?: string;
    style?: string;
    color?: string;
    weight?: number;
}

export interface IProductVariantType {
    type?: string[];
    fit?: string[];
    sleeve?: string[];
    style?: string[];
    color?: string[];
}

export interface IPrintMethodVariantType {
    colorCount?: string[];
    block?: string[];
}

export interface IProductPricing {
    id: string;
    minimumQuantity: number;
    pricePerUnit: number;
    size?: string;
    quantity?: number;
    quantityPrice?: number;
}

export interface IProductSize {
    'XS'?: number;
    'S'?: number;
    'M'?: number;
    'L'?: number;
    'XL'?: number;
    '2XL'?: number;
    '3XL'?: number;
    '4XL'?: number;
    '5XL'?: number;
    '6XL'?: number;
    '7XL'?: number;
    '8XL'?: number;
    Quantity?: number;
}

export interface IPrintMethod {
    side: EPrintSide,
    printMethod: string,
    block?: string,
    colorCount?: string,
    printVariantId?: string,
    printCustomId?: string, // printMethodId
}

export interface IPrintMethodParams {
    side: EPrintSide,
    printCustomId: string,
    printVariantId: string,
    pricePerUnit: number,
    printMethod?: string,
    block?: string,
    colorCount?: string,
}

export interface IPrintMethodList {
    id: string;
    name: string;
    printCustomType: number;
}

export interface IPrintMethodVariant {
    colorCount: string,
    categoryType: string,
    block: string,
    id: string,
}

export interface IProductDetails {
    productId?: string;
    variantId?: string;
    name: string; // ? product name
    variantText?: string;
    sizeText?: string;
    printMethodText?: string[];
    variant?: Omit<IProductVariant, 'id' | 'variantCode' | 'productId'>;
    size?: IProductSize;
    totalQuantity: number;
    quantities?: IProductPricing[];
    price: string; // ? pricePerUnit
    priceAfterDiscount?: number; // ? ppu after discount
    printMethodDetails?: IPrintMethod[]; // ? used to show print method
    printMethods?: IPrintMethodParams[]; // ? used as params in api call
    discountPercent?: number;
    discountFixed?: number;
    truePricePerUnit?: number;
    tax?: number;
    subtotal?: number; // ? price before discount
    total: number; // ? price after discount
    disableQuantityEdit?: boolean;
    totalPricePerUnit?: number;
    weight?: number;
    discountAmountApplied?: number;
    remark?: string;
}

export enum TaxRateEnum {
    GstTaxRate = 1,
    OutOfScopeTaxRate = 2
}
