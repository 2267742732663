import React, { FC, InputHTMLAttributes, useState } from 'react';

import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import icons from 'assets/icons';

import theme from 'styles/theme';

import { TextVariant } from './variants/TextVariant';
import Text from './Text';

type InputProps = InputHTMLAttributes<HTMLInputElement> & InputHTMLAttributes<HTMLTextAreaElement> & {
    variant?: 'default' | 'password' | 'inputForm' | 'textarea' | 'file';
    error?: boolean;

    header?: string;
    subtitle?: string;
    required?: boolean;
    actionIcons?: JSX.Element | null;
    isEditable?: boolean;
    uploadMultiple?: boolean;

    isSetPassword?: boolean;
}

const Input: FC<InputProps> = (props: InputProps) => {
    const {
        variant = 'default',
        subtitle,
        error,
        header,
        required,
        actionIcons,
        isEditable,
        uploadMultiple,
        isSetPassword,
        ...restOfProps
    } = props;

    const [passwordVisible, setPasswordVisible] = useState(false);

    if (variant === 'password') {
        return (
            <InputContainer>
                {header && (
                    <TitleName>
                        {header}
                        {required && (<Text variant={TextVariant.error}>*</Text>)}
                    </TitleName>
                )}
                <CustomInput
                    type={passwordVisible ? 'text' : 'password'}
                    variant={variant}
                    error={error}
                    {...restOfProps}
                    style={{ padding: '.75rem 3.2rem .75rem 1.5rem' }}
                />
                <PasswordViewIcon
                    isHeader={!!header}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    src={passwordVisible ? icons.eyeOpenIcon : icons.eyeCloseIcon}
                />
            </InputContainer>
        );
    }

    if (variant === 'inputForm') {
        return (
            <InputContainer>
                {header && (
                    <TitleName>
                        {header}
                        {required && (<Text variant={TextVariant.error}>*</Text>)}
                    </TitleName>
                )}
                <InputField
                    variant={variant}
                    error={error}
                    {...restOfProps}
                />
            </InputContainer>
        );
    }

    if (variant === 'textarea') {
        return (
            <InputContainer>
                {header && (
                    <TitleName>
                        {header}
                        {required && (<Text variant={TextVariant.error}>*</Text>)}
                    </TitleName>
                )}
                <TextAreaField
                    error={error}
                    {...restOfProps}
                />
            </InputContainer>
        );
    }

    if (variant === 'file') {
        return (
            <InputContainer>
                {header && (
                    <TitleName>
                        {header}
                        {required && (<Text variant={TextVariant.error}>*</Text>)}
                    </TitleName>
                )}
                <FileField
                    type='file'
                    multiple={uploadMultiple}
                    {...restOfProps}
                />
            </InputContainer>
        );
    }

    if (actionIcons) {
        return (
            <InputContainer>
                <InputWithActionsContainer>
                    <InputField
                        {...restOfProps}
                    />
                    <IconContainer>
                        {actionIcons}
                    </IconContainer>
                </InputWithActionsContainer>
            </InputContainer>
        );
    }

    return (
        <InputContainer>
            {header && (
                <TitleName>
                    {header}
                    {required && (<Text variant={TextVariant.error}>*</Text>)}
                </TitleName>
            )}
            {subtitle && (<SubtitleText>{subtitle}</SubtitleText>)}

            <CustomInput
                style={isSetPassword ? { border: 'none' } : {}}
                variant={variant}
                error={error}
                {...restOfProps}
            />
        </InputContainer>
    );
};

const CustomInput = styled.input.withConfig({
    shouldForwardProp: (prop) => prop !== 'error' && prop !== 'variant',
}) <InputProps>`
    all: unset;

    font-size: ${props => props.theme.fontSize.md};
    line-height: ${props => props.theme.lineHeight.default};
    font-weight: ${props => props.theme.fontWeight.default};
    font-family: ${theme.fonts.primary};

    width: 100%;
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.grey_light};
    /* border: 2px solid white; */
    border-radius: 2rem;
    padding: .75rem 1.5rem;

    &:focus {
        outline: 1px solid black;
    }

    @media (min-width: 1280px) {
        font-size: ${theme.fontSize.sm};
        line-height: ${theme.lineHeight.sm};
    }

    @media (min-width: 1520px) {
        font-size: ${theme.fontSize.default};
        line-height: ${theme.lineHeight.default};
    }

    ${props => props.error && `
        border: 2px solid ${props.theme.colors.error};
    `}

    &::placeholder {
        color: ${props => props.theme.fontColor.secondary};
    }
`;

const InputContainer = styled.div`
    position: relative;
    width: 100%;
`;

const PasswordViewIcon = styled(SVG) <{ isHeader?: boolean }>`
    position: absolute;
    top: ${props => (props.isHeader ? '70%' : '50%')};
    transform: translateY(-50%);
    color: ${props => props.theme.fontColor.primary};
    cursor: pointer;

    right: 1.25rem;
    width: 1rem;

    @media (min-width: 1280px) {
        right: 1.5rem;
        width: 1.5rem;
    }

    @media (min-width: 1520px) {
        /* right: 1.75rem; */
        width: 1.75rem;
    }
`;

const InputField = styled.input.withConfig({
    shouldForwardProp: (prop) => prop !== 'error',
}) <InputProps>`
    all: unset;

    width: 100%;
    height: 50px;

    border-radius: 25px;

    padding: 12px 25px;
    box-sizing: border-box;

    font-size: 16px;
    font-family: ${theme.fonts.primary};
    color: ${theme.fontColor.primary};
    line-height: ${props => props.theme.lineHeight.default};
    font-weight: ${props => props.theme.fontWeight.default};
    background-color: ${props => props.theme.colors.grey_light};

    &:focus {
        outline: 1px solid black;
    }

    @media (min-width: 1280px) {
        font-size: ${theme.fontSize.sm};
        line-height: ${theme.lineHeight.sm};
    }

    @media (min-width: 1520px) {
        font-size: ${theme.fontSize.default};
        line-height: ${theme.lineHeight.default};
    }

    &::placeholder {
        color: ${theme.fontColor.secondary};
    }

    ${props => props.error && `
        border: 2px solid ${props.theme.colors.error};
    `}
`;

export const TextAreaField = styled.textarea.withConfig({
    shouldForwardProp: (prop) => prop !== 'error',
})<InputProps>`
    width: 100%;
    height: 147px;
    resize: none;

    margin-bottom: -6px;

    border-radius: 10px;
    border: 1px solid transparent;

    padding: .75rem 1.5rem;

    font-size: ${props => props.theme.fontSize.md};
    line-height: ${props => props.theme.lineHeight.default};
    font-weight: ${props => props.theme.fontWeight.default};

    font-family: ${theme.fonts.primary};
    color: ${theme.fontColor.primary};
    background-color: ${props => props.theme.colors.grey_light};

    ${props => props.error && `
        border: 2px solid ${props.theme.colors.error};
    `}

    &:focus {
        outline: 1px solid black;
    }

    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        background-color: #42564E33;
        border-radius: 5.5px;
        height: 600px;
    }

    &::-webkit-scrollbar-thumb {
        background: #295543;
        border-radius: 5.5px;
    }

    @media (min-width: 1280px) {
        font-size: ${theme.fontSize.sm};
        line-height: ${theme.lineHeight.sm};
    }

    @media (min-width: 1520px) {
        font-size: ${theme.fontSize.default};
        line-height: ${theme.lineHeight.default};
    }
`;

const FileField = styled.input`
    // display: none;
    width: 30vw;

    &::file-selector-button {
        background-color: ${theme.colors.tertiary};
        font-size: ${theme.fontSize.xs};
        line-height: ${theme.lineHeight.xs};
        font-weight: ${theme.fontWeight.default};
        color: ${theme.fontColor.primary};
        font-family: ${theme.fonts.primary};
        text-align: center;

        border: none;
        border-radius: 2rem;

        height: 47px;
        width: 10vw;
        margin-right: 1rem;

        cursor: pointer;

        @media (min-width: 1280px) {
            font-size: ${theme.fontSize.sm};
            line-height: ${theme.lineHeight.sm};
        }

        @media (min-width: 1520px) {
            font-size: ${theme.fontSize.default};
            line-height: ${theme.lineHeight.default};
        }

    }

    // &::-webkit-file-upload-button {
    //     background: black;
    //     color: red;
    //     padding: 1em;
    //     cursor: pointer;
    // }
`;

const InputWithActionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    border-radius: 10px;
`;

const IconContainer = styled.div`
    position: absolute;
    background-color: transparent;

    margin-right: 0.5rem;
`;

export const TitleName = styled.div`
    font-size: ${theme.fontSize.xs};
    font-weight: 500;
    font-family: ${theme.fonts.primary};
    color: ${theme.fontColor.tertiary};

    margin-bottom: 6px;

    @media (min-width: 1280px) {
        font-size: ${theme.fontSize.sm};
        line-height: ${theme.lineHeight.sm};
    }

    @media (min-width: 1520px) {
        font-size: ${theme.fontSize.default};
        line-height: ${theme.lineHeight.default};
    }
`;

export const SubtitleText = styled.div`
    font-size: 12px;
    font-weight: 500;
    font-family: ${theme.fonts.primary};
    color: ${theme.fontColor.tertiary};

    margin-bottom: 6px;
`;

export default Input;
