import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';
import { IEnquiry } from 'entities/enquiries';

import { GetEnquiriesApiParams, GetEnquiriesApiResponse, GetEnquiryDetailsApiParams, IEnquiriesGateway, ResolveEnquiryApiParams } from './EnquiriesBase';

export default class EnquiriesGateway extends IEnquiriesGateway {
    async getEnquiries(params: GetEnquiriesApiParams): GatewayResponse<GetEnquiriesApiResponse> {
        const { authToken, ...enquiriesParams } = params;
        const response: ApiResponse<GetEnquiriesApiResponse> = await this.api.get(
            '/inquiry/viewInquiries',
            enquiriesParams,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getEnquiryDetails(params: GetEnquiryDetailsApiParams): GatewayResponse<IEnquiry> {
        const { authToken, id, type } = params;

        const response: ApiResponse<IEnquiry> = await this.api.get(
            `/inquiry/viewInquiries/${id}`,
            { type },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async resolveEnquiry(params: ResolveEnquiryApiParams): GatewayResponse<null> {
        const { authToken, id, type } = params;

        const response: ApiResponse<null> = await this.api.put(
            '/inquiry/resolveInquiry',
            { id, type },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deleteEnquiry(params: ResolveEnquiryApiParams): GatewayResponse<null> {
        const { authToken, id, type } = params;

        const response: ApiResponse<null> = await this.api.delete(
            '/inquiry/deleteInquiry',
            { id, type },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }
}
