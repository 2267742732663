import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PurchaseOrderGateway from 'api/PurchaseOrders';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

import Utils from 'lib/Utils';

export default function* watchSendPurchaseOrder(api: PurchaseOrderGateway): SagaWatcherReturnType {
    yield takeEvery('po/poSendPurchaseOrderAttempt', handleSendPurchaseOrder, api);
}

function* handleSendPurchaseOrder(api: PurchaseOrderGateway, data: PayloadAction<{ id: string, emails: string }>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const totalEmails = data.payload.emails.split(',');
    const trimmedEmails = totalEmails.map((item) => item.trim()).filter((item) => item.length > 0);

    let hasInvalidEmails = false;

    for (let i = 0; i < trimmedEmails.length; i += 1) {
        const item = trimmedEmails[i];

        const valid = Utils.Validators.validateEmail(item);

        if (!valid) {
            yield put(Actions.poSendPurchaseOrderFailure(`${item} is not a valid email.`));
            hasInvalidEmails = true;
            break;
        }
    }

    if (hasInvalidEmails) return;

    const response = yield* call([api, api.sendPurchaseOrder], {
        authToken,
        id: data.payload.id,
        supplierEmails: trimmedEmails,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.poSendPurchaseOrderFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.poSendPurchaseOrderSuccess());
        yield put(Actions.poSetSendPoModalIsOpen(false));

        toast.success('PO sent.');
    }
}
