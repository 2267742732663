import { RootState } from 'redux/store';

import { ICompanyDetails, ICompanyList } from 'entities/company';

const getCompanyListAttempting = (state: RootState): boolean => state.company.actions.getCompanyList;
const getCompanyListError = (state: RootState): string => state.company.error.getCompanyList;
const getCompanyList = (state: RootState): ICompanyList[] => state.company.companyList;

const getCompanyDetailsAttempting = (state: RootState): boolean => state.company.actions.getCompanyList;
const getCompanyDetailsError = (state: RootState): string => state.company.error.getCompanyList;
const getCompanyDetails = (state: RootState): ICompanyDetails | null => state.company.companyDetails;

export default {
    getCompanyListAttempting,
    getCompanyListError,
    getCompanyList,
    getCompanyDetailsAttempting,
    getCompanyDetailsError,
    getCompanyDetails,
};
