import _ from 'lodash';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import ProductsGateway from 'api/Products';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { GetProductVariantPayload } from 'redux/slices/products/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetProductVariant(api: ProductsGateway): SagaWatcherReturnType {
    yield takeEvery('products/productsGetProductVariantAttempt', handleGetProductVariant, api);
}

export function checkDuplicateOrEmpty(array: string[]): string[] | [] {
    // Early return for empty or array of all empty strings
    if (_.isEmpty(array) || _.every(array, _.isEmpty)) {
        return [];
    }

    // Use Lodash functions for efficient processing
    return _.uniq(_.compact(array));
}

function* handleGetProductVariant(api: ProductsGateway, data: GetProductVariantPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getProductVariant], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.productsGetProductVariantFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.productsGetProductVariantSuccess(response.data));

        const type = response.data.map(item => (item.type ? item.type : ''));
        const fit = response.data.map(item => (item.fit ? item.fit : ''));
        const sleeve = response.data.map(item => (item.sleeve ? item.sleeve : ''));
        const style = response.data.map(item => (item.style ? item.style : ''));
        const color = response.data.map(item => (item.color ? item.color : ''));

        yield put(Actions.productSetProductVariantType({
            type: checkDuplicateOrEmpty(type),
            fit: checkDuplicateOrEmpty(fit),
            sleeve: checkDuplicateOrEmpty(sleeve),
            style: checkDuplicateOrEmpty(style),
            color: checkDuplicateOrEmpty(color),
        }));
    }
}
