import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { CreateShipmentTrackingPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';

export default function* watchCreateShipmentTracking(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderCreateShipmentTrackingAttempt', handleCreateShipmentTracking, api);
}
function* handleCreateShipmentTracking(api: OrderGateway, data: CreateShipmentTrackingPayload) {
    const authToken = yield* select((state) => state.auth.authToken);

    const response = yield* call([api, api.createShipmentTracking], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderCreateShipmentTrackingFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderCreateShipmentTrackingSuccess());
        // yield put(Actions.orderGetOrderCommentAttempt({ orderId: data.payload.orderId, index: 0 }));
        NavActions.navToOrderDetails(data.payload.orderId);
        toast.success('Tracking added!');
    }
}
