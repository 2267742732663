import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchSetConfirmPayment(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderSetConfirmPaymentAttempt', handleSetConfirmPayment, api);
}
function* handleSetConfirmPayment(api: OrderGateway, data: PayloadAction<{ id: string }>) {
    const response = yield* call([api, api.setConfirmPayment], {
        orderId: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderSetConfirmPaymentFailure(response.message || ''));
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderSetConfirmPaymentSuccess(response.data));
    }
}
