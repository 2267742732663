import React, { useEffect } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

import config from 'config';

import LoadingIndicator from 'components/atoms/LoadingIndicator';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AuthLayout from 'components/Layout/AuthLayout';
import Routes from 'navigation/Routes';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelector from 'redux/slices/auth/Selectors';

import media from 'lib/Breakpoints';

import theme from 'styles/theme';

import PackageJson from '../../package.json';

const RootApp = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(AuthSelector.getStartupAttempting);

    useEffect(() => {
        dispatch(Actions.authStartupAttempt());

        console.log(`Version: ${PackageJson.version} (${config.env})`);
    }, []);

    if (loading) {
        return (
            <AuthLayout>
                <LoadingIndicator />
            </AuthLayout>
        );
    }

    const renderError = (error: Error) => {
        return (
            <AuthLayout>
                <MainContainer>
                    <Text variant={TextVariant.h2}>
                        Sorry, something went wrong on our end.
                        <br />
                        Please try again or contact us at liew@double.my
                    </Text>
                    <Text variant={TextVariant.error}>
                        {error.message}
                    </Text>
                </MainContainer>
            </AuthLayout>
        );
    };

    return (
        <ErrorBoundary fallbackRender={({ error }) => renderError(error)}>
            <Routes />
        </ErrorBoundary>
    );
};

const MainContainer = styled.div`
    width: 35.5rem;
    height: 15rem;
    padding: 1rem;
    gap: 1rem;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: white;
    border-radius: 2rem;

    ${media.lg`
        width: 40rem;
        height: 20rem;
        padding: 1.5rem;
        gap: 1.8rem;
    `}

    ${media.xxl`
        width: 45rem;
        height: 25rem;
        padding: 2.5rem;
        gap: 3rem;
    `}
`;

export default RootApp;
