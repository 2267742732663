import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { UploadClientPoPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchUploadClientPo(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderUploadClientPoAttempt', handleUploadClientPo, api);
}
function* handleUploadClientPo(api: OrderGateway, data: UploadClientPoPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.uploadClientPo], {
        authToken,
        orderId: data.payload.orderId,
        receivedPurchaseOrder: data.payload.receivedPurchaseOrder,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderUploadClientPoFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderUploadClientPoSuccess(response.data));
        yield put(Actions.orderGetOrderDetailsAttempt({ id: data.payload.orderId }));

        toast.success('Client\'s Purchase Order uploaded');
    }
}
