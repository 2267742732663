import { fork } from 'redux-saga/effects';

import HomeGateway from 'api/Home';
import { RootSagaReturnType } from 'sagas/types';

import watchGetDashboardDetails from './getDashboardDetails';

export default (api: HomeGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetDashboardDetails, api);
    }

    return {
        rootSaga,
    };
};
