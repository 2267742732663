import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import SVG from 'react-inlinesvg';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Table, { IActionsArray, IHeaderArray } from 'components/atoms/Table';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import StackedAreaChart from 'components/molecules/Charts/StackedAreaChart';
import StatCard from 'components/molecules/StatCard';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/purchaseOrder/Selectors';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { PurchaseOrderStatusEnum } from 'entities/purchaseOrders';

import icons from 'assets/icons';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import LatestEnquiriesTable from './LatestEnquiriesTable';
import LatestOrdersTable from './LatestOrdersTable';

const HomeScreen = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { dashboardDetails } = useAppSelector((state) => state.home);

    useEffect(() => {
        dispatch(Actions.homeGetDashboardDetailsAttempt());
    }, []);

    return (
        <AdminLayout withBackground>
            <DashboardTitle variant={TextVariant.title}>
                Dashboard Overview
            </DashboardTitle>
            <OverviewContainer>
                <StatCard
                    mtd
                    label='Total Orders'
                    value={dashboardDetails.totalOrdersInfo.total.toString()}
                    icon={<CardIcon src={icons.cartIcon} color='#0d894f' />}
                    color='#CFE7DC'
                    percent={dashboardDetails.totalOrdersInfo.percentageChangeFromPrevMonth ?? 0}
                />
                <StatCard
                    mtd
                    label='Total Orders Sales'
                    value={`RM${Utils.Formatter.formatPrice(dashboardDetails.totalOrderSalesInfo.total)}`}
                    icon={<CardIcon src={icons.moneyCheckmarkIcon} color='#0b65ec' />}
                    color='#C0D4F3'
                    percent={dashboardDetails.totalOrderSalesInfo.percentageChangeFromPrevMonth ?? 0}
                />
                <StatCard
                    mtd
                    label='Total Members'
                    value={dashboardDetails.totalMembersInfo.total.toString()}
                    icon={<CardIcon src={icons.moneyCheckmarkIcon} color='#fc9736' />}
                    color='#FFDDBC'
                    percent={dashboardDetails.totalMembersInfo.percentageChangeFromPrevMonth ?? 0}
                />
            </OverviewContainer>
            <OverviewChartContainer>
                <OverviewChartTitle>
                    <Text>
                        Overview
                    </Text>
                    <LegendContainer>
                        <div id='orders'>
                            Total Orders
                        </div>
                        <div id='sales'>
                            Total Orders Sales
                        </div>
                        <div id='member'>
                            Total Member
                        </div>
                    </LegendContainer>
                </OverviewChartTitle>
                <StackedAreaChart chartData={dashboardDetails.chartData} style={{ padding: '0.5rem 0px 0.5rem 0.5rem' }} />
            </OverviewChartContainer>
            <LatestReportContainer>
                <div id='header'>
                    <Text variant={TextVariant.title}>
                        Latest Orders
                    </Text>
                    <RedirectButton
                        label={(
                            <div>
                                View All Orders
                                <LogoContainer src={icons.goIcon} color='white' />
                            </div>
                        )}
                        onClick={() => NavActions.navToMenu('/orders')}
                    />
                </div>
                <LatestOrdersTable />
            </LatestReportContainer>
            <LatestReportContainer>
                <div id='header'>
                    <Text variant={TextVariant.title}>
                        Latest Enquiries
                    </Text>
                    <RedirectButton
                        label={(
                            <div>
                                View All Enquiries
                                <LogoContainer src={icons.goIcon} color='white' />
                            </div>
                        )}
                        onClick={() => NavActions.navToEnquiries()}
                    />
                </div>
                <LatestEnquiriesTable />
            </LatestReportContainer>
        </AdminLayout>
    );
};

const DashboardTitle = styled(Text)`
    color: white;
`;

const OverviewContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1.5rem;

    margin-top: 1.5rem;
    min-height: 8.25rem;
`;

const OverviewChartContainer = styled.div`
    margin-top: 1.75rem;
    padding: 1.75rem;
    width: 100%;
    height: 50vh;
    background-color: white;
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const CardIcon = styled(SVG)`
    width: 1.5rem;
    height: 1.5rem;

    @media (max-width: 1440px) {
        width: 1rem;
        height: 1rem;
    }
`;

const OverviewChartTitle = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    > p {
        font-weight: 600;
    }
`;

const LegendContainer = styled.div`
    display: flex;
    gap: 2rem;

    #orders {
        &::before {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            background-color: #33BEAD;
        }

        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    #sales {
        &::before {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            background-color: #0b65ec;
        }

        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    #member {
        &::before {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            background-color: #fc9736;
        }

        display: flex;
        align-items: center;
        gap: 0.5rem;

    }
`;

const LatestReportContainer = styled.div`
    margin-top: 5.25rem;

    #header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
`;

const RedirectButton = styled(Button)`
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    > div {
        display: flex;
        align-items: center;
        gap: 0.75rem;
    }
`;

const LogoContainer = styled(SVG)`
    width: 1rem;
    height: 1rem;
`;

export default HomeScreen;
