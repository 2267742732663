import { goBack, push, replace } from 'redux-first-history';

import { store } from 'redux/store';

import { EnquiryTypeEnum } from 'entities/enquiries';

const navBack = (): void => {
    store.dispatch(goBack());
};

const navPush = (routeName: string) => {
    store.dispatch(push(routeName));
};

const navReplace = (routeName: string) => {
    store.dispatch(replace(routeName));
};

const navResetToLogin = (): void => navReplace('/login');

const navToHome = (): void => navPush('/');

const navToForgotPassword = (): void => navPush('/forgot-password');

const navToResetPassword = (email: string, otp: string): void => navPush(`/reset-password?email=${email}&otp=${otp}`);

const navToChangePassword = (): void => navPush('/change-password');

const navToMenu = (url: string): void => navPush(url);

const navToOrder = (): void => navPush('/orders');
const navToAddNewOrder = (): void => navPush('/orders/new');
const navToEditOrder = (orderId: string): void => navPush(`/orders/edit/${orderId}`);
const navToOrderDetails = (orderId: string, replaceHistory?: boolean): void => {
    if (replaceHistory) {
        navReplace(`/orders/${orderId}`);
    } else {
        navPush(`/orders/${orderId}`);
    }
};
const navToPaymentOrder = (orderId: string): void => navPush(`/orders/payment/${orderId}`);
const navToAddShipmentTracking = (orderId: string, trackingId?: string): void => {
    if (trackingId) {
        navPush(`/orders/tracking/${orderId}/${trackingId}`);
    } else {
        navPush(`/orders/tracking/${orderId}`);
    }
};

const navToQuotation = (): void => navPush('/quotations');
const navToAddQuotations = (): void => navPush('/quotations/new');
const navToEditQuotation = (quotationId: string): void => navPush(`/quotations/edit/${quotationId}`);
const navToQuotationDetails = (quotationId: string, replaceHistory?: boolean): void => {
    if (replaceHistory) {
        navReplace(`/quotations/${quotationId}`);
    } else {
        navPush(`/quotations/${quotationId}`);
    }
};

const navToEnquiries = (): void => navReplace('/enquiries');
const navToEnquiryDetails = (id: string, type: EnquiryTypeEnum): void => navPush(`/enquiries/${id}?type=${type}`);

const navToUserManagement = (): void => navPush('/user-management');
const navToAddNewAdmin = (): void => navPush('/user-management/add-admin');
const navToEditAdmin = (userManagementId: string): void => navPush(`/user-management/edit/${userManagementId}`);

const navToSupplier = (): void => navPush('/suppliers');
const navToCreateSupplier = (): void => navPush('/suppliers/add');
const navToSupplierDetails = (supplierId: string): void => navPush(`/suppliers/${supplierId}`); // no page for this yet.
const navToEditSupplier = (supplierId: string): void => navPush(`/suppliers/edit/${supplierId}`);
const navReplaceToEditSupplier = (supplierId: string): void => navReplace(`/suppliers/edit/${supplierId}`);

const navToPurchaseOrders = (): void => navPush('/purchase-orders');
const navToPurchaseOrdersDetails = (id: string): void => navPush(`/purchase-orders/${id}`);
const navToCreatePurchaseOrder = (): void => navPush('/purchase-orders/new');
const navToEditPurchaseOrder = (id: string): void => navPush(`/purchase-orders/edit/${id}`);

const navToCustomers = (): void => navPush('/customers');
const navToCustomerEdit = (customerId: string): void => navPush(`/customers/edit/${customerId}`);
const navToCustomerCreate = (): void => navPush('/customers/add');
const navReplaceToCustomerEdit = (customerId: string): void => navReplace(`/customers/edit/${customerId}`);

export default {
    navBack,

    navResetToLogin,

    navToHome,

    navToForgotPassword,

    navToResetPassword,

    navToChangePassword,

    navToMenu,

    navToOrder,
    navToAddNewOrder,
    navToEditOrder,
    navToOrderDetails,

    navToPaymentOrder,
    navToAddShipmentTracking,

    navToQuotation,
    navToAddQuotations,
    navToEditQuotation,
    navToQuotationDetails,

    navToEnquiries,
    navToEnquiryDetails,

    navToUserManagement,
    navToAddNewAdmin,
    navToEditAdmin,

    navToSupplier,
    navToCreateSupplier,
    navToSupplierDetails,
    navToEditSupplier,
    navReplaceToEditSupplier,

    navToPurchaseOrders,
    navToPurchaseOrdersDetails,
    navToCreatePurchaseOrder,
    navToEditPurchaseOrder,

    navToCustomers,
    navToCustomerEdit,
    navToCustomerCreate,
    navReplaceToCustomerEdit,
};
