import React from 'react';
import '@fontsource/manrope';

import RootApp from 'containers/RootApp';
import Toast from 'components/atoms/Toast';

import ThemeProvider from 'styles/ThemeProvider';
import '@fontsource/manrope/500.css';
import '@fontsource/manrope/700.css';

const App = (): JSX.Element => {
    return (
        <ThemeProvider>
            <RootApp />
            <Toast />
        </ThemeProvider>
    );
};

export default App;
