import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { UpdateOrderDetailsPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchUpdateOrderDetails(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderUpdateOrderDetailsAttempt', handleUpdateOrderDetails, api);
}
function* handleUpdateOrderDetails(api: OrderGateway, data: UpdateOrderDetailsPayload) {
    const authToken = yield* select((state) => state.auth.authToken);

    const response = yield* call([api, api.updateOrder], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        if (response.code === '9105') {
            toast.error('quickbook is not connected. please connect and try again');
        } else {
            toast.error('something went wrong. please try again');
        }

        yield put(Actions.orderUpdateOrderDetailsFailure(response.message || ''));
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderUpdateOrderDetailsSuccess());
        yield put(Actions.orderSetEditOrderCommentModalIsOpen(false));
        yield put(Actions.orderGetOrderDetailsAttempt({ id: data.payload.id }));
        toast.success('Order updated!');
    }
}
