import React, { useEffect } from 'react';

import PageHeader from 'components/atoms/PageHeader';
import AdminLayout from 'components/Layout/AdminLayout';
import Form, { FormFields } from 'components/molecules/Form';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import OrderSelectors from 'redux/slices/order/Selectors';
import PoSelectors from 'redux/slices/purchaseOrder/Selectors';
import UiSelectors from 'redux/slices/ui/Selectors';

import { IAddress } from 'entities/address';

const NewPurchaseOrderScreen = (): JSX.Element => {
    const newPoDetailsFromRedux = useAppSelector(PoSelectors.getPurchaseOrderNewOrEditPurchaseOrder);
    const createPoLoading = useAppSelector(PoSelectors.getCreatePurchaseOrderAttempting);
    const createPoError = useAppSelector(PoSelectors.getCreatePurchaseOrderError);

    const orderDetails = useAppSelector(OrderSelectors.getOrderDetailsData);
    const supplierList = useAppSelector(UiSelectors.getUiSearchSuppliers);

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(Actions.poResetNewPoParams());
            dispatch(Actions.orderResetOrderDetails());
        };
    }, []);

    useEffect(() => {
        if (orderDetails) {
            dispatch(Actions.poUpdateNewPoParams({
                companyAccountId: orderDetails?.companyAccountId,
                deliveryAddress: orderDetails?.shippingAddress,
                deliveryCompanyName: orderDetails?.companyName,
                orderId: orderDetails?.id,
                orderNo: orderDetails?.orderNo,
            }));

            dispatch(Actions.uiSearchForCustomersAttempt({
                currentPage: -1,
                searchQuery: '',
            }));
            // ? dont need this for po for now.
            // dispatch(Actions.productSetDiscountPercent(orderDetails?.discountPercent?.toString() ?? undefined));
            // dispatch(Actions.productSetDiscountFixed(orderDetails?.discountFixed?.toString() ?? undefined));
            // dispatch(Actions.productSetShippingCost(orderDetails?.shippingFee?.toString() ?? undefined));
        }
    }, [orderDetails]);

    const formFieldsNeeded: FormFields = {
        companyAccount: {
            enabled: true, mandatory: true,
        },
        poDate: {
            enabled: true, mandatory: true,
        },
        supplierName: {
            enabled: true, mandatory: true,
        },
        supplierAddress: {
            enabled: true, mandatory: true,
        },
        notes: {
            enabled: true,
        },
        deliveryCompanyName: {
            enabled: true,
        },
        deliveryAddress: {
            enabled: true, mandatory: true,
        },
        productList: {
            enabled: true,
        },
    };

    const onCompanyAccountChanged = (companyAccountId: string) => {
        if (newPoDetailsFromRedux.companyAccountId !== companyAccountId) {
            // reset product selector if company account is changed
            dispatch(Actions.productResetProductSelector());
        }
        dispatch(Actions.poUpdateNewPoParams({ companyAccountId, supplierAddress: undefined, supplierId: undefined }));
        dispatch(Actions.uiResetSupplierAddress());
    };

    const onPoDateChanged = (poDate: string) => {
        dispatch(Actions.poUpdateNewPoParams({ poDate }));
    };

    const onSupplierChanged = (supplierId: string) => {
        const selectedSupplier = supplierList.find((item) => item.id === supplierId);
        const supplierName = selectedSupplier?.supplierName || '';

        dispatch(Actions.poUpdateNewPoParams({ supplierId, supplierName }));
    };

    const onSupplierAddressChanged = (supplierAddress: IAddress) => {
        dispatch(Actions.poUpdateNewPoParams({ supplierAddress }));
    };

    const onDeliveryCompanyNameChanged = (deliveryCompanyName: string) => {
        dispatch(Actions.poUpdateNewPoParams({ deliveryCompanyName }));
    };

    const onDeliveryAddressChanged = (deliveryAddress: Partial<IAddress>) => {
        const newDeliveryAddress: IAddress = {
            ...deliveryAddress,
            street1: deliveryAddress.street1 || '',
            street2: deliveryAddress.street2 || undefined,
            city: deliveryAddress.city || '',
            state: deliveryAddress.state || '',
            postcode: deliveryAddress.postcode || '',
            country: deliveryAddress.country || '',
        };
        dispatch(Actions.poUpdateNewPoParams({ deliveryAddress: newDeliveryAddress }));
    };

    const onNoteChanged = (note: string) => {
        dispatch(Actions.poUpdateNewPoParams({ note }));
    };

    const onSave = () => {
        dispatch(Actions.poCreatePurchaseOrderAttempt());
    };

    const renderPageHeader = () => {
        if (newPoDetailsFromRedux.orderNumber) {
            return (
                <PageHeader withBackButton>
                    {`Convert Order ${newPoDetailsFromRedux.orderNumber} to Purchase Order`}
                </PageHeader>
            );
        }
        return (
            <PageHeader withBackButton>
                Add New Purchase Order
            </PageHeader>
        );
    };

    return (
        <AdminLayout>
            {renderPageHeader()}

            <Form
                module='purchaseOrder'
                loading={createPoLoading}
                error={createPoError}
                formFields={formFieldsNeeded}
                values={newPoDetailsFromRedux}
                handlers={{
                    onCompanyAccountChanged,
                    onPoDateChanged,
                    onSupplierChanged,
                    onSupplierAddressChanged,
                    onNoteChanged,
                    onDeliveryAddressChanged,
                    onDeliveryCompanyNameChanged,
                    onSave,
                }}
            />
        </AdminLayout>
    );
};

export default NewPurchaseOrderScreen;
