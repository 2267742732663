import { RootState } from 'redux/store';

import { IAuthUser } from 'entities/auth';

const getStartupAttempting = (state: RootState): boolean => state.auth.actions.startup || false;
const getStartupError = (state: RootState): string => state.auth.error.startup || '';

const getLoginAttempting = (state: RootState): boolean => state.auth.actions.login || false;
const getLoginError = (state: RootState): string => state.auth.error.login || '';

const getForgotPasswordAttempting = (state: RootState): boolean => state.auth.actions.forgotPassword || false;
const getForgotPasswordError = (state: RootState): string => state.auth.error.forgotPassword || '';

const getChangePasswordAttempting = (state: RootState): boolean => state.auth.actions.changePassword || false;
const getChangePasswordError = (state: RootState): string => state.auth.error.changePassword || '';

const getAuthToken = (state: RootState): string => state.auth.authToken || '';
const getRequestResetPassword = (state: RootState): boolean => state.auth.requestResetPassword || false;

const getUserInfo = (state: RootState): IAuthUser | null => state.auth.userInfo || null;

export default {
    getStartupAttempting,
    getStartupError,

    getLoginAttempting,
    getLoginError,

    getForgotPasswordAttempting,
    getForgotPasswordError,

    getChangePasswordAttempting,
    getChangePasswordError,

    getAuthToken,
    getRequestResetPassword,

    getUserInfo,
};
