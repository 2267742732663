import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import Selectors from 'redux/slices/order/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetOrderReport(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderGetOrderReportAttempt', handleGetOrderReport, api);
}
function* handleGetOrderReport(api: OrderGateway) {
    const authToken = yield* select((state) => state.auth.authToken);

    const orderFilters = yield* select(Selectors.getOrdersFilters);

    let response;

    if (orderFilters.dateStart === '') {
        response = yield* call([api, api.getOrderReport], {
            authToken,
            index: orderFilters.currentPage,
            searchQuery: orderFilters.searchQuery,
            status: orderFilters.orderStatus,
        });
    } else {
        response = yield* call([api, api.getOrderReport], {
            authToken,
            index: orderFilters.currentPage,
            searchQuery: orderFilters.searchQuery,
            startDate: orderFilters.dateStart,
            endDate: orderFilters.dateEnd,
            status: orderFilters.orderStatus,
        });
    }

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderGetOrderReportFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderGetOrderReportSuccess(response.data));
    }
}
