import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { SendPaymentLinkPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchSendPaymentLink(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderSendPaymentLinkAttempt', handleSendPaymentLink, api);
}
function* handleSendPaymentLink(api: OrderGateway, data: SendPaymentLinkPayload) {
    const authToken = yield* select((state) => state.auth.authToken);

    const response = yield* call([api, api.sendPaymentLink], {
        authToken,
        orderId: data.payload.orderId,
    });

    if (response.status === GatewayResponseStatus.Error) {
        if (response.error) {
            toast.error(response.error);
        } else {
            toast.error('failed to send payment link');
        }
        yield put(Actions.orderSendPaymentLinkFailure(response.message || ''));
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderSendPaymentLinkSuccess());
        toast.success('Payment link sent!');
    }
}
