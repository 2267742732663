import React from 'react';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/ui/Selectors';

import InputWithSearch from '../InputWithSearch';

interface SupplierInputProps {
    disabled?: boolean;
    value?: string; // supplierId
    supplierName?: string;
    mandatory?: boolean;

    inputError?: boolean;

    onSupplierChanged: (supplierId: string) => void;

    companyId?: string;
}

const SupplierInput = (props: SupplierInputProps): JSX.Element => {
    const { disabled, value, supplierName, onSupplierChanged, mandatory = false, inputError, companyId } = props;

    const dispatch = useAppDispatch();

    const supplierList = useAppSelector(Selectors.getUiSearchSuppliers);
    const supplierListLoading = useAppSelector(Selectors.getUiSearchSuppliersAttempting);
    const supplierListError = useAppSelector(Selectors.getUiSearchSuppliersError);

    const onSupplierSearch = (searchQuery: string) => {
        dispatch(Actions.uiSearchForSuppliersAttempt({ searchQuery, currentPage: -1 }));
    };

    const onSupplierSelected = (supplierId: string | number) => {
        if (onSupplierChanged) onSupplierChanged(supplierId as string);
    };

    const supplierCompanyFiltered = supplierList.filter((supplier) => supplier.companyAccountId === companyId as string);

    const supplierOptions = supplierCompanyFiltered.map((item) => ({
        label: item.supplierName,
        value: item.id,
    }));

    // If we're editing a field where a supplier was already selected before this,
    // we won't have the supplier's data in redux (as we have not gotten a list of suppliers yet).
    // If this happens, we insert the supplier as the default option.
    if ((!supplierList || !supplierList.length) && value && supplierName) {
        supplierOptions.push({
            label: supplierName,
            value,
        });
    }

    return (
        <InputWithSearch
            inputError={inputError}
            label='Supplier'
            disabled={disabled}
            value={value}
            data={supplierOptions}
            onSelect={onSupplierSelected}
            onSearch={onSupplierSearch}
            loading={supplierListLoading}
            error={supplierListError}
            emptyLabel='Start typing to search for suppliers.'
            noDataFoundLabel="We can't seem to find this supplier. Please create a new one in Suppliers page."
            mandatory={mandatory}
            companyId={companyId}
            requiredCompanyId
        />
    );
};

export default SupplierInput;
