import React, { useEffect } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import ErrorMessage from 'components/atoms/ErrorMessage';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import PageHeader from 'components/atoms/PageHeader';
import StatusBadge from 'components/atoms/StatusBadge';
import Text from 'components/atoms/Text';
import { BadgeVariant } from 'components/atoms/variants/BadgeVariant';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AdminLayout from 'components/Layout/AdminLayout';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import EnquiresSelectors from 'redux/slices/enquiries/Selectors';

import NavActions from 'lib/NavActions';
import { AuthRoleEnum } from 'entities/auth';
import { EnquiryStatusEnum, EnquiryTypeEnum } from 'entities/enquiries';

import theme from 'styles/theme';

import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import ConfirmResolveModal from './components/ConfirmResolveModal';

const EnquiryDetails = (): JSX.Element => {
    const [searchParams] = useSearchParams();
    const params = useParams();
    const dispatch = useAppDispatch();

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);
    const loading = useAppSelector(EnquiresSelectors.getEnquiryDetailsAttempting);
    const error = useAppSelector(EnquiresSelectors.getEnquiryDetailsError);

    const enquiry = useAppSelector(EnquiresSelectors.getEnquiryDetails);

    const enquiryType = Number(searchParams.get('type'));
    const enquiryId = params.id;

    const isValidEnquiry = () => {
        if (!enquiryId || !enquiryType || Number.isNaN(enquiryType) || !EnquiryTypeEnum[enquiryType]) return false;
        return true;
    };

    useEffect(() => {
        if (isValidEnquiry()) {
            dispatch(Actions.enquiriesGetEnquiryDetailsAttempt({ id: enquiryId || '', type: enquiryType }));
        }
    }, []);

    if (!isValidEnquiry() || error) {
        let errorMessage = 'Sorry, we could not find this enquiry. It might have been deleted/moved.';

        if (error) errorMessage = error;

        return (
            <AdminLayout>
                <ErrorContainer>
                    <ErrorMessage error={errorMessage} />

                    <Button
                        label='Back to all enquiries'
                        onClick={NavActions.navToEnquiries}
                        style={{ width: 250 }}
                    />
                </ErrorContainer>
            </AdminLayout>
        );
    }

    if (loading) {
        return (
            <ErrorContainer>
                <LoadingIndicator />
            </ErrorContainer>
        );
    }

    const onDeletePressed = () => {
        dispatch(Actions.enquiriesSetDeleteConfirmationModalIsOpen(true));
    };

    const onResolvePressed = () => {
        dispatch(Actions.enquiriesSetResolveConfirmationModalIsOpen(true));
    };

    const renderActions = () => {
        if (!enquiry) return null;
        if (userInfo?.role === AuthRoleEnum.Executive) return null;

        return (
            <ButtonRow>
                <Button
                    label='Delete Message'
                    variant={ButtonVariant.warning}
                    style={{ width: 220 }}
                    onClick={onDeletePressed}
                />
                { enquiry.status !== EnquiryStatusEnum.Resolved && (
                    <Button
                        label='Resolve Message'
                        variant={ButtonVariant.default}
                        style={{ width: 220 }}
                        onClick={onResolvePressed}
                    />
                )}
            </ButtonRow>
        );
    };
    const renderEnquiry = () => {
        if (!enquiry) return null;

        return (
            <>
                <EnquiryContainer>
                    <Text variant={TextVariant.h2} color={theme.fontColor.primary}>
                        Customer Details
                    </Text>

                    <CustomerContainer>
                        <CustomerKeyContainer>
                            <CustomerKeyText>Name:</CustomerKeyText>
                            <CustomerKeyText>Email:</CustomerKeyText>
                            <CustomerKeyText>Phone No:</CustomerKeyText>
                        </CustomerKeyContainer>

                        <CustomerValueContainer>
                            <Text>{enquiry.name}</Text>
                            <Text>{enquiry.email}</Text>
                            <Text>{enquiry.phoneNumber}</Text>
                        </CustomerValueContainer>
                    </CustomerContainer>

                    <Separator />

                    <MessageTitleContainer>
                        <Text variant={TextVariant.h2}>
                            Message
                        </Text>
                        <BadgeContainer>
                            <StatusBadge variant={EnquiryStatusEnum[enquiry.status] as BadgeVariant} />
                        </BadgeContainer>
                    </MessageTitleContainer>

                    <MessageContainer>
                        <CustomerKeyText>
                            {enquiry.subject}
                        </CustomerKeyText>

                        <Text>
                            {enquiry.message}
                        </Text>
                    </MessageContainer>
                </EnquiryContainer>
                {renderActions()}

            </>
        );
    };

    let pageTitle;

    if (Number(enquiryType) === EnquiryTypeEnum.HomePage) pageTitle = 'Home Page Inquiry';
    else pageTitle = 'Design Services Inquiry';

    return (
        <AdminLayout>
            <PageHeader withBackButton>
                {pageTitle}
            </PageHeader>

            {renderEnquiry()}

            <ConfirmResolveModal
                enquiryId={enquiryId}
                enquiryType={enquiry?.type}
                enquirySubject={enquiry?.subject}
            />
            <ConfirmDeleteModal
                enquiryId={enquiryId}
                enquiryType={enquiry?.type}
                enquirySubject={enquiry?.subject}
            />
        </AdminLayout>
    );
};

const ErrorContainer = styled.div`
    height: 400px;
    width: 100%;

    padding: 32px;

    background-color: white;
    border-radius: 15px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    text-align: center;
`;

const EnquiryContainer = styled.div`
    border-radius: 15px;
    margin-top: 20px;

    padding: 26px 40px;

    background-color: white;
`;

const CustomerContainer = styled.div`
    display: flex;
    flex-direction: row;

    margin-top: 20px;
`;

const CustomerKeyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;

    width: 160px;
`;

const CustomerKeyText = styled(Text)`
    font-weight: 700;
    color: ${theme.fontColor.primary};
`;

const CustomerValueContainer = styled.div`
    display: flex;
    flex-direction: column;

    gap: 6px;
`;

const Separator = styled.div`
    height: 1px;

    margin: 26px -40px;

    background-color: ${theme.colors.border};
`;

const MessageTitleContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
`;

const BadgeContainer = styled.div`
    margin-left: 24px;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 22px;

    gap: 6px;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 8px;

    margin-top: 24px;
`;

export default EnquiryDetails;
