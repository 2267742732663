import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { GetAllShipmentTrackingResponse, GetOrderCommentResponse, GetOrderReportResponse, GetTimelineResponse } from 'api/OrderBase';

import { IOrder, IShipmentTrackingDetails } from 'entities/order';

import {
    ConvertOrderStatusToPaidPayload,
    CreateOrderCommentPayload,
    CreateOrderUpdateParamsPayload,
    CreateShipmentTrackingPayload,
    DeleteClientPoPayload,
    DeleteOrderAttachmentFolderPayload,
    DeleteOrderAttachmentPayload,
    DeleteOrderCommentPayload,
    DeletePaymentProofPayload,
    DeleteShipmentTrackingPayload,
    ExportCommercialInvoicePayload,
    ExportDeliveryOrderPayload,
    GetAllShipmentTrackingPayload,
    GetOrderAttachmentFolderNamePayload,
    GetOrderCommentPayload,
    GetOrdersPayload,
    GetPaymentLinkPayload,
    GetShipmentTrackingDetailsPayload,
    OrderReduxState,
    SendPaymentLinkPayload,
    UpdateOrderCommentPayload,
    UpdateOrderDetailsPayload,
    UpdateOrderStatusPayload,
    UpdatePaymentTermsPayload,
    UpdateProcessOrderPayload,
    UpdateShipmentTrackingPayload,
    UploadClientPoPayload,
    UploadOrderAttachmentPayload,
    UploadPaymentProofPayload,
} from './types';

const initialState: OrderReduxState = {
    actions: {
        getOrderReport: false,
        createOrder: false,
        updateOrder: false,
        deleteOrder: false,
        exportOrder: false,
        getOrderDetails: false,
        getTimeline: false,
        updateOrderStatus: false,
        updateProcessOrder: false,
        updatePaymentTerms: false,
        getOrderComment: false,
        createOrderComment: false,
        deleteOrderComment: false,
        updateOrderComment: false,
        uploadClientPo: false,
        deleteClientPo: false,
        uploadPaymentProof: false,
        deletePaymentProof: false,
        uploadOrderAttachment: false,
        deleteOrderAttachment: false,
        deleteOrderAttachmentFolder: false,
        getAllShipmentTracking: false,
        getShipmentTrackingDetails: false,
        createShipmentTracking: false,
        updateShipmentTracking: false,
        deleteShipmentTracking: false,
        getOrderDetailsForPayment: false,
        setConfirmPayment: false,
        getPaymentLink: false,
        sendPaymentLink: false,
        exportDeliveryOrder: false,
        exportCommercialInvoice: false,
        convertOrderStatusToPaid: false,
        convertOrderStatusToUnpaid: false,
        getOrderAttachmentFolderName: false,
    },
    filters: {
        currentPage: 0,
        dateStart: '',

        searchQuery: undefined,
        orderStatus: undefined,
    },
    orders: {
        index: 0,
        maxIndex: 0,
        totalRecords: 0,
        data: [],
    },
    orderDetails: null,
    timeline: [],
    createOrEditOrder: {
        companyAccountId: undefined,
        createdAt: dayjs().startOf('day').toISOString(),
        clientId: undefined,
        clientName: undefined,
        clientEmail: undefined,
        clientNumber: undefined,
        projectName: undefined,
        projectDueDate: dayjs().startOf('day').toISOString(),
        paymentTerm: undefined,
        paymentDueDate: dayjs().startOf('day').toISOString(),
        shippingAddress: undefined,
        shippingMethod: undefined,
        billingAddress: undefined,
        paymentMethod: undefined,
        note: undefined,
        remark: undefined,
        products: [],
    },
    orderComment: {
        index: 0,
        maxIndex: 0,
        totalRecords: 0,
        data: [],
    },
    orderCommentDetails: null,
    allShipmentTracking: [],
    shipmentTrackingDetails: null,
    paymentLink: null,
    orderAttachmentFolderName: [],
    ui: {
        editOrderCommentModalIsOpen: false,
        confirmationDeleteModalIsOpen: false,
        uploadAttachmentModalIsOpen: false,
        uploadPaymentProofModalIsOpen: false,
        deletePaymentProofModalIsOpen: false,
        deleteShipmentTrackingModalIsOpen: false,
        updateShippingAddressModalIsOpen: false,
        updateBillingAddressModalIsOpen: false,
        deleteAttachmentFolderModalIsOpen: false,
        convertOrderStatusToPaidModalIsOpen: false,
        convertOrderStatusToUnpaidModalIsOpen: false,
    },
    error: {
        getOrderReport: '',
        createOrder: '',
        updateOrder: '',
        deleteOrder: '',
        exportOrder: '',
        getOrderDetails: '',
        getTimeline: '',
        updateOrderStatus: '',
        updateProcessOrder: '',
        updatePaymentTerms: '',
        getOrderComment: '',
        createOrderComment: '',
        deleteOrderComment: '',
        updateOrderComment: '',
        uploadClientPo: '',
        deleteClientPo: '',
        uploadPaymentProof: '',
        deletePaymentProof: '',
        uploadOrderAttachment: '',
        deleteOrderAttachment: '',
        deleteOrderAttachmentFolder: '',
        getAllShipmentTracking: '',
        getShipmentTrackingDetails: '',
        createShipmentTracking: '',
        updateShipmentTracking: '',
        deleteShipmentTracking: '',
        getOrderDetailsForPayment: '',
        setConfirmPayment: '',
        getPaymentLink: '',
        sendPaymentLink: '',
        exportDeliveryOrder: '',
        exportCommercialInvoice: '',
        convertOrderStatusToPaid: '',
        convertOrderStatusToUnpaid: '',
        getOrderAttachmentFolderName: '',
    },
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        orderUpdateNewOrderParams: (state, action: CreateOrderUpdateParamsPayload) => {
            state.createOrEditOrder = {
                ...state.createOrEditOrder,
                ...action.payload,
            };
        },
        orderResetNewOrderParams: (state) => {
            state.actions = initialState.actions;
            state.error = initialState.error;
            state.createOrEditOrder = initialState.createOrEditOrder;
        },
        orderResetNewOrderClientParams: (state) => {
            state.createOrEditOrder = {
                ...state.createOrEditOrder,
                clientId: undefined,
                clientName: undefined,
                clientEmail: '',
                clientNumber: '',
                shippingAddress: undefined,
                billingAddress: undefined,
            };
        },
        orderResetOrdersFilters: (state) => {
            state.filters = initialState.filters;
        },
        orderSetFilters: (state, action: GetOrdersPayload) => {
            const { currentPage, dateEnd, dateStart, searchQuery, orderStatus } = action.payload;

            if (currentPage !== undefined) state.filters.currentPage = currentPage;
            if (dateStart) {
                state.filters.dateStart = dateStart;
                state.filters.dateEnd = undefined;
            }
            if (dateEnd) {
                state.filters.dateEnd = dateEnd;
                state.filters.currentPage = 0;
            }
            if (searchQuery !== undefined) {
                if (!searchQuery.length) state.filters.searchQuery = undefined;
                else state.filters.searchQuery = searchQuery;
            }
            if (orderStatus !== undefined) {
                if (orderStatus > 0) {
                    state.filters.orderStatus = orderStatus;
                    state.filters.currentPage = 0;
                } else {
                    state.filters.orderStatus = undefined;
                    state.filters.currentPage = 0;
                }
            }
        },
        orderResetOrderAttachmentFolderName: (state) => {
            state.orderAttachmentFolderName = [];
        },
        orderGetOrderReportAttempt: (state) => {
            state.actions.getOrderReport = true;
            state.error.getOrderReport = '';
        },
        orderGetOrderReportSuccess: (state, action: PayloadAction<GetOrderReportResponse>) => {
            state.actions.getOrderReport = false;
            state.orders = action.payload;
        },
        orderGetOrderReportFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getOrderReport = false;
            if (action.payload) {
                state.error.getOrderReport = action.payload;
            }
        },
        orderGetOrderDetailsAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.getOrderDetails = true;
            state.error.getOrderDetails = '';
        },
        orderGetOrderDetailsSuccess: (state, action: PayloadAction<IOrder>) => {
            state.actions.getOrderDetails = false;
            state.orderDetails = action.payload;
        },
        orderGetOrderDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getOrderDetails = false;
            if (action.payload) {
                state.error.getOrderDetails = action.payload;
            }
        },
        orderGetOrderDetailsForPaymentAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.getOrderDetailsForPayment = true;
            state.error.getOrderDetailsForPayment = '';
        },
        orderGetOrderDetailsForPaymentSuccess: (state, action: PayloadAction<IOrder>) => {
            state.actions.getOrderDetailsForPayment = false;
            state.orderDetails = action.payload;
        },
        orderGetOrderDetailsForPaymentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getOrderDetailsForPayment = false;
            if (action.payload) {
                state.error.getOrderDetailsForPayment = action.payload;
            }
        },
        orderSetConfirmPaymentAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.setConfirmPayment = true;
            state.error.setConfirmPayment = '';
        },
        orderSetConfirmPaymentSuccess: (state) => {
            state.actions.setConfirmPayment = false;
        },
        orderSetConfirmPaymentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setConfirmPayment = false;
            if (action.payload) {
                state.error.setConfirmPayment = action.payload;
            }
        },
        orderGetTimelineAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.getTimeline = true;
            state.error.getTimeline = '';
        },
        orderGetTimelineSuccess: (state, action: PayloadAction<GetTimelineResponse[]>) => {
            state.actions.getTimeline = false;
            state.timeline = action.payload;
        },
        orderGetTimelineFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getTimeline = false;
            if (action.payload) {
                state.error.getTimeline = action.payload;
            }
        },
        orderCreateOrderAttempt: (state) => {
            state.actions.createOrder = true;
            state.error.createOrder = '';
        },
        orderCreateOrderSuccess: (state) => {
            state.actions.createOrder = false;
            state.error.createOrder = '';
        },
        orderCreateOrderFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createOrder = false;
            if (action.payload) {
                state.error.createOrder = action.payload;
            }
        },
        orderUpdateOrderDetailsAttempt: (state, _action: UpdateOrderDetailsPayload) => {
            state.actions.updateOrder = true;
            state.error.updateOrder = '';
        },
        orderUpdateOrderDetailsSuccess: (state) => {
            state.actions.updateOrder = false;
        },
        orderUpdateOrderDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateOrder = false;
            if (action.payload) {
                state.error.updateOrder = action.payload;
            }
        },
        orderDeleteOrderAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.deleteOrder = true;
            state.error.deleteOrder = '';
        },
        orderDeleteOrderSuccess: (state) => {
            state.actions.deleteOrder = false;
        },
        orderDeleteOrderFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteOrder = false;
            if (action.payload) {
                state.error.deleteOrder = action.payload;
            }
        },
        orderExportOrderAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.exportOrder = true;
            state.error.exportOrder = '';
        },
        orderExportOrderSuccess: (state) => {
            state.actions.exportOrder = false;
        },
        orderExportOrderFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.exportOrder = false;
            if (action.payload) {
                state.error.exportOrder = action.payload;
            }
        },
        orderUpdateOrderStatusAttempt: (state, _action: UpdateOrderStatusPayload) => {
            state.actions.updateOrderStatus = true;
            state.error.updateOrderStatus = '';
        },
        orderUpdateOrderStatusSuccess: (state) => {
            state.actions.updateOrderStatus = false;
        },
        orderUpdateOrderStatusFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateOrderStatus = false;
            if (action.payload) {
                state.error.updateOrderStatus = action.payload;
            }
        },
        orderUpdateProcessOrderAttempt: (state, _action: UpdateProcessOrderPayload) => {
            state.actions.updateProcessOrder = true;
            state.error.updateProcessOrder = '';
        },
        orderUpdateProcessOrderSuccess: (state) => {
            state.actions.updateProcessOrder = false;
        },
        orderUpdateProcessOrderFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateProcessOrder = false;
            if (action.payload) {
                state.error.updateProcessOrder = action.payload;
            }
        },
        orderUpdatePaymentTermsAttempt: (state, _action: UpdatePaymentTermsPayload) => {
            state.actions.updatePaymentTerms = true;
            state.error.updatePaymentTerms = '';
        },
        orderUpdatePaymentTermsSuccess: (state) => {
            state.actions.updatePaymentTerms = false;
        },
        orderUpdatePaymentTermsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updatePaymentTerms = false;
            if (action.payload) {
                state.error.updatePaymentTerms = action.payload;
            }
        },
        orderGetOrderCommentAttempt: (state, _action: GetOrderCommentPayload) => {
            state.actions.getOrderComment = true;
            state.error.getOrderComment = '';
        },
        orderGetOrderCommentSuccess: (state, action: PayloadAction<GetOrderCommentResponse>) => {
            state.actions.getOrderComment = false;
            state.orderComment = action.payload;
        },
        orderGetOrderCommentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getOrderComment = false;
            if (action.payload) {
                state.error.getOrderComment = action.payload;
            }
        },
        orderCreateOrderCommentAttempt: (state, _action: CreateOrderCommentPayload) => {
            state.actions.createOrderComment = true;
            state.error.createOrderComment = '';
        },
        orderCreateOrderCommentSuccess: (state) => {
            state.actions.createOrderComment = false;
        },
        orderCreateOrderCommentFailure: (state, action: PayloadAction<string>) => {
            state.actions.createOrderComment = false;
            if (action.payload) {
                state.error.createOrderComment = action.payload;
            }
        },
        orderDeleteOrderCommentAttempt: (state, _action: DeleteOrderCommentPayload) => {
            state.actions.deleteOrderComment = true;
            state.error.deleteOrderComment = '';
        },
        orderDeleteOrderCommentSuccess: (state) => {
            state.actions.deleteOrderComment = false;
        },
        orderDeleteOrderCommentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteOrderComment = false;
            if (action.payload) {
                state.error.deleteOrderComment = action.payload;
            }
        },
        orderUpdateOrderCommentAttempt: (state, _action: UpdateOrderCommentPayload) => {
            state.actions.updateOrderComment = true;
            state.error.updateOrderComment = '';
        },
        orderUpdateOrderCommentSuccess: (state) => {
            state.actions.updateOrderComment = false;
        },
        orderUpdateOrderCommentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateOrderComment = false;
            if (action.payload) {
                state.error.updateOrderComment = action.payload;
            }
        },
        orderUploadClientPoAttempt: (state, _action: UploadClientPoPayload) => {
            state.actions.uploadClientPo = true;
            state.error.uploadClientPo = '';
        },
        orderUploadClientPoSuccess: (state) => {
            state.actions.uploadClientPo = false;
        },
        orderUploadClientPoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.uploadClientPo = false;
            if (action.payload) {
                state.error.uploadClientPo = action.payload;
            }
        },
        orderDeleteClientPoAttempt: (state, _action: DeleteClientPoPayload) => {
            state.actions.deleteClientPo = true;
            state.error.deleteClientPo = '';
        },
        orderDeleteClientPoSuccess: (state) => {
            state.actions.deleteClientPo = false;
        },
        orderDeleteClientPoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteClientPo = false;
            if (action.payload) {
                state.error.deleteClientPo = action.payload;
            }
        },
        orderUploadPaymentProofAttempt: (state, _action: UploadPaymentProofPayload) => {
            state.actions.uploadPaymentProof = true;
            state.error.uploadPaymentProof = '';
        },
        orderUploadPaymentProofSuccess: (state) => {
            state.actions.uploadPaymentProof = false;
        },
        orderUploadPaymentProofFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.uploadPaymentProof = false;
            if (action.payload) {
                state.error.uploadPaymentProof = action.payload;
            }
        },
        orderDeletePaymentProofAttempt: (state, _action: DeletePaymentProofPayload) => {
            state.actions.deletePaymentProof = true;
            state.error.deletePaymentProof = '';
        },
        orderDeletePaymentProofSuccess: (state) => {
            state.actions.deletePaymentProof = false;
        },
        orderDeletePaymentProofFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deletePaymentProof = false;
            if (action.payload) {
                state.error.deletePaymentProof = action.payload;
            }
        },
        orderUploadOrderAttachmentAttempt: (state, _action: UploadOrderAttachmentPayload) => {
            state.actions.uploadOrderAttachment = true;
            state.error.uploadOrderAttachment = '';
        },
        orderUploadOrderAttachmentSuccess: (state) => {
            state.actions.uploadOrderAttachment = false;
        },
        orderUploadOrderAttachmentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.uploadOrderAttachment = false;
            if (action.payload) {
                state.error.uploadOrderAttachment = action.payload;
            }
        },
        orderDeleteOrderAttachmentAttempt: (state, _action: DeleteOrderAttachmentPayload) => {
            state.actions.deleteOrderAttachment = true;
            state.error.deleteOrderAttachment = '';
        },
        orderDeleteOrderAttachmentSuccess: (state) => {
            state.actions.deleteOrderAttachment = false;
        },
        orderDeleteOrderAttachmentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteOrderAttachment = false;
            if (action.payload) {
                state.error.deleteOrderAttachment = action.payload;
            }
        },
        orderDeleteOrderAttachmentFolderAttempt: (state, _action: DeleteOrderAttachmentFolderPayload) => {
            state.actions.deleteOrderAttachmentFolder = true;
            state.error.deleteOrderAttachmentFolder = '';
        },
        orderDeleteOrderAttachmentFolderSuccess: (state) => {
            state.actions.deleteOrderAttachmentFolder = false;
        },
        orderDeleteOrderAttachmentFolderFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteOrderAttachmentFolder = false;
            if (action.payload) {
                state.error.deleteOrderAttachmentFolder = action.payload;
            }
        },
        orderGetAllShipmentTrackingAttempt: (state, _action: GetAllShipmentTrackingPayload) => {
            state.actions.getAllShipmentTracking = true;
            state.error.getAllShipmentTracking = '';
        },
        orderGetAllShipmentTrackingSuccess: (state, action: PayloadAction<GetAllShipmentTrackingResponse>) => {
            state.actions.getAllShipmentTracking = false;
            state.allShipmentTracking = action.payload;
        },
        orderGetAllShipmentTrackingFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getAllShipmentTracking = false;
            if (action.payload) {
                state.error.getAllShipmentTracking = action.payload;
            }
        },
        orderGetShipmentTrackingDetailsAttempt: (state, _action: GetShipmentTrackingDetailsPayload) => {
            state.actions.getShipmentTrackingDetails = true;
            state.error.getShipmentTrackingDetails = '';
        },
        orderGetShipmentTrackingDetailsSuccess: (state, action: PayloadAction<IShipmentTrackingDetails>) => {
            state.actions.getShipmentTrackingDetails = false;
            state.shipmentTrackingDetails = action.payload;
        },
        orderGetShipmentTrackingDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getShipmentTrackingDetails = false;
            if (action.payload) {
                state.error.getShipmentTrackingDetails = action.payload;
            }
        },
        orderCreateShipmentTrackingAttempt: (state, _action: CreateShipmentTrackingPayload) => {
            state.actions.createShipmentTracking = true;
            state.error.createShipmentTracking = '';
        },
        orderCreateShipmentTrackingSuccess: (state) => {
            state.actions.createShipmentTracking = false;
        },
        orderCreateShipmentTrackingFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createShipmentTracking = false;
            if (action.payload) {
                state.error.createShipmentTracking = action.payload;
            }
        },
        orderUpdateShipmentTrackingAttempt: (state, _action: UpdateShipmentTrackingPayload) => {
            state.actions.updateShipmentTracking = true;
            state.error.updateShipmentTracking = '';
        },
        orderUpdateShipmentTrackingSuccess: (state) => {
            state.actions.updateShipmentTracking = false;
        },
        orderUpdateShipmentTrackingFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateShipmentTracking = false;
            if (action.payload) {
                state.error.updateShipmentTracking = action.payload;
            }
        },
        orderDeleteShipmentTrackingAttempt: (state, _action: DeleteShipmentTrackingPayload) => {
            state.actions.deleteShipmentTracking = true;
            state.error.deleteShipmentTracking = '';
        },
        orderDeleteShipmentTrackingSuccess: (state) => {
            state.actions.deleteShipmentTracking = false;
        },
        orderDeleteShipmentTrackingFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteShipmentTracking = false;
            if (action.payload) {
                state.error.deleteShipmentTracking = action.payload;
            }
        },
        orderGetPaymentLinkAttempt: (state, _action: GetPaymentLinkPayload) => {
            state.actions.getPaymentLink = true;
            state.error.getPaymentLink = '';
        },
        orderGetPaymentLinkSuccess: (state, action: PayloadAction<string>) => {
            state.actions.getPaymentLink = false;
            state.paymentLink = action.payload;
        },
        orderGetPaymentLinkFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPaymentLink = false;
            if (action.payload) {
                state.error.getPaymentLink = action.payload;
            }
        },
        orderSendPaymentLinkAttempt: (state, _action: SendPaymentLinkPayload) => {
            state.actions.sendPaymentLink = true;
            state.error.sendPaymentLink = '';
        },
        orderSendPaymentLinkSuccess: (state) => {
            state.actions.sendPaymentLink = false;
        },
        orderSendPaymentLinkFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.sendPaymentLink = false;
            if (action.payload) {
                state.error.sendPaymentLink = action.payload;
            }
        },
        orderExportDeliveryOrderAttempt: (state, _action: ExportDeliveryOrderPayload) => {
            state.actions.exportDeliveryOrder = true;
            state.error.exportDeliveryOrder = '';
        },
        orderExportDeliveryOrderSuccess: (state) => {
            state.actions.exportDeliveryOrder = false;
        },
        orderExportDeliveryOrderFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.exportDeliveryOrder = false;
            if (action.payload) {
                state.error.exportDeliveryOrder = action.payload;
            }
        },
        orderExportCommercialInvoiceAttempt: (state, _action: ExportCommercialInvoicePayload) => {
            state.actions.exportCommercialInvoice = true;
            state.error.exportCommercialInvoice = '';
        },
        orderExportCommercialInvoiceSuccess: (state) => {
            state.actions.exportCommercialInvoice = false;
        },
        orderExportCommercialInvoiceFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.exportCommercialInvoice = false;
            if (action.payload) {
                state.error.exportCommercialInvoice = action.payload;
            }
        },
        orderConvertOrderStatusToUnpaidAttempt: (state, _action: ConvertOrderStatusToPaidPayload) => {
            state.actions.convertOrderStatusToUnpaid = true;
            state.error.convertOrderStatusToUnpaid = '';
        },
        orderConvertOrderStatusToUnpaidSuccess: (state) => {
            state.actions.convertOrderStatusToUnpaid = false;
        },
        orderConvertOrderStatusToUnpaidFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.convertOrderStatusToUnpaid = false;
            if (action.payload) {
                state.error.convertOrderStatusToUnpaid = action.payload;
            }
        },
        orderConvertOrderStatusToPaidAttempt: (state, _action: ConvertOrderStatusToPaidPayload) => {
            state.actions.convertOrderStatusToPaid = true;
            state.error.convertOrderStatusToPaid = '';
        },
        orderConvertOrderStatusToPaidSuccess: (state) => {
            state.actions.convertOrderStatusToPaid = false;
        },
        orderConvertOrderStatusToPaidFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.convertOrderStatusToPaid = false;
            if (action.payload) {
                state.error.convertOrderStatusToPaid = action.payload;
            }
        },
        orderGetOrderAttachmentFolderNameAttempt: (state, _action: GetOrderAttachmentFolderNamePayload) => {
            state.actions.getOrderAttachmentFolderName = true;
            state.error.getOrderAttachmentFolderName = '';
        },
        orderGetOrderAttachmentFolderNameSuccess: (state, action: PayloadAction<string[]>) => {
            state.actions.getOrderAttachmentFolderName = false;
            state.orderAttachmentFolderName = action.payload;
        },
        orderGetOrderAttachmentFolderNameFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getOrderAttachmentFolderName = false;
            if (action.payload) {
                state.error.getOrderAttachmentFolderName = action.payload;
            }
        },
        orderResetOrderDetails: (state) => {
            state.orderDetails = initialState.orderDetails;
        },
        orderClearOrderComment: (state) => {
            state.actions.createOrderComment = false;
            state.error.createOrderComment = '';
            state.orderCommentDetails = initialState.orderCommentDetails;
        },
        orderResetShipmentTrackingDetails: (state) => {
            state.shipmentTrackingDetails = initialState.shipmentTrackingDetails;
        },
        orderSetConfirmDeleteModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.confirmationDeleteModalIsOpen = action.payload;
        },
        orderSetEditOrderCommentModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.editOrderCommentModalIsOpen = action.payload;
        },
        orderSetUploadAttachmentModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.uploadAttachmentModalIsOpen = action.payload;
        },
        orderSetUploadPaymentProofModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.uploadPaymentProofModalIsOpen = action.payload;
        },
        orderSetDeletePaymentProofModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.deletePaymentProofModalIsOpen = action.payload;
        },
        orderSetDeleteShipmentTrackingModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.deleteShipmentTrackingModalIsOpen = action.payload;
        },
        orderSetUpdateShippingAddressModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.updateShippingAddressModalIsOpen = action.payload;
        },
        orderSetUpdateBillingAddressModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.updateBillingAddressModalIsOpen = action.payload;
        },
        orderSetDeleteAttachmentFolderModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.deleteAttachmentFolderModalIsOpen = action.payload;
        },
        orderSetConvertOrderStatusToPaidModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.convertOrderStatusToPaidModalIsOpen = action.payload;
        },
        orderSetConvertOrderStatusToUnpaidModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.convertOrderStatusToUnpaidModalIsOpen = action.payload;
        },
    },
});

export type OrderState = typeof initialState;

export default {
    actions: orderSlice.actions,
    reducers: orderSlice.reducer,
};
