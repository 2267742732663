import React, { FC } from 'react';

import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import theme from './theme';

type ThemeProviderProps = {
    children: React.ReactNode;
}
const ThemeProvider: FC<ThemeProviderProps> = (props: ThemeProviderProps) => {
    const { children } = props;
    return (
        <StyledComponentsThemeProvider theme={theme}>
            {children}
        </StyledComponentsThemeProvider>
    );
};

export default ThemeProvider;
