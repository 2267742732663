import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IHomeChartData, IHomeStatCard } from 'entities/Home';

export type GenericParams<T> = {
    authToken: string;
    params: T;
}

export type GetDashboardDetailsParams = {
    authToken: string;
    dateToday: string;
}

export type GetDashboardDetailsResponse = {
    totalOrdersInfo:IHomeStatCard,
    totalOrderSalesInfo:IHomeStatCard,
    totalMembersInfo: IHomeStatCard,
    chartData: {
        totalOrdersData: IHomeChartData[],
        totalOrderSalesData: IHomeChartData[],
        totalMembersData: IHomeChartData[],
    }
}

export abstract class IHomeGateway extends Gateway {
    abstract getDashboardDetails(params: GetDashboardDetailsParams): GatewayResponse<GetDashboardDetailsResponse>;
}
