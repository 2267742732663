import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import { GatewayResponseStatus } from 'api/types/types';
import UserManagementGateway from 'api/UserManagement';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetUserManagementDetails(api: UserManagementGateway): SagaWatcherReturnType {
    yield takeEvery('userManagement/userManagementGetUserManagementDetailsAttempt', handleGetUserManagementDetails, api);
}

function* handleGetUserManagementDetails(api: UserManagementGateway, data: PayloadAction<{ id: string }>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getUserManagementDetails], {
        authToken,
        userId: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.userManagementGetUserManagementDetailsFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.userManagementGetUserManagementDetailsSuccess(response.data));
    }
}
