import React, { useEffect, useRef, useState } from 'react';

import { create } from 'apisauce';
import { parse } from 'path';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import config from 'config';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import PageHeader from 'components/atoms/PageHeader';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import AdminLayout from 'components/Layout/AdminLayout';

import theme from 'styles/theme';

const ProductsScreen = (): JSX.Element => {
    const [files, setFiles] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>();
    const [fileUrls, setFileUrls] = useState<string[]>([]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target as HTMLInputElement;
        if (target.files === null || target.files.length === 0) {
            setFiles(null);
            setFileName(undefined);
            setFileUrls([]);
            return;
        }
        setFiles(target.files[0]);
        setFileName(target.files[0].name);
    };

    const handleFileUpload = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!files) {
            toast.error('No file selected');
            return;
        }

        const fileExt = files?.name && files.name.slice(files.name.lastIndexOf('.'));

        if (fileExt === '.xlsx' || fileExt === '.xls') {
            const endpoint = `${config.baseUrl}/products/upload`;

            const formData = new FormData();

            formData.append('file', files as File);

            const authToken = localStorage.getItem('authToken');

            const api = create({
                baseURL: endpoint,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            api.post(endpoint, formData).then((response) => {
                const testUrl = response.data as string;
                setFiles(files);
                setFileUrls([...fileUrls, testUrl]);
                setFileName(files?.name);

                if (response.status === 200) {
                    toast.success('File uploaded successfully');
                } else if (response.status !== 200) {
                    toast.error(response.problem);
                }
            }).catch((error) => {
                toast.error(error.response.problem);
            });
        } else {
            toast.error('Only .xlsx or .xls files are allowed');
        }
    };

    return (
        <AdminLayout>
            <PageHeader>
                Upload Products File
            </PageHeader>
            <CSVContainer>
                <ChoseFileContainer>

                    {/* <LabelCSV htmlFor='csvFileInput'>
                        Choose File
                    </LabelCSV> */}
                    <Input
                        variant='file'
                        type='file'
                        id='csvFileInput'
                        accept='.xlsx'
                        onChange={handleFileChange}
                    />
                </ChoseFileContainer>

                <UploadFileContainer>
                    <Button
                        label='Upload File'
                        variant={ButtonVariant.default}
                        onClick={handleFileUpload}
                    />
                </UploadFileContainer>
            </CSVContainer>
        </AdminLayout>
    );
};

const ChoseFileContainer = styled.div`
    width: fit-content;

    // margin-right: 1rem;
`;

const UploadFileContainer = styled.div`
    width: 10vw;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const CSVContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // justify-content: center;

`;

const LabelCSV = styled.label`
    cursor: pointer;
    font-size: ${theme.fontSize.default};
    line-height: ${theme.lineHeight.default};
    font-weight: ${theme.fontWeight.default};
    color: ${theme.fontColor.primary};
    font-family: ${theme.fonts.primary};
    text-align: center;

    width: 100%;
    background-color: ${theme.colors.tertiary};
    border-radius: 2rem;
    padding: .75rem 1.5rem;

`;

export default ProductsScreen;
