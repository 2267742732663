import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetCompanyListResponse } from 'api/CompanyBase';

import { ICompanyDetails } from 'entities/company';

import { CompanyReduxState } from './types';

const initialState: CompanyReduxState = {
    actions: {
        getCompanyList: false,
        getCompanyDetails: false,
    },
    companyList: [],
    companyDetails: null,
    error: {
        getCompanyList: '',
        getCompanyDetails: '',
    },
};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        companyGetCompanyListAttempt(state, _action: PayloadAction<{ index: number }>) {
            state.actions.getCompanyList = true;
            state.error.getCompanyList = '';
        },
        companyGetCompanyListSuccess(state, action: PayloadAction<GetCompanyListResponse>) {
            state.actions.getCompanyList = false;
            state.companyList = action.payload.data;
        },
        companyGetCompanyListFailure(state, action: PayloadAction<string>) {
            state.actions.getCompanyList = false;
            state.error.getCompanyList = action.payload;
        },
        companyGetCompanyDetailsAttempt(state, _action: PayloadAction<{ id: string }>) {
            state.actions.getCompanyDetails = true;
            state.error.getCompanyDetails = '';
        },
        companyGetCompanyDetailsSuccess(state, action: PayloadAction<ICompanyDetails>) {
            state.actions.getCompanyDetails = false;
            state.companyDetails = action.payload;
        },
        companyGetCompanyDetailsFailure(state, action: PayloadAction<string>) {
            state.actions.getCompanyDetails = false;
            state.error.getCompanyDetails = action.payload;
        },
    },
});

export type CompanyState = typeof initialState;

export default {
    actions: companySlice.actions,
    reducers: companySlice.reducer,
};
