import { fork } from 'redux-saga/effects';

import SupplierGateway from 'api/Supplier';
import { RootSagaReturnType } from 'sagas/types';

import watchCreateSupplier from './createSupplier';
import watchDeleteSupplier from './deleteSupplier';
import watchGetSupplierDetails from './getSupplierDetails';
import watchGetSuppliers from './getSuppliers';
import watchUpdateSupplier from './updateSupplier';

export default (api: SupplierGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetSuppliers, api);
        yield fork(watchGetSupplierDetails, api);
        yield fork(watchCreateSupplier, api);
        yield fork(watchUpdateSupplier, api);
        yield fork(watchDeleteSupplier, api);
    }

    return {
        rootSaga,
    };
};
