import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import PageHeader from 'components/atoms/PageHeader';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import DiscardSaveButtons from 'components/molecules/DiscardSaveButtons';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/userManagement/Selectors';

import NavActions from 'lib/NavActions';
import { IUserManagementDetails } from 'entities/userManagement';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';

import UserManagementForm from './components/UserManagementForm';

const EditAdmin = (): JSX.Element => {
    const params = useParams();
    const adminId = params.id;

    const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null);
    const [userNameToDelete, setUserNameToDelete] = useState<string | null>(null);

    const loading = useAppSelector(Selectors.getUserManagementDetailsAttempting);
    const error = useAppSelector(Selectors.getUserManagementDetailsError);
    const admin = useAppSelector(Selectors.getUserManagementDetails);
    const { data: user } = useAppSelector(Selectors.getUserManagement);

    const updateLoading = useAppSelector(Selectors.editUserManagementAttempting);
    const updateError = useAppSelector(Selectors.editUserManagementError);

    const confirmationModalIsOpen = useAppSelector(Selectors.getUserManagementConfirmationDeleteModalIsOpen);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (adminId) {
            dispatch(Actions.userManagementGetUserManagementDetailsAttempt({ id: adminId }));
        }
        return () => {
            dispatch(Actions.userManagementClearEditUserManagement());
        };
    }, [adminId]);

    const onChange = (update: Partial<IUserManagementDetails>) => {
        dispatch(Actions.userManagementSetUserManagementDetails(update));
    };

    const onSubmit = () => {
        dispatch(Actions.userManagementEditUserManagementAttempt());
    };

    const onDeletePressed = (userId: string) => {
        const selectedUser = user.find((item) => item.id === userId);
        if (selectedUser) {
            setUserIdToDelete(userId);
            setUserNameToDelete(selectedUser.fullName);

            dispatch(Actions.userManagementSetConfirmationDeleteModalIsOpen(true));
        }
    };

    const onCancelled = () => {
        setUserIdToDelete(null);
        setUserNameToDelete(null);
        dispatch(Actions.userManagementSetConfirmationDeleteModalIsOpen(false));
    };

    const onDeleteConfirmed = () => {
        if (userIdToDelete) {
            dispatch(Actions.userManagementDeleteUserManagementAttempt({ id: userIdToDelete }));
        }
    };

    const renderBody = () => {
        if (loading) {
            return (
                <LoadingContainer>
                    <LoadingIndicator />
                </LoadingContainer>
            );
        }

        if (error) {
            return (
                <LoadingContainer>
                    <Text variant={TextVariant.error}>{error}</Text>
                </LoadingContainer>
            );
        }

        return (
            <FormContainer>
                <UserManagementForm
                    error={updateError}
                    onChange={onChange}
                    admin={admin}
                    passwordHeader='Set New Password'
                    isSetPassword
                />
                <ButtonRow>
                    <Button
                        label='Delete Admin'
                        variant={ButtonVariant.warning}
                        style={{ width: 220 }}
                        onClick={() => admin?.id && onDeletePressed(admin?.id)}
                    />
                    <div>
                        <DiscardSaveButtons
                            onDiscard={NavActions.navBack}
                            onSave={onSubmit}
                            loading={updateLoading}
                            error={updateError}
                        />
                    </div>
                </ButtonRow>
            </FormContainer>
        );
    };

    return (
        <AdminLayout>
            <PageHeader withBackButton>
                Edit Admin
            </PageHeader>

            {renderBody()}

            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                icon={<ConfirmationIcon />}
                title={`Delete user "${userNameToDelete}"?`}
                text='Please confirm deleting this admin.'
                onCancel={onCancelled}
                onConfirm={onDeleteConfirmed}
                cancelText='Cancel'
                confirmText='Confirm'
                loading={updateLoading}
                error={updateError}
                confirmButtonVariant={ButtonVariant.warning}
            />
        </AdminLayout>
    );
};

const FormContainer = styled.div`
    height: 750px;
    display: flex;
    flex-direction: column;
`;

const ButtonRow = styled.div`
    width: 50%;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
`;

const LoadingContainer = styled.div`
    display: flex;

    background-color: white;
    border-radius: 15px;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 240px;
`;
export default EditAdmin;
