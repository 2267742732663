import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetCustomersApiResponse } from 'api/CustomerBase';
import { GetProductsApiResponse } from 'api/ProductsBase';

import { IAddress, ICustomerAddress } from 'entities/address';
import { ICustomer } from 'entities/customer';
import { ISupplier } from 'entities/supplier';

import {
    FormSearchCustomersPayload,
    UiReduxState,
} from './types';

const initialState: UiReduxState = {
    actions: {
        searchingCustomers: false,
        creatingCustomer: false,
        getCustomerAddress: false,
        searchingProducts: false,

        searchingSuppliers: false,
        getSupplierAddress: false,
    },
    form: {
        customerList: [],
        productList: [],
        customerAddresses: [],
        newCustomerCreated: null,
        createCustomerModalIsOpen: false,

        supplierList: [],
        supplierAddress: undefined,
    },
    error: {
        searchingCustomers: '',
        creatingCustomer: '',
        getCustomerAddress: '',
        searchingProducts: '',

        searchingSuppliers: '',
        getSupplierAddress: '',
    },
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        uiSearchForCustomersAttempt: (state, _action: FormSearchCustomersPayload) => {
            state.actions.searchingCustomers = true;
            state.error.searchingCustomers = '';
        },
        uiSearchForCustomersSuccess: (state, action: PayloadAction<GetCustomersApiResponse>) => {
            state.actions.searchingCustomers = false;
            state.form.customerList = action.payload.data;
        },
        uiSearchForCustomersFailure: (state, action: PayloadAction<string>) => {
            state.actions.searchingCustomers = false;
            state.error.searchingCustomers = action.payload || 'Something went wrong. Please try again later.';
        },
        uiSearchForProductsAttempt: (state, _action: FormSearchCustomersPayload) => {
            state.actions.searchingProducts = true;
            state.error.searchingProducts = '';
        },
        uiSearchForProductsSuccess: (state, action: PayloadAction<GetProductsApiResponse>) => {
            state.actions.searchingProducts = false;
            state.form.productList = action.payload.data;
        },
        uiSearchForProductsFailure: (state, action: PayloadAction<string>) => {
            state.actions.searchingProducts = false;
            state.error.searchingProducts = action.payload || 'Something went wrong. Please try again later.';
        },
        uiSetAddNewCustomerModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.form.createCustomerModalIsOpen = action.payload;
        },
        uiCreateCustomerAttempt: (state, _action: PayloadAction<Partial<ICustomer>>) => {
            state.actions.creatingCustomer = true;
            state.error.creatingCustomer = '';
        },
        uiCreateCustomerSuccess: (state) => {
            state.actions.creatingCustomer = false;
            state.form.createCustomerModalIsOpen = false;
        },
        uiCreateCustomerFailure: (state, action: PayloadAction<string>) => {
            state.actions.creatingCustomer = false;
            state.error.creatingCustomer = action.payload;
        },
        uiGetCustomerAddressesAttempt: (state, _action: PayloadAction<{ customerId: string }>) => {
            state.actions.getCustomerAddress = true;
            state.error.getCustomerAddress = '';
        },
        uiGetCustomerAddressesSuccess: (state, action: PayloadAction<ICustomerAddress[]>) => {
            state.actions.getCustomerAddress = false;
            state.form.customerAddresses = action.payload;
        },
        uiGetCustomerAddressesFailure: (state, action: PayloadAction<string>) => {
            state.actions.getCustomerAddress = false;
            state.error.getCustomerAddress = action.payload;
        },

        uiSearchForSuppliersAttempt: (state, _action: FormSearchCustomersPayload) => {
            state.actions.searchingSuppliers = true;
            state.error.searchingSuppliers = '';
        },
        uiSearchForSuppliersSuccess: (state, action: PayloadAction<ISupplier[]>) => {
            state.actions.searchingSuppliers = false;
            state.form.supplierList = action.payload;
        },
        uiSearchForSuppliersFailure: (state, action: PayloadAction<string>) => {
            state.actions.searchingSuppliers = false;
            state.error.searchingSuppliers = action.payload || 'Something went wrong. Please try again later.';
        },

        uiGetSupplierAddressAttempt: (state, _action: PayloadAction<{ supplierId: string }>) => {
            state.actions.getSupplierAddress = true;
            state.error.getSupplierAddress = '';
        },
        uiGetSupplierAddressSuccess: (state, action: PayloadAction<IAddress>) => {
            state.actions.getSupplierAddress = false;
            state.form.supplierAddress = action.payload;
        },
        uiGetSupplierAddressFailure: (state, action: PayloadAction<string>) => {
            state.actions.getSupplierAddress = false;
            state.error.getSupplierAddress = action.payload;
        },
        uiClearSearchCustomerForm: (state) => {
            state.form.customerList = [];
            state.form.customerAddresses = [];
            state.form.newCustomerCreated = null;
            state.form.createCustomerModalIsOpen = false;

            state.actions.creatingCustomer = false;
            state.actions.searchingCustomers = false;
            state.actions.getCustomerAddress = false;

            state.error.creatingCustomer = '';
            state.error.searchingCustomers = '';
            state.error.getCustomerAddress = '';
        },
        uiResetCustomerAddress: (state) => {
            state.form.customerAddresses = [];
        },
        uiResetSupplierAddress: (state) => {
            state.form.supplierAddress = undefined;
        },
    },
});

export type UiState = typeof initialState;

export default {
    actions: uiSlice.actions,
    reducers: uiSlice.reducer,
};
