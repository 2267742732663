import { GetQuotationsApiResponse } from 'api/QuotationsBase';
import { RootState } from 'redux/store';

import { IQuotation } from 'entities/quotations';

import { QuotationReduxFilters } from './types';

const getQuotationsAttempting = (state: RootState): boolean => state.quotations.actions.getQuotations;
const getQuotationsError = (state: RootState): string => state.quotations.error.getQuotations;
const getQuotations = (state: RootState): GetQuotationsApiResponse => state.quotations.quotations;
const getQuotationsFilters = (state: RootState): QuotationReduxFilters => state.quotations.filters;

const getQuotationDetailsAttempting = (state: RootState): boolean => state.quotations.actions.getQuotationDetails;
const getQuotationDetailsError = (state: RootState): string => state.quotations.error.getQuotationDetails;
const getQuotationDetails = (state: RootState): IQuotation | null => state.quotations.quotationDetails;

const createQuotationAttempting = (state: RootState): boolean => state.quotations.actions.createQuotation;
const createQuotationError = (state: RootState): string => state.quotations.error.createQuotation;
const createQuotation = (state: RootState): IQuotation | null => state.quotations.quotationDetails;

const updateQuotationAttempting = (state: RootState): boolean => state.quotations.actions.updateQuotation;
const updateQuotationError = (state: RootState): string => state.quotations.error.updateQuotation;
const updateQuotation = (state: RootState): IQuotation | null => state.quotations.quotationDetails;

const deleteQuotationAttempting = (state: RootState): boolean => state.quotations.actions.deleteQuotation;
const deleteQuotationError = (state: RootState): string => state.quotations.error.deleteQuotation;
const deleteQuotation = (state: RootState): IQuotation | null => state.quotations.quotationDetails;

const exportQuotationAttempting = (state: RootState): boolean => state.quotations.actions.exportQuotation;
const exportQuotationError = (state: RootState): string => state.quotations.error.exportQuotation;

const getQuotationsNewOrEditQuotation = (state: RootState): Partial<IQuotation> => state.quotations.createOrEditQuotation;

export default {
    getQuotationsAttempting,
    getQuotationsError,
    getQuotations,
    getQuotationDetailsAttempting,
    getQuotationDetailsError,
    getQuotationDetails,
    getQuotationsFilters,

    createQuotationAttempting,
    createQuotationError,
    createQuotation,
    updateQuotationAttempting,
    updateQuotationError,
    updateQuotation,
    deleteQuotationAttempting,
    deleteQuotationError,
    deleteQuotation,
    exportQuotationAttempting,
    exportQuotationError,

    getQuotationsNewOrEditQuotation,
};
