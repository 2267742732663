import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import DropdownButton from 'components/atoms/DropdownButton';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import ProductSelectors from 'redux/slices/products/Selectors';

import Utils from 'lib/Utils';
import { EDiscountType, IProductDetails } from 'entities/products';
import { DiscountOptionLov } from 'lov/ProductSelectorLov';

interface AddCustomItemModalProps {
    isOpen: boolean;
    onCancel: () => void;
    isEdit: boolean;
    editedProductIndex: number;

    error?: string;
}

const AddCustomItemModal: React.FC<AddCustomItemModalProps> = (props: AddCustomItemModalProps) => {
    const { isOpen, onCancel, isEdit, editedProductIndex, error } = props;

    const dispatch = useAppDispatch();
    const selectedProducts = useAppSelector(ProductSelectors.getSelectedProducts);

    const [quantity, setQuantity] = useState<string>('');
    const [price, setPrice] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [weight, setWeight] = useState<string>('');

    const [discountType, setDiscountType] = useState<string>(EDiscountType.PERCENTAGE);
    const [discountValue, setDiscountValue] = useState<string>('');

    const [productRemark, setProductRemark] = useState<string>('');

    useEffect(() => {
        if (isEdit) {
            const weightPerQuantity = (selectedProducts[editedProductIndex].weight ?? 0) / selectedProducts[editedProductIndex].totalQuantity;
            setQuantity(selectedProducts[editedProductIndex].totalQuantity.toString());
            setPrice(selectedProducts[editedProductIndex].price.toString());
            setDescription(selectedProducts[editedProductIndex].name);
            setWeight(weightPerQuantity.toString() ?? '');
            setProductRemark(selectedProducts[editedProductIndex].remark ?? '');
            if (selectedProducts[editedProductIndex].discountPercent) {
                setDiscountType(EDiscountType.PERCENTAGE);
                setDiscountValue(selectedProducts[editedProductIndex].discountPercent?.toString() ?? '0');
            }
            if (selectedProducts[editedProductIndex].discountFixed) {
                setDiscountType(EDiscountType.FIXED);
                setDiscountValue(Utils.Formatter.formatPrice(selectedProducts[editedProductIndex].discountFixed as number));
            }
        } else {
            setQuantity('');
            setPrice('');
            setDescription('');
            setWeight('');
            setDiscountType(EDiscountType.PERCENTAGE);
            setDiscountValue('');
            setProductRemark('');
        }
    }, [isOpen]);

    useEffect(() => {
        setDiscountValue('');
    }, [discountType]);

    const onValueChange = (value: string, name: string) => {
        const newValue = value.replace(/[^0-9\b.-]/g, '');

        switch (name) {
            case 'quantity':
                setQuantity(newValue);
                break;
            case 'price':
                setPrice(newValue);
                break;
            case 'weight':
                setWeight(newValue);
                break;
            default:
                break;
        }
    };
    const onDiscountValueChange = (value: string) => {
        const newDiscount = value?.replace(/[^0-9\b.-]/g, '');

        if (discountType === EDiscountType.PERCENTAGE) {
            if (!(Number.isNaN(newDiscount)) && Number(newDiscount) <= 100) {
                setDiscountValue(newDiscount);
                return;
            }
            toast.error('Discount value cannot be greater than 100%');
            return;
        }

        setDiscountValue(newDiscount || '');
    };
    const onAddCustomLine = () => {
        if (!quantity || !price || !description) {
            return toast.error('All fields are required');
        }

        let calculatedTotal = 0;
        if (discountType === EDiscountType.PERCENTAGE) {
            const totalPrice = Number(price) * Number(quantity);
            calculatedTotal = totalPrice - (totalPrice * (Number(discountValue) / 100));
        }

        if (discountType === EDiscountType.FIXED) {
            calculatedTotal = Number(price) * Number(quantity) - Number(discountValue);
        }

        if (isEdit) {
            const updatedItem: IProductDetails = {
                ...selectedProducts[editedProductIndex],
                totalQuantity: Number(quantity),
                price: Number(price).toFixed(2),
                name: description,
                total: calculatedTotal,
                discountPercent: discountType === EDiscountType.PERCENTAGE ? Number(discountValue) : undefined,
                discountFixed: discountType === EDiscountType.FIXED ? Number(discountValue) : undefined,
                discountAmountApplied: (Number(price) * Number(quantity)) - calculatedTotal,
                weight: weight ? Number(weight) * Number(quantity) : undefined,
                remark: productRemark,
            };
            const newUpdatedItem = selectedProducts.map((item, i) => {
                if (i === editedProductIndex) {
                    return updatedItem;
                }
                return item;
            });
            dispatch(Actions.productReplaceSelectedProducts(newUpdatedItem));
        } else {
            const addedItem: IProductDetails = {
                productId: '',
                variantId: '',
                name: description,
                totalQuantity: Number(quantity),
                price: Number(price).toFixed(2),
                total: calculatedTotal,
                discountPercent: discountType === EDiscountType.PERCENTAGE ? Number(discountValue) : undefined,
                discountFixed: discountType === EDiscountType.FIXED ? Number(discountValue) : undefined,
                disableQuantityEdit: false,
                discountAmountApplied: (Number(price) * Number(quantity)) - calculatedTotal,
                weight: weight ? Number(weight) * Number(quantity) : undefined,
                remark: productRemark,
            };
            dispatch(Actions.productSetSelectedProducts(addedItem));
        }

        onCancel();
        if (isEdit) return toast.success('Custom line updated successfully');
        return toast.success('Custom line added successfully');
    };

    return (
        <Modal
            isModalOpen={isOpen}
            onClosed={onCancel}
            title={isEdit ? 'Update Custom Line' : 'Add Custom Line'}
        >
            <Container>
                <ProductContainer>
                    <Input
                        error={!!error && !quantity}
                        variant='inputForm'
                        header='Quantity'
                        value={quantity}
                        onChange={(e) => onValueChange(e.target.value, 'quantity')}
                        required
                    />
                    <Input
                        error={!!error && !price}
                        variant='inputForm'
                        header='Price'
                        value={price}
                        onChange={(e) => onValueChange(e.target.value, 'price')}
                        required
                    />
                    <div id='description'>
                        <Input
                            error={!!error && !description}
                            variant='textarea'
                            header='Description'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </div>
                    <div id='weight'>
                        <Input
                            variant='inputForm'
                            header='Weight per quantity (KG)'
                            value={weight}
                            onChange={(e) => onValueChange(e.target.value, 'weight')}
                        />
                    </div>
                </ProductContainer>
                <DiscountContainer>
                    <DropdownButton
                        label='Discount type'
                        options={DiscountOptionLov}
                        value={discountType}
                        onSelect={(e) => setDiscountType(e as string)}
                    />
                    <Input
                        header='Discount value'
                        value={discountValue}
                        disabled={Number(price) < 0}
                        onChange={(e) => onDiscountValueChange(e.target.value)}
                    />
                </DiscountContainer>
                <RemarkContainer>
                    <Input
                        header='Remark'
                        variant='textarea'
                        value={productRemark}
                        onChange={(e) => setProductRemark(e.target.value)}
                    />
                </RemarkContainer>
                <ButtonContainer>
                    <div>
                        <Button label='Discard' variant={ButtonVariant.primary} onClick={onCancel} />
                        <Button label='Add Custom Line' variant={ButtonVariant.secondary} onClick={onAddCustomLine} />
                    </div>
                </ButtonContainer>
            </Container>
        </Modal>
    );
};

const Container = styled.div`
    width: 56rem;
`;

const ProductContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    gap: 1rem;
    border-bottom: 1px solid #EDEDED;
    padding-bottom:  2rem;

    > div {
        flex: 1 0 auto;
    }

    #description {
        grid-area: 2 / 1 / 3 / 3;
        display: flex;
        gap: 1rem;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
    }
    #weight {
        grid-area: 3 / 1 / 4 / 3;
        width: 100%;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-block: 1.5rem;
    width: 100%;

    > div {
        display: flex;
        gap: 1rem;
        width: 50%;
    }
`;

const DiscountContainer = styled.div`
    padding-block: 1rem;
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid #EDEDED;
`;

const RemarkContainer = styled.div`
    padding-block: 1rem;
    display: flex;
    width: 100%;
`;

export default AddCustomItemModal;
