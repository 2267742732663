import React, { FC } from 'react';

import { ToastContainer, ToastContainerProps } from 'react-toastify';
import styled from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

const Toast: FC<ToastContainerProps> = () => {
    return (
        <CustomToast
            position='top-center'
            closeButton={false}
            draggablePercent={60}
            limit={5}
            hideProgressBar
            closeOnClick
            pauseOnHover
            pauseOnFocusLoss
        />
    );
};

const CustomToast = styled(ToastContainer)`
    .Toastify__toast-container {
        max-width: 400px;
    }
    .Toastify__toast {
        width: 350px;
        background-color: ${props => props.theme.colors.background};
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        /* color: white; */
    }

    /* .Toastify__toast-body {
    } */
`;

export default Toast;
