import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PurchaseOrderGateway from 'api/PurchaseOrders';
import { CreatePoApiParams } from 'api/PurchaseOrdersBase';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import CompanySelectors from 'redux/slices/company/Selectors';
import ProductSelectors from 'redux/slices/products/Selectors';
import PoSelectors from 'redux/slices/purchaseOrder/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';
import { IAddress } from 'entities/address';

export default function* watchCreatePurchaseOrder(api: PurchaseOrderGateway): SagaWatcherReturnType {
    yield takeEvery('po/poCreatePurchaseOrderAttempt', handleCreatePurchaseOrder, api);
}

function* handleCreatePurchaseOrder(api: PurchaseOrderGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);
    const poDetails = yield* select(PoSelectors.getPurchaseOrderNewOrEditPurchaseOrder);
    const companyDetails = yield* select(CompanySelectors.getCompanyDetails);

    const selectedProducts = yield* select(ProductSelectors.getSelectedProducts);
    const discountPercent = yield* select(ProductSelectors.getDiscountPercent);
    const discountFixed = yield* select(ProductSelectors.getDiscountFixed);
    const shippingPrice = yield* select(ProductSelectors.getShippingPrice);
    const taxPrice = yield* select(ProductSelectors.getTaxPrice);
    // const totalWeight = yield* select(ProductSelectors.getTotalWeight);

    const calculateSubTotal = () => {
        let subTotal = 0;
        selectedProducts.forEach((product) => {
            subTotal += Number(product.price) * product.totalQuantity;
        });

        return subTotal;
    };

    const calculateTotal = () => {
        let finalTotal = 0;

        if (discountPercent) {
            finalTotal = calculateSubTotal() - (calculateSubTotal() * (Number(discountPercent) / 100)) + Number(shippingPrice);
        }

        if (discountFixed) {
            finalTotal = calculateSubTotal() - Number(discountFixed) + Number(shippingPrice);
        }

        if (!discountPercent && !discountFixed) {
            finalTotal = calculateSubTotal() + Number(shippingPrice);
        }

        return Number(finalTotal.toFixed(2));
    };

    const calculateTotalWithTax = () => {
        if (!companyDetails?.tax) return calculateTotal();
        const tax = calculateTotal() * (Number(companyDetails.tax) / 100);
        return calculateTotal() + tax;
    };

    // const paramsData: CreatePurchaseOrderApiDTO = {
    //     ...poDetails as CreatePurchaseOrderApiDTO,
    //     products: selectedProducts.filter((product) => product.productId)
    //         .map(product => ({
    //             productId: product.productId as string,
    //             variantId: product.variantId as string,
    //             productName: product.name,
    //             productVariant: {
    //                 fit: product.variant?.fit,
    //                 type: product.variant?.type,
    //                 sleeve: product.variant?.sleeve,
    //                 style: product.variant?.style,
    //                 color: product.variant?.color,
    //             },
    //             printMethods: product.printMethods ? product.printMethods.map((printMethod) => ({
    //                 side: printMethod.side,
    //                 printCustomId: printMethod.printCustomId,
    //                 printVariantId: printMethod.printVariantId,
    //                 pricePerUnit: Number(printMethod.pricePerUnit),
    //                 printMethod: printMethod.printMethod,
    //                 block: printMethod.block,
    //                 colorCount: printMethod.colorCount,
    //             })) : [],
    //             quantities: product.quantities ? product.quantities?.map((quantities) => ({
    //                 quantity: quantities.quantity as number,
    //                 productPriceId: quantities.id,
    //                 size: quantities.size ? quantities.size : undefined,
    //                 pricePerUnit: Number(quantities.pricePerUnit),
    //             })) : [],
    //             finalQuantity: product.totalQuantity,
    //             discountPercent: product.discountPercent ?? undefined,
    //             discountFixed: product.discountFixed ?? undefined,
    //             totalPricePerUnit: Number(product.price),
    //             subtotal: product.discountPercent ? product.total + product.total * (product.discountPercent / 100) : product.total + (product.discountFixed ?? 0),
    //             finalProductPrice: product.total,
    //         })),
    //     customLineProducts: selectedProducts.filter((product) => !product.productId)
    //         .map(product => ({
    //             productName: product.name,
    //             finalQuantity: product.totalQuantity,
    //             finalProductPrice: Number(product.total),
    //             totalPricePerUnit: Number(product.price),
    //             subtotal: product.discountPercent ? product.total + product.total * (product.discountPercent / 100) : product.total + (product.discountFixed ?? 0),
    //             discountPercent: product.discountPercent ?? undefined,
    //             discountFixed: product.discountFixed ?? undefined,
    //         })),
    //     // discountPercent: discountPercent ? Number(discountPercent) : undefined,
    //     // discountFixed: discountFixed ? Number(discountFixed) : undefined,
    //     shippingFee: 0,
    //     subtotal: calculateSubTotal(),
    //     tax: 0,
    //     finalPrice: calculateSubTotal(),
    // };

    const newProducts = selectedProducts.filter((product) => product.productId)
        .map((product) => ({
            productId: product.productId as string,
            variantId: product.variantId as string,
            productName: product.name,
            productVariant: {
                fit: product.variant?.fit,
                type: product.variant?.type,
                sleeve: product.variant?.sleeve,
                style: product.variant?.style,
                color: product.variant?.color,
            },
            quantities: product.quantities ? product.quantities?.map((quantities) => ({
                quantity: quantities.quantity as number,
                productPriceId: quantities.id,
                size: quantities.size ? quantities.size : undefined,
                pricePerUnit: Number(quantities.pricePerUnit),
            })) : [],
            printMethods: product.printMethods ? product.printMethods.map((printMethod) => ({
                side: printMethod.side,
                printCustomId: printMethod.printCustomId,
                printVariantId: printMethod.printVariantId,
                pricePerUnit: Number(printMethod.pricePerUnit),
                printMethod: printMethod.printMethod,
                block: printMethod.block,
                colorCount: printMethod.colorCount,
            })) : [],
            finalQuantity: product.totalQuantity,
            // discountPercent: product.discountPercent ?? undefined,
            // discountFixed: product.discountFixed ?? undefined,
            // truePricePerUnit: Number(product.price) - (Number(product.subtotal) - product.total),
            totalPricePerUnit: Number(product.price),
            subtotal: product.subtotal ?? 0,
            finalProductPrice: Number(product.price) * product.totalQuantity,
            remark: product.remark,
            // weight: product.weight ?? 0, // todo: might need this in the future
        }));

    const customLineProducts = selectedProducts.filter((product) => !product.productId)
        .map((product) => ({
            productName: product.name,
            finalQuantity: product.totalQuantity,
            finalProductPrice: Number(product.total),
            totalPricePerUnit: Number(product.price),
            subtotal: product.subtotal ?? 0,
            // discountPercent: product.discountPercent ?? undefined,
            // discountFixed: product.discountFixed ?? undefined,
            weight: product.weight ?? 0,
            remark: product.remark,
        }));

    const newPurchaseOrder: Omit<CreatePoApiParams, 'authToken'> = {
        orderId: poDetails?.orderId as string,
        orderNo: poDetails?.orderNumber as string,
        poDate: poDetails?.poDate ? dayjs(poDetails?.poDate).toISOString() : dayjs().toISOString(),
        companyAccountId: poDetails?.companyAccountId as string,
        supplierId: poDetails?.supplierId as string,
        supplierName: poDetails?.supplierName as string,
        supplierAddress: poDetails?.supplierAddress as IAddress,
        deliveryAddress: poDetails?.deliveryAddress as IAddress,
        deliveryCompanyName: poDetails?.deliveryCompanyName as string,
        note: poDetails?.note as string,
        remark: poDetails?.remark as string,
        products: newProducts,
        customLineProducts,
        discountPercent: discountPercent ? Number(discountPercent) : undefined,
        discountFixed: discountFixed ? Number(discountFixed) : undefined,
        shippingFee: Number(shippingPrice),
        subtotal: calculateSubTotal(),
        tax: taxPrice ? Number(taxPrice) : 0,
        finalPrice: calculateSubTotal(),
        // totalWeight, // todo: might need this in the future
    };

    const response = yield* call([api, api.createPurchaseOrder], {
        authToken,
        ...newPurchaseOrder,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.poCreatePurchaseOrderFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.poCreatePurchaseOrderSuccess());
        toast.success('Purchase order created successfully');
        if (response.data) NavActions.navToPurchaseOrdersDetails(response.data);
    }
}
