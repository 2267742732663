import React from 'react';

import styled from 'styled-components';

import { TextVariant } from './variants/TextVariant';
import Text from './Text';

interface ErrorMessageProps {
    error?: string;
    height?: number;

    style?: React.CSSProperties;
}

const ErrorMessage = (props: ErrorMessageProps): JSX.Element => {
    const { error, height, style } = props;

    return (
        <ErrorContainer height={height} style={style}>
            <Text variant={TextVariant.error}>
                {error || 'Something went wrong. Please try again later.'}
            </Text>
        </ErrorContainer>
    );
};

const ErrorContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== 'height',
}) <{ height?: number }>`
    height: ${props => (props.height ? `${props.height}px` : '150px')};
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export default ErrorMessage;
