import React from 'react';

import styled from 'styled-components';

import Button from 'components/atoms/Button';
import DateFilter from 'components/atoms/DateFilter';
import DropdownButton from 'components/atoms/DropdownButton';
import SearchButton from 'components/atoms/SearchButton';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';

export interface StatusFilterOption {
    label: string;
    value: number | string;
}

interface TableFiltersProps {
    dateFilterEnabled?: boolean;
    selectedFromDate?: Date;
    selectedToDate?: Date;
    onDatesSelected?: (dateFrom: Date, dateTo: Date) => void;

    statusFilterEnabled?: boolean;
    availableStatuses?: StatusFilterOption[];
    selectedStatus?: number;
    newPlaceholder?: string;
    onStatusSelected?: (value: number) => void;

    typeFilterEnabled?: boolean;
    availableTypes?: StatusFilterOption[];
    onTypeSelected?: (value: number) => void;
    typeFilterPlaceholder?: string;

    searchFilterEnabled?: boolean;
    searchValue?: string;
    onSearchChanged?: (value: string) => void;
    onSearch?: () => void;

    exportButtonEnabled?: boolean;
    exportLabel?: string;
    exportButtonVariant?: ButtonVariant;
    onExport?: (dateFrom: Date, dateTo: Date) => void;

    addNewButtonEnabled?: boolean;
    addNewLabel?: string;
    addButtonVariant?: ButtonVariant;
    onAddNew?: () => void;
    disableAddNew?: boolean;
}

const TableFilters = (props: TableFiltersProps): JSX.Element => {
    const {
        dateFilterEnabled,
        selectedFromDate,
        selectedToDate,
        onDatesSelected,

        statusFilterEnabled,
        availableStatuses,
        selectedStatus,
        newPlaceholder,
        onStatusSelected,

        typeFilterEnabled = false,
        availableTypes,
        onTypeSelected,
        typeFilterPlaceholder,

        searchFilterEnabled,
        searchValue,
        onSearchChanged,
        onSearch,

        exportButtonEnabled,
        exportLabel,
        exportButtonVariant = ButtonVariant.primary,
        onExport,

        addNewButtonEnabled,
        addNewLabel,
        addButtonVariant = ButtonVariant.secondary,
        onAddNew,
        disableAddNew,
    } = props;

    const handleExport = () => {
        if (selectedFromDate && selectedToDate && onExport) {
            onExport(selectedFromDate, selectedToDate);
        }
    };

    const renderTypeFilter = () => {
        if (!typeFilterEnabled || !onTypeSelected) return null;

        return (
            <>
                <TypeFilterContainer>
                    <DropdownButton
                        options={availableTypes || []}
                        onSelect={(type) => onTypeSelected(type as number)}
                        placeholder={typeFilterPlaceholder || 'Filter by type'}
                    />
                </TypeFilterContainer>
                <Separator />
            </>
        );
    };

    const renderStatusFilter = () => {
        if (!statusFilterEnabled || !onStatusSelected) return null;

        return (
            <>
                <DropDownContainer>
                    <DropdownButton
                        options={availableStatuses || []}
                        value={selectedStatus}
                        onSelect={(status) => onStatusSelected(status as number)}
                        placeholder={newPlaceholder || 'Filter by status'}
                    />
                </DropDownContainer>
                <Separator />
            </>
        );
    };

    const renderDateFilter = () => {
        if (!dateFilterEnabled) return null;

        return (
            <DateFilter
                rangeFilter
                dateFrom={selectedFromDate}
                dateTo={selectedToDate}
                onChangeDate={onDatesSelected}
            />
        );
    };

    const renderSearchFilter = () => {
        if (!searchFilterEnabled || !onSearch || !onSearchChanged) return null;

        return (
            <>
                <Separator />
                <SearchButton
                    value={searchValue}
                    onChange={onSearchChanged}
                    placeholder={searchValue || 'Search'}
                    onSearch={onSearch}
                />
                <Separator />
            </>
        );
    };

    const renderExportFilter = () => {
        if (!exportButtonEnabled) return null;

        return (
            <>
                <ExportContainer>
                    <Button
                        variant={exportButtonVariant}
                        label={exportLabel || 'Export'}
                        onClick={handleExport}
                    />
                </ExportContainer>
                <Separator />
            </>
        );
    };

    const renderAddNewFilter = () => {
        if (!addNewButtonEnabled || !addNewLabel) return null;

        return (
            <AddButtonContainer>
                <Button
                    label={addNewLabel}
                    onClick={onAddNew}
                    variant={addButtonVariant}
                />
            </AddButtonContainer>
        );
    };

    return (
        <Container>
            {renderTypeFilter()}

            <FilterRow>
                <LeftContainer>
                    {renderStatusFilter()}
                    {renderDateFilter()}
                </LeftContainer>

                <RightContainer>
                    {renderSearchFilter()}
                    {renderExportFilter()}
                    {disableAddNew ? null : renderAddNewFilter()}
                </RightContainer>
            </FilterRow>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
`;

const FilterRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Separator = styled.div`
    width: 10px;
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const DropDownContainer = styled.div`

    @media (min-width: 600px) {
        width: 150px;
    }

    @media (min-width: 1280px) {
        width: 200px;
    }

    @media (min-width: 1520px) {
        width: 250px;
    }
`;

const TypeFilterContainer = styled.div`
    width: 300px;

    margin-bottom: 12px;
`;

const ExportContainer = styled.div`
    width: 174px;
`;

const AddButtonContainer = styled.div`
    width: fit-content;
    white-space: nowrap;
`;

export default TableFilters;
