import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { GetEnquiriesApiResponse } from 'api/EnquiriesBase';

import { IEnquiry } from 'entities/enquiries';

import {
    EnquiriesReduxState,
    GetEnquiriesPayload,
    GetEnquiryDetailsPayload,
    ResolveEnquiryPayload,
} from './types';

const initialState: EnquiriesReduxState = {
    actions: {
        getEnquiries: false,
        getEnquiryDetails: false,
        resolveEnquiries: false,
        deleteEnquiries: false,
    },
    filters: {
        currentPage: 0,
        dateStart: dayjs().startOf('day').subtract(30, 'days').toISOString(),
        dateEnd: dayjs().endOf('day').toISOString(),

        enquiryStatus: undefined,
        enquiryType: undefined,
        searchQuery: undefined,
    },
    ui: {
        confirmationResolveModalIsOpen: false,
        confirmationDeleteModalIsOpen: false,
    },
    enquiries: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    enquiryDetails: null,
    error: {
        getEnquiries: '',
        getEnquiryDetails: '',
        resolveEnquiries: '',
        deleteEnquiries: '',
    },
};

const enquiriesSlice = createSlice({
    name: 'enquiries',
    initialState,
    reducers: {
        enquiriesGetEnquiriesAttempt: (state) => {
            state.actions.getEnquiries = true;
            state.error.getEnquiries = '';
        },
        enquiriesGetEnquiriesSuccess: (state, action: PayloadAction<GetEnquiriesApiResponse>) => {
            state.actions.getEnquiries = false;
            state.enquiries = action.payload;
        },
        enquiriesGetEnquiriesFailure: (state, action: PayloadAction<string>) => {
            state.actions.getEnquiries = false;
            state.error.getEnquiries = action.payload ?? 'Something went wrong. Please try again.';
        },
        enquiriesSetFilters: (state, action: GetEnquiriesPayload) => {
            const { currentPage, dateEnd, dateStart, searchQuery, enquiryStatus, enquiryType } = action.payload;

            if (currentPage !== undefined) state.filters.currentPage = currentPage;
            if (dateStart) {
                state.filters.dateStart = dateStart;
                state.filters.dateEnd = undefined;
            }
            if (dateEnd) {
                state.filters.dateEnd = dateEnd;
                state.filters.currentPage = 0;
            }
            if (searchQuery !== undefined) {
                if (!searchQuery.length) state.filters.searchQuery = undefined;
                else state.filters.searchQuery = searchQuery;
            }
            if (enquiryStatus !== undefined) {
                if (enquiryStatus > 0) {
                    state.filters.enquiryStatus = enquiryStatus;
                    state.filters.currentPage = 0;
                } else {
                    state.filters.enquiryStatus = undefined;
                    state.filters.currentPage = 0;
                }
            }
            if (enquiryType !== undefined) {
                if (enquiryType > 0) {
                    state.filters.enquiryType = enquiryType;
                    state.filters.currentPage = 0;
                } else {
                    state.filters.enquiryType = undefined;
                    state.filters.currentPage = 0;
                }
            }
        },
        enquiriesResetEnquiriesFilters: (state) => {
            state.filters = initialState.filters;
        },
        enquiriesGetEnquiryDetailsAttempt: (state, _action: GetEnquiryDetailsPayload) => {
            state.actions.getEnquiryDetails = true;
            state.error.getEnquiryDetails = '';
        },
        enquiriesGetEnquiryDetailsSuccess: (state, action: PayloadAction<IEnquiry>) => {
            state.actions.getEnquiryDetails = false;
            state.enquiryDetails = action.payload;
        },
        enquiriesGetEnquiryDetailsFailure: (state, action: PayloadAction<string>) => {
            state.actions.getEnquiryDetails = false;
            state.error.getEnquiryDetails = action.payload ?? 'Something went wrong. Please try again.';
        },
        enquiriesResolveEnquiryAttempt: (state, _action: ResolveEnquiryPayload) => {
            state.actions.resolveEnquiries = true;
            state.error.resolveEnquiries = '';
        },
        enquiriesResolveEnquirySuccess: (state) => {
            state.actions.resolveEnquiries = false;
            state.ui.confirmationResolveModalIsOpen = false;
        },
        enquiriesResolveEnquiryFailure: (state, action: PayloadAction<string>) => {
            state.actions.resolveEnquiries = false;
            state.error.resolveEnquiries = action.payload ?? 'Something went wrong. Please try again.';
        },
        enquiriesDeleteEnquiryAttempt: (state, _action: ResolveEnquiryPayload) => {
            state.actions.deleteEnquiries = true;
            state.error.deleteEnquiries = '';
        },
        enquiriesDeleteEnquirySuccess: (state) => {
            state.actions.deleteEnquiries = false;
            state.ui.confirmationDeleteModalIsOpen = false;
        },
        enquiriesDeleteEnquiryFailure: (state, action: PayloadAction<string>) => {
            state.actions.deleteEnquiries = false;
            state.error.deleteEnquiries = action.payload ?? 'Something went wrong. Please try again.';
        },
        enquiriesSetResolveConfirmationModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.confirmationResolveModalIsOpen = action.payload;
        },
        enquiriesSetDeleteConfirmationModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.confirmationDeleteModalIsOpen = action.payload;
        },
        enquiriesResetState: (state) => {
            state.ui = initialState.ui;
            state.actions = initialState.actions;
            state.error = initialState.error;
        },
    },
});

export type EnquiriesState = typeof initialState;

export default {
    actions: enquiriesSlice.actions,
    reducers: enquiriesSlice.reducer,
};
