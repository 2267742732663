import { fork } from 'redux-saga/effects';

import OrderGateway from 'api/Order';
import { RootSagaReturnType } from 'sagas/types';

import watchConvertOrderStatusToPaid from './convertOrderStatusToPaid';
import watchConvertOrderStatusToUnpaid from './convertOrderStatusToUnpaid';
import watchCreateOrder from './createOrder';
import watchCreateOrderComment from './createOrderComment';
import watchCreateShipmentTracking from './createShipmentTracking';
import watchDeleteClientPo from './deleteClientPo';
import watchDeleteOrder from './deleteOrder';
import watchDeleteOrderAttachment from './deleteOrderAttachment';
import watchDeleteOrderAttachmentFolder from './deleteOrderAttachmentFolder';
import watchDeleteOrderComment from './deleteOrderComment';
import watchDeletePaymentProof from './deletePaymentProof';
import watchDeleteShipmentTracking from './deleteShipmentTracking';
import watchExportCommercialInvoice from './exportCommercialInvoice';
import watchExportDeliveryOrder from './exportDeliveryOrder';
import watchExportOrder from './exportOrder';
import watchGetAllShipmentTracking from './getAllShipmentTracking';
import watchGetOrderAttachmentFolderName from './getOrderAttachmentFolderName';
import watchGetOrderComment from './getOrderComment';
import watchGetOrderDetails from './getOrderDetails';
import watchGetOrderDetailsForPayment from './getOrderDetailsForPayment';
import watchGetOrderReport from './getOrderReport';
import watchGetPaymentLink from './getPaymentLink';
import watchGetShipmentTrackingDetails from './getShipmentTrackingDetails';
import watchGetTimeline from './getTimeline';
import watchSendPaymentLink from './sendPaymentLink';
import watchSetConfirmPayment from './setConfirmPayment';
import watchUpdateOrderComment from './updateOrderComment';
import watchUpdateOrderDetails from './updateOrderDetails';
import watchUpdateOrderStatus from './updateOrderStatus';
import watchUpdatePaymentTerms from './updatePaymentTerms';
import watchUpdateProcessOrder from './updateProcessOrder';
import watchUpdateShipmentTracking from './updateShipmentTracking';
import watchUploadClientPo from './uploadClientPo';
import watchUploadOrderAttachment from './uploadOrderAttachment';
import watchUploadPaymentProof from './uploadPaymentProof';

export default (api: OrderGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetOrderReport, api);
        yield fork(watchGetOrderDetails, api);
        yield fork(watchGetTimeline, api);
        yield fork(watchCreateOrder, api);
        yield fork(watchUpdateOrderDetails, api);
        yield fork(watchDeleteOrder, api);
        yield fork(watchExportOrder, api);
        yield fork(watchUpdateOrderStatus, api);
        yield fork(watchUpdateProcessOrder, api);
        yield fork(watchUpdatePaymentTerms, api);
        yield fork(watchCreateOrderComment, api);
        yield fork(watchGetOrderComment, api);
        yield fork(watchDeleteOrderComment, api);
        yield fork(watchUpdateOrderComment, api);
        yield fork(watchUploadClientPo, api);
        yield fork(watchDeleteClientPo, api);
        yield fork(watchUploadPaymentProof, api);
        yield fork(watchDeletePaymentProof, api);
        yield fork(watchUploadOrderAttachment, api);
        yield fork(watchDeleteOrderAttachment, api);
        yield fork(watchDeleteOrderAttachmentFolder, api);
        yield fork(watchGetAllShipmentTracking, api);
        yield fork(watchGetShipmentTrackingDetails, api);
        yield fork(watchCreateShipmentTracking, api);
        yield fork(watchUpdateShipmentTracking, api);
        yield fork(watchDeleteShipmentTracking, api);
        yield fork(watchGetOrderDetailsForPayment, api);
        yield fork(watchSetConfirmPayment, api);
        yield fork(watchGetPaymentLink, api);
        yield fork(watchSendPaymentLink, api);
        yield fork(watchExportDeliveryOrder, api);
        yield fork(watchExportCommercialInvoice, api);
        yield fork(watchConvertOrderStatusToPaid, api);
        yield fork(watchConvertOrderStatusToUnpaid, api);
        yield fork(watchGetOrderAttachmentFolderName, api);
    }

    return {
        rootSaga,
    };
};
