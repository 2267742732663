import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import PageHeader from 'components/atoms/PageHeader';
import Paginator from 'components/atoms/Paginator';
import Table, { IActionsArray, IHeaderArray } from 'components/atoms/Table';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import TableFilters from 'components/molecules/TableFilters';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
// import AuthSelectors from 'redux/slices/auth/Selectors';
import QuotationsSelector from 'redux/slices/quotations/Selectors';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { QuotationStatusEnum } from 'entities/quotations';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

const tableAvailableOptions = [{
    label: 'All statuses',
    value: 0,
}, {
    label: 'Pending',
    value: QuotationStatusEnum.Pending,
}, {
    label: 'Converted',
    value: QuotationStatusEnum.Converted,
}];

const Quotations = (): JSX.Element => {
    // const userInfo = useAppSelector(AuthSelectors.getUserInfo);

    const loading = useAppSelector(QuotationsSelector.getQuotationsAttempting);
    const error = useAppSelector(QuotationsSelector.getQuotationsError);

    const filters = useAppSelector(QuotationsSelector.getQuotationsFilters);

    const { maxIndex, data: quotations, totalRecords } = useAppSelector(QuotationsSelector.getQuotations);

    const deleteQuotationLoading = useAppSelector(QuotationsSelector.deleteQuotationAttempting);
    const deleteQuotationError = useAppSelector(QuotationsSelector.deleteQuotationError);

    const { confirmationDeleteModalIsOpen } = useAppSelector(state => state.quotations.ui);
    const dispatch = useAppDispatch();

    const { currentPage, searchQuery, dateStart, dateEnd, quoteStatus } = filters;

    const [idToDelete, setIdToDelete] = useState<string | null>(null);
    const [quoteNumberToDelete, setQuoteNumberToDelete] = useState<string | null>(null);

    const getQuotations = () => {
        dispatch(Actions.quoteGetQuotationsAttempt());
    };

    useEffect(() => {
        dispatch(Actions.quoteResetQuotationsFilters());
    }, []);

    useEffect(() => {
        getQuotations();
    }, [currentPage, quoteStatus, dateStart, dateEnd]);

    const onPaginatorPressed = (page: number) => {
        dispatch(Actions.quoteSetFilters({ currentPage: page }));
    };

    const onStatusFiltered = (option: number) => {
        dispatch(Actions.quoteSetFilters({
            quoteStatus: option,
        }));
    };

    const onDateFilterSelected = (selectedStartDate: Date, selectedEndDate: Date) => {
        dispatch(Actions.quoteSetFilters({
            dateStart: dayjs(selectedStartDate).startOf('day').toISOString(),
            dateEnd: selectedEndDate ? dayjs(selectedEndDate).endOf('day').toISOString() : undefined,
        }));
    };

    const onSearchQueryChanged = (val: string) => {
        dispatch(Actions.quoteSetFilters({
            searchQuery: val,
        }));
    };

    const onNewQuotedPressed = () => {
        NavActions.navToAddQuotations();
    };

    const onDeleteQuotationCancelled = () => {
        setIdToDelete(null);
        setQuoteNumberToDelete(null);

        dispatch(Actions.quoteSetConfirmDeleteModalIsOpen(false));
    };

    const onDeleteQuotationConfirmed = () => {
        if (idToDelete) {
            dispatch(Actions.quoteDeleteQuotationAttempt({ id: idToDelete }));
        } else {
            toast.error('Please select a quotation to delete');
        }
    };

    const tableHeaders: IHeaderArray[] = [
        { header: 'Quote No', field: 'quoteNo' },
        { header: 'Date', field: 'quotationDate' },
        { header: 'Customer', field: 'clientName', width: 250 },
        { header: 'User', field: 'preparedBy' },
        { header: 'Quotation', field: 'total' },
        { header: 'Status', field: 'status' },
        { header: 'Actions', field: 'action', width: 120 },
    ];

    const tableData = quotations.map((item) => ({
        id: item.id,
        onClick: () => NavActions.navToQuotationDetails(item.id),
        cellData: {
            quoteNo: item.quotationNumber,
            quotationDate: dayjs(item.quotationDate).format('DD/MM/YYYY'),
            preparedBy: item.personInCharge,
            clientName: item.clientName,
            total: item.finalPrice && `${Utils.Formatter.formatCurrency(item.currency)}${Utils.Formatter.formatPrice(item.finalPrice)}`,
            status: QuotationStatusEnum[item.quotationStatus],
        },
    }));

    const tableActions: IActionsArray[] = [{
        id: 'edit',
        icon: <EditIcon />,
        onClick: (quotationId: string) => {
            const selectedQuotation = quotations.find((item) => item.id === quotationId);
            if (selectedQuotation?.quotationStatus !== QuotationStatusEnum.Converted) {
                NavActions.navToEditQuotation(quotationId);
            } else {
                toast.error('Cannot edit converted quotation');
            }
        },
    }, {
        id: 'delete',
        icon: <DeleteIcon />,
        onClick: (quotationId: string) => {
            const selectedQuotation = quotations.find((item) => item.id === quotationId);
            if (selectedQuotation) {
                setIdToDelete(selectedQuotation.id);
                setQuoteNumberToDelete(selectedQuotation.quotationNumber);
            }

            dispatch(Actions.quoteSetConfirmDeleteModalIsOpen(true));
        },
    }];

    return (
        <AdminLayout>
            <PageHeader subtitle={`${totalRecords} total quotation(s)`}>
                Quotations
            </PageHeader>

            <TableFilters
                statusFilterEnabled
                availableStatuses={tableAvailableOptions}
                onStatusSelected={onStatusFiltered}
                selectedStatus={quoteStatus}
                dateFilterEnabled
                selectedFromDate={dateStart ? dayjs(dateStart).toDate() : undefined}
                selectedToDate={dateEnd ? dayjs(dateEnd).toDate() : undefined}
                onDatesSelected={onDateFilterSelected}
                searchFilterEnabled
                searchValue={searchQuery}
                onSearchChanged={onSearchQueryChanged}
                onSearch={getQuotations}
                addNewButtonEnabled
                addNewLabel='Add new quote'
                onAddNew={onNewQuotedPressed}
                addButtonVariant={ButtonVariant.secondary}
                // disableAddNew={userInfo?.role === AuthRoleEnum.Executive}
            />

            <Table
                headerArrays={tableHeaders}
                dataArray={tableData}
                actions={tableActions}
                style={{ marginTop: 32, marginBottom: 32 }}
                loading={loading}
                error={error}
                // disableActions={userInfo?.role === AuthRoleEnum.Executive}
            />

            <PaginationContainer>
                <Paginator
                    currentPage={currentPage}
                    onPagePressed={onPaginatorPressed}
                    maxPages={maxIndex}
                    disabled={loading}
                />
            </PaginationContainer>

            <ConfirmationModal
                isOpen={confirmationDeleteModalIsOpen}
                icon={<ConfirmationIcon />}
                title={`Delete "${quoteNumberToDelete}"?`}
                text='Please confirm deleting this quotation.'
                onCancel={onDeleteQuotationCancelled}
                onConfirm={onDeleteQuotationConfirmed}
                cancelText='Cancel'
                confirmText='Confirm'
                confirmButtonVariant={ButtonVariant.warning}
                loading={deleteQuotationLoading}
                error={deleteQuotationError}
            />
        </AdminLayout>
    );
};

const PaginationContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

export default Quotations;
