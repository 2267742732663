import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AdminLayout from 'components/Layout/AdminLayout';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/auth/Selectors';

const ChangePasswordScreen = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(Selectors.getChangePasswordAttempting);
    const error = useAppSelector(Selectors.getChangePasswordError);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [inputError, setInputError] = useState<string>('');

    useEffect(() => {
        setInputError(error);
    }, [error]);

    const handleOnSubmit = () => {
        if (!currentPassword) {
            setInputError('Please enter your current password');
        }
        if (!newPassword && !confirmPassword) {
            setInputError('Please enter your new password');
        } else if (!newPassword || !confirmPassword) {
            setInputError('Please fill in all fields');
        } else if (newPassword !== confirmPassword) {
            setInputError('Passwords do not match');
        } else {
            dispatch(Actions.authChangePasswordAttempt({
                currentPassword,
                newPassword,
            }));
            setInputError('');
        }
    };

    return (
        <AdminLayout>
            <Text variant={TextVariant.title}>
                Change Password
            </Text>
            <Text variant={TextVariant.default}>
                Your new password must contain one uppercase letter, one lowercase letter, one number, one special character and atleast 8 characters long.
            </Text>
            <InputContainer>
                <Input
                    variant='password'
                    placeholder='Current password'
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    error={!!inputError}
                />
                <Input
                    variant='password'
                    placeholder='New password'
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    error={!!inputError}
                />
                <Input
                    variant='password'
                    placeholder='Re-enter new password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    error={!!inputError}
                />
                <ErrorMessage error={!!inputError}>
                    <Text variant={TextVariant.error}>
                        {inputError}
                    </Text>
                </ErrorMessage>
            </InputContainer>

            <ChangePasswordButton
                label='Change password'
                onClick={handleOnSubmit}
                loading={loading}
            />
        </AdminLayout>
    );
};

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 1rem;
    margin-top: 2rem;
    padding-block: 1rem;
`;

const ErrorMessage = styled.div.withConfig({
    shouldForwardProp: prop => prop !== 'error',
}) <{ error?: boolean }>`
    visibility: ${props => (props.error ? 'visible' : 'hidden')};
    height: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 0rem;
    padding-left: 1.5rem;
`;

const ChangePasswordButton = styled(Button)`
    margin-top: 1rem;
    width: 20%;
`;

export default ChangePasswordScreen;
