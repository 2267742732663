import { ModulesEnum } from 'entities/auth';
import { TSideMenu } from 'entities/components';

import icons from 'assets/icons';

export const SideMenuLOV: TSideMenu[] = [
    {
        title: 'Main Menu',
        permissions: [
            ModulesEnum.Dashboard,
            ModulesEnum.Orders,
            ModulesEnum.Enquiries,
        ],
        submenu: [
            {
                title: 'Dashboard',
                path: '/',
                icon: icons.dashboardIcon,
                permission: ModulesEnum.Dashboard,
            },
            {
                title: 'Orders',
                path: '/orders',
                icon: icons.orderIcon,
                permission: ModulesEnum.Orders,
            },
            {
                title: 'Enquiries',
                path: '/enquiries',
                icon: icons.enquiriesIcon,
                permission: ModulesEnum.Enquiries,
            },
        ],
    },
    {
        title: 'Other Menu',
        permissions: [
            ModulesEnum.Quotations,
            ModulesEnum.PurchaseOrders,
            ModulesEnum.CustomerManagement,
            ModulesEnum.SupplierManagement,
        ],
        submenu: [
            {
                title: 'Quotations',
                path: '/quotations',
                icon: icons.pencilIcon,
                permission: ModulesEnum.Quotations,
            },
            {
                title: 'Purchase Order',
                path: '/purchase-orders',
                icon: icons.databaseIcon,
                permission: ModulesEnum.PurchaseOrders,
            },
            {
                title: 'Customers',
                path: '/customers',
                icon: icons.userIcon,
                permission: ModulesEnum.CustomerManagement,
            },
            // {
            //     title: 'Shipping Methods',
            //     path: '/shipping-methods',
            //     icon: icons.shippingIcon,
            // },
            // {
            //     title: 'Payment Methods',
            //     path: '/payment-methods',
            //     icon: icons.creditCardIcon,
            // },
            // {
            //     title: 'Currencies',
            //     path: '/currencies',
            //     icon: icons.currenciesIcon,
            // },
            {
                title: 'Suppliers',
                path: '/suppliers',
                icon: icons.storageBoxIcon,
                permission: ModulesEnum.SupplierManagement,
            },
            {
                title: 'Reports',
                path: '/reports',
                icon: icons.piechartIcon,
                permission: ModulesEnum.Reports,
            },
            // {
            //     title: 'Products',
            //     path: '/products',
            //     icon: icons.productsIcon,
            //     permission: ModulesEnum.Products,
            // }, // ? we upload manually
        ],
    },
    {
        title: 'Help & Settings',
        permissions: [
            ModulesEnum.UserManagement,
            ModulesEnum.Settings,
        ],
        submenu: [
            {
                title: 'User Management',
                path: '/user-management',
                icon: icons.userIcon,
                permission: ModulesEnum.UserManagement,
            },
            // {
            //     title: 'Settings',
            //     path: '/settings',
            //     icon: icons.settingIcon,
            //     permission: ModulesEnum.Settings,
            // },
        ],
    },
];

export default {
    SideMenuLOV,
};
