import React, { useEffect, useState } from 'react';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/ui/Selectors';

import { ICustomer } from 'entities/customer';

import CreateCustomerModal from '../CreateCustomerModal';
import InputWithSearch from '../InputWithSearch';

interface CustomerInputProps {
    disabled?: boolean;
    value?: string;

    onClientChanged: (customerId: string) => void;

    inputError?: boolean;

    companyId?: string;
}

const CustomerInput = (props: CustomerInputProps): JSX.Element => {
    const { disabled, value, onClientChanged, inputError, companyId } = props;

    const [newCustomerName, setNewCustomerName] = useState<string | undefined>(undefined);

    const dispatch = useAppDispatch();

    const customerList = useAppSelector(Selectors.getUiSearchCustomers);
    const customerListLoading = useAppSelector(Selectors.getUiSearchCustomersAttempting);
    const customerListError = useAppSelector(Selectors.getUiSearchCustomersError);

    const createCustomerModalIsOpen = useAppSelector(Selectors.getUiCreateCustomerModalIsOpen);
    const createCustomerLoading = useAppSelector(Selectors.getUiCreateCustomerAttempting);
    const createCustomerError = useAppSelector(Selectors.getUiCreateCustomerError);

    const onCustomerSearch = (searchQuery: string) => {
        dispatch(Actions.uiSearchForCustomersAttempt({ currentPage: -1, searchQuery }));
    };

    const onCustomerSelected = (customerId: string | number) => {
        if (onClientChanged) onClientChanged(customerId as string);
    };

    const onCreateCustomerClicked = (customerName: string) => {
        setNewCustomerName(customerName);
        dispatch(Actions.uiSetAddNewCustomerModalIsOpen(true));
    };

    const onSubmitCreateCustomer = (data: Partial<ICustomer>) => {
        dispatch(Actions.uiCreateCustomerAttempt(data));
        dispatch(Actions.customersSetCustomerDetails(data));
        setNewCustomerName(undefined);
    };

    const onCancelCreateCustomer = () => {
        dispatch(Actions.uiSetAddNewCustomerModalIsOpen(false));
        dispatch(Actions.uiSearchForCustomersAttempt({ currentPage: -1, searchQuery: '' }));
        setNewCustomerName(undefined);
    };

    const companyAccountFilter = customerList.filter((customer) => customer.companyAccountId === companyId as string);

    const customerDropdownOptions = companyAccountFilter.map((item) => {
        let label = item.contactPerson;
        if (item.companyName) label = `${label} (${item.companyName})`;

        return {
            label,
            value: item.id,
        };
    });

    return (
        <>
            <InputWithSearch
                inputError={inputError}
                label='Client/Customer name'
                disabled={disabled}
                value={value}
                data={customerDropdownOptions}
                onSelect={onCustomerSelected}
                // allowCreate // todo: disable for now
                createLabel='Add {search} as a new client'
                noDataFoundLabel="We can't seem to find this customer. Please create a new one in Customers page."
                onSearch={onCustomerSearch}
                onCreate={onCreateCustomerClicked}
                loading={customerListLoading}
                error={customerListError}
                mandatory
                companyId={companyId}
                requiredCompanyId
                isSearchable
                createClient
            />
            <CreateCustomerModal
                companyId={companyId}
                isOpen={createCustomerModalIsOpen}
                onModalClosed={onCancelCreateCustomer}
                onCreate={onSubmitCreateCustomer}
                loading={createCustomerLoading}
                error={createCustomerError}
                defaultCustomerName={newCustomerName}
                updateDefaultCustomerName={setNewCustomerName}
            />
        </>
    );
};

export default CustomerInput;
