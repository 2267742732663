import { IAddress } from './address';

export interface ISupplier {
    id: string;
    supplierName: string;
    contactPersonNumber: string;
    contactPersonName: string;
    contactPersonEmail: string;
    createdAt: string;
    companyAccountId: string;
    currency: CurrencyEnum;
}

export interface ISupplierDetails extends ISupplier {
    supplierContact: string;
    supplierDescription: string;
    supplierWebsite: string;
    supplierAddress: IAddress;
    state: string;
    country: string;
}

export enum CurrencyEnum {
    MalaysianRinggit = 1,
    SingaporeDollar = 2,
    USDollar = 3,
}
