/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import dayjs from 'dayjs';
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import styled from 'styled-components';

import { GetDashboardDetailsResponse } from 'api/HomeBase';

import Utils from 'lib/Utils';

interface StackedAreaChartProps {
    chartData: GetDashboardDetailsResponse['chartData'];
    style?: React.CSSProperties;
}

const CustomizedLabel = (props: any) => {
    const { x, y, stroke, value, month } = props;
    const currentMonth = new Date().getMonth() + 1;

    if (currentMonth === value as number) {
        return null;
    }
    return (
        <text
            x={x}
            y={y}
            dy={-4}
            fill={stroke}
            fontSize={12}
            textAnchor='middle'
            style={{ fontWeight: 'bold' }}
        >
            {value}
        </text>
    );
};

const CustomizedXAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={22} textAnchor='middle'>
                {payload.value}
            </text>
        </g>
    );
};

const CustomizedTooltip = (props: any) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
        return (
            <CustomTooltip>
                <p>{`${payload[0].payload.name} ${dayjs(new Date()).format('YYYY')}`}</p>
                <div>
                    <div id='orders'>{`${payload[0].value}`}</div>
                    <div id='sales'>{`${Utils.Formatter.formatPrice(payload[1].value)}`}</div>
                    <div id='members'>{`${payload[2].value}`}</div>
                </div>
            </CustomTooltip>
        );
    }

    return null;
};

const StackedAreaChart: React.FC<StackedAreaChartProps> = (props: StackedAreaChartProps) => {
    const { chartData, style } = props;
    const currentMonth = dayjs(new Date()).format('MMM');
    const MonthString = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const rearrangeMonthString = MonthString
        .slice(MonthString.indexOf(currentMonth) + 1, MonthString.length)
        .concat(MonthString.slice(0, MonthString.indexOf(currentMonth) + 1));

    const massagedChartData = rearrangeMonthString.map((month) => {
        const totalOrders = chartData.totalOrdersData.find(item => dayjs().month(item.month - 1).format('MMM') === month);
        const totalOrderSales = chartData.totalOrderSalesData.find(item => dayjs().month(item.month - 1).format('MMM') === month);
        const totalMembers = chartData.totalMembersData.find(item => dayjs().month(item.month - 1).format('MMM') === month);

        return {
            name: month,
            totalOrders: totalOrders ? totalOrders.value : 0,
            totalOrderSales: totalOrderSales ? totalOrderSales.value : 0,
            totalMembers: totalMembers ? totalMembers.value : 0,
        };
    });

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <AreaChart
                data={massagedChartData}
                margin={{
                    top: 20,
                    right: 0,
                    left: 0,
                    bottom: 20,
                }}
                style={style}
            >
                <CartesianGrid strokeDasharray='3 6' vertical={false} />
                <XAxis
                    dataKey='name'
                    axisLine={false}
                    tickLine={false}
                    textDecoration='none'
                    tick={<CustomizedXAxisTick />}
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                />
                <Tooltip
                    cursor={{ stroke: '#0B65EC', strokeWidth: 2 }}
                    content={<CustomizedTooltip />}
                />
                <defs>
                    <linearGradient id='GradTotalOrder' x1='0%' y1='0%' x2='0%' y2='1000%'>
                        <stop offset='0%' stopColor='#33BEAD' />
                        <stop offset='90%' stopColor='#ffffff' />
                    </linearGradient>
                    <linearGradient id='GradTotalOrderSales' x1='0%' y1='0%' x2='0%' y2='100%'>
                        <stop offset='0%' stopColor='#0B65EC' />
                        <stop offset='90%' stopColor='#FFFFFF' />
                    </linearGradient>
                    <linearGradient id='GradTotalMember' x1='0%' y1='0%' x2='0%' y2='100%'>
                        <stop offset='0%' stopColor='#FC9736' />
                        <stop offset='90%' stopColor='#fffffF' />
                    </linearGradient>
                </defs>
                <Area
                    type='bump'
                    dataKey='totalOrders'
                    stackId='1'
                    stroke='#33BEAD'
                    strokeWidth={4}
                    fillOpacity={0.6}
                    fill='url(#GradTotalOrder)'
                    activeDot={{ r: 8 }}
                />
                <Area
                    type='bump'
                    dataKey='totalOrderSales'
                    stackId='1'
                    stroke='#0B65EC'
                    strokeWidth={4}
                    fillOpacity={0.6}
                    fill='url(#GradTotalOrderSales)'
                    activeDot={{ r: 8 }}
                />
                <Area
                    type='bump'
                    dataKey='totalMembers'
                    stackId='1'
                    stroke='#FC9736'
                    strokeWidth={4}
                    fillOpacity={0.6}
                    fill='url(#GradTotalMember)'
                    activeDot={{ r: 8 }}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

const CustomTooltip = styled.div`
    border-radius: 0.5rem;
    background-color: #002F1B;
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    font-family: ${props => props.theme.fonts.primary};
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;

    gap: 0.25rem;

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        height: 1rem;

        #orders {
            &::before {
                content: '';
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 50%;
                background-color: #33BEAD;
            }
        }

        #sales {
            &::before {
                content: '';
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 50%;
                background-color: #0b65ec;
            }
        }

        #members {
            &::before {
                content: '';
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 50%;
                background-color: #fc9736;
            }
        }
    }
`;

export default StackedAreaChart;
