import React, { FC } from 'react';

import styled from 'styled-components';

import BackgroundImage from '../../assets/images/background.jpeg';

type AdminLayoutProps = {
    children: React.ReactNode;
    withBackground?: boolean;
    style?: React.CSSProperties;
}

const AdminLayout: FC<AdminLayoutProps> = (props: AdminLayoutProps) => {
    const { children, withBackground = false, style } = props;

    return (
        <MainContainer withBackground={withBackground}>
            <Content style={style}>
                {children}
            </Content>
        </MainContainer>
    );
};

const MainContainer = styled.main.withConfig({
    shouldForwardProp: prop => prop !== 'withBackground',
}) <{ withBackground?: boolean }>`
    background-color: ${props => props.theme.colors.background};
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;

    box-sizing: border-box;

    position: relative;

    color: ${props => props.theme.fontColor.primary};
    z-index: 3;

    ${props => props.withBackground && `
        &::after {
            content: '';
            position: absolute;
            top: -40px;
            left: -40px;

            width: 120%;
            height: 200px;

            background-image: url(${BackgroundImage});
            background-size: cover;
            background-repeat: no-repeat;
            background-position-y: center;

            transform: scaleX(-1);
            z-index: -1;
        }
    `}
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;

    overflow: hidden;
`;

export default AdminLayout;
