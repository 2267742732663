import { fork } from 'redux-saga/effects';

import CustomerGateway from 'api/Customer';
import SupplierGateway from 'api/Supplier';
import { RootSagaReturnType } from 'sagas/types';

import watchCreateCustomer from './createCustomer';
import watchGetCustomerAddresses from './getCustomerAddress';
import watchGetSupplierAddress from './getSupplierAddress';
import watchSearchForCustomers from './searchForCustomers';
import watchSearchForSuppliers from './searchForSuppliers';

export default (customerApi: CustomerGateway, supplierApi: SupplierGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchSearchForCustomers, customerApi);
        yield fork(watchCreateCustomer, customerApi);
        yield fork(watchGetCustomerAddresses, customerApi);
        yield fork(watchSearchForSuppliers, supplierApi);
        yield fork(watchGetSupplierAddress, supplierApi);
    }

    return {
        rootSaga,
    };
};
