import authSlice from 'redux/slices/auth';
import companySlice from 'redux/slices/company';
import customersSlice from 'redux/slices/customers';
import enquiriesSlice from 'redux/slices/enquiries';
import homeSlice from 'redux/slices/home';
import orderSlice from 'redux/slices/order';
import productsSlice from 'redux/slices/products';
import poSlice from 'redux/slices/purchaseOrder';
import quotationsSlice from 'redux/slices/quotations';
import reportSlice from 'redux/slices/report';
import suppliersSlice from 'redux/slices/suppliers';
import uiSlice from 'redux/slices/ui';
import userManagementSlice from 'redux/slices/userManagement';

export default {
    ...authSlice.actions,
    ...homeSlice.actions,
    ...orderSlice.actions,
    ...quotationsSlice.actions,
    ...suppliersSlice.actions,
    ...customersSlice.actions,
    ...poSlice.actions,
    ...enquiriesSlice.actions,
    ...uiSlice.actions,
    ...userManagementSlice.actions,
    ...productsSlice.actions,
    ...companySlice.actions,
    ...reportSlice.actions,
};
