import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import CustomersGateway from 'api/Customer';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import Selectors from 'redux/slices/customers/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetCustomers(api: CustomersGateway): SagaWatcherReturnType {
    yield takeEvery('customers/customersGetCustomersAttempt', handleGetCustomers, api);
}

function* handleGetCustomers(api: CustomersGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const customerFilters = yield* select(Selectors.getCustomerFilters);

    const response = yield* call([api, api.getCustomers], {
        authToken,
        currentPage: customerFilters.currentPage,
        searchQuery: customerFilters.searchQuery,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.customersGetCustomersFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.customersGetCustomersSuccess(response.data));
    }
}
