import React from 'react';

import debounce from 'lodash.debounce';
import styled from 'styled-components';

import { ReactComponent as searchIcon } from 'assets/icons/searchIcon.svg';

interface SearchProps {
  placeholder: string;
  value?: string;
  onChange: (text: string) => void;
  onSearch: () => void;
}

const SearchButton = (props: SearchProps): JSX.Element => {
    const {
        placeholder,
        value = '',
        onChange,
        onSearch,
    } = props;

    const debouncedSearch = React.useMemo(() => debounce(onSearch, 500, { trailing: true }), []);

    const onTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
        debouncedSearch();
    };

    const onSearchPressed = () => {
        onSearch();

        if (debouncedSearch) debouncedSearch.cancel();
    };

    return (
        <SearchContainer>
            <SearchInput
                placeholder={placeholder}
                value={value}
                onChange={onTextChanged}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        onSearchPressed();
                    }
                }}
                autoFocus
            />
            <SearchIcon onClick={onSearchPressed} />
        </SearchContainer>
    );
};

const SearchContainer = styled.div`
    position: relative;
    height: 47px;
    
    border-radius: 0;
    display: flex;
    asign-items: center;
    justify-content: space-between;

    @media (min-width: 600px) {
        width: 150px;
    }

    @media (min-width: 1280px) {
        width: 250px;
    }

    @media (min-width: 1520px) {
        width: 320px;
    }
`;

const SearchInput = styled.input`
    background-color: white;
    outline: none;
    border-radius: 25px;
    position: absolute;
    display: flex;
    box-sizing: border-box;


    height: 100%;
    width: 100%;
    padding: 5px 45px 5px 15px;

    border: 1px solid transparent;
    font-size: 18px;
    font-family: ${props => props.theme.fonts.primary};

    justify-content: center;
    align-items: center;

    @media (min-width: 600px) {
        font-size: ${props => props.theme.fontSize.xs};
        line-height: ${props => props.theme.lineHeight.xs};
    }

    @media (min-width: 1280px) {
        font-size: ${props => props.theme.fontSize.sm};
        line-height: ${props => props.theme.lineHeight.sm};
    }

    @media (min-width: 1520px) {
        font-size: ${props => props.theme.fontSize.default};
        line-height: ${props => props.theme.lineHeight.default};
    }
`;

const SearchIcon = styled(searchIcon)`
    width: 24px;
    height: 24px;
    right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    vertical-align: -0.125em;
    background-color: transparent;
    cursor: pointer;

    position: absolute;
`;

export default SearchButton;
