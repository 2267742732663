import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IAddress } from 'entities/address';
import { IPrintMethodParams } from 'entities/products';
import { IEmailVariables, IPurchaseOrder, IPurchaseOrderList, PurchaseOrderStatusEnum } from 'entities/purchaseOrders';

export interface GetPurchaseOrdersApiParams {
    authToken: string;
    index: number;

    searchQuery?: string;
    dateStart?: string;
    dateEnd?: string;
    status?: PurchaseOrderStatusEnum;
}

export interface GetPurchaseOrdersApiResponse {
    maxIndex: number;
    index: number;
    totalRecords: number;
    data: IPurchaseOrderList[];
}

export interface GetPurchaseOrderDetailsApiParams {
    authToken: string;
    purchaseOrderId: string;
}

export interface IPurchaseOrderProductDetails {
    id: string;
    productName: string;
    quantity: number;
    productPrice: number;
    productDiscount: number;
    productTotal: number;
}

export interface UpdatePurchaseOrderApiParams {
    authToken: string;
    id: string;
    status?: PurchaseOrderStatusEnum;
}

export interface CreatePoApiParams {
    authToken: string;
    poDate: string;
    orderId?: string;
    orderNo?: string;
    companyAccountId: string;
    supplierId: string;
    supplierName: string;
    supplierAddress: IAddress;
    deliveryCompanyName?: string;
    deliveryAddress: IAddress;
    products: {
        productId: string;
        variantId: string;
        productName: string;
        productVariant: {
            fit?: string;
            type?: string;
            sleeve?: string;
            style?: string;
            color?: string;
        },
        quantities: {
            productPriceId: string;
            size?: string;
            quantity: number;
            pricePerUnit: number;
        }[],
        printMethods: IPrintMethodParams[],
        finalQuantity: number;
        totalPricePerUnit: number;
        subtotal: number;
        discountPercent?: number;
        discountFixed?: number;
        finalProductPrice: number;
    }[],
    customLineProducts: {
        productName: string;
        finalQuantity: number;
        finalProductPrice: number;
        totalPricePerUnit: number;
        subtotal: number;
        discountPercent?: number;
        discountFixed?: number;
    }[];
    subtotal: number;
    discountPercent?: number;
    discountFixed?: number;
    shippingFee: number;
    tax: number;
    finalPrice: number;

    note?: string;
    remark?: string;

    totalWeight?: number;

    emailVariables?: IEmailVariables;
}

export interface UpdatePoApiParams extends CreatePoApiParams {
    id: string;
    status: PurchaseOrderStatusEnum;
}

export interface PoProductListApiParams {
    productId: string;
    variantId: string;
    productName: string;
    productVariant: {
        fit?: string;
        type?: string;
        sleeve?: string;
        style?: string;
        color?: string;
    },
    quantities: {
        productPriceId: string;
        size?: string;
        quantity: number;
        pricePerUnit?: number;
    }[],
    printMethods: IPrintMethodParams[],
    finalQuantity: number;
    subtotal: number;
    discountPercent?: number;
    discountFixed?: number;
    finalProductPrice: number;
}

export interface DeletePurchaseOrderApiParams {
    authToken: string;
    id: string;
}

export interface DownloadPurchaseOrderApiParams {
    authToken: string;
    id: string;
}

export interface SendPurchaseOrderApiParams {
    authToken: string;
    id: string;
    supplierEmails: string[];
}

export interface ViewPurchaseOrderEmailParams{
    authToken: string;
    id: string;
}

export abstract class IPurchaseOrderGateway extends Gateway {
    abstract getPurchaseOrders(params: GetPurchaseOrdersApiParams): GatewayResponse<GetPurchaseOrdersApiResponse>;

    abstract getPurchaseOrderDetails(params: GetPurchaseOrderDetailsApiParams): GatewayResponse<IPurchaseOrder>;

    abstract updatePurchaseOrderDetailsStatus(params: UpdatePurchaseOrderApiParams): GatewayResponse<string | null>;

    abstract createPurchaseOrder(params: CreatePoApiParams): GatewayResponse<string | null>;

    abstract editPurchaseOrder(params: UpdatePoApiParams): GatewayResponse<string | null>;

    abstract deletePurchaseOrder(params: DeletePurchaseOrderApiParams): GatewayResponse<null>;

    abstract downloadPurchaseOrder(params: DownloadPurchaseOrderApiParams): GatewayResponse<Blob>;

    abstract sendPurchaseOrder(params: SendPurchaseOrderApiParams): GatewayResponse<null>;

    abstract viewPurchaseOrderEmail(params: ViewPurchaseOrderEmailParams): GatewayResponse<BlobPart>;
}
