import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';

import {
    ChangePasswordParams,
    ForgotPasswordParam,
    IAuthGateway,
    LoginParams,
    LoginResponse,
    RefreshParam,
    ResetPasswordParams,
} from './AuthBase';

export default class AuthGateway extends IAuthGateway {
    async refresh(params: RefreshParam): GatewayResponse<LoginResponse> {
        const response: ApiResponse<LoginResponse> = await this.api.post('/auth/refresh', params);

        return this.process(response);
    }

    async login(params: LoginParams): GatewayResponse<LoginResponse> {
        const response: ApiResponse<LoginResponse> = await this.api.post('/auth/login', params);

        return this.process(response);
    }

    async forgotPassword(params: ForgotPasswordParam): GatewayResponse<string> {
        const response: ApiResponse<string> = await this.api.post('/auth/requestResetPassword', params);

        return this.process(response);
    }

    async resetPassword(params: ResetPasswordParams): GatewayResponse<string> {
        const response: ApiResponse<string> = await this.api.post('/auth/resetPassword', params);

        return this.process(response);
    }

    async changePassword(params: ChangePasswordParams): GatewayResponse<string | null> {
        const { authToken, ...rest } = params;
        const response: ApiResponse<string> = await this.api.put(
            '/auth/changePassword',
            rest,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }
}
