export type IAuthUser = {
    userId: string;
    email: string;
    fullName: string;
    role: AuthRoleEnum;
    rolePermission: ModulesEnum[];
}

export enum AuthRoleEnum {
    Admin = 1,
    Executive = 2,
    Manager = 3,
    Supplier = 4,
    Deactivated = 5,
}

export enum ModulesEnum {
    Dashboard = 1,
    Orders = 2,
    Enquiries = 3,
    Quotations = 4,
    PurchaseOrders = 5,
    CustomerManagement = 6,
    SupplierManagement = 7,
    Reports = 8,
    Products = 9,
    UserManagement = 10,
    Settings = 11,
}
