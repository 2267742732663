import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import SupplierGateway from 'api/Supplier';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { FormSearchCustomersPayload } from 'redux/slices/ui/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchSearchForSuppliers(api: SupplierGateway): SagaWatcherReturnType {
    yield takeEvery('ui/uiSearchForSuppliersAttempt', handleGetSuppliers, api);
}

function* handleGetSuppliers(api: SupplierGateway, data: FormSearchCustomersPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getSuppliers], {
        authToken,
        currentPage: data.payload.currentPage ? data.payload.currentPage : -1,
        searchQuery: data.payload.searchQuery ? data.payload.searchQuery : undefined,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.uiSearchForSuppliersFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.uiSearchForSuppliersSuccess(response.data.data));
    }
}
