import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { UpdatePaymentTermsPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchUpdatePaymentTerms(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderUpdatePaymentTermsAttempt', handleUpdatePaymentTerms, api);
}
function* handleUpdatePaymentTerms(api: OrderGateway, data: UpdatePaymentTermsPayload) {
    const authToken = yield* select((state) => state.auth.authToken);

    const { orderId, paymentTerm, paymentDueDate } = data.payload;

    const response = yield* call([api, api.updatePaymentTerms], {
        authToken,
        orderId,
        paymentTerm,
        paymentDueDate,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderUpdatePaymentTermsFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderUpdatePaymentTermsSuccess(response.data));
        yield put(Actions.orderGetOrderDetailsAttempt({ id: orderId }));
        toast.success('Payment terms updated');
    }
}
