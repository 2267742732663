import React from 'react';

import styled from 'styled-components';

type ToggleProps = {
    isActive: boolean;
    onClick: () => void;
    disabled?: boolean;
}

const Toggle: React.FC<ToggleProps> = (props: ToggleProps) => {
    const {
        isActive,
        onClick,
        disabled,
    } = props;

    return (
        <ToggleContainer isActive={isActive} onClick={onClick}>
            <span className='slider round' />
        </ToggleContainer>
    );
};

const ToggleContainer = styled.div<{ isActive?: boolean }>`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${props => (props.isActive ? props.theme.colors.primary : props.theme.colors.tertiary)};
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: ${props => (props.isActive ? '30px' : '4px')};
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
    border-radius: 50%;
    }
`;

export default Toggle;
