import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import ProductsGateway from 'api/Products';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { GetPrintMethodPayload } from 'redux/slices/products/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetPrintMethod(api: ProductsGateway): SagaWatcherReturnType {
    yield takeEvery('products/productsGetPrintMethodAttempt', handleGetPrintMethod, api);
}

function* handleGetPrintMethod(api: ProductsGateway, data: GetPrintMethodPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getPrintMethod], {
        authToken,
        index: data.payload.index,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.productsGetPrintMethodFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.productsGetPrintMethodSuccess(response.data));
    }
}
