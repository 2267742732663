import React, { useEffect } from 'react';

import styled from 'styled-components';

import PageHeader from 'components/atoms/PageHeader';
import AdminLayout from 'components/Layout/AdminLayout';
import DiscardSaveButtons from 'components/molecules/DiscardSaveButtons';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/userManagement/Selectors';

import NavActions from 'lib/NavActions';
import { IUserManagementDetails } from 'entities/userManagement';

import UserManagementForm from './components/UserManagementForm';

const AddAdmin = (): JSX.Element => {
    const newUser = useAppSelector(Selectors.getUserManagementDetails);

    const loading = useAppSelector(Selectors.createUserManagementAttempting);
    const error = useAppSelector(Selectors.createUserManagementError);

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(Actions.userManagementClearCreateUserManagement());
        };
    }, []);

    const onChange = (update: Partial<IUserManagementDetails>) => {
        dispatch(Actions.userManagementSetUserManagementDetails(update));
    };

    const onSubmit = () => {
        dispatch(Actions.userManagementCreateUserManagementAttempt());
    };

    return (
        <AdminLayout>
            <PageHeader withBackButton>
                Add New Admin
            </PageHeader>
            <FormContainer>
                <UserManagementForm
                    error={error}
                    admin={newUser}
                    onChange={onChange}
                    passwordRequired
                />
                <ButtonRow>
                    <DiscardSaveButtons
                        onDiscard={NavActions.navBack}
                        onSave={onSubmit}
                        saveLabel='Add new admin'
                        loading={loading}
                        error={error}
                    />
                </ButtonRow>
            </FormContainer>
        </AdminLayout>
    );
};

const FormContainer = styled.div`
    height: 750px;
    display: flex;
    flex-direction: column;
`;

const ButtonRow = styled.div`
    width: 50%;
    margin-top: 18px;
`;

export default AddAdmin;
