import React from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import LoadingIndicator from 'components/atoms/LoadingIndicator';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import { GetTimelineResponse } from 'api/OrderBase';

import Utils from 'lib/Utils';
import { IAdditionalDataForOrderAuditLog, OrderAuditLogActionTypeEnum, OrderPaymentStatusEnum, OrderStatusEnum, PaymentChannelEnum } from 'entities/order';
import { CurrencyEnum } from 'entities/supplier';

interface ComponentProps {
    timelines: GetTimelineResponse[];
    currency: CurrencyEnum;
    loading?: boolean;
    error?: string;
}

const AuditLog: React.FC<ComponentProps> = (props: ComponentProps) => {
    const { timelines, currency, loading, error } = props;

    const formatDate = (dateString: string) => {
        const date = dayjs(dateString);
        return date.format('DD MMMM YYYY');
    };

    const formatTime = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    // Group timelines with the same date
    const groupedByDate = timelines.reduce((acc: { [key: string]: GetTimelineResponse[] }, timeline) => {
        const date = formatDate(timeline.createdAt);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(timeline);
        return acc;
    }, {});

    // Convert the grouped object to an array of date groups
    const groupedTimelines = Object.entries(groupedByDate).map(([date, items]) => ({
        date,
        items,
    }));

    const getStatus = (statusUpdate: number | undefined) => {
        switch (statusUpdate) {
            case 1:
                return 'pending.';
            case 2:
                return 'sent to production.';
            case 3:
                return 'packing.';
            case 4:
                return 'shipped';
            case 5:
                return 'delivered.';
            case 6:
                return 'cancelled.';
            default:
                return '';
        }
    };

    const getShippingMethod = (statusUpdate: number | undefined) => {
        switch (statusUpdate) {
            case 1:
                return 'International Shipping.';
            case 2:
                return 'East Malaysia Shipping.';
            case 3:
                return 'West Malaysia Shipping.';
            default:
                return '';
        }
    };
    const renderPaymentStatusUpdate = (status: IAdditionalDataForOrderAuditLog) => {
        if (status.paymentStatusUpdate === OrderPaymentStatusEnum.Paid) {
            return 'Paid.';
        }
        if (status.paymentStatusUpdate === OrderPaymentStatusEnum.PartiallyPaid) {
            return 'Partially Paid.';
        }
        if (status.paymentStatusUpdate === OrderPaymentStatusEnum.POReceived) {
            return 'Purchase Order Recieved.';
        }
        if (status.paymentStatusUpdate === OrderPaymentStatusEnum.Unpaid) {
            return 'Unpaid.';
        }
        return ' ';
    };

    const renderMessage = (action: OrderAuditLogActionTypeEnum, person: string, data: IAdditionalDataForOrderAuditLog) => {
        if (!data) return '';

        switch (action) {
            case OrderAuditLogActionTypeEnum.OrderCreated:
                return (
                    <>
                        <span style={{ fontWeight: 'bold' }}>{person}</span>
                        {' '}
                        created this order.
                    </>
                );
            case OrderAuditLogActionTypeEnum.StatusUpdate:
                return (
                    <>
                        <span style={{ fontWeight: 'bold' }}>{person}</span>
                        {' '}
                        marked this order as
                        {' '}
                        <span style={{ fontWeight: 'bold' }}>{getStatus(data.statusUpdate)}</span>
                    </>
                );
            case OrderAuditLogActionTypeEnum.PaymentReceived:
                if (data?.paymentMethod === PaymentChannelEnum.PaymentProof) {
                    if (data.paymentStatusUpdate === 4) {
                        return (
                            <>
                                <span style={{ fontWeight: 'bold' }}>Partial payment </span>
                                {' '}
                                <span>of </span>
                                {' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    {
                                        data.paymentAmount
                                            ? `${Utils.Formatter.formatCurrency(currency)}${data.paymentAmount}`
                                            : null
                                    }
                                </span>
                                {' '}
                                <span>received. Ref: </span>
                                {' '}
                                <span style={{ fontWeight: 'bold' }}>{data.paymentProofFileName ? `${data.paymentProofFileName}.` : 'uploaded file'}</span>
                            </>
                        );
                    }

                    return (
                        <>
                            <span style={{ fontWeight: 'bold' }}>Full payment </span>
                            {' '}
                            <span>of </span>
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {
                                    data.paymentAmount
                                        ? `${Utils.Formatter.formatCurrency(currency)}${data.paymentAmount}`
                                        : null
                                }
                            </span>
                            {' '}
                            <span>received. Ref: </span>
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>{data.paymentProofFileName ? `${data.paymentProofFileName}.` : 'uploaded file'}</span>
                        </>
                    );
                }
                return (
                    <>
                        Payment of
                        {' '}
                        <span style={{ fontWeight: 'bold' }}>{`${Utils.Formatter.formatCurrency(currency)}${data.paymentAmount}`}</span>
                        {' '}
                        with
                        {' '}
                        <span style={{ fontWeight: 'bold' }}>{data.paymentMethod === 1 ? 'Stripe' : 'Online Banking'}</span>
                        {' '}
                        received.
                    </>
                );
            case OrderAuditLogActionTypeEnum.Shipped:
                return (
                    <>
                        <span style={{ fontWeight: 'bold' }}>{person}</span>
                        {' '}
                        marked order has been shipped with
                        {' '}
                        <span style={{ fontWeight: 'bold' }}>{getShippingMethod(data.shippingMethod)}</span>
                    </>
                );
            case OrderAuditLogActionTypeEnum.PaymentProofRemoved:
                return (
                    <>
                        <span>
                            Payment proof
                        </span>
                        {' '}
                        <span style={{ fontWeight: 'bold' }}>{data.paymentProofFileName}</span>
                        {' '}
                        <span>was removed and payment of </span>
                        {' '}
                        <span style={{ fontWeight: 'bold' }}>{`${Utils.Formatter.formatCurrency(currency)}${data.paymentAmount}`}</span>
                        {' '}
                        <span>was voided.</span>
                    </>
                );
            case OrderAuditLogActionTypeEnum.PaymentStatusUpdate:
                return (
                    <>
                        <span>
                            Order payment status changed to
                        </span>
                        {' '}
                        <span style={{ fontWeight: 'bold' }}>
                            {renderPaymentStatusUpdate(data)}
                        </span>
                    </>
                );
            default:
                return `${person} performed action ${action}.`;
        }
    };

    if (loading) {
        return (
            <FlexContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
                <LoadingIndicator />
            </FlexContainer>
        );
    }
    if (error) {
        return (
            <FlexContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text variant={TextVariant.error}>{error}</Text>
            </FlexContainer>
        );
    }

    return (
        <FlexContainer>
            <Text variant={TextVariant.h2}>Audit Logs</Text>
            <TimeLinesContainer>
                {groupedTimelines.map((group, index) => (
                    <TimeLine key={`${group.date.toString()}-${index + 1}`}>
                        <TimeLineDate>
                            <RoundShape />
                            <div>{group.date}</div>
                        </TimeLineDate>
                        {group.items.map((timeline) => (
                            <TimeLineInfo>
                                <div>{formatTime(timeline.createdAt)}</div>
                                <div>{renderMessage(timeline.action, timeline.createdByName, timeline.data)}</div>
                            </TimeLineInfo>
                        ))}
                    </TimeLine>
                ))}
            </TimeLinesContainer>
        </FlexContainer>
    );
};

const FlexContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    padding: 2rem;
    gap: 2rem;
`;

const TimeLinesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

`;

const TimeLine = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const TimeLineDate = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 10px;
    font-weight: bold;
`;

const RoundShape = styled.div`
    background-color: #14D2BB;
    width: 10px;
    height: 10px;
    border-radius: 100%;
`;

const TimeLineInfo = styled.div`
    border-left: 3px solid #42564E33;
    margin-left: 3px;
    padding: 10px 15px;
    color: #42564EA6;
`;

export default AuditLog;
