import React, { useState } from 'react';

import styled from 'styled-components';

import LoadingIndicator from 'components/atoms/LoadingIndicator';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

interface TabsProps {
    tabs: {
        label: string;
        content: React.ReactNode;
    }[];
    loading?: boolean;
    error?: string;
}

const TabContainer: React.FC<TabsProps> = ({
    tabs,
    loading,
    error,
}) => {
    const [activeTab, setActiveTab] = useState(0);
    if (tabs.length < 1) return null;

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    if (loading) {
        return (
            <FlexContainer style={{ justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                <LoadingIndicator />
            </FlexContainer>
        );
    }
    if (error) {
        return (
            <FlexContainer style={{ justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                <Text variant={TextVariant.error}>{error}</Text>
            </FlexContainer>
        );
    }

    return (
        <FlexContainer>
            <TabList>
                {tabs && tabs.map((tab, index) => (
                    <Tab key={tab.label} isActive={activeTab === index} onClick={() => handleTabClick(index)}>
                        {tab.label}
                    </Tab>
                ))}
            </TabList>
            <TabContent>
                {tabs[activeTab].content}
            </TabContent>
        </FlexContainer>
    );
};

export default TabContainer;

const FlexContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    background-color: white;
    border-radius: 15px;
    padding: 1rem 0;
`;

const TabList = styled.div`
    display: flex;
    border-bottom: 1px solid #42564E33;
    padding-bottom: 10px;
    padding-inline: 1rem;
    gap: 10px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

`;

const Tab = styled.button<{ isActive: boolean }>`
    padding: 10px 20px;
    border-radius: 100px;
    min-width: 150px;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    color: ${(props) => (props.isActive ? '#295543' : '#42564EA6')};
    font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
    
    background: ${(props) => (props.isActive ? '#CBDAD3' : 'transparent')};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        background: #42564E33;
    }
`;

const TabContent = styled.div`
    padding: 20px 50px;
`;
