import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';
import { IPrintMethodVariant, IProductPricing, IProductVariant } from 'entities/products';

import {
    GetPrintMethodApiParams,
    GetPrintMethodApiResponse,
    getPrintMethodPricingApiParams,
    GetPrintMethodVariantApiParams,
    GetProductPricingApiParams,
    GetProductsApiParams,
    GetProductsApiResponse,
    GetProductVariantApiParams,
    GetShippingCostParams,
    GetShippingCostResponse,
    IProductsGateway,
    UploadProductsFilesApiParams,
} from './ProductsBase';

export default class ProductsGateway extends IProductsGateway {
    async uploadProductsFiles(params: UploadProductsFilesApiParams): GatewayResponse<string | File | null> {
        const { authToken, file } = params;

        const form = new FormData();

        if (file) {
            form.append('file', file as Blob);
        }

        const response: ApiResponse<string | File | null> = await this.api.post(
            '/products/upload',
            form,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getProducts(params: GetProductsApiParams): GatewayResponse<GetProductsApiResponse> {
        const { authToken, ...rest } = params;
        const response: ApiResponse<GetProductsApiResponse> = await this.api.get(
            '/products',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getProductVariant(params: GetProductVariantApiParams): GatewayResponse<IProductVariant[]> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<IProductVariant[]> = await this.api.get(
            '/products/variant',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getProductPricing(params: GetProductPricingApiParams): GatewayResponse<IProductPricing[]> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<IProductPricing[]> = await this.api.get(
            '/products/pricing',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getPrintMethod(params: GetPrintMethodApiParams): GatewayResponse<GetPrintMethodApiResponse> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<GetPrintMethodApiResponse> = await this.api.get(
            '/printMethods',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getPrintMethodVariant(params: GetPrintMethodVariantApiParams): GatewayResponse<IPrintMethodVariant[]> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<IPrintMethodVariant[]> = await this.api.get(
            '/printMethods/variant',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getPrintMethodPricing(params: getPrintMethodPricingApiParams): GatewayResponse<IProductPricing[]> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<IProductPricing[]> = await this.api.get(
            '/printMethods/pricing',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getShippingCost(params: GetShippingCostParams): GatewayResponse<GetShippingCostResponse> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<GetShippingCostResponse> = await this.api.get(
            '/shipping/weightRate',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }
}
