import React from 'react';

import styled from 'styled-components';

import NavActions from 'lib/NavActions';

import { ReactComponent as BackIcon } from 'assets/icons/back.svg';

import theme from 'styles/theme';

import { TextVariant } from './variants/TextVariant';
import Text from './Text';

interface PageHeaderProps {
    children: React.ReactNode;

    withBackButton?: boolean;
    subtitle?: string;
}

const PageHeader = (props: PageHeaderProps): JSX.Element => {
    const { children, withBackButton, subtitle } = props;

    const renderBackButton = () => {
        if (withBackButton) {
            return (
                <BackRow onClick={NavActions.navBack}>
                    <BackIcon height={20} width={20} />
                    <BackText>Back</BackText>
                </BackRow>
            );
        }

        return null;
    };

    return (
        <Container>
            {renderBackButton()}

            <Text variant={TextVariant.title}>{children}</Text>
            {subtitle && (<Text>{subtitle}</Text>)}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;

    margin-bottom: 12px;
`;

const BackRow = styled.div`
    display: flex;
    flex-direction: row;

    width: 80px;

    align-items: center;
    gap: 4px;

    padding: 4px 0px 12px 0px;
    cursor: pointer;
`;

const BackText = styled(Text)`
    font-weight: 500;
    font-size: 16px;
    
    color: ${theme.fontColor.primary};
`;

export default PageHeader;
