import { fork } from 'redux-saga/effects';

import QuotationsGateway from 'api/Quotations';
import { RootSagaReturnType } from 'sagas/types';

import watchConvertQuotationToOrder from './convertQuotationToOrder';
import watchCreateQuotation from './createQuotation';
import watchDeleteQuotation from './deleteQuotation';
import watchGetQuotationDetails from './getQuotationDetails';
import watchGetQuotationDetailsExport from './getQuotationDetailsExport';
import watchGetQuotations from './getQuotations';
import watchUpdateQuotation from './updateQuotation';

export default (api: QuotationsGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetQuotations, api);
        yield fork(watchCreateQuotation, api);
        yield fork(watchDeleteQuotation, api);
        yield fork(watchGetQuotationDetails, api);
        yield fork(watchGetQuotationDetailsExport, api);
        yield fork(watchConvertQuotationToOrder, api);
        yield fork(watchUpdateQuotation, api);
    }

    return {
        rootSaga,
    };
};
