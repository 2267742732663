import dayjs from 'dayjs';
import { call, put, takeEvery } from 'typed-redux-saga/macro';

import AuthGateway from 'api/Auth';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { SagaWatcherReturnType } from 'sagas/types';

import Utils from 'lib/Utils';

export default function* watchStartup(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authStartupAttempt', handleStartup, api);
}

function* handleStartup(api: AuthGateway) {
    const localAuthToken = Utils.Auth.getAuthToken();
    const localRefreshToken = Utils.Auth.getRefreshToken();

    const resetAuthLocalStorage = () => {
        Utils.Auth.clearAuthToken();
        Utils.Auth.clearUserInfo();
        Utils.Auth.clearExpiresAt();
    };

    if (localAuthToken && localRefreshToken) {
        if ((Number(Utils.Auth.getExpiresAt()) > dayjs().unix())) {
            const response = yield* call([api, api.refresh], { refreshToken: localRefreshToken });

            if (response.status === GatewayResponseStatus.Success) {
                if (response.data) {
                    const {
                        authToken,
                        refreshToken,
                        userInfo,
                    } = response.data;

                    Utils.Auth.storeAuthToken(authToken);
                    Utils.Auth.storeRefreshToken(refreshToken);
                    // Utils.Auth.storeUserEmail(userInfo.email);
                    // Utils.Auth.storeUserName(userInfo.fullName);
                    // Utils.Auth.storeUserRole(userInfo.role);
                    // Utils.Auth.storeUserPermissions(userInfo.rolePermission);
                    yield* put(Actions.authLoginSuccess(authToken));
                    yield* put(Actions.authSetUserInfo(userInfo));
                } else {
                    yield put(Actions.authLoginFailure('Something went wrong. Please try again.'));
                }
            }

            if (response.status === GatewayResponseStatus.Error) {
                resetAuthLocalStorage();
            }
        } else {
            resetAuthLocalStorage();
        }
    } else {
        resetAuthLocalStorage();
    }

    yield put(Actions.authStartupSuccess());
}
