import React, { useEffect } from 'react';

import styled from 'styled-components';

import AddressDisplay from 'components/atoms/AddressDisplay';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/ui/Selectors';

import { IAddress } from 'entities/address';

interface OrderSupplierAddressProps {
    onChange: (address: IAddress) => void;
    supplierId?: string;
    value?: IAddress;
}

const SupplierAddressInput = (props: OrderSupplierAddressProps): JSX.Element => {
    const { onChange, supplierId, value } = props;

    const dispatch = useAppDispatch();

    const loading = useAppSelector(Selectors.getUiSupplierAddressAttempting);
    const error = useAppSelector(Selectors.getUiSupplierAddressError);
    const address = useAppSelector(Selectors.getUiSupplierAddress);

    useEffect(() => {
        if (supplierId) {
            dispatch(Actions.uiGetSupplierAddressAttempt({ supplierId }));
        }
    }, [supplierId]);

    useEffect(() => {
        if (address) {
            // when a supplier is first selected and we get their address for the first time, we set it as the default
            onChange(address);
        }
    }, [address]);

    if (loading) {
        return (
            <Container>
                <Label>Shipping address</Label>
                <LoadingIndicator size={24} />
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Label>Shipping address</Label>
                <Text variant={TextVariant.error}>{error}</Text>
            </Container>
        );
    }

    return (
        <Container>
            <AddressDisplay
                label='Supplier address'
                address={value}
                editable
                onChange={onChange}
                disabledAddAddress
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Label = styled(Text)`
    display: flex;

    font-weight: 500;
    font-size: 16px;

    margin-bottom: 6px;
`;

export default SupplierAddressInput;
