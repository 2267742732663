import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetUserManagementApiResponse } from 'api/UserManagementBase';

import { IUserManagementDetails } from 'entities/userManagement';

import {
    GetUserManagementPayload,
    UserManagementReduxState,
} from './types';

const initialState: UserManagementReduxState = {
    actions: {
        getUserManagement: false,
        getUserManagementDetails: false,
        createUserManagement: false,
        editUserManagement: false,
        deleteUserManagement: false,
    },
    filters: {
        currentPage: 0,
        searchQuery: undefined,
    },
    userManagement: {
        index: 0,
        maxIndex: 0,
        totalRecords: 0,
        data: [],
    },
    userManagementDetails: null,
    ui: {
        confirmationDeleteModalIsOpen: false,
    },
    errors: {
        getUserManagement: '',
        getUserManagementDetails: '',
        createUserManagement: '',
        editUserManagement: '',
        deleteUserManagement: '',
    },
};

const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        userManagementGetUserManagementAttempt: (state) => {
            state.actions.getUserManagement = true;
            state.errors.getUserManagement = '';
        },
        userManagementGetUserManagementSuccess: (state, action: PayloadAction<GetUserManagementApiResponse>) => {
            state.actions.getUserManagement = false;
            state.userManagement = action.payload;
        },
        userManagementGetUserManagementFailure: (state, action: PayloadAction<string>) => {
            state.actions.getUserManagement = false;
            state.errors.getUserManagement = action.payload ?? 'Something went wrong. Please try again.';
        },
        userManagementSetFilters: (state, action: GetUserManagementPayload) => {
            const { currentPage, searchQuery } = action.payload;

            if (currentPage !== undefined) state.filters.currentPage = currentPage;
            if (searchQuery !== undefined) {
                if (!searchQuery.length) state.filters.searchQuery = undefined;
                else state.filters.searchQuery = searchQuery;
            }
        },
        userManagementResetUserManagementFilters: (state) => {
            state.filters = initialState.filters;
        },

        userManagementGetUserManagementDetailsAttempt: (state, _action: PayloadAction<{id: string}>) => {
            state.actions.getUserManagementDetails = true;
            state.errors.getUserManagementDetails = '';
        },
        userManagementGetUserManagementDetailsSuccess: (state, action: PayloadAction<IUserManagementDetails>) => {
            state.actions.getUserManagementDetails = false;
            state.userManagementDetails = action.payload;
        },
        userManagementGetUserManagementDetailsFailure: (state, action: PayloadAction<string>) => {
            state.actions.getUserManagementDetails = false;
            state.errors.getUserManagementDetails = action.payload;
        },
        userManagementClearUserManagementDetails: (state) => {
            state.userManagementDetails = initialState.userManagementDetails;
            state.actions.getUserManagementDetails = false;
            state.errors.getUserManagementDetails = '';
        },

        userManagementEditUserManagementAttempt: (state) => {
            state.actions.editUserManagement = true;
            state.errors.editUserManagement = '';
        },
        userManagementEditUserManagementSuccess: (state) => {
            state.actions.editUserManagement = false;
        },
        userManagementEditUserManagementFailure: (state, action: PayloadAction<string>) => {
            state.actions.editUserManagement = false;
            state.errors.editUserManagement = action.payload;
        },
        userManagementClearEditUserManagement: (state) => {
            state.actions.editUserManagement = false;
            state.errors.editUserManagement = '';
            state.userManagementDetails = initialState.userManagementDetails;
        },

        userManagementSetUserManagementDetails: (state, action: PayloadAction<Partial<IUserManagementDetails>>) => {
            state.userManagementDetails = {
                ...state.userManagementDetails,
                ...action.payload,
            };
        },

        userManagementCreateUserManagementAttempt: (state) => {
            state.actions.createUserManagement = true;
            state.errors.createUserManagement = '';
        },
        userManagementCreateUserManagementSuccess: (state) => {
            state.actions.createUserManagement = false;
        },
        userManagementCreateUserManagementFailure: (state, action: PayloadAction<string>) => {
            state.actions.createUserManagement = false;
            state.errors.createUserManagement = action.payload;
        },
        userManagementClearCreateUserManagement: (state) => {
            state.actions.createUserManagement = false;
            state.errors.createUserManagement = '';
            state.userManagementDetails = initialState.userManagementDetails;
        },

        userManagementDeleteUserManagementAttempt: (state, _action: PayloadAction<{id: string}>) => {
            state.actions.deleteUserManagement = true;
            state.errors.deleteUserManagement = '';
        },
        userManagementDeleteUserManagementSuccess: (state) => {
            state.actions.deleteUserManagement = false;
            state.ui.confirmationDeleteModalIsOpen = false;
        },
        userManagementDeleteUserManagementFailure: (state, action: PayloadAction<string>) => {
            state.actions.deleteUserManagement = false;
            state.errors.deleteUserManagement = action.payload;
        },

        userManagementSetConfirmationDeleteModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.confirmationDeleteModalIsOpen = action.payload;
        },
    },
});

export type UserManagementState = typeof initialState;

export default {
    actions: userManagementSlice.actions,
    reducers: userManagementSlice.reducer,
};
