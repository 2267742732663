import React, { FunctionComponent } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import ForgotPasswordPage from 'containers/auth/ForgotPassword';
import LoginPage from 'containers/auth/Login';
import ResetPasswordPage from 'containers/auth/ResetPassword';
import CustomersPage from 'containers/customers';
import CustomersCreatePage from 'containers/customers/AddCustomer';
import CustomersEditPage from 'containers/customers/EditCustomer';
import EnquiriesPage from 'containers/enquiries';
import EnquiryDetails from 'containers/enquiries/EnquiryDetails';
import HomePage from 'containers/home';
import ChangePasswordPage from 'containers/home/ChangePassword';
import OrdersPage from 'containers/orders';
import AddShipmentTrackingPage from 'containers/orders/AddShipmentTracking';
import EditOrderPage from 'containers/orders/EditOrder';
import AddOrderPage from 'containers/orders/NewOrder';
import PaymentConfirmedPage from 'containers/orders/PaymentConfirmed';
import PaymentOrderPage from 'containers/orders/PaymentOrder';
import ViewOrderPage from 'containers/orders/ViewOrderDetails';
import ProductsPage from 'containers/products';
import PurchaseOrderPage from 'containers/purchaseOrders';
import EditPurchaseOrderPage from 'containers/purchaseOrders/EditPurchaseOrder';
import NewPurchaseOrderPage from 'containers/purchaseOrders/NewPurchaseOrder';
import PurchaseOrdersDetailsPage from 'containers/purchaseOrders/ViewPurchaseOrderDetails';
import QuotationsPage from 'containers/quotations';
import QuotationsEditPage from 'containers/quotations/EditQuotation';
import QuotationsNewPage from 'containers/quotations/NewQuotation';
import QuotationViewPage from 'containers/quotations/ViewQuotationDetails';
import ReportPage from 'containers/reports';
import SuppliersPage from 'containers/suppliers';
import CreateSupplierPage from 'containers/suppliers/CreateSupplier';
import UpdateSupplierPage from 'containers/suppliers/UpdateSupplier';
import UserManagementPage from 'containers/userManagement';
import AddAdminPage from 'containers/userManagement/AddAdmin';
import EditAdminPage from 'containers/userManagement/EditAdmin';

import { history } from 'redux/store';

import PrivateRoute from './PrivateRoutes';

const PrivateBucket: FunctionComponent = () => {
    return (
        <Route path='/' element={<PrivateRoute />}>
            <Route path='/' element={<HomePage />} />

            <Route path='/orders' element={<OrdersPage />} />
            <Route path='/orders/new' element={<AddOrderPage />} />
            <Route path='/orders/edit/:id' element={<EditOrderPage />} />
            <Route path='/orders/:id' element={<ViewOrderPage />} />
            <Route path='/orders/tracking/:orderId' element={<AddShipmentTrackingPage />} />
            <Route path='/orders/tracking/:orderId/:trackingId' element={<AddShipmentTrackingPage />} />

            <Route path='/enquiries' element={<EnquiriesPage />} />
            <Route path='/enquiries/:id' element={<EnquiryDetails />} />

            <Route path='/quotations' element={<QuotationsPage />} />
            <Route path='/quotations/new' element={<QuotationsNewPage />} />
            <Route path='/quotations/edit/:id' element={<QuotationsEditPage />} />
            <Route path='/quotations/:id' element={<QuotationViewPage />} />

            <Route path='/purchase-orders' element={<PurchaseOrderPage />} />
            <Route path='/purchase-orders/new' element={<NewPurchaseOrderPage />} />
            <Route path='/purchase-orders/edit/:id' element={<EditPurchaseOrderPage />} />
            <Route path='/purchase-orders/:id' element={<PurchaseOrdersDetailsPage />} />

            <Route path='/shipping-methods' element={<HomePage />} />
            <Route path='/payment-methods' element={<HomePage />} />
            <Route path='/currencies' element={<HomePage />} />

            <Route path='/suppliers' element={<SuppliersPage />} />
            <Route path='/suppliers/add' element={<CreateSupplierPage />} />
            <Route path='/suppliers/edit/:id' element={<UpdateSupplierPage />} />

            <Route path='/customers' element={<CustomersPage />} />
            <Route path='/customers/edit/:id' element={<CustomersEditPage />} />
            <Route path='/customers/add' element={<CustomersCreatePage />} />

            <Route path='/reports' element={<ReportPage />} />

            <Route path='/user-management' element={<UserManagementPage />} />
            <Route path='/user-management/add-admin' element={<AddAdminPage />} />
            <Route path='/user-management/edit/:id' element={<EditAdminPage />} />
            <Route path='/settings' element={<HomePage />} />
            <Route path='/change-password' element={<ChangePasswordPage />} />

            <Route path='/products' element={<ProductsPage />} />
        </Route>
    );
};

const NavRoutes: FunctionComponent = (props) => {
    return (
        <Router history={history}>
            <Routes>
                <Route path='/login' element={<LoginPage />} />

                <Route path='/forgot-password' element={<ForgotPasswordPage />} />

                <Route path='/reset-password' element={<ResetPasswordPage />} />

                <Route path='/orders/payment/:id' element={<PaymentOrderPage />} />

                <Route path='/payment-confirmed/:id' element={<PaymentConfirmedPage />} />

                <Route path='*' element={<Navigate replace to='/' />} />
                {PrivateBucket(props)}
            </Routes>
        </Router>
    );
};

export default NavRoutes;
