import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import Button from 'components/atoms/Button';
import DropdownButton from 'components/atoms/DropdownButton';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import OrderSelectors from 'redux/slices/order/Selectors';
import PoSelectors from 'redux/slices/purchaseOrder/Selectors';

import { IPurchaseOrder } from 'entities/purchaseOrders';

import theme from 'styles/theme';

interface SendPoModalProps {
    poDetails: IPurchaseOrder | null;
}

const SendPoModal = (props: SendPoModalProps): JSX.Element | null => {
    const { poDetails } = props;

    const isOpen = useAppSelector(PoSelectors.getPurchaseOrderSendPoModalIsOpen);
    const loading = useAppSelector(PoSelectors.getSendPurchaseOrderAttempting);
    const error = useAppSelector(PoSelectors.getSendPurchaseOrderError);

    const updateEmailVariablesLoading = useAppSelector(PoSelectors.updateEmailVariablesAttempting);
    const updateEmailVariablesError = useAppSelector(PoSelectors.updateEmailVariablesError);

    const viewEmailVariablesLoading = useAppSelector(PoSelectors.viewEmailVariablesAttempting);
    const viewEmailVariablesError = useAppSelector(PoSelectors.viewEmailVariablesError);

    const attachmentFolderName = useAppSelector(OrderSelectors.orderGetOrderAttachmentFolderNameData);

    const dispatch = useAppDispatch();

    const [emails, setEmails] = useState('');

    const [frontPrintSize, setFrontPrintSize] = useState('');
    const [backPrintSize, setBackPrintSize] = useState('');
    const [brand, setBrand] = useState('');
    const [productCode, setProductCode] = useState('');
    const [color, setColor] = useState('');
    const [attachmentFolder, setAttachmentFolder] = useState('');

    const [localError, setLocalError] = useState('');

    useEffect(() => {
        if (poDetails) {
            setEmails(poDetails.supplierContactPersonEmail);
        }
        if (poDetails?.emailVariables) {
            setFrontPrintSize(poDetails?.emailVariables?.frontPrintSize);
            setBackPrintSize(poDetails?.emailVariables?.backPrintSize);
            setBrand(poDetails?.emailVariables?.brand);
            setProductCode(poDetails?.emailVariables?.productCode);
            setColor(poDetails?.emailVariables?.color);
            setAttachmentFolder(poDetails?.emailVariables?.attachmentFolder ?? '');
        } else {
            setFrontPrintSize('');
            setBackPrintSize('');
            setBrand('');
            setProductCode('');
            setColor('');
            setAttachmentFolder('');
        }
        if (poDetails?.orderId) {
            dispatch(Actions.orderGetOrderAttachmentFolderNameAttempt({ orderId: poDetails?.orderId }));
        }
        if (!poDetails?.orderId) {
            dispatch(Actions.orderResetOrderAttachmentFolderName());
        }
    }, [poDetails, isOpen]);

    useEffect(() => {
        if (error) {
            setLocalError(error);
        } else {
            setLocalError('');
        }
    }, [error]);

    if (!poDetails) return null;

    const attachmentFolderNameList = attachmentFolderName.length > 0 ? attachmentFolderName.map((name) => ({
        label: name,
        value: name,
    })) : [];

    const onConfirm = () => {
        if (!poDetails.emailVariables) {
            setLocalError('Please add email variables');
            return;
        }
        dispatch(Actions.poSendPurchaseOrderAttempt({ id: poDetails.id, emails }));
    };

    const onSaveEmailVariables = () => {
        if (!frontPrintSize || !backPrintSize) {
            setLocalError('Please fill in all required fields');
            return;
        }

        dispatch(Actions.poUpdateEmailVariablesAttempt({
            frontPrintSize,
            backPrintSize,
            brand,
            productCode,
            color,
            attachmentFolder,
        }));
    };

    const onViewEmailVariables = () => {
        dispatch(Actions.poViewPurchaseOrderEmailAttempt({ id: poDetails.id }));
    };

    const onCloseModal = () => {
        setFrontPrintSize('');
        setBackPrintSize('');
        setBrand('');
        setProductCode('');
        setColor('');
        setAttachmentFolder('');
        setLocalError('');
        dispatch(Actions.poResetSendPo());
        dispatch(Actions.orderResetOrderAttachmentFolderName());
    };

    return (
        <Modal
            isModalOpen={isOpen}
            title='Send PO'
            onClosed={onCloseModal}
        >
            <Container>
                <ModalText>Enter the email addresses that the PO will be sent to:</ModalText>
                <ModalSubtitleText variant={TextVariant.h4}>
                    Please enter multiple email addresses separated by commas (e.g., email1@example.com, email2@example.com).
                </ModalSubtitleText>

                <StyledInput
                    onChange={(e) => setEmails(e.target.value)}
                    value={emails}
                />

                {localError && <ErrorText variant={TextVariant.error}>{localError}</ErrorText>}
            </Container>

            <ButtonRow>
                <Button label='Cancel' onClick={onCloseModal} variant={ButtonVariant.primary} disabled={loading} />
                <Button
                    label='Send PO'
                    onClick={onConfirm}
                    loading={loading}
                    // disabled={disableSendPO}
                />
            </ButtonRow>
            <hr />
            <Container>
                <RowContainer>
                    <Input
                        required
                        header='Front Print Size'
                        value={frontPrintSize}
                        onChange={(e) => setFrontPrintSize(e.target.value)}
                        error={!!localError && !frontPrintSize}
                    />
                    <Input
                        required
                        header='Back Print Size'
                        value={backPrintSize}
                        onChange={(e) => setBackPrintSize(e.target.value)}
                        error={!!localError && !backPrintSize}
                    />
                </RowContainer>
                <RowContainer>
                    <Input
                        required
                        header='Brand'
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                        error={!!localError && !brand}
                    />
                    <Input
                        required
                        header='Color'
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                        error={!!localError && !color}
                    />
                </RowContainer>
                <Input
                    required
                    header='Product Code'
                    value={productCode}
                    onChange={(e) => setProductCode(e.target.value)}
                    error={!!localError && !productCode}
                />
                <DropdownButton
                    error={error}
                    label='Attachment Folder'
                    // disabled={disabled}
                    value={attachmentFolder}
                    placeholder='Select folder name'
                    options={attachmentFolderNameList ?? []}
                    onSelect={(name) => setAttachmentFolder(name as string)}
                    noDataLabel='There is no attachment folder'
                />
                {/* <Input
                    header='Attachment Folder'
                    value={attachmentFolder}
                    onChange={(e) => setAttachmentFolder(e.target.value)}
                    // error={!!localError && !attachmentFolder}
                /> */}
            </Container>
            <ButtonRow>
                <Button
                    label='Save'
                    onClick={onSaveEmailVariables}
                    variant={ButtonVariant.primary}
                    loading={updateEmailVariablesLoading}
                    // disabled={disableSendPO}
                />
                <Button
                    label='View Email'
                    onClick={onViewEmailVariables}
                    variant={ButtonVariant.primary}
                    loading={viewEmailVariablesLoading}
                    // disabled={disableSendPO}
                />
            </ButtonRow>
        </Modal>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const ModalText = styled(Text)`
    font-size: 16px;
    color: ${theme.fontColor.primary};
    text-align: left;
`;

const ModalSubtitleText = styled(Text)`
    margin-top: 4px;
    color: ${theme.fontColor.primary};
    text-align: left;
`;

const StyledInput = styled(Input)`
    margin-top: 12px;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 12px;

    margin-top: 20px;
`;

const ErrorText = styled(Text)`
    margin-top: 12px;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
`;
export default SendPoModal;
