import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Footer from 'components/atoms/Footer';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import Header from 'components/molecules/Header';
import ProductViewer from 'components/molecules/ProductViewer';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import OrderSelectors from 'redux/slices/order/Selectors';

import Utils from 'lib/Utils';
import { IProductDetails } from 'entities/products';
import { CurrencyEnum } from 'entities/supplier';

import theme from 'styles/theme';

import CustomerDetailsRow, { ICustomerDetails } from './components/CustomerDetailsRow';

const PaymentOrder = (): JSX.Element => {
    const params = useParams();
    const ordersId = params.id;

    const {
        formatVariantLabel,
        formatSizeLabel,
        formatPrintMethodLabel,
    } = Utils.Formatter;

    const dispatch = useAppDispatch();

    const orderDetails = useAppSelector(OrderSelectors.getOrderDetailsData);

    const [taxPercent, setTaxPercent] = useState<number>(0);

    useEffect(() => {
        if (ordersId) {
            dispatch(Actions.orderGetOrderDetailsForPaymentAttempt({ id: ordersId }));
        }
    }, []);

    useEffect(() => {
        if (orderDetails) {
            dispatch(Actions.companyGetCompanyDetailsAttempt({ id: orderDetails.companyAccountId }));
        }
    }, [orderDetails]);

    useEffect(() => {
        const taxPrice = orderDetails?.tax ?? 0;
        const totalPrice = orderDetails?.finalPrice ?? 0;

        if (taxPrice === 0) {
            setTaxPercent(0);
            return;
        }

        if (totalPrice === 0) {
            setTaxPercent(0);
            return;
        }
        const priceBeforeTax = totalPrice - taxPrice;

        setTaxPercent((taxPrice / priceBeforeTax) * 100);
    }, [orderDetails?.tax]);

    const NewSelectedProducts = (): IProductDetails[] => {
        if (!orderDetails?.products) return [];
        const selectedProducts = orderDetails?.products.map(product => {
            return {
                productId: product.productId,
                variantId: product.variantId,
                name: product.productName ?? 'Product Name Here',
                variant: {
                    type: product.productVariant?.type,
                    fit: product.productVariant?.fit,
                    sleeve: product.productVariant?.sleeve,
                    style: product.productVariant?.style,
                    color: product.productVariant?.color,
                },
                variantText: formatVariantLabel(product.productVariant ? product.productVariant : null),
                sizeText: formatSizeLabel(product.quantities),
                printMethodText: product.printMethods.map((method) => {
                    return formatPrintMethodLabel({
                        side: method.side,
                        printMethod: method.printMethod ?? '',
                        block: method.block ?? '',
                        colorCount: method.colorCount ?? '',
                    });
                }),
                totalQuantity: product.finalQuantity,
                quantities: product.quantities.map(item => ({
                    id: item.productPriceId,
                    size: item.size,
                    quantity: item.quantity,
                    pricePerUnit: item.pricePerUnit,
                })),
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                printMethods: product.printMethods,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                disableQuantityEdit: true,
            };
        });

        const customLineProducts = orderDetails?.customLineProducts.map(product => {
            return {
                productId: '',
                variantId: '',
                name: product.productName ?? 'Product Name Here',
                totalQuantity: product.finalQuantity,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                disableQuantityEdit: true,
            };
        });

        const combinedSelectedProduct = [...selectedProducts, ...customLineProducts];
        return combinedSelectedProduct;
    };

    const customerInfoRowtitle = [
        { header: 'Order Number', field: 0 },
        { header: 'Customer Name', field: 1 },
        { header: 'Customer Email', field: 2 },
        { header: 'Customer Phone', field: 3 },
    ];

    const customerDetails: ICustomerDetails = {
        orderId: orderDetails?.orderNo,
        name: orderDetails?.clientName,
        email: orderDetails?.clientEmail,
        phone: orderDetails?.clientNumber,
    };

    const productTableHeaders = [
        { header: 'Name' },
        { header: 'Quantity' },
        { header: 'Price Per Unit' },
        { header: 'Price After Discount' },
        { header: 'Discount Total' },
        { header: 'Total' },
    ];

    const onPaymentClicked = () => {
        if (!orderDetails?.stripePaymentLink) {
            toast.error('Payment link not found');
            return;
        }
        window.open(orderDetails?.stripePaymentLink, '_blank');
    };

    return (
        <main>
            <Header
                disableBreadcrumbAndUserProfile
            />
            <div>
                <OrderPaymentContainer>
                    <Text variant={TextVariant.h2} style={{ letterSpacing: '0.025rem' }}>
                        ORDER PAYMENT
                    </Text>
                </OrderPaymentContainer>
                <ContentContainer>
                    <CustomerDetailsRow
                        title={customerInfoRowtitle}
                        capitalizedTitle
                        customerDetails={customerDetails}
                    />
                    <ProductViewer
                        tableHeaders={productTableHeaders}
                        currency={orderDetails?.currency ?? CurrencyEnum.MalaysianRinggit}
                        viewData={{
                            selectedProducts: NewSelectedProducts(),
                            discountPercent: orderDetails?.discountPercent?.toString(),
                            discountFixed: orderDetails?.discountFixed?.toFixed(2),
                            shippingCost: orderDetails?.shippingFee.toFixed(2) ?? '0.00',
                            taxPrice: orderDetails?.tax.toFixed(2) ?? '0.00',
                        }}
                        taxPercent={Number(taxPercent.toFixed(0))}
                    />
                    <Button
                        label='Proceed to Payment'
                        variant={ButtonVariant.secondary}
                        style={{ marginTop: '1rem' }}
                        onClick={onPaymentClicked}
                    />
                </ContentContainer>
            </div>
            <Footer />
        </main>
    );
};

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.5rem 15rem;
    // justify-content: center;
    align-items: center;

    min-height: calc(100vh - 202px);

    background-color: ${theme.colors.secondary};
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center

    
`;

const OrderPaymentContainer = styled.div`
    width: 100%;
    height: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.background};
`;

export default PaymentOrder;
