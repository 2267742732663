import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import ErrorMessage from 'components/atoms/ErrorMessage';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import PageHeader from 'components/atoms/PageHeader';
import AdminLayout from 'components/Layout/AdminLayout';
import Form, { FormFields } from 'components/molecules/Form';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import QuotationSelectors from 'redux/slices/quotations/Selectors';
import UiSelectors from 'redux/slices/ui/Selectors';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { IAddress } from 'entities/address';
import { IProductDetails, TaxRateEnum } from 'entities/products';
import { QuotationShippingMethodEnum } from 'entities/quotations';

const EditQuotation = (): JSX.Element => {
    const params = useParams();
    const quotationId = params.id;

    const {
        formatVariantLabel,
        formatSizeLabel,
        formatPrintMethodLabel,
    } = Utils.Formatter;

    const dispatch = useAppDispatch();

    const editQuoteDetailsFromRedux = useAppSelector(QuotationSelectors.getQuotationsNewOrEditQuotation);
    const quotationDetails = useAppSelector(QuotationSelectors.getQuotationDetails);
    const loading = useAppSelector(QuotationSelectors.getQuotationDetailsAttempting);
    const error = useAppSelector(QuotationSelectors.getQuotationDetailsError);

    const customerAddressList = useAppSelector(UiSelectors.getUiGetCustomerAddresses);

    useEffect(() => {
        return () => {
            dispatch(Actions.quoteResetQuotationDetails());
        };
    }, [quotationId]);

    const newSelectedProducts = (): IProductDetails[] => {
        if (!quotationDetails?.products) return [];
        const selectedProductsData = quotationDetails?.products.map(product => {
            return {
                productId: product.productId,
                variantId: product.variantId,
                name: product.productName ?? 'Product Name Here',
                variantText: formatVariantLabel(product.productVariant),
                variant: product.productVariant,
                sizeText: formatSizeLabel(product.quantities),
                printMethodText: product.printMethods.map((method) => {
                    return formatPrintMethodLabel({
                        side: method.side,
                        printMethod: method.printMethod ?? '',
                        block: method.block ?? '',
                        colorCount: method.colorCount ?? '',
                    });
                }),
                totalQuantity: product.finalQuantity,
                quantities: product.quantities.map(item => ({
                    id: item.productPriceId,
                    size: item.size,
                    minimumQuantity: item.quantity,
                    quantity: item.quantity,
                    pricePerUnit: item.pricePerUnit,
                })),
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                printMethods: product.printMethods,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                disableQuantityEdit: product.quantities.length > 0,
                // weight: product.weight,
                remark: product.remark ?? '',
            };
        });

        const customLineProducts = quotationDetails?.customLineProducts.map(product => {
            return {
                productId: '',
                variantId: '',
                name: product.productName ?? 'Product Name Here',
                totalQuantity: product.finalQuantity,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                disableQuantityEdit: true,
                weight: product.weight,
                remark: product.remark ?? '',
            };
        });

        const combinedSelectedProduct = [...selectedProductsData, ...customLineProducts];
        return combinedSelectedProduct;
    };

    useEffect(() => {
        if (quotationId) {
            dispatch(Actions.quoteGetQuotationDetailsAttempt({ id: quotationId }));
        }
        return () => {
            dispatch(Actions.quoteResetNewQuotationParams());
            dispatch(Actions.companyGetCompanyListAttempt({ index: 0 }));
            dispatch(Actions.productsGetProductsAttempt({ index: -1 }));
        };
    }, [quotationId]);

    useEffect(() => {
        if (quotationDetails) {
            dispatch(Actions.quoteUpdateNewQuotationParams({ ...quotationDetails }));
            dispatch(Actions.uiSearchForCustomersAttempt({ searchQuery: '' }));
        }
        if (quotationDetails?.clientId) {
            dispatch(Actions.quoteUpdateNewQuotationParams({ clientId: quotationDetails?.clientId }));
        }
        if (quotationDetails?.products) {
            dispatch(Actions.productReplaceSelectedProducts(newSelectedProducts()));
        }
        if (quotationDetails?.discountPercent) {
            dispatch(Actions.productSetDiscountPercent(quotationDetails.discountPercent.toString()));
        }
        if (quotationDetails?.discountFixed) {
            dispatch(Actions.productSetDiscountFixed(quotationDetails.discountFixed.toString()));
        }
        if (quotationDetails?.shippingFee) {
            dispatch(Actions.productSetShippingCost(quotationDetails.shippingFee.toString()));
        }
        if (quotationDetails?.taxType) {
            if (quotationDetails.taxType === TaxRateEnum.GstTaxRate) {
                dispatch(Actions.productSetIsTaxApplied(true));
            } else {
                dispatch(Actions.productSetIsTaxApplied(false));
            }
        }
    }, [quotationDetails]);

    // useEffect(() => {
    //     if (quotationDetails?.clientId) {
    //         dispatch(Actions.quoteUpdateNewQuotationParams({ clientId: quotationDetails?.clientId }));
    //     }
    // }, [editQuoteDetailsFromRedux.clientId]);

    const onCompanyAccountChanged = (companyAccountId: string) => {
        if (editQuoteDetailsFromRedux.companyAccountId !== companyAccountId) {
            // reset product selector if company account is changed
            dispatch(Actions.productResetProductSelector());
            dispatch(Actions.quoteUpdateNewQuotationParams({ companyAccountId, shippingAddress: undefined, clientId: undefined }));
            dispatch(Actions.uiResetCustomerAddress());
        }
        dispatch(Actions.quoteUpdateNewQuotationParams({ companyAccountId }));
    };

    const onQuotationDateChanged = (quotationDate: string) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ quotationDate }));
    };

    const onClientChanged = (clientId: string) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ clientId, shippingAddress: customerAddressList?.[0] }));
    };

    const onShippingAddressChanged = (shippingAddress: IAddress) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ shippingAddress }));
    };

    const onShippingMethodChanged = (shippingMethod: QuotationShippingMethodEnum) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ shippingMethod }));
    };

    const onRemarksChanged = (remark: string) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ remark }));
    };

    const onNoteChanged = (note: string) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ note }));
    };

    const onSave = () => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ id: quotationId, quotationStatus: quotationDetails?.quotationStatus }));
        dispatch(Actions.quoteUpdateQuotationAttempt());
    };

    const formFieldsNeeded: FormFields = {
        companyAccount: {
            enabled: true, mandatory: true,
        },
        quotationDate: {
            enabled: true, mandatory: true,
        },
        clientName: {
            enabled: true, mandatory: true,
        },
        shippingAddress: {
            enabled: true, mandatory: true,
        },
        shippingMethod: {
            enabled: true, mandatory: true,
        },
        remarks: { enabled: true },
        notes: { enabled: true },
        productList: { enabled: true },
    };

    const renderBody = () => {
        if (loading) {
            return (
                <AdminLayout style={{ minHeight: '70vh', justifyContent: 'center' }}>
                    <LoadingIndicator />
                </AdminLayout>
            );
        }

        if (error) {
            return (
                <ErrorContainer>
                    <ErrorMessage error='Sorry, we could not find this quotations. It might have been deleted/moved.' />

                    <Button
                        label='Back to all quotations'
                        onClick={() => NavActions.navToMenu('/quotations')}
                        style={{ width: 250 }}
                    />
                </ErrorContainer>
            );
        }

        return (
            <Form
                isEdit
                formFields={formFieldsNeeded}
                values={editQuoteDetailsFromRedux}
                handlers={{
                    onCompanyAccountChanged,
                    onQuotationDateChanged,
                    onClientChanged,
                    onShippingAddressChanged,
                    onShippingMethodChanged,
                    onRemarksChanged,
                    onNoteChanged,
                    onSave,
                }}
                existingShippingCost={editQuoteDetailsFromRedux.shippingFee}
            />
        );
    };
    return (
        <AdminLayout>
            <PageHeader withBackButton>
                Edit Quotation
                {' '}
                {editQuoteDetailsFromRedux.quotationNumber}
            </PageHeader>
            {renderBody()}
        </AdminLayout>
    );
};

const ErrorContainer = styled.div`
    height: 400px;
    width: 100%;

    padding: 32px;

    background-color: white;
    border-radius: 15px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    text-align: center;
`;

export default EditQuotation;
