import { fork } from 'redux-saga/effects';

import ReportGateway from 'api/Report';
import { RootSagaReturnType } from 'sagas/types';

import watchGetReportChartData from './getReportChartData';
import watchGetReportDetails from './getReportDetails';
import watchGetReportOrderList from './getReportOrderList';

export default (api: ReportGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetReportDetails, api);
        yield fork(watchGetReportOrderList, api);
        yield fork(watchGetReportChartData, api);
    }

    return {
        rootSaga,
    };
};
