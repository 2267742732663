import React, { useEffect, useState } from 'react';

import PageHeader from 'components/atoms/PageHeader';
import AdminLayout from 'components/Layout/AdminLayout';
import Form, { FormFields } from 'components/molecules/Form';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import CustomerSelectors from 'redux/slices/customers/Selectors';
import OrderSelectors from 'redux/slices/order/Selectors';
import QuotationSelectors from 'redux/slices/quotations/Selectors';

import { IAddress } from 'entities/address';
import { OrderPaymentMethodEnum, OrderPaymentTermEnum } from 'entities/order';
import { TaxRateEnum } from 'entities/products';
import { QuotationShippingMethodEnum } from 'entities/quotations';

const AddOrder = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const quotationDetails = useAppSelector(QuotationSelectors.getQuotationDetails);

    const newOrderDetailsFromRedux = useAppSelector(OrderSelectors.getOrderNewOrEditOrder);
    const createOrderLoading = useAppSelector(OrderSelectors.createOrderAttempting);
    const createOrderError = useAppSelector(OrderSelectors.createOrderError);

    const customerDetails = useAppSelector(CustomerSelectors.getCustomerDetails);

    useEffect(() => {
        return () => {
            dispatch(Actions.orderResetNewOrderParams());
            dispatch(Actions.quoteResetQuotationDetails());
            dispatch(Actions.customersClearCustomerDetails());
            dispatch(Actions.uiClearSearchCustomerForm());
        };
    }, []);

    useEffect(() => {
        if (quotationDetails) {
            dispatch(Actions.orderUpdateNewOrderParams({
                companyAccountId: quotationDetails?.companyAccountId,
                clientId: quotationDetails?.clientId,
                clientName: quotationDetails?.clientName,
                clientEmail: quotationDetails?.clientEmail,
                clientNumber: quotationDetails?.clientContact,
                shippingAddress: quotationDetails?.shippingAddress,
                shippingMethod: quotationDetails?.shippingMethod,
                quotationId: quotationDetails?.id,
                quotationNumber: quotationDetails?.quotationNumber,
            }));

            dispatch(Actions.uiSearchForCustomersAttempt({
                currentPage: -1,
                searchQuery: '',
            }));

            dispatch(Actions.productSetDiscountPercent(quotationDetails?.discountPercent?.toString() ?? undefined));
            dispatch(Actions.productSetDiscountFixed(quotationDetails?.discountFixed?.toString() ?? undefined));
            dispatch(Actions.productSetShippingCost(quotationDetails?.shippingFee?.toString() ?? undefined));
            if (quotationDetails?.taxType) {
                if (quotationDetails.taxType === TaxRateEnum.GstTaxRate) {
                    dispatch(Actions.productSetIsTaxApplied(true));
                } else {
                    dispatch(Actions.productSetIsTaxApplied(false));
                }
            }
            dispatch(Actions.productSetIsShippingCostEdited(true));
        }
    }, [quotationDetails]);

    useEffect(() => {
        if (customerDetails) {
            dispatch(Actions.orderUpdateNewOrderParams({
                clientEmail: customerDetails.email,
                clientNumber: customerDetails.contact,
                clientName: customerDetails.contactPerson,
            }));
        }
    }, [customerDetails]);

    const formFieldsNeeded: FormFields = {
        companyAccount: {
            enabled: true, mandatory: true,
        },
        clientName: {
            enabled: true, mandatory: true,
        },
        clientEmail: {
            enabled: true, mandatory: true,
        },
        clientNumber: {
            enabled: true, mandatory: true,
        },
        projectName: { enabled: true },
        projectDueDate: { enabled: true },
        paymentTerms: { enabled: true },
        paymentDueDate: { enabled: true },
        shippingAddress: {
            enabled: true, mandatory: true,
        },
        shippingMethod: {
            enabled: true, mandatory: true,
        },
        billingAddress: {
            enabled: true, mandatory: true,
        },
        paymentMethod: {
            enabled: true, mandatory: true,
        },
        remarks: { enabled: true },
        notes: { enabled: true },
        productList: { enabled: true },
    };

    const onCompanyAccountChanged = (companyAccountId: string) => {
        if (newOrderDetailsFromRedux.companyAccountId !== companyAccountId) {
            // reset product selector if company account is changed
            dispatch(Actions.productResetProductSelector());
            dispatch(Actions.orderUpdateNewOrderParams({
                clientId: undefined,
                shippingAddress: undefined,
                billingAddress: undefined,
                clientEmail: '',
                clientNumber: '',
            }));
        }
        dispatch(Actions.orderUpdateNewOrderParams({ companyAccountId }));
        if (!newOrderDetailsFromRedux.quotationId) {
            dispatch(Actions.orderUpdateNewOrderParams({
                clientId: undefined,
                shippingAddress: undefined,
                billingAddress: undefined,
                clientEmail: '',
                clientNumber: '',
            }));
            // dispatch(Actions.orderResetNewOrderClientParams());
            dispatch(Actions.uiResetCustomerAddress());
        }
    };
    const onClientChanged = (clientId: string) => {
        dispatch(Actions.orderUpdateNewOrderParams({ clientId }));
        dispatch(Actions.customersGetCustomerDetailsAttempt({ id: clientId }));
    };

    const onEmailChanged = (clientEmail: string) => {
        dispatch(Actions.orderUpdateNewOrderParams({ clientEmail }));
    };
    const onContactChanged = (clientNumber: string) => {
        dispatch(Actions.orderUpdateNewOrderParams({ clientNumber }));
    };
    const onProjectNameChanged = (projectName: string) => {
        dispatch(Actions.orderUpdateNewOrderParams({ projectName }));
    };
    const onProjectDueDateChanged = (value: string) => {
        dispatch(Actions.orderUpdateNewOrderParams({ projectDueDate: value }));
    };
    const onPaymentTermsChanged = (value: OrderPaymentTermEnum) => {
        dispatch(Actions.orderUpdateNewOrderParams({ paymentTerm: value }));
    };
    const onPaymentDueDateChanged = (value: string) => {
        dispatch(Actions.orderUpdateNewOrderParams({ paymentDueDate: value }));
    };
    const onNoteChanged = (value: string) => {
        dispatch(Actions.orderUpdateNewOrderParams({ note: value }));
    };
    const onRemarksChanged = (value: string) => {
        dispatch(Actions.orderUpdateNewOrderParams({ remark: value }));
    };
    const onShippingAddressChanged = (shippingAddress: IAddress) => {
        dispatch(Actions.orderUpdateNewOrderParams({ shippingAddress }));
    };

    const onEditShippingAddress = (shippingAddress: IAddress) => {
        dispatch(Actions.orderUpdateNewOrderParams({ shippingAddress }));
    };

    const onBillingAddressChanged = (billingAddress: IAddress) => {
        dispatch(Actions.orderUpdateNewOrderParams({ billingAddress }));
    };

    const onEditBillingAddress = (billingAddress: IAddress) => {
        dispatch(Actions.orderUpdateNewOrderParams({ billingAddress }));
    };

    const onShippingMethodChanged = (shippingMethod: QuotationShippingMethodEnum) => {
        dispatch(Actions.orderUpdateNewOrderParams({ shippingMethod }));
    };
    const onPaymentMethodChanged = (paymentMethod: OrderPaymentMethodEnum) => {
        dispatch(Actions.orderUpdateNewOrderParams({ paymentMethod }));
    };
    const onSave = () => {
        dispatch(Actions.orderCreateOrderAttempt());
    };

    const renderPageHeader = () => {
        if (newOrderDetailsFromRedux.quotationNumber) {
            return (
                <PageHeader withBackButton>
                    {`Convert Quotation ${newOrderDetailsFromRedux.quotationNumber} to Order`}
                </PageHeader>
            );
        }
        return (
            <PageHeader withBackButton>
                Add New Order
            </PageHeader>
        );
    };
    return (
        <AdminLayout>
            {renderPageHeader()}
            <Form
                isEdit={!!quotationDetails}
                module='order'
                formFields={formFieldsNeeded}
                values={newOrderDetailsFromRedux}
                loading={createOrderLoading}
                error={createOrderError}
                handlers={{
                    onCompanyAccountChanged,
                    onClientChanged,
                    onProjectNameChanged,
                    onProjectDueDateChanged,
                    onEmailChanged,
                    onContactChanged,
                    onPaymentTermsChanged,
                    onPaymentDueDateChanged,
                    onShippingAddressChanged,
                    onEditShippingAddress,
                    onShippingMethodChanged,
                    onBillingAddressChanged,
                    onEditBillingAddress,
                    onPaymentMethodChanged,
                    onNoteChanged,
                    onRemarksChanged,
                    onSave,
                }}
            />

        </AdminLayout>
    );
};

export default AddOrder;
