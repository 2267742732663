import React from 'react';

import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import ConfirmationModal from 'components/molecules/ConfirmationModal';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/enquiries/Selectors';

import { EnquiryTypeEnum } from 'entities/enquiries';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';

interface ConfirmDeleteModalProps {
    enquiryId?: string;
    enquiryType?: EnquiryTypeEnum;
    enquirySubject?: string;
}

const ConfirmDeleteModal = (props: ConfirmDeleteModalProps): JSX.Element | null => {
    const { enquiryId, enquirySubject, enquiryType } = props;

    const isOpen = useAppSelector(Selectors.getDeleteConfirmationModalIsOpen);

    const loading = useAppSelector(Selectors.getDeleteEnquiryAttempting);
    const error = useAppSelector(Selectors.getDeleteEnquiryError);

    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(Actions.enquiriesSetDeleteConfirmationModalIsOpen(false));
        dispatch(Actions.enquiriesDeleteEnquiryFailure(''));
    };

    if (!enquiryId || !enquirySubject || !enquiryType) return null;

    const onConfirm = () => {
        dispatch(Actions.enquiriesDeleteEnquiryAttempt({ id: enquiryId, type: enquiryType }));
    };

    return (
        <ConfirmationModal
            isOpen={isOpen}
            icon={<ConfirmationIcon />}
            title={`Delete enquiry "${enquirySubject}"?`}
            text='Please confirm deleting this enquiry.'
            onCancel={onCancel}
            onConfirm={onConfirm}
            cancelText='Cancel'
            confirmText='Confirm'
            loading={loading}
            error={error}
            confirmButtonVariant={ButtonVariant.warning}
        />
    );
};

export default ConfirmDeleteModal;
