import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { CreateOrderCommentPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchCreateOrderComment(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderCreateOrderCommentAttempt', handleCreateOrderComment, api);
}
function* handleCreateOrderComment(api: OrderGateway, data: CreateOrderCommentPayload) {
    const authToken = yield* select((state) => state.auth.authToken);

    const response = yield* call([api, api.createOrderComment], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderCreateOrderCommentFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderCreateOrderCommentSuccess());
        yield put(Actions.orderGetOrderCommentAttempt({ orderId: data.payload.orderId, index: 0 }));
        toast.success('Comment added!');
    }
}
