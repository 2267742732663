import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PurchaseOrderGateway from 'api/PurchaseOrders';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import PoSelectors from 'redux/slices/purchaseOrder/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchDownloadPurchaseOrder(api: PurchaseOrderGateway): SagaWatcherReturnType {
    yield takeEvery('po/poDownloadPurchaseOrderAttempt', handleDownloadPurchaseOrder, api);
}

function* handleDownloadPurchaseOrder(api: PurchaseOrderGateway, data: PayloadAction<{ id: string }>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);
    const poDetails = yield* select(PoSelectors.getPurchaseOrderDetails);

    const response = yield* call([api, api.downloadPurchaseOrder], {
        authToken,
        id: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.poDownloadPurchaseOrderFailure(response.message || ''));

        toast.error('Failed to download PO. Please try again later.');
    }

    if (response.status === GatewayResponseStatus.Success) {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Purchase Order-${poDetails?.poNumber}.pdf`);
        document.body.appendChild(link);
        link.click();

        yield put(Actions.poDownloadPurchaseOrderSuccess());
        toast.success('Purchase order downloaded successfully');
    }
}
