import { GetCustomerOrdersApiResponse, GetCustomersApiResponse } from 'api/CustomerBase';
import { RootState } from 'redux/store';

import { ICustomer } from 'entities/customer';

import { CustomersReduxFilters } from './types';

const getCustomersAttempting = (state: RootState): boolean => state.customers.actions.getCustomers;
const getCustomersError = (state: RootState): string => state.customers.error.getCustomers;
const getCustomers = (state: RootState): GetCustomersApiResponse => state.customers.customers;

const getCustomerDetailsAttempting = (state: RootState): boolean => state.customers.actions.getCustomerDetails;
const getCustomerDetailsError = (state: RootState): string => state.customers.error.getCustomerDetails;
const getCustomerDetails = (state: RootState): Partial<ICustomer> | null => state.customers.customerDetails;

const getCustomerOrdersAttempting = (state: RootState): boolean => state.customers.actions.getCustomerOrders;
const getCustomerOrdersError = (state: RootState): string => state.customers.error.getCustomerOrders;
const getCustomerOrders = (state: RootState): GetCustomerOrdersApiResponse => state.customers.customerOrders;

const createCustomerAttempting = (state: RootState): boolean => state.customers.actions.createCustomer;
const createCustomerError = (state: RootState): string => state.customers.error.createCustomer;

const editCustomerAttempting = (state: RootState): boolean => state.customers.actions.editCustomer;
const editCustomerError = (state: RootState): string => state.customers.error.editCustomer;

const deleteCustomerAttempting = (state: RootState): boolean => state.customers.actions.deleteCustomer;
const deleteCustomerError = (state: RootState): string => state.customers.error.deleteCustomer;

const getCustomerFilters = (state: RootState): CustomersReduxFilters => state.customers.filters;
const getCustomerConfirmationDeleteModalIsOpen = (state: RootState): boolean => state.customers.ui.confirmationDeleteModalIsOpen;

export default {
    getCustomersAttempting,
    getCustomersError,
    getCustomers,

    getCustomerDetailsAttempting,
    getCustomerDetailsError,
    getCustomerDetails,

    getCustomerOrdersAttempting,
    getCustomerOrdersError,
    getCustomerOrders,

    createCustomerAttempting,
    createCustomerError,

    editCustomerAttempting,
    editCustomerError,

    deleteCustomerAttempting,
    deleteCustomerError,

    getCustomerFilters,
    getCustomerConfirmationDeleteModalIsOpen,
};
