import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { GetShipmentTrackingDetailsPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetShipmentTrackingDetails(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderGetShipmentTrackingDetailsAttempt', handleGetShipmentTrackingDetails, api);
}
function* handleGetShipmentTrackingDetails(api: OrderGateway, data: GetShipmentTrackingDetailsPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getShipmentTrackingDetails], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderGetShipmentTrackingDetailsFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderGetShipmentTrackingDetailsSuccess(response.data));
    }
}
