import dayjs from 'dayjs';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PurchaseOrderGateway from 'api/PurchaseOrders';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import Selectors from 'redux/slices/purchaseOrder/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetPurchaseOrders(api: PurchaseOrderGateway): SagaWatcherReturnType {
    yield takeEvery('po/poGetPurchaseOrdersAttempt', handleGetPurchaseOrders, api);
}

function* handleGetPurchaseOrders(api: PurchaseOrderGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const poFilters = yield* select(Selectors.getPurchaseOrdersFilters);

    let response;

    if (poFilters.dateStart === '') {
        response = yield* call([api, api.getPurchaseOrders], {
            authToken,
            index: poFilters.currentPage,
            searchQuery: poFilters.searchQuery,
            status: poFilters.status,
        });
    } else {
        response = yield* call([api, api.getPurchaseOrders], {
            authToken,
            index: poFilters.currentPage,
            searchQuery: poFilters.searchQuery,
            dateStart: poFilters.dateStart,
            dateEnd: poFilters.dateEnd,
            status: poFilters.status,
        });
    }

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.poGetPurchaseOrdersFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.poGetPurchaseOrdersSuccess(response.data));
    }
}
