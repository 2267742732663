import React from 'react';

import styled from 'styled-components';

import AddressDisplay from 'components/atoms/AddressDisplay';
import DropdownButton from 'components/atoms/DropdownButton';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import CompanyInput from 'components/molecules/Form/CompanyInput';

import { ICustomerAddress } from 'entities/address';
import { CurrencyEnum, ISupplierDetails } from 'entities/supplier';

interface SupplierFormProps {
    supplier: Partial<ISupplierDetails> | null;

    onChange: (update: Partial<ISupplierDetails>) => void;

    error?: string;
    disabledCompanyAccount?: boolean;
    disableInputs?: boolean;
}

const SupplierForm = (props: SupplierFormProps): JSX.Element => {
    const { supplier, onChange, error, disabledCompanyAccount, disableInputs } = props;

    const onAddressChanged = (address: ICustomerAddress) => {
        onChange({
            supplierAddress: {
                street1: address.street1,
                street2: address.street2,
                city: address.city,
                state: address.state,
                country: address.country,
                postcode: address.postcode,
            },
        });
    };

    return (
        <Container>
            <LeftContainer>
                <Text variant={TextVariant.h3}>
                    Supplier Details
                </Text>

                <InputColumn>
                    <Input
                        error={!!error && !supplier?.supplierName}
                        value={supplier?.supplierName}
                        header='Supplier name'
                        onChange={(e) => onChange({ supplierName: e.target.value })}
                        required
                        disabled={disableInputs}
                    />

                    <Input
                        value={supplier?.supplierDescription}
                        variant='textarea'
                        header='Supplier description'
                        onChange={(e) => onChange({ supplierDescription: e.target.value })}
                        disabled={disableInputs}
                    />
                    {/* {!disabledCompanyAccount && ( */}
                    <div style={{ flex: 1 }}>
                        <CompanyInput
                            disabled={disabledCompanyAccount}
                            mandatory
                            error={!!error && !supplier?.companyAccountId}
                            value={supplier?.companyAccountId}
                            onCompanyChanged={(companyId) => onChange({ companyAccountId: companyId })}
                        />
                    </div>
                    {/*  )} */}

                    {/* {!disabledCompanyAccount && ( */}
                    <div style={{ flex: 1 }}>
                        <DropdownButton
                            error={!!error && !supplier?.currency}
                            label='Currency type'
                            disabled={disabledCompanyAccount}
                            value={supplier?.currency || CurrencyEnum.MalaysianRinggit}
                            placeholder='Select currency'
                            options={[{ label: 'Malaysian Ringgit', value: CurrencyEnum.MalaysianRinggit }, { label: 'Singapore Dollar', value: CurrencyEnum.SingaporeDollar }, { label: 'US Dollar', value: CurrencyEnum.USDollar }]}
                            onSelect={(currency) => onChange({ currency: Number(currency) })}
                        />
                    </div>
                    {/* )} */}
                </InputColumn>
            </LeftContainer>

            <RightContainer>
                <Text variant={TextVariant.h3}>
                    Contact Details
                </Text>

                <InputColumn>
                    <Input
                        error={!!error && !supplier?.contactPersonName}
                        value={supplier?.contactPersonName}
                        header='Contact person'
                        onChange={(e) => onChange({ contactPersonName: e.target.value })}
                        required
                        disabled={disableInputs}
                    />

                    <Input
                        error={!!error && !supplier?.contactPersonEmail}
                        value={supplier?.contactPersonEmail}
                        header='Email'
                        onChange={(e) => onChange({ contactPersonEmail: e.target.value })}
                        required
                        disabled={disableInputs}
                    />

                    <Input
                        // error={!!error && !supplier?.contactPersonNumber}
                        value={supplier?.contactPersonNumber}
                        header='Phone number'
                        onChange={(e) => onChange({ contactPersonNumber: e.target.value })}
                        // required
                        disabled={disableInputs}
                    />

                    <Input
                        value={supplier?.supplierWebsite}
                        header='Website'
                        onChange={(e) => onChange({ supplierWebsite: e.target.value })}
                        disabled={disableInputs}
                    />
                    <AddressDisplay
                        required
                        label='Supplier Addresses'
                        address={supplier?.supplierAddress}
                        onChange={(supplierAddress) => onAddressChanged(supplierAddress)}
                        error={!!error && !supplier?.supplierAddress}
                        editable={!disableInputs}
                    />
                </InputColumn>
            </RightContainer>
        </Container>
    );
};

const Container = styled.div`
    border-radius: 15px;
    background-color: white;

    display: flex;
    flex-direction: row;

    width: 100%;

    padding: 32px;
    gap: 20px;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;

    flex: 1;
`;

const InputColumn = styled.div`
    margin-top: 28px;

    display: flex;
    flex-direction: column;

    gap: 20px;
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;

    flex: 1;
`;

export default SupplierForm;
