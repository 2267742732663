import { GetEnquiriesApiResponse } from 'api/EnquiriesBase';
import { RootState } from 'redux/store';

import { IEnquiry } from 'entities/enquiries';

import { EnquiriesReduxFilters } from './types';

const getEnquiriesAttempting = (state: RootState): boolean => state.enquiries.actions.getEnquiries;
const getEnquiriesError = (state: RootState): string => state.enquiries.error.getEnquiries;
const getEnquiries = (state: RootState): GetEnquiriesApiResponse => state.enquiries.enquiries;
const getEnquiriesFilters = (state: RootState): EnquiriesReduxFilters => state.enquiries.filters;

const getEnquiryDetailsAttempting = (state: RootState): boolean => state.enquiries.actions.getEnquiryDetails;
const getEnquiryDetailsError = (state: RootState): string => state.enquiries.error.getEnquiryDetails;
const getEnquiryDetails = (state: RootState): IEnquiry | null => state.enquiries.enquiryDetails;

const getResolveEnquiryAttempting = (state: RootState): boolean => state.enquiries.actions.resolveEnquiries;
const getResolveEnquiryError = (state: RootState): string => state.enquiries.error.resolveEnquiries;

const getDeleteEnquiryAttempting = (state: RootState): boolean => state.enquiries.actions.deleteEnquiries;
const getDeleteEnquiryError = (state: RootState): string => state.enquiries.error.deleteEnquiries;

const getResolveConfirmationModalIsOpen = (state: RootState): boolean => state.enquiries.ui.confirmationResolveModalIsOpen;
const getDeleteConfirmationModalIsOpen = (state: RootState): boolean => state.enquiries.ui.confirmationDeleteModalIsOpen;

export default {
    getEnquiriesAttempting,
    getEnquiriesError,
    getEnquiries,
    getEnquiriesFilters,

    getEnquiryDetailsAttempting,
    getEnquiryDetailsError,
    getEnquiryDetails,

    getResolveEnquiryAttempting,
    getResolveEnquiryError,

    getDeleteEnquiryAttempting,
    getDeleteEnquiryError,

    getResolveConfirmationModalIsOpen,
    getDeleteConfirmationModalIsOpen,
};
