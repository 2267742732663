import { OrderPaymentTermEnum } from 'entities/order';

const PaymentTermLov = [{
    label: 'Net 14 After Delivery',
    value: OrderPaymentTermEnum.NetFourteenAfterDelivery,
}, {
    label: 'Net 14',
    value: OrderPaymentTermEnum.NetFourteen,
}, {
    label: 'Net 30',
    value: OrderPaymentTermEnum.NetThirty,
}, {
    label: '100% Deposit',
    value: OrderPaymentTermEnum.HundredPercentDeposit,
}, {
    label: '60% Deposit, 40% Upon Delivery',
    value: OrderPaymentTermEnum.SixtyPercentDeposit,
}, {
    label: '50% Deposit, 50% Upon Delivery',
    value: OrderPaymentTermEnum.FiftyPercentUponDelivery,
}, {
    label: 'N/A',
    value: OrderPaymentTermEnum.NA,
},
];

export default PaymentTermLov;
