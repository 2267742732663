const theme = {
    colors: {
        primary: '#33BEAD',
        secondary: '#F4F8F7',
        tertiary: '#CBDAD3',
        info: '#0B65EC',
        success: '#28a745',
        error: '#D15757',
        background: '#EEF2F0',
        border: '#42564E33',

        grey_light: '#CBDAD34D',
    },
    fonts: {
        primary: 'Manrope, sans-serif',
        secondary: 'Roboto, sans-serif',
    },
    fontSize: {
        xs: '0.75rem',
        sm: '0.875rem',
        default: '1.125rem',
        lg: '1.75rem',
        xl: '2.25rem',
        xxl: '2.8125rem',
    },
    lineHeight: {
        xs: '1.25rem',
        sm: '1.5rem',
        default: '1.75rem',
        lg: '2rem',
        xl: '2.75rem',
        xxl: '3.4375rem',
    },
    fontWeight: {
        light: '300',
        default: '400',
        bold: '500',
    },
    fontColor: {
        primary: '#295543',
        secondary: '#00291866',
        tertiary: '#42564ecc',
        default: '#6C7278',
        info: '#0B65EC',
        success: '#0D894F',
        warning: '#FC9736',
        error: '#F2594F',
    },
};

export default theme;
