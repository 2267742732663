import { GetPurchaseOrdersApiResponse } from 'api/PurchaseOrdersBase';
import { RootState } from 'redux/store';

import { IPurchaseOrder } from 'entities/purchaseOrders';

import { PurchaseOrdersReduxFilters } from './types';

const getPurchaseOrdersAttempting = (state: RootState): boolean => state.po.actions.getPurchaseOrders;
const getPurchaseOrdersError = (state: RootState): string => state.quotations.error.getQuotations;
const getPurchaseOrders = (state: RootState): GetPurchaseOrdersApiResponse => state.po.purchaseOrders;
const getPurchaseOrdersFilters = (state: RootState): PurchaseOrdersReduxFilters => state.po.filters;

const getPurchaseOrderDetailsAttempting = (state: RootState): boolean => state.po.actions.getPurchaseOrderDetails;
const getPurchaseOrderDetailsError = (state: RootState): string => state.po.error.getPurchaseOrderDetails;
const getPurchaseOrderDetails = (state: RootState): IPurchaseOrder | null => state.po.purchaseOrderDetails;

const getPurchaseOrderConfirmationDeleteModalIsOpen = (state: RootState): boolean => state.po.ui.confirmationDeleteModalIsOpen;
const getPurchaseOrderSendPoModalIsOpen = (state: RootState): boolean => state.po.ui.sendPoModalIsOpen;

const updatePurchaseOrderDetailsStatus = (state: RootState): boolean => state.po.actions.updatePurchaseOrderDetailsStatus;
const updatePurchaseOrderDetailsStatusError = (state: RootState): string => state.po.error.updatePurchaseOrderDetailsStatus;

const getPurchaseOrderNewOrEditPurchaseOrder = (state: RootState): Partial<IPurchaseOrder> => state.po.createOrEditPurchaseOrder;

const getCreatePurchaseOrderAttempting = (state: RootState): boolean => state.po.actions.createPurchaseOrder;
const getCreatePurchaseOrderError = (state: RootState): string => state.po.error.createPurchaseOrder;

const getUpdatePurchaseOrderAttempting = (state: RootState): boolean => state.po.actions.updatePurchaseOrder;
const getUpdatePurchaseOrderError = (state: RootState): string => state.po.error.updatePurchaseOrder;

const getDeletePurchaseOrderAttempting = (state: RootState): boolean => state.po.actions.deletePurchaseOrder;
const getDeletePurchaseOrderError = (state: RootState): string => state.po.error.deletePurchaseOrder;

const getDownloadPurchaseOrderAttempting = (state: RootState): boolean => state.po.actions.downloadPurchaseOrder;

const getSendPurchaseOrderAttempting = (state: RootState): boolean => state.po.actions.sendPurchaseOrder;
const getSendPurchaseOrderError = (state: RootState): string => state.po.error.sendPurchaseOrder;

const updateEmailVariablesAttempting = (state: RootState): boolean => state.po.actions.updateEmailVariables;
const updateEmailVariablesError = (state: RootState): string => state.po.error.updateEmailVariables;

const viewEmailVariablesAttempting = (state: RootState): boolean => state.po.actions.viewPurchaseOrderEmail;
const viewEmailVariablesError = (state: RootState): string => state.po.error.viewPurchaseOrderEmail;

export default {
    getPurchaseOrdersAttempting,
    getPurchaseOrdersError,
    getPurchaseOrders,
    getPurchaseOrdersFilters,

    getPurchaseOrderDetailsAttempting,
    getPurchaseOrderDetailsError,
    getPurchaseOrderDetails,

    getPurchaseOrderConfirmationDeleteModalIsOpen,
    getPurchaseOrderSendPoModalIsOpen,

    updatePurchaseOrderDetailsStatus,
    updatePurchaseOrderDetailsStatusError,

    getPurchaseOrderNewOrEditPurchaseOrder,

    getCreatePurchaseOrderAttempting,
    getCreatePurchaseOrderError,

    getUpdatePurchaseOrderAttempting,
    getUpdatePurchaseOrderError,

    getDeletePurchaseOrderAttempting,
    getDeletePurchaseOrderError,

    getDownloadPurchaseOrderAttempting,

    getSendPurchaseOrderAttempting,
    getSendPurchaseOrderError,

    updateEmailVariablesAttempting,
    updateEmailVariablesError,

    viewEmailVariablesAttempting,
    viewEmailVariablesError,
};
