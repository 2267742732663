import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import PageHeader from 'components/atoms/PageHeader';
import Paginator from 'components/atoms/Paginator';
import Table, { IActionsArray, IHeaderArray } from 'components/atoms/Table';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import TableFilters from 'components/molecules/TableFilters';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import CustomerSelectors from 'redux/slices/customers/Selectors';
import OrderSelectors from 'redux/slices/order/Selectors';

import NavActions from 'lib/NavActions';
import { OrderStatusEnum } from 'entities/order';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

interface CustomerOrderTableProps {
    customerId?: string;
    disableActions?: boolean;
}

const CustomerOrderTable = (props: CustomerOrderTableProps): JSX.Element | null => {
    const { customerId, disableActions } = props;

    const [idToDelete, setIdToDelete] = useState<string | null>(null);
    const [orderNumberToDelete, setOrderNumberToDelete] = useState<string | null>(null);

    const loading = useAppSelector(CustomerSelectors.getCustomerOrdersAttempting);
    const error = useAppSelector(CustomerSelectors.getCustomerOrdersError);
    const { maxIndex, data: orders, totalRecords } = useAppSelector(CustomerSelectors.getCustomerOrders);

    const { ordersCurrentPage, ordersSearchQuery } = useAppSelector(CustomerSelectors.getCustomerFilters);
    const confirmationModalIsOpen = useAppSelector(OrderSelectors.deleteConfirmationModalIsOpen);

    const dispatch = useAppDispatch();

    if (!customerId) return null;

    const getOrders = () => {
        dispatch(Actions.customersGetCustomerOrdersAttempt({ id: customerId }));
    };

    useEffect(() => {
        if (ordersCurrentPage <= maxIndex) getOrders();
    }, [ordersCurrentPage]);

    const onPaginatorPressed = (page: number) => {
        dispatch(Actions.customersSetFilters({ ordersCurrentPage: page }));
    };

    const onSearchQueryChanged = (query: string) => {
        dispatch(Actions.customersSetFilters({ ordersSearchQuery: query }));
    };

    const ordersHeaders = [
        { header: 'Order No', field: 'id' },
        { header: 'Date', field: 'dateOfProject' },
        { header: 'Project', field: 'projectName' },
        { header: 'Status', field: 'status' },
        { header: 'Actions', field: 'action' },
    ];

    const tableData = orders.map((item) => ({
        id: item.id,
        onClick: () => NavActions.navToOrderDetails(item.id),
        cellData: {
            id: item.orderNo,
            dateOfProject: dayjs(item.createdAt).format('DD/MM/YYYY'),
            projectName: item.projectName ?? '',
            status: OrderStatusEnum[item.status],
        },
    }));

    const tableActions: IActionsArray[] = [{
        id: 'delete',
        icon: <DeleteIcon />,
        onClick: (orderId: string) => {
            const selectedOrder = orders.find((item) => item.id === orderId);

            if (selectedOrder) {
                setIdToDelete(orderId);
                setOrderNumberToDelete(selectedOrder.orderNo);

                toast.error('This feature is still under development');
            }
        },
    }];

    const onDeleteOrderCancelled = () => {
        setIdToDelete(null);
        setOrderNumberToDelete(null);

        dispatch(Actions.orderSetConfirmDeleteModalIsOpen(false));
    };

    const onDeleteOrderConfirmed = () => {
        if (idToDelete) {
            dispatch(Actions.orderDeleteOrderAttempt({ id: idToDelete }));
        } else {
            toast.error('Please select an order to delete');
        }
    };

    return (
        <OrderContainer>
            <PageHeader subtitle={`${totalRecords} total order(s)`}>
                Orders Placed
            </PageHeader>

            <TableFilters
                searchFilterEnabled
                searchValue={ordersSearchQuery}
                onSearchChanged={onSearchQueryChanged}
                onSearch={getOrders}
            />

            <Table
                headerArrays={ordersHeaders}
                dataArray={tableData}
                actions={tableActions}
                style={{ marginTop: 32, marginBottom: 32 }}
                loading={loading}
                error={error}
                disableActions={disableActions}
            />

            <PaginationContainer>
                <Paginator
                    currentPage={ordersCurrentPage}
                    onPagePressed={onPaginatorPressed}
                    maxPages={maxIndex}
                    disabled={loading}
                />
            </PaginationContainer>
            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                icon={<ConfirmationIcon />}
                title={`Delete "${orderNumberToDelete}"?`}
                text='Please confirm deleting this order.'
                onCancel={onDeleteOrderCancelled}
                onConfirm={onDeleteOrderConfirmed}
                cancelText='Cancel'
                confirmText='Confirm'
                confirmButtonVariant={ButtonVariant.warning}
            />
        </OrderContainer>
    );
};

const OrderContainer = styled.div`
    margin-top: 28px;
`;

const PaginationContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

export default CustomerOrderTable;
