import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import DateFilterButton from 'components/atoms/DateFilter';
import DropdownButton from 'components/atoms/DropdownButton';
import Input from 'components/atoms/Input';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';

import { AuthRoleEnum } from 'entities/auth';
import { OrderPaymentTermEnum } from 'entities/order';
import PaymentTermLov from 'lov/PaymentTermLov';

import theme from 'styles/theme';

interface ComponentProps {
    paymentTerm?: OrderPaymentTermEnum;
    paymentDueDate?: string;
    loading?: boolean;
    error?: string;
    onUpdatePaymentTerms: (paymentTerm: OrderPaymentTermEnum, paymentDueDate: string) => void;
    updateLoading: boolean;
}

const PaymentTerm: React.FC<ComponentProps> = (props: ComponentProps) => {
    const {
        paymentTerm,
        paymentDueDate,
        loading,
        error,
        onUpdatePaymentTerms,
        updateLoading,
    } = props;

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);
    const [newPaymentTerm, setNewPaymentTerm] = useState<OrderPaymentTermEnum>();
    const [date, setDate] = useState<Date>(new Date());

    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        return () => {
            setIsChanged(false);
            setNewPaymentTerm(undefined);
            setDate(new Date());
        };
    }, []);

    useEffect(() => {
        if (paymentTerm) {
            setNewPaymentTerm(paymentTerm);
        } else {
            setNewPaymentTerm(undefined);
        }
    }, [paymentTerm]);

    useEffect(() => {
        if (paymentDueDate) {
            setDate(dayjs(paymentDueDate).toDate());
        } else {
            setDate(new Date());
        }
    }, [paymentDueDate]);

    useEffect(() => {
        if (newPaymentTerm !== paymentTerm || dayjs(date).toISOString() !== paymentDueDate) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    }, [newPaymentTerm, date]);

    const onChangePaymentTerm = (value: OrderPaymentTermEnum) => {
        setNewPaymentTerm(value);
    };

    const renderSaveButton = () => {
        if (userInfo?.role === AuthRoleEnum.Supplier) return null;
        return (
            <ButtonContainer>
                <Text variant={TextVariant.h4} style={{ color: theme.fontColor.warning }}>
                    {isChanged ? 'you made some changes!' : ''}
                </Text>
                <Button
                    style={{ width: 'fit-content' }}
                    variant={ButtonVariant.secondary}
                    label='Save'
                    loading={updateLoading}
                    onClick={() => onUpdatePaymentTerms(newPaymentTerm as OrderPaymentTermEnum, dayjs(date).toISOString())}
                />
            </ButtonContainer>
        );
    };

    if (loading) {
        return (
            <FlexContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
                <LoadingIndicator />
            </FlexContainer>
        );
    }
    if (error) {
        return (
            <FlexContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text variant={TextVariant.error}>{error}</Text>
            </FlexContainer>
        );
    }

    return (
        <FlexContainer>
            <Text variant={TextVariant.h2}>Payment Terms</Text>
            <DropdownButton
                value={newPaymentTerm}
                label='Payment terms'
                options={PaymentTermLov}
                onSelect={(e) => onChangePaymentTerm(e as OrderPaymentTermEnum)}
                // disabled={userInfo?.role === AuthRoleEnum.Executive}
            />
            <DateFilterButton
                label='Due Date'
                onChangeDate={(e: Date) => setDate(e)}
                dateFrom={date}
                // disabled={userInfo?.role === AuthRoleEnum.Executive}
            />
            {renderSaveButton()}
        </FlexContainer>
    );
};
const FlexContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    background-color: white;
    border-radius: 15px;
    padding: 2rem;
    min-height: 350px;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 3rem;
`;

export default PaymentTerm;
