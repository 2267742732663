import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import ProductsGateway from 'api/Products';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { GetShippingCostPayload } from 'redux/slices/products/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetShippingCost(api: ProductsGateway): SagaWatcherReturnType {
    yield takeEvery('products/productsGetShippingCostAttempt', handleGetShippingCost, api);
}

function* handleGetShippingCost(api: ProductsGateway, data: GetShippingCostPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getShippingCost], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.productsGetShippingCostFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.productsGetShippingCostSuccess(response.data));
    }
}
