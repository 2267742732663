import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { UpdateOrderCommentPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchUpdateOrderComment(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderUpdateOrderCommentAttempt', handleUpdateOrderComment, api);
}
function* handleUpdateOrderComment(api: OrderGateway, data: UpdateOrderCommentPayload) {
    const authToken = yield* select((state) => state.auth.authToken);

    const response = yield* call([api, api.updateOrderComment], {
        authToken,
        id: data.payload.id,
        text: data.payload.text,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderUpdateOrderCommentFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderUpdateOrderCommentSuccess());
        yield put(Actions.orderSetEditOrderCommentModalIsOpen(false));
        yield put(Actions.orderGetOrderCommentAttempt({ orderId: data.payload.orderId, index: 0 }));
        toast.success('Comment updated!');
    }
}
