import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';
import { ISupplierDetails } from 'entities/supplier';

import {
    CreateSupplierApiParams,
    DeleteSupplierApiParams,
    GetSupplierDetailsParams,
    GetSuppliersApiParams,
    GetSuppliersApiResponse,
    ISupplierGateway,
    UpdateSupplierApiParams,
} from './SupplierBase';

export default class SupplierGateway extends ISupplierGateway {
    async getSuppliers(params: GetSuppliersApiParams): GatewayResponse<GetSuppliersApiResponse> {
        const { authToken, ...supplierParams } = params;
        const response: ApiResponse<GetSuppliersApiResponse> = await this.api.get(
            '/suppliers/viewSuppliers',
            supplierParams,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getSupplierDetails(params: GetSupplierDetailsParams): GatewayResponse<ISupplierDetails> {
        const { authToken, supplierId } = params;
        const response: ApiResponse<ISupplierDetails> = await this.api.get(
            `/suppliers/viewSuppliers/${supplierId}`,
            {},
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async updateSupplier(params: UpdateSupplierApiParams): GatewayResponse<string> {
        const { authToken, contactPersonNumber, ...supplierParams } = params;
        let newContactPersonNumber;
        if (contactPersonNumber === '') {
            newContactPersonNumber = 'N/A';
        } else {
            newContactPersonNumber = contactPersonNumber;
        }
        const response: ApiResponse<string> = await this.api.put(
            '/suppliers/updateSupplier',
            {
                ...supplierParams,
                contactPersonNumber: newContactPersonNumber,
            },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async createSupplier(params: CreateSupplierApiParams): GatewayResponse<string> {
        const { authToken, contactPersonNumber, ...supplierParams } = params;
        let newContactPersonNumber;
        if (contactPersonNumber === '') {
            newContactPersonNumber = 'N/A';
        } else {
            newContactPersonNumber = contactPersonNumber;
        }
        const response: ApiResponse<string> = await this.api.post(
            '/suppliers/createSupplier',
            {
                ...supplierParams,
                contactPersonNumber: newContactPersonNumber,
            },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deleteSupplier(params: DeleteSupplierApiParams): GatewayResponse<string> {
        const { authToken, supplierId } = params;

        const response: ApiResponse<string> = await this.api.delete(
            '/suppliers/deleteSupplier',
            { id: supplierId },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }
}
