import React from 'react';

import dayjs from 'dayjs';
import {
    Area,
    Bar,
    CartesianGrid,
    ComposedChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import styled from 'styled-components';

import Utils from 'lib/Utils';

export type ComposedChartData = {
    name: string;
    averageSourceTime: number;
    averageProductionTime: number;
    averageDeliveryTime: number;
    averageFulfillmentTime: number;
}
type ComponentProps = {
    style?: string;
    data: ComposedChartData[];
};
const CustomizedXAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={22} textAnchor='middle'>
                {payload.value}
            </text>
        </g>
    );
};

const CustomizedTooltip = (props: any) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
        return (
            <CustomTooltip>
                <p>{`${payload[0].payload.name}`}</p>
                <div>
                    <div id='averageFulfillmentTime'>{`${payload[0].value} days`}</div>
                    <div id='averageSourceTime'>{`${payload[1].value} days`}</div>
                    <div id='averageProductionTime'>{`${payload[2].value} days`}</div>
                    <div id='averageDeliveryTime'>{`${payload[3].value} days`}</div>
                </div>
            </CustomTooltip>
        );
    }

    return null;
};

const OverviewChart: React.FC<ComponentProps> = (props: ComponentProps) => {
    const { style, data } = props;
    // const data = [
    //     {
    //         name: 'Week 1',
    //         averageSourceTime: 2,
    //         averageProductionTime: 5,
    //         averageDeliveryTime: 1,
    //         averageFulfillmentTime: 11,
    //     },
    //     {
    //         name: 'Week 2',
    //         averageSourceTime: 3,
    //         averageProductionTime: 6,
    //         averageDeliveryTime: 2,
    //         averageFulfillmentTime: 12,
    //     },
    //     {
    //         name: 'Week 3',
    //         averageSourceTime: 3,
    //         averageProductionTime: 7,
    //         averageDeliveryTime: 5,
    //         averageFulfillmentTime: 14,
    //     },
    //     {
    //         name: 'Week 4',
    //         averageSourceTime: 3,
    //         averageProductionTime: 6,
    //         averageDeliveryTime: 2,
    //         averageFulfillmentTime: 12,
    //     },
    // ];
    return (
        <ResponsiveContainer width='100%' height='100%'>
            <ComposedChart
                data={data}
                margin={{
                    top: 20,
                    right: 0,
                    left: 0,
                    bottom: 20,
                }}
                style={style}
            >
                <CartesianGrid strokeDasharray='3 6' vertical={false} />
                <XAxis
                    dataKey='name'
                    axisLine={false}
                    tickLine={false}
                    textDecoration='none'
                    tick={<CustomizedXAxisTick />}

                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    type='number'
                    allowDataOverflow
                />
                <Tooltip
                    cursor={{ stroke: '#0B65EC', strokeWidth: 2 }}
                    content={<CustomizedTooltip />}
                />
                <defs>
                    <linearGradient id='gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
                        <stop offset='0%' stopColor='#33BEAD' />
                        <stop offset='90%' stopColor='#ffffff' />
                    </linearGradient>
                </defs>
                <Area
                    type='linear'
                    dataKey='averageFulfillmentTime'
                    stroke='#33BEAD'
                    strokeWidth={2}
                    fillOpacity={0.2}
                    fill='url(#gradient)'
                    activeDot={{ r: 8 }}
                    connectNulls
                />
                <Bar dataKey='averageSourceTime' barSize={40} fill='#DA46FF' />
                <Bar dataKey='averageProductionTime' barSize={40} fill='#0B65EC' />
                <Bar dataKey='averageDeliveryTime' barSize={40} fill='#FC9736' />
            </ComposedChart>
        </ResponsiveContainer>
    );
};
const CustomTooltip = styled.div`
    border-radius: 0.5rem;
    background-color: #002F1B;
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    font-family: ${props => props.theme.fonts.primary};
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;

    gap: 0.25rem;

    div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        height: fit-content;

        #averageFulfillmentTime {
            flex-direction: row;
            &::before {
                content: '';
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 50%;
                background-color: #33BEAD;
            }
        }

        #averageSourceTime {
            flex-direction: row;
            &::before {
                content: '';
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 50%;
                background-color: #DA46FF;
            }
        }

        #averageProductionTime {
            flex-direction: row;
            &::before {
                content: '';
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 50%;
                background-color: #0B65EC;
            }
        }

        #averageDeliveryTime {
            flex-direction: row;

            &::before {
                content: '';
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 50%;
                background-color: #FC9736;
            }
        }
    }
`;
export default OverviewChart;
