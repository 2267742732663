import cancelledIcon from './orders/cancelled.svg';
import collectionIcon from './orders/collection.svg';
import deliveredIcon from './orders/delivered.svg';
import draftIcon from './orders/draft.svg';
import failedIcon from './orders/failed.svg';
import manufacturingIcon from './orders/manufacturing.svg';
import onHoldIcon from './orders/onhold.svg';
import packingIcon from './orders/packing.svg';
import paidIcon from './orders/paid.svg';
import pendingIcon from './orders/pending.svg';
import processingIcon from './orders/processing.svg';
import productionIcon from './orders/production.svg';
import refundedIcon from './orders/refunded.svg';
import samplingIcon from './orders/sampling.svg';
import shippedIcon from './orders/shipped.svg';
import unPaidIcon from './orders/unpaid.svg';
import acaSaltycustomsLogo from './acaLogo.svg';
import addIcon from './add.svg';
import backIcon from './back.svg';
import calendarIcon from './calendarIcon.svg';
import cancelIcon from './cancel.svg';
import cartIcon from './cart.svg';
import checkIcon from './check.svg';
import confirmationIcon from './confirmation.svg';
import creditCardIcon from './creditCard.svg';
import currenciesIcon from './currencies.svg';
import dashboardIcon from './dashboard.svg';
import databaseIcon from './database.svg';
import deleteIcon from './delete.svg';
import editIcon from './edit.svg';
import enquiriesIcon from './enquiries.svg';
import eyeCloseIcon from './eyeClose.svg';
import eyeOpenIcon from './eyeOpen.svg';
import goIcon from './go.svg';
import lockIcon from './lock.svg';
import saltyCustomsLogo from './logo.svg';
import logoutIcon from './logout.svg';
import mailIcon from './mail.svg';
import moneyCheckmarkIcon from './moneyCheckmark.svg';
import orderIcon from './order.svg';
import paymentConfirmedIcon from './paymentConfirmed.svg';
import paymentProcessingIcon from './paymentProcessing.svg';
import pencilIcon from './pencil.svg';
import permissionIcon from './permission.svg';
import piechartIcon from './piechart.svg';
import productsIcon from './products.svg';
import profileIcon from './profile.svg';
import saltyCustoms from './saltyCustoms.svg';
import settingIcon from './setting.svg';
import shippingIcon from './shipping.svg';
import storageBoxIcon from './storageBox.svg';
import userIcon from './user.svg';

export default {
    addIcon,
    backIcon,
    editIcon,
    deleteIcon,
    calendarIcon,
    checkIcon,
    saltyCustomsLogo,
    acaSaltycustomsLogo,
    profileIcon,
    lockIcon,
    eyeOpenIcon,
    eyeCloseIcon,
    mailIcon,
    orderIcon,
    dashboardIcon,
    enquiriesIcon,
    pencilIcon,
    databaseIcon,
    shippingIcon,
    creditCardIcon,
    currenciesIcon,
    storageBoxIcon,
    piechartIcon,
    userIcon,
    settingIcon,
    logoutIcon,
    cartIcon,
    moneyCheckmarkIcon,
    goIcon,
    permissionIcon,
    confirmationIcon,
    productsIcon,
    pendingIcon,
    shippedIcon,
    paidIcon,
    cancelledIcon,
    collectionIcon,
    deliveredIcon,
    processingIcon,
    failedIcon,
    refundedIcon,
    manufacturingIcon,
    samplingIcon,
    productionIcon,
    draftIcon,
    packingIcon,
    onHoldIcon,
    unPaidIcon,
    saltyCustoms,
    cancelIcon,
    paymentProcessingIcon,
    paymentConfirmedIcon,
};
