import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import CustomerGateway from 'api/Customer';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetCustomerAddresses(api: CustomerGateway): SagaWatcherReturnType {
    yield takeEvery('ui/uiGetCustomerAddressesAttempt', handleGetCustomerAddresses, api);
}

function* handleGetCustomerAddresses(api: CustomerGateway, data: PayloadAction<{ customerId: string}>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getCustomerDetails], {
        authToken,
        customerId: data.payload.customerId,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.uiGetCustomerAddressesFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.uiGetCustomerAddressesSuccess(response.data.address));
    }
}
