import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import CompanyGateway from 'api/Company';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetCompanyDetails(api: CompanyGateway): SagaWatcherReturnType {
    yield takeEvery('company/companyGetCompanyDetailsAttempt', handleGetCompanyDetails, api);
}
function* handleGetCompanyDetails(api: CompanyGateway, data: PayloadAction<{ id: string }>) {
    const authToken = yield* select((state) => state.auth.authToken);

    const response = yield* call([api, api.getCompanyDetails], {
        authToken,
        id: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.companyGetCompanyDetailsFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.companyGetCompanyDetailsSuccess(response.data));
    }
}
