import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PurchaseOrderGateway from 'api/PurchaseOrders';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';

export default function* watchDeletePurchaseOrder(api: PurchaseOrderGateway): SagaWatcherReturnType {
    yield takeEvery('po/poDeletePurchaseOrderAttempt', handleDeletePurchaseOrder, api);
}

function* handleDeletePurchaseOrder(api: PurchaseOrderGateway, data: PayloadAction<{ id: string }>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.deletePurchaseOrder], {
        authToken,
        id: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.poDeletePurchaseOrderFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('PO deleted.');

        yield put(Actions.poSetConfirmDeleteModalIsOpen(false));
        yield put(Actions.poDeletePurchaseOrderSuccess());
        yield put(Actions.poGetPurchaseOrdersAttempt());

        NavActions.navToPurchaseOrders();
    }
}
