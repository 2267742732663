import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import QuotationsGateway from 'api/Quotations';
import { UpdateQuotationApiParams } from 'api/QuotationsBase';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import CompanySelectors from 'redux/slices/company/Selectors';
import ProductSelectors from 'redux/slices/products/Selectors';
import QuotationSelectors from 'redux/slices/quotations/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

import { IAddress } from 'entities/address';
import { TaxRateEnum } from 'entities/products';
import { QuotationShippingMethodEnum, QuotationStatusEnum } from 'entities/quotations';

export default function* watchUpdateQuotation(api: QuotationsGateway): SagaWatcherReturnType {
    yield takeEvery('quotations/quoteUpdateQuotationAttempt', handleUpdateQuotation, api);
}
function* handleUpdateQuotation(api: QuotationsGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const quotationDetails = yield* select(QuotationSelectors.getQuotationsNewOrEditQuotation);
    const companyDetails = yield* select(CompanySelectors.getCompanyDetails);

    const selectedProducts = yield* select(ProductSelectors.getSelectedProducts);
    const discountPercent = yield* select(ProductSelectors.getDiscountPercent);
    const discountFixed = yield* select(ProductSelectors.getDiscountFixed);
    const shippingPrice = yield* select(ProductSelectors.getShippingPrice);
    const taxPrice = yield* select(ProductSelectors.getTaxPrice);
    const totalWeight = yield* select(ProductSelectors.getTotalWeight);
    const isTaxApplied = yield* select(ProductSelectors.getIsTaxApplied);

    const calculateSubTotal = () => {
        let subTotal = 0;
        selectedProducts.forEach((product) => {
            if (product.discountPercent) {
                const total = calculateDiscountedPrice(Number(product.total), Number(product.totalQuantity));

                subTotal += (total * product.totalQuantity);
            } else {
                const total = product.total / product.totalQuantity;

                subTotal += total * product.totalQuantity;
            }
        });
        return subTotal;
    };

    const calculateTotal = () => {
        let finalTotal = 0;

        if (discountPercent) {
            finalTotal = calculateSubTotal() - (calculateSubTotal() * (Number(discountPercent) / 100)) + Number(shippingPrice);
        }

        if (discountFixed) {
            finalTotal = calculateSubTotal() - Number(discountFixed) + Number(shippingPrice);
        }

        if (!discountPercent && !discountFixed) {
            finalTotal = calculateSubTotal() + Number(shippingPrice);
        }

        return Number(finalTotal.toFixed(2));
    };

    const calculateDiscountedPrice = (total: number, quantity: number) => {
        const price = total / quantity;

        return Number(price.toFixed(2));
    };

    const calculateTotalWithTax = () => {
        if (!companyDetails?.tax) return calculateTotal();
        const tax = calculateTotal() * (Number(companyDetails.tax) / 100);
        return calculateTotal() + tax;
    };

    const newProducts = selectedProducts.filter((product) => product.productId)
        .map((product) => ({
            productId: product.productId as string,
            variantId: product.variantId as string,
            productName: product.name,
            productVariant: {
                fit: product.variant?.fit,
                type: product.variant?.type,
                sleeve: product.variant?.sleeve,
                style: product.variant?.style,
                color: product.variant?.color,
            },
            quantities: product.quantities ? product.quantities?.map((quantities) => ({
                quantity: quantities.quantity as number,
                productPriceId: quantities.id,
                size: quantities.size ? quantities.size : undefined,
                pricePerUnit: Number(quantities.pricePerUnit),
            })) : [],
            printMethods: product.printMethods ? product.printMethods.map((printMethod) => ({
                side: printMethod.side,
                printCustomId: printMethod.printCustomId,
                printVariantId: printMethod.printVariantId,
                pricePerUnit: Number(printMethod.pricePerUnit),
                printMethod: printMethod.printMethod,
                block: printMethod.block,
                colorCount: printMethod.colorCount,
            })) : [],
            finalQuantity: product.totalQuantity,
            discountPercent: product.discountPercent ?? undefined,
            discountFixed: product.discountFixed ?? undefined,
            discountAmountApplied: Number(((Number(product.price) * Number(product.totalQuantity)) - calculateDiscountedPrice(Number(product.total), Number(product.totalQuantity)) * product.totalQuantity).toFixed(2)),
            truePricePerUnit: calculateDiscountedPrice(Number(product.total), Number(product.totalQuantity)),
            totalPricePerUnit: Number(product.price),
            subtotal: product.subtotal ?? 0,
            finalProductPrice: calculateDiscountedPrice(Number(product.total), Number(product.totalQuantity)) * product.totalQuantity,
            remark: product.remark,
            // weight: product.weight ?? 0, // todo: might need this in the future
        }));

    const customLineProducts = selectedProducts.filter((product) => !product.productId)
        .map((product) => ({
            productName: product.name,
            finalQuantity: product.totalQuantity,
            finalProductPrice: Number(product.total),
            totalPricePerUnit: Number(product.price),
            subtotal: product.subtotal ?? 0,
            discountPercent: product.discountPercent ?? undefined,
            discountFixed: product.discountFixed ?? undefined,
            discountAmountApplied: Number(((Number(product.price) * Number(product.totalQuantity)) - calculateDiscountedPrice(Number(product.total), Number(product.totalQuantity)) * product.totalQuantity).toFixed(2)),
            truePricePerUnit: calculateDiscountedPrice(Number(product.total), Number(product.totalQuantity)),
            weight: product.weight ?? 0,
            remark: product.remark,
        }));

    const newQuotation: Omit<UpdateQuotationApiParams, 'authToken'> = {
        id: quotationDetails.id as string,
        quotationStatus: quotationDetails.quotationStatus as QuotationStatusEnum,
        companyAccountId: quotationDetails.companyAccountId as string,
        clientId: quotationDetails.clientId as string,
        quotationDate: quotationDetails.quotationDate ?? dayjs().format('MM/DD/YYYY'),
        shippingAddress: quotationDetails.shippingAddress as IAddress,
        shippingMethod: quotationDetails.shippingMethod as QuotationShippingMethodEnum,
        remark: quotationDetails.remark,
        note: quotationDetails.note,
        products: newProducts,
        customLineProducts,
        discountPercent: discountPercent ? Number(discountPercent) : undefined,
        discountFixed: discountFixed ? Number(discountFixed) : undefined,
        shippingFee: Number(shippingPrice),
        subtotal: calculateSubTotal(),
        tax: (taxPrice && isTaxApplied) ? Number(taxPrice) : 0,
        finalPrice: isTaxApplied ? calculateTotalWithTax() : calculateTotal(),
        totalWeight,
        taxType: isTaxApplied ? TaxRateEnum.GstTaxRate : TaxRateEnum.OutOfScopeTaxRate,

    };

    const response = yield* call([api, api.updateQuotation], {
        authToken,
        ...newQuotation,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.quoteUpdateQuotationFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.quoteUpdateQuotationSuccess());
        toast.success('Quotation updated successfully');

        yield put(Actions.quoteGetQuotationDetailsAttempt({ id: quotationDetails.id as string }));
        // NavActions.navToMenu('/quotations');
    }
}
