import { GetSuppliersApiResponse } from 'api/SupplierBase';
import { RootState } from 'redux/store';

import { ISupplierDetails } from 'entities/supplier';

import { SuppliersReduxFilters } from './types';

const getSuppliersAttempting = (state: RootState): boolean => state.suppliers.actions.getSuppliers;
const getSuppliersError = (state: RootState): string => state.suppliers.error.getSuppliers;
const getSuppliers = (state: RootState): GetSuppliersApiResponse => state.suppliers.suppliers;
const getSuppliersFilters = (state: RootState): SuppliersReduxFilters => state.suppliers.filters;

const getSupplierDetailsAttempting = (state: RootState): boolean => state.suppliers.actions.getSupplierDetails;
const getSupplierDetailsError = (state: RootState): string => state.suppliers.error.getSupplierDetails;
const getSupplierDetails = (state: RootState): ISupplierDetails | null => state.suppliers.supplierDetails;

const getSupplierUpdateAttempting = (state: RootState): boolean => state.suppliers.actions.updateSupplier;
const getSupplierUpdateError = (state: RootState): string => state.suppliers.error.updateSupplier;

const getSupplierCreateAttempting = (state: RootState): boolean => state.suppliers.actions.createSupplier;
const getSupplierCreateError = (state: RootState): string => state.suppliers.error.createSupplier;

const getSupplierDeleteAttempting = (state: RootState): boolean => state.suppliers.actions.deleteSupplier;
const getSupplierDeleteError = (state: RootState): string => state.suppliers.error.deleteSupplier;

const getSupplierConfirmationDeleteModalIsOpen = (state: RootState): boolean => state.suppliers.ui.confirmationDeleteModalIsOpen;

const getSuppliersCreateEditSupplierParams = (state: RootState): Partial<ISupplierDetails> | null => state.suppliers.createEditSupplier;

export default {
    getSuppliersAttempting,
    getSuppliersError,
    getSuppliers,
    getSuppliersFilters,

    getSupplierDetailsAttempting,
    getSupplierDetailsError,
    getSupplierDetails,

    getSupplierUpdateAttempting,
    getSupplierUpdateError,

    getSupplierCreateAttempting,
    getSupplierCreateError,

    getSupplierDeleteAttempting,
    getSupplierDeleteError,

    getSupplierConfirmationDeleteModalIsOpen,

    getSuppliersCreateEditSupplierParams,
};
