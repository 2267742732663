import React from 'react';

import styled from 'styled-components';

import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import InputWithSearch from 'components/molecules/InputWithSearch';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/ui/Selectors';

import { AuthRoleEnum } from 'entities/auth';
import { ISupplierDetails } from 'entities/supplier';
import { IUserManagementDetails } from 'entities/userManagement';

interface UserManagementFormProps {
    admin: Partial<IUserManagementDetails> | null;
    onChange: (update: Partial<IUserManagementDetails> | Partial<ISupplierDetails>) => void;

    passwordHeader?: string;
    passwordRequired?: boolean;
    passwordPlaceholder?: string;

    error?: string;
    isSetPassword?: boolean;
}

const UserManagementForm = (props: UserManagementFormProps): JSX.Element => {
    const { admin, onChange, passwordHeader, passwordRequired, passwordPlaceholder, error, isSetPassword } = props;

    const dispatch = useAppDispatch();

    const supplierList = useAppSelector(Selectors.getUiSearchSuppliers);
    const supplierListLoading = useAppSelector(Selectors.getUiSearchSuppliersAttempting);
    const supplierListError = useAppSelector(Selectors.getUiSearchSuppliersError);

    const supplierOptions = supplierList.map((item) => {
        return {
            label: item.supplierName,
            value: item.id,
        };
    });

    if (admin?.supplierName && admin.supplierId && !supplierList.length) {
        supplierOptions.push({
            label: admin.supplierName,
            value: admin.supplierId,
        });
    }

    const Options = [
        {
            label: 'Admin',
            value: AuthRoleEnum.Admin,
        },
        {
            label: 'Executive',
            value: AuthRoleEnum.Executive,
        },
        {
            label: 'Manager',
            value: AuthRoleEnum.Manager,
        },
        {
            label: 'Supplier',
            value: AuthRoleEnum.Supplier,
        },
        {
            label: 'Deactivated',
            value: AuthRoleEnum.Deactivated,
        },
    ];

    const onSupplierSearch = (searchQuery: string) => {
        dispatch(Actions.uiSearchForSuppliersAttempt({ searchQuery, currentPage: -1 }));
    };

    const renderSupplierOption = () => {
        if (admin?.role !== AuthRoleEnum.Supplier) {
            return null;
        }

        const selectedSupplierId = admin?.supplierId;

        return (
            <InputWithSearch
                isSearchable
                label='Supplier List'
                emptyLabel='Start typing to search for suppliers. '
                noDataFoundLabel='This supplier does not exist. '
                loading={supplierListLoading}
                error={supplierListError}
                data={supplierOptions}
                value={selectedSupplierId}
                onSelect={(value) => onChange({ supplierId: value as string })}
                onSearch={onSupplierSearch}
                disabled={admin?.role !== AuthRoleEnum.Supplier}
                mandatory
                inputError={!!error && !admin?.supplierId}
            />
        );
    };

    return (
        <Container>
            <Text variant={TextVariant.h2}>
                Admin Details
            </Text>

            <Input
                error={!!error && !admin?.fullName}
                header='Full Name'
                variant='inputForm'
                required
                value={admin?.fullName}
                onChange={(e) => onChange({ fullName: e.target.value })}
            />
            <EmailPasswordContainer>
                <Input
                    error={!!error && !admin?.email}
                    header='Email Address'
                    variant='inputForm'
                    required
                    value={admin?.email}
                    onChange={(e) => onChange({ email: e.target.value })}
                    placeholder='Enter email'
                />

                <Input
                    error={!!error && !admin?.password}
                    header={passwordHeader || 'Password'}
                    variant='password'
                    value={admin?.password || ''}
                    onChange={(e) => onChange({ password: e.target.value })}
                    required={passwordRequired}
                    placeholder={passwordPlaceholder || 'Enter password'}
                    isSetPassword={isSetPassword}
                />

            </EmailPasswordContainer>
            <DropdownContainer>
                <RoleContainer>
                    <InputWithSearch
                        inputError={!!error && !admin?.role}
                        label='Admin Role'
                        data={Options}
                        value={admin?.role}
                        onSelect={(value) => onChange({ role: value as AuthRoleEnum })}
                        isSearchable={false}
                        mandatory
                    />
                </RoleContainer>
                <RoleContainer>
                    {renderSupplierOption()}
                </RoleContainer>
            </DropdownContainer>
        </Container>
    );
};

const Container = styled.div`
    width: 50%;
    background-color: white;
    border-radius: 15px;

    display: flex;
    flex-direction: column;

    padding: 40px 50px;
    gap: 30px;
`;

const EmailPasswordContainer = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: row;
`;

const RoleContainer = styled.div`
    width: 50%;
`;

const DropdownContainer = styled.div`
    display: flex;
    flex-direction: row;

    gap: 20px;
`;

export default UserManagementForm;
