import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'typed-redux-saga/macro';

import AuthGateway from 'api/Auth';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { LoginActionPayload } from 'redux/slices/auth/types';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { ModulesEnum } from 'entities/auth';

export default function* watchLogin(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authLoginAttempt', handleLogin, api);
}

function* handleLogin(api: AuthGateway, data: LoginActionPayload) {
    const { email, password } = data.payload;

    if (!email || !password) {
        yield put(Actions.authLoginFailure('Please enter your email and password'));
        return;
    }

    const response = yield* call([api, api.login], data.payload);

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.authLoginFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            const {
                authToken,
                authTokenExp,
                refreshToken,
                userInfo,
            } = response.data;

            Utils.Auth.storeAuthToken(authToken);
            Utils.Auth.storeExpiresAt(authTokenExp.toString());
            Utils.Auth.storeRefreshToken(refreshToken);
            // Utils.Auth.storeUserEmail(userInfo.email);
            // Utils.Auth.storeUserName(userInfo.fullName);
            // Utils.Auth.storeUserRole(userInfo.role);
            // Utils.Auth.storeUserPermissions(userInfo.rolePermission);
            yield* put(Actions.authSetUserInfo(userInfo));
            yield put(Actions.authLoginSuccess(authToken));

            toast.success('Login Successful');

            if (userInfo.rolePermission[0] === ModulesEnum.Dashboard) {
                NavActions.navToHome();
            } else {
                NavActions.navToMenu(`/${ModulesEnum[userInfo.rolePermission[0]].toLowerCase()}`);
            }
        } else {
            yield put(Actions.authLoginFailure('Something went wrong. Please try again.'));
        }
    }
}
