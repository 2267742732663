import React, { ForwardedRef, forwardRef } from 'react';

import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import { ReactComponent as calendarIcon } from 'assets/icons/calendarIcon.svg';

import theme from 'styles/theme';
import 'react-datepicker/dist/react-datepicker.css';

import { TextVariant } from './variants/TextVariant';
import Text from './Text';

interface DateFilterRangeProps {
  dateFrom: Date | undefined;
  dateTo?: Date | undefined;

  onChangeDate?: (dateFrom: Date, dateTo: Date) => void;
  rangeFilter: true;
  label?: string;
  mandatory?: boolean;
  disabled?: boolean;
  limitMaxDatetoToday?: boolean;
}

interface DateFilterProps {
    dateFrom: Date | undefined;
    dateTo?: Date | undefined;

    onChangeDate?: (selectedDate: Date) => void;
    rangeFilter?: false | undefined;
    label?: string;
    mandatory?: boolean;
    disabled?: boolean;
    limitMaxDatetoToday?: boolean;
  }

type DatePickerCustomInputProps = {
    value: string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const DatePickerCustomButton = forwardRef(({ value, onClick }: DatePickerCustomInputProps, ref: ForwardedRef<HTMLButtonElement>) => (
    <DateButton
        type='button'
        ref={ref}
        onClick={onClick}
    >
        {value}
    </DateButton>
));

const DateFilterButton = (props: DateFilterRangeProps | DateFilterProps): JSX.Element => {
    const {
        dateFrom,
        dateTo,

        onChangeDate,
        rangeFilter,
        label,
        mandatory = false,
        disabled = false,
        limitMaxDatetoToday = false,
    } = props;

    const handleChange = (range: [Date, Date]) => {
        if (rangeFilter && onChangeDate) {
            const [start, end] = range;
            onChangeDate(start, end);
        }
    };

    const handleSingleDateOnChange = (date: Date) => {
        if (!rangeFilter && onChangeDate) {
            onChangeDate(date);
        }
    };

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
    };

    if (rangeFilter) {
        return (
            <DateStyle>
                <DatePicker
                    disabled={disabled}
                    showIcon
                    placeholderText=''
                    dateFormat='dd/MM/YY'
                    toggleCalendarOnIconClick
                    selected={dateFrom}
                    startDate={dateFrom}
                    endDate={dateTo}
                    onChange={handleChange}
                    selectsRange
                    showPopperArrow={false}
                    maxDate={limitMaxDatetoToday ? new Date() : undefined}
                    icon={<DateIcon />}
                    customInput={(
                        <DatePickerCustomButton
                            onClick={handleOnClick}
                            value={dateFrom && dateTo ? `${dateFrom.toLocaleDateString()} - ${dateTo.toLocaleDateString()}` : ''}
                        />
                    )}
                />
            </DateStyle>
        );
    }

    return (
        <Container>
            {label && (
                <Label>
                    {label}
                    { mandatory && (<Text variant={TextVariant.error}>*</Text>) }
                </Label>
            )}
            <DateStyle>
                <DatePicker
                    showIcon
                    disabled={disabled}
                    placeholderText=''
                    dateFormat='dd/MM/YY'
                    toggleCalendarOnIconClick
                    selected={dateFrom}
                    onChange={handleSingleDateOnChange}
                    showPopperArrow={false}
                    icon={<DateIcon />}
                    customInput={(
                        <DatePickerCustomButton
                            onClick={handleOnClick}
                            value={dateFrom ? `${dateFrom.toLocaleDateString()}` : ''}
                        />
                    )}
                />
            </DateStyle>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const DateIcon = styled(calendarIcon)`
    width: 26px;
    height: 26px;
    vertical-align: -0.125em;
    background-color: transparent;
`;

const DateStyle = styled.div`
    font-family: ${theme.fonts.primary};
    // width: 240px;

    border-radius: 25px;
`;

const Label = styled(Text)`
    display: flex;

    font-weight: 500;
    font-size: 16px;

    margin-bottom: 6px;
`;

const DateButton = styled.button`

    display: flex;
    align-items: center;
    text-align: left;

    font-family: ${theme.fonts.primary};
    font-size: 16px;
    line-height: 24px;
    color: ${theme.fontColor.primary};

    width: 100%;
    min-width: 190px;

    border: none;
    outline: none;
    border-radius: 25px;

    background: none;

    padding: 0 30px 0 0;
    cursor: pointer;

    @media (min-width: 600px) {
        font-size: ${theme.fontSize.xs};
        line-height: ${theme.lineHeight.xs};
    }

    @media (min-width: 1280px) {
        font-size: ${theme.fontSize.sm};
        line-height: ${theme.lineHeight.sm};
    }

    @media (min-width: 1520px) {
        font-size: ${theme.fontSize.default};
        line-height: ${theme.lineHeight.default};
    }
    
`;

export default DateFilterButton;
