import { RootState } from 'redux/store';

import { IAddress, ICustomerAddress } from 'entities/address';
import { ICustomer } from 'entities/customer';
import { ISupplier } from 'entities/supplier';

const getUiSearchCustomersAttempting = (state: RootState): boolean => state.ui.actions.searchingCustomers;
const getUiSearchCustomersError = (state: RootState): string => state.ui.error.searchingCustomers;
const getUiSearchCustomers = (state: RootState): ICustomer[] => state.ui.form.customerList;

const getUiGetCustomerAddressesAttempting = (state: RootState): boolean => state.ui.actions.getCustomerAddress;
const getUiGetCustomerAddressesError = (state: RootState): string => state.ui.error.getCustomerAddress;
const getUiGetCustomerAddresses = (state: RootState): ICustomerAddress[] => state.ui.form.customerAddresses;

const getUiCreateCustomerModalIsOpen = (state: RootState): boolean => state.ui.form.createCustomerModalIsOpen;
const getUiCreateCustomerAttempting = (state: RootState): boolean => state.ui.actions.creatingCustomer;
const getUiCreateCustomerError = (state: RootState): string => state.ui.error.creatingCustomer;
const getUiNewCustomer = (state: RootState): ICustomer | null => state.ui.form.newCustomerCreated;

const getUiSearchSuppliersAttempting = (state: RootState): boolean => state.ui.actions.searchingSuppliers;
const getUiSearchSuppliersError = (state: RootState): string => state.ui.error.searchingSuppliers;
const getUiSearchSuppliers = (state: RootState): ISupplier[] => state.ui.form.supplierList;

const getUiSupplierAddressAttempting = (state: RootState): boolean => state.ui.actions.getSupplierAddress;
const getUiSupplierAddressError = (state: RootState): string => state.ui.error.getSupplierAddress;
const getUiSupplierAddress = (state: RootState): IAddress | undefined => state.ui.form.supplierAddress;

export default {
    getUiSearchCustomersAttempting,
    getUiSearchCustomersError,
    getUiSearchCustomers,

    getUiGetCustomerAddressesAttempting,
    getUiGetCustomerAddressesError,
    getUiGetCustomerAddresses,

    getUiCreateCustomerModalIsOpen,
    getUiCreateCustomerAttempting,
    getUiCreateCustomerError,
    getUiNewCustomer,

    getUiSearchSuppliersAttempting,
    getUiSearchSuppliersError,
    getUiSearchSuppliers,

    getUiSupplierAddressAttempting,
    getUiSupplierAddressError,
    getUiSupplierAddress,
};
