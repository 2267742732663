import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';
import { IUserManagementDetails } from 'entities/userManagement';

import { CreateUsermanagementApiParams, DeleteUserManagementParams, EditUserManagementApiParams, GetUserManagementApiParams,
    GetUserManagementApiResponse,
    GetUserManagementDetailsApiParams,
    IUserManagementGateway } from './UserManagementBase';

export default class UserManagementGateway extends IUserManagementGateway {
    async getUserManagement(params: GetUserManagementApiParams): GatewayResponse<GetUserManagementApiResponse> {
        const { authToken, ...userManagementParams } = params;

        const response: ApiResponse<GetUserManagementApiResponse> = await this.api.get(
            '/auth/viewUsers',
            userManagementParams,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async createUserManagement(params: CreateUsermanagementApiParams): GatewayResponse<string> {
        const { authToken, ...userManagementParams } = params;

        const response: ApiResponse<string> = await this.api.post(
            '/auth/addUser',
            userManagementParams,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getUserManagementDetails(params: GetUserManagementDetailsApiParams): GatewayResponse<IUserManagementDetails> {
        const { authToken, userId } = params;

        const response: ApiResponse<IUserManagementDetails> = await this.api.get(
            `/auth/viewUsers/${userId}`,
            {},
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async editUserManagement(params: EditUserManagementApiParams): GatewayResponse<string> {
        const { authToken, ...userManagementParams } = params;

        const response: ApiResponse<string> = await this.api.put(
            '/auth/editUser',
            userManagementParams,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deleteUserManagement(params: DeleteUserManagementParams): GatewayResponse<void> {
        const { authToken, userId } = params;

        const response: ApiResponse<void> = await this.api.delete(
            '/auth/deleteUser',
            { userId },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }
}
