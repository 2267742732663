import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import SuppliersGateway from 'api/Supplier';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchDeleteSupplier(api: SuppliersGateway): SagaWatcherReturnType {
    yield takeEvery('suppliers/supplierDeleteSupplierAttempt', handleDeleteSupplier, api);
}

function* handleDeleteSupplier(api: SuppliersGateway, data: PayloadAction<{ id: string }>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.deleteSupplier], {
        authToken,
        supplierId: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        if (response.code === '9990') {
            yield put(Actions.supplierDeleteSupplierFailure('We are unable to verify this supplier. Please try again.'));
            return;
        }

        yield put(Actions.supplierDeleteSupplierFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.supplierDeleteSupplierSuccess());
        yield put(Actions.supplierGetSuppliersAttempt());

        toast.success('Supplier deleted.');
    }
}
