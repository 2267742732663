import { GetPrintMethodApiResponse, GetProductsApiResponse } from 'api/ProductsBase';
import { RootState } from 'redux/store';

import { IPrintMethodVariant, IPrintMethodVariantType, IProductDetails, IProductPricing, IProductVariant, IProductVariantType } from 'entities/products';

const uploadProductsFilesAttempting = (state: RootState): boolean => state.products.actions.uploadProductsFiles;
const uploadProductsFilesError = (state: RootState): string => state.products.error.uploadProductsFiles;

const getProductsAttempting = (state: RootState): boolean => state.products.actions.getProducts;
const getProductsError = (state: RootState): string => state.products.error.getProducts;
const getProducts = (state: RootState): GetProductsApiResponse => state.products.products;

const getProductVariantAttempting = (state: RootState): boolean => state.products.actions.getProductVariant;
const getProductVariantError = (state: RootState): string => state.products.error.getProductVariant;
const getProductVariant = (state: RootState): IProductVariant[] => state.products.productVariant;

const getProductVariantType = (state: RootState): IProductVariantType => state.products.productVariantType;

const getProductPricingAttempting = (state: RootState): boolean => state.products.actions.getProductPricing;
const getProductPricingError = (state: RootState): string => state.products.error.getProductPricing;
const getProductPricing = (state: RootState): IProductPricing[] => state.products.productPricing;

const getPrintMethodListAttempting = (state: RootState): boolean => state.products.actions.getPrintMethod;
const getPrintMethodListError = (state: RootState): string => state.products.error.getPrintMethod;
const getPrintMethodList = (state: RootState): GetPrintMethodApiResponse => state.products.printMethodList;

const getPrintMethodVariantAttempting = (state: RootState): boolean => state.products.actions.getPrintMethodVariant;
const getPrintMethodVariantError = (state: RootState): string => state.products.error.getPrintMethodVariant;
const getPrintMethodVariant = (state: RootState): IPrintMethodVariant[][] => state.products.printMethodVariant;

const getPrintMethodPricingAttempting = (state: RootState): boolean => state.products.actions.getPrintMethodPricing;
const getPrintMethodPricingError = (state: RootState): string => state.products.error.getPrintMethodPricing;
const getPrintMethodPricing = (state: RootState): IProductPricing[][] => state.products.printMethodPricing;

const getPrintMethodVariantType = (state: RootState): IPrintMethodVariantType[] => state.products.printMethodVariantType;

const getSelectedProducts = (state: RootState): IProductDetails[] => state.products.selectedProducts;

const getDiscountPercent = (state: RootState): string | undefined => state.products.discountPercent;
const getDiscountFixed = (state: RootState): string | undefined => state.products.discountFixed;

const getDiscountPrice = (state: RootState): string | null => state.products.discount;
const getShippingPrice = (state: RootState): string | null => state.products.shippingCost;
const getTaxPrice = (state: RootState): string | null => state.products.taxPrice;
const getTotalWeight = (state: RootState): number => state.products.totalWeight;
const getIsShippingCostEdited = (state: RootState): boolean => state.products.isShippingCostEdited;
const getIsTaxApplied = (state: RootState): boolean => state.products.isTaxApplied;

export default {
    uploadProductsFilesAttempting,
    uploadProductsFilesError,

    getProductsAttempting,
    getProductsError,
    getProducts,

    getProductVariantAttempting,
    getProductVariantError,
    getProductVariant,

    getProductVariantType,

    getProductPricingAttempting,
    getProductPricingError,
    getProductPricing,

    getPrintMethodListAttempting,
    getPrintMethodListError,
    getPrintMethodList,
    getPrintMethodVariantAttempting,
    getPrintMethodVariantError,
    getPrintMethodVariant,
    getPrintMethodPricingAttempting,
    getPrintMethodPricingError,
    getPrintMethodPricing,
    getPrintMethodVariantType,

    getSelectedProducts,

    getDiscountPercent,
    getDiscountFixed,

    getDiscountPrice,
    getShippingPrice,
    getTaxPrice,
    getTotalWeight,
    getIsShippingCostEdited,
    getIsTaxApplied,
};
