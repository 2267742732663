import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IPrintMethodList, IPrintMethodVariant, IProduct, IProductPricing, IProductVariant } from 'entities/products';
import { CurrencyEnum } from 'entities/supplier';

export interface UploadProductsFilesApiParams {
    authToken: string;
    file: File;
}

export interface IProductsFiles {
    fileName: string;
    file: File;
}

export interface GetProductsApiParams {
    authToken: string;

    index: number;
    searchQuery?: string;
}

export interface GetProductsApiResponse {
    maxIndex: number;
    index: number;
    totalRecords: number;
    data: IProduct[];
}

export interface GetProductVariantApiParams {
    authToken: string;
    productId: string;
}

export interface GetProductPricingApiParams {
    authToken: string;
    productId: string;
    variantId: string;
    currency: CurrencyEnum;
}

export interface GetPrintMethodApiParams {
    authToken: string;
    index: number;
}

export interface GetPrintMethodApiResponse {
    maxIndex: number;
    index: number;
    totalRecords: number;
    data: IPrintMethodList[];
}

export interface GetPrintMethodVariantApiParams {
    authToken: string;
    printCustomId: string;
}

export interface getPrintMethodPricingApiParams extends GetPrintMethodVariantApiParams {
    variantId: string;
    currency: CurrencyEnum;
}

export type GetShippingCostParams = {
    authToken: string;
    country: number;
    vendor: string;
    weight: number;
    currency: CurrencyEnum;
}

export type GetShippingCostResponse = Omit<GetShippingCostParams, 'authToken'> & {
    price: number;
}

export abstract class IProductsGateway extends Gateway {
    abstract uploadProductsFiles(params: UploadProductsFilesApiParams): GatewayResponse<string | File | null>;

    abstract getProducts(params: GetProductsApiParams): GatewayResponse<GetProductsApiResponse>;

    abstract getProductVariant(params: GetProductVariantApiParams): GatewayResponse<IProductVariant[]>;

    abstract getProductPricing(params: GetProductPricingApiParams): GatewayResponse<IProductPricing[]>;

    abstract getPrintMethod(params: GetPrintMethodApiParams): GatewayResponse<GetPrintMethodApiResponse>

    abstract getPrintMethodVariant(params: GetPrintMethodVariantApiParams): GatewayResponse<IPrintMethodVariant[]>

    abstract getPrintMethodPricing(params: getPrintMethodPricingApiParams): GatewayResponse<IProductPricing[]>

    abstract getShippingCost(params: GetShippingCostParams): GatewayResponse<GetShippingCostResponse>;
}
