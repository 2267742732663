import React, { FC, useEffect } from 'react';

import styled from 'styled-components';

import theme from 'styles/theme';

import { TextVariant } from './variants/TextVariant';
import LoadingIndicator from './LoadingIndicator';
import Text from './Text';

type ModalProps = {
    icon?: React.ReactNode;
    children: React.ReactNode;
    isModalOpen: boolean;

    onClosed: () => void;
    title?: string;
    onClick?: () => void;

    loading?: boolean;
    loadingText?: string;
}

const Modal: FC<ModalProps> = ({
    icon,
    children,
    isModalOpen,
    onClosed,
    title,
    onClick,
    loading,
    loadingText,
}) => {
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isModalOpen]);

    return (
        <ModalDiv isOpen={isModalOpen} onClick={onClick}>
            <ModalContainer onClick={onClosed}>
                <ModalWrapper onClick={handleClick}>
                    <ModalOverlay loading={loading}>
                        <LoadingIndicator />
                        <Text>{loadingText}</Text>
                    </ModalOverlay>
                    {icon && <ModalIcon>{icon}</ModalIcon>}
                    <ModalTitle variant={TextVariant.h2} center={!!icon}>
                        {title}
                    </ModalTitle>
                    <ModalBody>
                        {children}
                    </ModalBody>
                </ModalWrapper>
            </ModalContainer>
        </ModalDiv>
    );
};

const ModalDiv = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== 'isOpen',
}) <{ isOpen: boolean }>`
    /* width: 100%; */
    display: ${props => (props.isOpen ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
`;

const ModalContainer = styled.div`
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    justify-content: center;
    align-items: center;
    background-color: rgba(27, 27, 27, 0.7);
    backdrop-filter: blur(2px);
`;

const ModalOverlay = styled.div<{ loading?: boolean }>`
    display: ${props => (props.loading ? 'flex' : 'none')};
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 27, 27, 0.8);

    > p {
        color: white;
        font-family: ${theme.fonts.primary};
        font-size: 1.25rem;
        font-weight: 700;
    }
`;

const ModalWrapper = styled.div`
    max-height: 700px;
    max-width: 1000px;
    padding: 40px;
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #eee;
    overflow: auto;

    &::-webkit-scrollbar {
        margin-block: 10px;
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        // background-color: #42564E33;
        border-radius: 5.5px;
    }

    &::-webkit-scrollbar-thumb {
        // background: #295543;
        border-radius: 5.5px;
    }
`;

const ModalIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
`;

const ModalTitle = styled(Text).withConfig({
    shouldForwardProp: (prop) => prop !== 'center',
}) <{center?: boolean}>`
    color: ${theme.fontColor.primary};
    word-wrap: break-word;

    ${props => props.center && 'text-align: center;'}
`;

const ModalBody = styled.div`
    margin-top: 20px;
`;

export default Modal;
