import { fork } from 'redux-saga/effects';

import UserManagementGateway from 'api/UserManagement';
import { RootSagaReturnType } from 'sagas/types';

import watchCreateUserManagement from './createUserManagement';
import watchDeleteUserManagement from './deleteUserManagement';
import watchEditUserManagement from './editUserManagemant';
import watchUserManagement from './getUserManagement';
import watchGetUserManagementDetails from './getUserManagementDetails';

export default (api: UserManagementGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchUserManagement, api);
        yield fork(watchGetUserManagementDetails, api);
        yield fork(watchEditUserManagement, api);
        yield fork(watchDeleteUserManagement, api);
        yield fork(watchCreateUserManagement, api);
    }

    return {
        rootSaga,
    };
};
