import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import EnquiriesGateway from 'api/Enquiries';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { ResolveEnquiryPayload } from 'redux/slices/enquiries/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchDeleteEnquiry(api: EnquiriesGateway): SagaWatcherReturnType {
    yield takeEvery('enquiries/enquiriesDeleteEnquiryAttempt', handleDeleteEnquiry, api);
}
function* handleDeleteEnquiry(api: EnquiriesGateway, data: ResolveEnquiryPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.deleteEnquiry], {
        authToken,
        id: data.payload.id,
        type: data.payload.type,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.enquiriesDeleteEnquiryFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('Enquiry deleted');

        yield put(Actions.enquiriesGetEnquiriesAttempt());
        yield put(Actions.enquiriesDeleteEnquirySuccess());
    }
}
