import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IAddress } from 'entities/address';
import { IPrintMethodParams, TaxRateEnum } from 'entities/products';
import { IQuotation, IQuotationList, QuotationShippingMethodEnum, QuotationStatusEnum } from 'entities/quotations';

export interface GetQuotationsApiParams {
    authToken: string;
    currentPage: number;

    searchQuery?: string;
    dateStart?: string;
    dateEnd?: string;
    quoteStatus?: QuotationStatusEnum;
}

export interface GetQuotationsApiResponse {
    maxIndex: number;
    index: number;
    totalRecords: number;
    data: IQuotationList[];
}

export interface GetQuotationDetailsApiParams {
    authToken: string;
    id: string;
}

export interface CreateQuotationApiParams {
    authToken: string;
    companyAccountId: string;
    quotationDate: string;
    clientId: string;
    shippingMethod: QuotationShippingMethodEnum;
    shippingAddress: IAddress;
    note?: string;
    remark?: string;
    products: {
        productId: string;
        variantId: string;
        productName: string;
        productVariant: {
            fit?: string;
            type?: string;
            sleeve?: string;
            style?: string;
            color?: string;
        },
        quantities: {
            productPriceId: string;
            size?: string;
            quantity: number;
            pricePerUnit: number;
        }[],
        printMethods: IPrintMethodParams[],
        finalQuantity: number;
        totalPricePerUnit: number;
        subtotal: number;
        discountPercent?: number;
        discountFixed?: number;
        finalProductPrice: number;
        // weight: number; // todo: might need this in future
        remark?: string;
    }[],
    customLineProducts: {
        productName: string;
        finalQuantity: number;
        finalProductPrice: number;
        totalPricePerUnit: number;
        subtotal: number;
        discountPercent?: number;
        discountFixed?: number;
        weight?: number;
        remark?: string;
    }[];
    subtotal: number;
    discountPercent?: number;
    discountFixed?: number;
    shippingFee: number;
    tax: number;
    finalPrice: number;
    totalWeight: number;
    taxType: TaxRateEnum;
}

export interface UpdateQuotationApiParams extends CreateQuotationApiParams {
    id: string;
    quotationStatus: QuotationStatusEnum;
}

export interface DeleteQuotationApiParams {
    authToken: string;
    id: string;
}

export abstract class IQuotationsGateway extends Gateway {
    abstract getQuotations(params: GetQuotationsApiParams): GatewayResponse<GetQuotationsApiResponse>;

    abstract getQuotationDetails(params: GetQuotationDetailsApiParams): GatewayResponse<IQuotation>;

    abstract createQuotation(params: CreateQuotationApiParams): GatewayResponse<string>;

    abstract updateQuotation(params: UpdateQuotationApiParams): GatewayResponse<void>;

    abstract deleteQuotation(params: DeleteQuotationApiParams): GatewayResponse<void>;

    abstract exportQuotations(params: GetQuotationDetailsApiParams): GatewayResponse<Blob>;

    abstract convertQuotationsToOrders(params: GetQuotationDetailsApiParams): GatewayResponse<void>;
}
