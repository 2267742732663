import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import PageHeader from 'components/atoms/PageHeader';
import Paginator from 'components/atoms/Paginator';
import Table, { IActionsArray, IDataArray, IHeaderArray } from 'components/atoms/Table';
import AdminLayout from 'components/Layout/AdminLayout';
import TableFilters from 'components/molecules/TableFilters';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import EnquiresSelectors from 'redux/slices/enquiries/Selectors';

import NavActions from 'lib/NavActions';
import { AuthRoleEnum } from 'entities/auth';
import { EnquiryStatusEnum, EnquiryTypeEnum } from 'entities/enquiries';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import ConfirmResolveModal from './components/ConfirmResolveModal';
import EnquirySubjectAndMessage from './components/EnquirySubjectAndMessage';

const tableAvailableStatus = [{
    label: 'All statuses',
    value: 0,
}, {
    label: 'Pending',
    value: EnquiryStatusEnum.Pending,
}, {
    label: 'Resolved',
    value: EnquiryStatusEnum.Resolved,
}];

const tableAvailableTypes = [{
    label: 'All types',
    value: 0,
}, {
    label: 'Home Page',
    value: EnquiryTypeEnum.HomePage,
}, {
    label: 'Design Services',
    value: EnquiryTypeEnum.DesignServices,
}];

const EnquiriesScreen = (): JSX.Element => {
    const userInfo = useAppSelector(AuthSelectors.getUserInfo);

    const loading = useAppSelector(EnquiresSelectors.getEnquiriesAttempting);
    const error = useAppSelector(EnquiresSelectors.getEnquiriesError);
    const { maxIndex, data: enquiries } = useAppSelector(EnquiresSelectors.getEnquiries);

    const [idToDeleteOrResolve, setIdToDeleteOrResolve] = useState<string | undefined>(undefined);
    const [typeToDeleteOrResolve, setTypeToDeleteOrResolve] = useState<EnquiryTypeEnum | undefined>(undefined);
    const [subjectToDeleteOrResolve, setSubjectToDeleteOrResolve] = useState<string | undefined>(undefined);

    const {
        currentPage, searchQuery, dateStart, dateEnd, enquiryStatus, enquiryType,
    } = useAppSelector(EnquiresSelectors.getEnquiriesFilters);

    const dispatch = useAppDispatch();

    const getEnquiries = () => {
        dispatch(Actions.enquiriesGetEnquiriesAttempt());
    };

    useEffect(() => {
        dispatch(Actions.enquiriesResetEnquiriesFilters());
    }, []);

    useEffect(() => {
        if (currentPage <= maxIndex && dateStart && dateEnd) {
            getEnquiries();
        }

        return () => {
            dispatch(Actions.enquiriesResetState());
        };
    }, [currentPage, enquiryStatus, enquiryType, dateStart, dateEnd]);

    const onPaginatorPressed = (page: number) => {
        dispatch(Actions.enquiriesSetFilters({ currentPage: page }));
    };

    const onStatusFiltered = (option: number) => {
        dispatch(Actions.enquiriesSetFilters({
            enquiryStatus: option,
        }));
    };

    const onTypeFiltered = (option: number) => {
        dispatch(Actions.enquiriesSetFilters({
            enquiryType: option,
        }));
    };

    const onDateFilterSelected = (selectedStartDate: Date, selectedEndDate: Date) => {
        dispatch(Actions.enquiriesSetFilters({
            dateStart: dayjs(selectedStartDate).startOf('day').toISOString(),
            dateEnd: selectedEndDate ? dayjs(selectedEndDate).endOf('day').toISOString() : undefined,
        }));
    };

    const onSearchQueryChanged = (val: string) => {
        dispatch(Actions.enquiriesSetFilters({
            searchQuery: val,
        }));
    };

    const tableHeaders: IHeaderArray[] = [
        { header: 'id', field: 'id', hidden: true },
        { header: 'Date & Time', field: 'createdAt', width: 150 },
        { header: 'Type', field: 'type', width: 150 },
        { header: 'Name', field: 'name' },
        { header: 'Message', field: 'message', width: 360 },
        { header: 'Status', field: 'status' },
        { header: 'Actions', field: 'action', width: 120 },
    ];

    const tableData: IDataArray[] = enquiries.map((item) => ({
        id: item.id,
        onClick: () => NavActions.navToEnquiryDetails(item.id, item.type),
        cellData: {
            createdAt: dayjs(item.createdAt).format('DD/MM/YYYY HH:mm'),
            type: item.type === EnquiryTypeEnum.HomePage ? 'Home Page' : 'Design Services',
            name: item.name,
            message: <EnquirySubjectAndMessage subject={item.subject} message={item.message} />,
            status: EnquiryStatusEnum[item.status],
        },
    }));

    const tableActions: IActionsArray[] = [
        {
            id: 'resolve',
            icon: <CheckIcon />,
            shouldHide: (item: IDataArray) => {
                if (item.cellData.status === EnquiryStatusEnum[EnquiryStatusEnum.Resolved]) return true;
                return false;
            },
            onClick: (enquiryId: string) => {
                dispatch(Actions.enquiriesSetResolveConfirmationModalIsOpen(true));

                const selectedEnquiry = enquiries.find((item) => item.id === enquiryId);
                if (selectedEnquiry) {
                    setIdToDeleteOrResolve(enquiryId);
                    setTypeToDeleteOrResolve(selectedEnquiry.type);
                    setSubjectToDeleteOrResolve(selectedEnquiry.subject);
                }
            },
        },
        {
            id: 'delete',
            icon: <DeleteIcon />,
            onClick: (enquiryId: string) => {
                dispatch(Actions.enquiriesSetDeleteConfirmationModalIsOpen(true));

                const selectedEnquiry = enquiries.find((item) => item.id === enquiryId);
                if (selectedEnquiry) {
                    setIdToDeleteOrResolve(enquiryId);
                    setTypeToDeleteOrResolve(selectedEnquiry.type);
                    setSubjectToDeleteOrResolve(selectedEnquiry.subject);
                }
            },
        }];

    return (
        <AdminLayout>
            <PageHeader>
                Website Enquiries
            </PageHeader>

            <TableFilters
                statusFilterEnabled
                availableStatuses={tableAvailableStatus}
                onStatusSelected={onStatusFiltered}
                typeFilterEnabled
                availableTypes={tableAvailableTypes}
                onTypeSelected={onTypeFiltered}
                typeFilterPlaceholder='Filter by enquiry type'
                dateFilterEnabled
                selectedFromDate={dateStart ? dayjs(dateStart).toDate() : undefined}
                selectedToDate={dateEnd ? dayjs(dateEnd).toDate() : undefined}
                onDatesSelected={onDateFilterSelected}
                searchFilterEnabled
                searchValue={searchQuery}
                onSearchChanged={onSearchQueryChanged}
                onSearch={getEnquiries}
            />

            <Table
                headerArrays={tableHeaders}
                dataArray={tableData}
                actions={tableActions}
                style={{ marginTop: 32, marginBottom: 32 }}
                loading={loading}
                error={error}
                disableActions={userInfo?.role === AuthRoleEnum.Executive}
            />

            <PaginationContainer>
                <Paginator
                    currentPage={currentPage}
                    onPagePressed={onPaginatorPressed}
                    maxPages={maxIndex}
                    disabled={loading}
                />
            </PaginationContainer>

            <ConfirmResolveModal
                enquiryId={idToDeleteOrResolve}
                enquiryType={typeToDeleteOrResolve}
                enquirySubject={subjectToDeleteOrResolve}
            />
            <ConfirmDeleteModal
                enquiryId={idToDeleteOrResolve}
                enquiryType={typeToDeleteOrResolve}
                enquirySubject={subjectToDeleteOrResolve}
            />
        </AdminLayout>
    );
};

const PaginationContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

export default EnquiriesScreen;
