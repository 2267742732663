import React, { FC } from 'react';

import SVG from 'react-inlinesvg';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';

import { useAppSelector } from 'redux/Hooks';

import NavActions from 'lib/NavActions';
import { TSideMenu, TSubMenu } from 'entities/components';
import { SideMenuLOV } from 'lov/SideMenuLOV';

import icons from 'assets/icons';

const SideMenu: FC = () => {
    const location = useLocation();
    const { userInfo } = useAppSelector((state) => state.auth);
    const userRolePermission = userInfo?.rolePermission || [];

    const renderSubMenu = (subMenu: TSubMenu[]) => {
        if (userRolePermission) {
            return subMenu.map((item: TSubMenu) => {
                if (userRolePermission.includes(item.permission)) {
                    return (
                        <Button
                            variant={ButtonVariant.menu}
                            key={item.path}
                            label={item.title}
                            icon={item.icon}
                            active={location.pathname === item.path || location.pathname.split('/')[1] === item.path.split('/')[1]}
                            onClick={() => NavActions.navToMenu(item.path)}
                        />
                    );
                }

                return null;
            });
        }

        return null;
    };

    function hasAtLeastOnePermission(menuPermission: number[], userPermission: number[]): boolean {
        // Use Set intersection to efficiently check for overlapping elements
        const newUserPermission = userPermission.filter(permission => permission !== 11);
        return menuPermission.some(element => newUserPermission.includes(element));
    }

    const renderMenu = () => {
        return SideMenuLOV.map((menu: TSideMenu) => {
            if (userRolePermission) {
                if (hasAtLeastOnePermission(menu.permissions, userRolePermission)) {
                    return (
                        <MenuContainer key={menu.title}>
                            <TitleContainer>
                                {menu.title}
                            </TitleContainer>
                            {renderSubMenu(menu.submenu)}
                        </MenuContainer>
                    );
                }
                return null;
            }
            return null;
        });
    };

    return (
        <SideMenuContainer>
            <LogoContainer src={icons.acaSaltycustomsLogo} height={40} width={260} />

            {renderMenu()}
        </SideMenuContainer>
    );
};

const SideMenuContainer = styled.div`
    width: 270px;
    height: 100vh;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    gap: 1.5rem;
    padding-inline: 1.5rem;
    padding-top: 7rem;
    padding-bottom: 2rem;

    position: fixed;
    left: 0;
    top: 0;

    background-color: white;
    overflow-y: scroll;

    // hide scrollbar
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /* z-index: 10; */
`;

const LogoContainer = styled(SVG)`
    position: absolute;
    top: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
`;

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const TitleContainer = styled(Text)`
    font-size: ${props => props.theme.fontSize.xs};
    line-height: ${props => props.theme.lineHeight.xs};
    font-weight: ${props => props.theme.fontWeight.default};

    padding-left: 1rem;
    margin-bottom: 1rem;

    @media (min-width: 1280px) {
        font-size: ${props => props.theme.fontSize.sm};
        line-height: ${props => props.theme.lineHeight.sm};
    }

    @media (min-width: 1520px) {
        font-size: ${props => props.theme.fontSize.sm};
        line-height: ${props => props.theme.lineHeight.sm};
    }
`;

export default SideMenu;
