import { fork } from 'redux-saga/effects';

import api from 'api';

import auth from './auth';
import company from './company';
import customers from './customers';
import enquiries from './enquiries';
import home from './home';
import order from './order';
import products from './products';
import purchaseOrders from './purchaseOrders';
import quotations from './quotations';
import report from './report';
import suppliers from './suppliers';
import { SagaForkReturnType } from './types';
import ui from './ui';
import userManagement from './userManagement';

const apiInstance = api;

export default function* root(): SagaForkReturnType {
    yield fork(auth(apiInstance.auth).rootSaga);
    yield fork(home(apiInstance.home).rootSaga);
    yield fork(order(apiInstance.order).rootSaga);
    yield fork(quotations(apiInstance.quotations).rootSaga);
    yield fork(suppliers(apiInstance.suppliers).rootSaga);
    yield fork(customers(apiInstance.customers).rootSaga);
    yield fork(enquiries(apiInstance.enquiries).rootSaga);
    yield fork(purchaseOrders(apiInstance.purchaseOrders).rootSaga);
    yield fork(ui(apiInstance.customers, apiInstance.suppliers).rootSaga);
    yield fork(userManagement(apiInstance.userManagement).rootSaga);
    yield fork(products(apiInstance.products).rootSaga);
    yield fork(company(apiInstance.company).rootSaga);
    yield fork(report(apiInstance.report).rootSaga);
}
