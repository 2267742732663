import React, { useEffect } from 'react';

import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';

import EditAddressModal from 'components/atoms/EditAddressModal';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import OrderSelectors from 'redux/slices/order/Selectors';

import { IAddress, ICustomerAddress } from 'entities/address';
import { AuthRoleEnum } from 'entities/auth';
import { IOrder } from 'entities/order';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eyeOpen.svg';

import theme from 'styles/theme';

interface ComponentProps {
    orderDetails?: Partial<IOrder> | null;
    onViewQuotation?: () => void;
    onViewCustomer?: () => void;
    onEditShippingAddress?: (shippingAddress: IAddress) => void;
    onEditBillingAddress?: (billingAddress: IAddress) => void;
    loading?: boolean;
    error?: string;
    updateAddressLoading?: boolean;
    fitWidth?: boolean;
}

const OrderDetails: React.FC<ComponentProps> = (props: ComponentProps) => {
    const {
        orderDetails,
        onViewQuotation,
        onViewCustomer,
        onEditShippingAddress,
        onEditBillingAddress,
        loading,
        error,
        updateAddressLoading,
        fitWidth,
    } = props;

    const dispatch = useAppDispatch();

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);

    const updateShippingAddressModalIsOpen = useAppSelector(OrderSelectors.updateShippingAddressModalIsOpen);
    const updateBillingAddressModalIsOpen = useAppSelector(OrderSelectors.updateBillingAddressModalIsOpen);

    const onShippingAddressChanged = (address: ICustomerAddress) => {
        if (!onEditShippingAddress) return;
        delete address.addressName;
        delete address.default;
        onEditShippingAddress(address as IAddress);
    };

    const onBillingAddressChanged = (address: ICustomerAddress) => {
        if (!onEditBillingAddress) return;
        delete address.addressName;
        delete address.default;
        onEditBillingAddress(address as IAddress);
    };

    const renderEntities = () => {
        if (!orderDetails?.companyName) return null;
        return (
            <div>
                <Text variant={TextVariant.h3}>Saltycustoms Entities</Text>
                <Text>{orderDetails?.companyName}</Text>
            </div>
        );
    };

    const renderQuickbookNo = () => {
        if (!orderDetails?.quickbooksInvoiceId) return null;
        return (
            <div>
                <Text variant={TextVariant.h3}>Quickbook No</Text>
                <Text>{orderDetails?.quickbooksInvoiceId}</Text>
            </div>
        );
    };

    const renderQuotationNo = () => {
        if (!orderDetails?.quotationNumber) return null;
        return (
            <div>
                <div>
                    <Text variant={TextVariant.h3}>
                        Quotation
                        {' '}
                        <EyeIcon
                            color={theme.colors.info}
                            style={{ cursor: 'pointer' }}
                            onClick={onViewQuotation}
                        />
                    </Text>
                </div>
                <Text>{orderDetails?.quotationNumber}</Text>
            </div>
        );
    };

    const renderCustomerDetails = () => {
        return (
            <div>
                <div>
                    <Text variant={TextVariant.h3}>
                        Customer
                        {' '}
                        <EyeIcon
                            color={theme.colors.info}
                            style={{ cursor: 'pointer' }}
                            onClick={onViewCustomer}
                        />
                    </Text>
                </div>
                <Text>{orderDetails?.clientName}</Text>
                <Text>{orderDetails?.clientEmail}</Text>
                <Text>{orderDetails?.clientNumber}</Text>
            </div>
        );
    };

    const renderShippingAddress = () => {
        return (
            <div>
                <Text variant={TextVariant.h3}>
                    Shipping Address
                    {' '}
                    {userInfo?.role === AuthRoleEnum.Supplier ? null : (
                        <EditIcon
                            color={theme.colors.info}
                            style={{ cursor: 'pointer' }}
                            onClick={() => dispatch(Actions.orderSetUpdateShippingAddressModalIsOpen(true))}
                        />
                    )}
                </Text>
                <Text>{orderDetails?.shippingAddress?.street1}</Text>
                {orderDetails?.shippingAddress?.street2 && <Text>{orderDetails?.shippingAddress?.street2}</Text>}
                <Text>{`${orderDetails?.shippingAddress?.city}, ${orderDetails?.shippingAddress?.postcode}`}</Text>
                <Text>{`${orderDetails?.shippingAddress?.state}, ${orderDetails?.shippingAddress?.country}`}</Text>
            </div>
        );
    };

    const renderBillingAddress = () => {
        return (
            <div>
                <Text variant={TextVariant.h3}>
                    Billing Address
                    {' '}
                    {userInfo?.role === AuthRoleEnum.Supplier ? null : (
                        <EditIcon
                            color={theme.colors.info}
                            style={{ cursor: 'pointer' }}
                            onClick={() => dispatch(Actions.orderSetUpdateBillingAddressModalIsOpen(true))}
                        />
                    )}
                </Text>
                <Text>{orderDetails?.billingAddress?.street1}</Text>
                {orderDetails?.billingAddress?.street2 && <Text>{orderDetails?.billingAddress?.street2}</Text>}
                <Text>{`${orderDetails?.billingAddress?.city}, ${orderDetails?.billingAddress?.postcode}`}</Text>
                <Text>{`${orderDetails?.billingAddress?.state}, ${orderDetails?.billingAddress?.country}`}</Text>
            </div>
        );
    };

    if (loading) {
        return (
            <FlexContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
                <LoadingIndicator />
            </FlexContainer>
        );
    }
    if (error) {
        return (
            <FlexContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text variant={TextVariant.error}>{error}</Text>
            </FlexContainer>
        );
    }
    return (
        <>
            <FlexContainer fitWidth={fitWidth}>
                <Text variant={TextVariant.h2}>Order Details</Text>
                {renderEntities()}
                {renderQuickbookNo()}
                {renderQuotationNo()}
                {renderCustomerDetails()}
                {renderShippingAddress()}
                {renderBillingAddress()}
            </FlexContainer>
            <EditAddressModal
                isOpen={updateShippingAddressModalIsOpen}
                onCancel={() => dispatch(Actions.orderSetUpdateShippingAddressModalIsOpen(false))}
                onSave={onShippingAddressChanged}
                address={orderDetails?.shippingAddress}
                loading={updateAddressLoading}
                noAddressName
            />
            <EditAddressModal
                isOpen={updateBillingAddressModalIsOpen}
                onCancel={() => dispatch(Actions.orderSetUpdateBillingAddressModalIsOpen(false))}
                onSave={onBillingAddressChanged}
                address={orderDetails?.billingAddress}
                loading={updateAddressLoading}
                noAddressName
            />
        </>

    );
};

const FlexContainer = styled.div<{fitWidth?: boolean}>`
    width: ${props => (props.fitWidth ? 'fit-content' : '100%')};
    display: flex;
    flex-direction: column;
    gap: 2rem;

    background-color: white;
    border-radius: 15px;
    padding: 2rem;
    padding-right: ${props => (props.fitWidth ? '3rem' : '2rem')};
    min-height: 688px;

    > div {
        display: flex;
        flex-direction: column;
    }

    > div > div {
        display: flex;
        gap: 0.5rem;
    }

`;

export default OrderDetails;
