import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PurchaseOrderGateway from 'api/PurchaseOrders';
import { UpdatePoApiParams } from 'api/PurchaseOrdersBase';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import ProductSelectors from 'redux/slices/products/Selectors';
import PoSelectors from 'redux/slices/purchaseOrder/Selectors';
import { UpdatePoEmailVariablesPayload } from 'redux/slices/purchaseOrder/types';
import { SagaWatcherReturnType } from 'sagas/types';

import { IAddress } from 'entities/address';
import { IEmailVariables, PurchaseOrderStatusEnum } from 'entities/purchaseOrders';

export default function* watchUpdateEmailVariables(api: PurchaseOrderGateway): SagaWatcherReturnType {
    yield takeEvery('po/poUpdateEmailVariablesAttempt', handleUpdateEmailVariables, api);
}

function* handleUpdateEmailVariables(api: PurchaseOrderGateway, data: UpdatePoEmailVariablesPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);
    const poDetails = yield* select(PoSelectors.getPurchaseOrderDetails);

    const selectedProducts = yield* select(ProductSelectors.getSelectedProducts);
    const discountPercent = yield* select(ProductSelectors.getDiscountPercent);
    const discountFixed = yield* select(ProductSelectors.getDiscountFixed);
    const shippingPrice = yield* select(ProductSelectors.getShippingPrice);
    const taxPrice = yield* select(ProductSelectors.getTaxPrice);
    // const totalWeight = yield* select(ProductSelectors.getTotalWeight);

    const calculateSubTotal = () => {
        let subTotal = 0;
        selectedProducts.forEach((product) => {
            subTotal += product.total;
        });
        return subTotal;
    };

    const newProducts = selectedProducts.filter((product) => product.productId)
        .map((product) => ({
            productId: product.productId as string,
            variantId: product.variantId as string,
            productName: product.name,
            productVariant: {
                fit: product.variant?.fit,
                type: product.variant?.type,
                sleeve: product.variant?.sleeve,
                style: product.variant?.style,
                color: product.variant?.color,
            },
            quantities: product.quantities ? product.quantities?.map((quantities) => ({
                quantity: quantities.quantity as number,
                productPriceId: quantities.id,
                size: quantities.size ? quantities.size : undefined,
                pricePerUnit: Number(quantities.pricePerUnit),
            })) : [],
            printMethods: product.printMethods ? product.printMethods.map((printMethod) => ({
                side: printMethod.side,
                printCustomId: printMethod.printCustomId,
                printVariantId: printMethod.printVariantId,
                pricePerUnit: Number(printMethod.pricePerUnit),
                printMethod: printMethod.printMethod,
                block: printMethod.block,
                colorCount: printMethod.colorCount,
            })) : [],
            finalQuantity: product.totalQuantity,
            // discountPercent: product.discountPercent ?? undefined,
            // discountFixed: product.discountFixed ?? undefined,
            totalPricePerUnit: Number(product.price),
            subtotal: product.subtotal ?? 0,
            finalProductPrice: product.total,
            remark: product.remark,
            // weight: product.weight ?? 0, // todo: might need this in the future
        }));

    const customLineProducts = selectedProducts.filter((product) => !product.productId)
        .map((product) => ({
            productName: product.name,
            finalQuantity: product.totalQuantity,
            finalProductPrice: Number(product.total),
            totalPricePerUnit: Number(product.price),
            subtotal: product.subtotal ?? 0,
            // discountPercent: product.discountPercent ?? undefined,
            // discountFixed: product.discountFixed ?? undefined,
            weight: product.weight ?? 0,
            remark: product.remark,
        }));

    const newPurchaseOrder: Omit<UpdatePoApiParams, 'authToken'> = {
        id: poDetails?.id as string,
        status: poDetails?.status as PurchaseOrderStatusEnum,
        orderId: poDetails?.orderId as string,
        orderNo: poDetails?.orderNumber as string,
        poDate: poDetails?.poDate ? dayjs(poDetails?.poDate).toISOString() : dayjs().toISOString(),
        companyAccountId: poDetails?.companyAccountId as string,
        supplierId: poDetails?.supplierId as string,
        supplierName: poDetails?.supplierName as string,
        supplierAddress: poDetails?.supplierAddress as IAddress,
        deliveryAddress: poDetails?.deliveryAddress as IAddress,
        deliveryCompanyName: poDetails?.deliveryCompanyName as string,
        note: poDetails?.note as string,
        remark: poDetails?.remark as string,
        products: newProducts,
        customLineProducts,
        discountPercent: discountPercent ? Number(discountPercent) : undefined,
        discountFixed: discountFixed ? Number(discountFixed) : undefined,
        shippingFee: Number(shippingPrice),
        subtotal: calculateSubTotal(),
        tax: taxPrice ? Number(taxPrice) : 0,
        finalPrice: calculateSubTotal(),
        // totalWeight,
    };

    let newAttachmentFolder;

    if (data.payload.attachmentFolder === '') {
        newAttachmentFolder = undefined;
    } else {
        newAttachmentFolder = data.payload.attachmentFolder;
    }
    const emailVariables: IEmailVariables = {
        frontPrintSize: data.payload.frontPrintSize,
        backPrintSize: data.payload.backPrintSize,
        brand: data.payload.brand,
        color: data.payload.color,
        productCode: data.payload.productCode,
        attachmentFolder: newAttachmentFolder,
    };

    const response = yield* call([api, api.editPurchaseOrder], {
        authToken,
        ...newPurchaseOrder,
        emailVariables,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.poUpdateEmailVariablesFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.poGetPurchaseOrderDetailsAttempt({ id: poDetails?.id as string }));
        yield put(Actions.poUpdateEmailVariablesSuccess());
        toast.success('Email variables updated successfully.');
    }
}
