import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PurchaseOrderGateway from 'api/PurchaseOrders';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchViewPurchaseOrderEmail(api: PurchaseOrderGateway): SagaWatcherReturnType {
    yield takeEvery('po/poViewPurchaseOrderEmailAttempt', handleViewPurchaseOrderEmail, api);
}

function* handleViewPurchaseOrderEmail(api: PurchaseOrderGateway, data: PayloadAction<{ id: string }>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.viewPurchaseOrderEmail], {
        authToken,
        id: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.poViewPurchaseOrderEmailFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }

    if (response.status === GatewayResponseStatus.Success) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/html' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
        document.body.appendChild(link);
        link.click();
        yield put(Actions.poViewPurchaseOrderEmailSuccess());
    }
}
