import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import OrderSelectors from 'redux/slices/order/Selectors';
import { ExportDeliveryOrderPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchExportDeliveryOrder(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderExportDeliveryOrderAttempt', handleExportDeliveryOrder, api);
}
function* handleExportDeliveryOrder(api: OrderGateway, data: ExportDeliveryOrderPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);
    const orderDetails = yield* select(OrderSelectors.getOrderDetailsData);

    const response = yield* call([api, api.exportDeliveryOrder], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderExportDeliveryOrderFailure(response.message || ''));
        toast.error(response.error.errorMessage ?? response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Delivery Order-${orderDetails?.orderNo}.pdf`);
        document.body.appendChild(link);
        link.click();
        yield put(Actions.orderExportDeliveryOrderSuccess());
        toast.success('Delivery order downloaded successfully');
    }
}
