import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import { IOrderComment } from 'entities/order';

import theme from 'styles/theme';

type ComponentProps = {
    isOpen: boolean;
    selectedComment: IOrderComment;
    onCloseModal: () => void;
    onConfirm: (id: string, text: string) => void;
    loading?: boolean;
};

const EditCommentModal: React.FC<ComponentProps> = (props: ComponentProps) => {
    const {
        isOpen,
        selectedComment,
        onCloseModal,
        onConfirm,
        loading,
    } = props;

    const [comments, setComments] = useState<string>('');
    const [isCommentBlank, setIsCommentBlank] = useState(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (selectedComment) {
            setComments(selectedComment.text);
        }

        return () => {
            setComments('');
            setError('');
            setIsCommentBlank(false);
        };
    }, [isOpen]);

    const onCommentChange = (value: string) => {
        setComments(value);
        setIsCommentBlank(false);
    };

    const handleOnConfirm = (id: string, text: string) => {
        if (!comments) {
            setError('Comment cannot be empty');
            setIsCommentBlank(true);
            return;
        }

        onConfirm(id, text);
    };

    return (
        <Modal
            isModalOpen={isOpen}
            title='Edit Comment'
            onClosed={onCloseModal}
        >
            <Container>
                <ModalText>Edit Comment:</ModalText>
                <StyledInput
                    onChange={(e) => onCommentChange(e.target.value)}
                    value={comments}
                    error={isCommentBlank}
                />

                {error && <ErrorText variant={TextVariant.error}>{error}</ErrorText>}
            </Container>

            <ButtonRow>
                <Button label='Cancel' onClick={onCloseModal} variant={ButtonVariant.primary} disabled={loading} />
                <Button
                    label='Edit'
                    onClick={() => handleOnConfirm(selectedComment._id, comments)}
                    loading={loading}
                />
            </ButtonRow>
        </Modal>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 400px;
`;

const ModalText = styled(Text)`
    font-size: 16px;
    color: ${theme.fontColor.primary};
    text-align: left;
`;

const StyledInput = styled(Input)`
    margin-top: 12px;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 12px;

    margin-top: 20px;
`;

const ErrorText = styled(Text)`
    margin-top: 12px;
`;

export default EditCommentModal;
