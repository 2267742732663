import React, { useEffect, useState } from 'react';

import SVG from 'react-inlinesvg';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Footer from 'components/atoms/Footer';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import Header from 'components/molecules/Header';
import ProductViewer from 'components/molecules/ProductViewer';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import OrderSelectors from 'redux/slices/order/Selectors';

import Utils from 'lib/Utils';
import { IProductDetails } from 'entities/products';
import { CurrencyEnum } from 'entities/supplier';

import icons from 'assets/icons';

import theme from 'styles/theme';

import CustomerDetailsRow, { ICustomerDetails } from './components/CustomerDetailsRow';

const PaymentConfirmed = (): JSX.Element => {
    const params = useParams();
    const ordersId = params.id;

    const {
        formatVariantLabel,
        formatSizeLabel,
        formatPrintMethodLabel,
    } = Utils.Formatter;

    const dispatch = useAppDispatch();

    const orderDetails = useAppSelector(OrderSelectors.getOrderDetailsData);

    const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false);
    const [taxPercent, setTaxPercent] = useState<number>(0);

    useEffect(() => {
        if (ordersId) {
            dispatch(Actions.orderGetOrderDetailsForPaymentAttempt({ id: ordersId }));
        }
    }, []);

    useEffect(() => {
        if (orderDetails?.isPaidWithStripePaymentGateway) {
            if (orderDetails.isPaidWithStripePaymentGateway === true) {
                setIsPaymentSuccess(true);
            } else {
                setIsPaymentSuccess(false);
            }
        }
    }, [orderDetails?.isPaidWithStripePaymentGateway]);

    useEffect(() => {
        const taxPrice = orderDetails?.tax ?? 0;
        const totalPrice = orderDetails?.finalPrice ?? 0;

        if (taxPrice === 0) {
            setTaxPercent(0);
            return;
        }

        if (totalPrice === 0) {
            setTaxPercent(0);
            return;
        }
        const priceBeforeTax = totalPrice - taxPrice;

        setTaxPercent((taxPrice / priceBeforeTax) * 100);
    }, [orderDetails?.tax]);

    const NewSelectedProducts = (): IProductDetails[] => {
        if (!orderDetails?.products) return [];
        const selectedProducts = orderDetails?.products.map(product => {
            return {
                productId: product.productId,
                variantId: product.variantId,
                name: product.productName ?? 'Product Name Here',
                variant: {
                    type: product.productVariant?.type,
                    fit: product.productVariant?.fit,
                    sleeve: product.productVariant?.sleeve,
                    style: product.productVariant?.style,
                    color: product.productVariant?.color,
                },
                variantText: formatVariantLabel(product.productVariant ? product.productVariant : null),
                sizeText: formatSizeLabel(product.quantities),
                printMethodText: product.printMethods.map((method) => {
                    return formatPrintMethodLabel({
                        side: method.side,
                        printMethod: method.printMethod ?? '',
                        block: method.block ?? '',
                        colorCount: method.colorCount ?? '',
                    });
                }),
                totalQuantity: product.finalQuantity,
                quantities: product.quantities.map(item => ({
                    id: item.productPriceId,
                    size: item.size,
                    minimumQuantity: item.quantity,
                    quantity: item.quantity,
                    pricePerUnit: item.pricePerUnit,
                })),
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                printMethods: product.printMethods,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                disableQuantityEdit: true,
            };
        });

        const customLineProducts = orderDetails?.customLineProducts.map(product => {
            return {
                productId: '',
                variantId: '',
                name: product.productName ?? 'Product Name Here',
                totalQuantity: product.finalQuantity,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                disableQuantityEdit: true,
            };
        });

        const combinedSelectedProduct = [...selectedProducts, ...customLineProducts];
        return combinedSelectedProduct;
    };

    const customerInfoRowtitle = [
        { header: 'Order Number', field: 0 },
        { header: 'Customer Name', field: 1 },
        { header: 'Customer Email', field: 2 },
        { header: 'Customer Phone', field: 3 },
    ];

    const customerDetails: ICustomerDetails = {
        orderId: orderDetails?.orderNo,
        name: orderDetails?.clientName,
        email: orderDetails?.clientEmail,
        phone: orderDetails?.clientNumber,
    };

    const productTableHeaders = [
        { header: 'Name' },
        { header: 'Quantity' },
        { header: 'Price Per Unit' },
        { header: 'Price After Discount' },
        { header: 'Discount Total' },
        { header: 'Total' },
    ];

    const renderPaymentStatus = () => {
        // if (confirmPaymentLoading) {
        //     return (
        //         <OrderPaymentContainer>
        //             <LoadingIndicator size={80} color={theme.colors.primary} />
        //         </OrderPaymentContainer>
        //     );
        // }
        if (isPaymentSuccess) {
            return (
                <OrderPaymentContainer>
                    <LogoContainer>
                        <CheckmarkLogo src={icons.paymentConfirmedIcon} color='white' />
                    </LogoContainer>
                    <Text variant={TextVariant.h2} style={{ letterSpacing: '0.025rem' }}>
                        Payment successful!
                    </Text>
                    <Text>Thank you! We will process your order shortly.</Text>
                </OrderPaymentContainer>
            );
        }
        return (
            <OrderPaymentContainer>
                <LogoContainer error>
                    <CheckmarkLogo src={icons.paymentProcessingIcon} />
                </LogoContainer>
                <Text variant={TextVariant.h2} style={{ letterSpacing: '0.025rem' }}>
                    Payment processing...
                </Text>
                <Text>Your payment is being processed. You will receive a confirmation email shortly.</Text>
            </OrderPaymentContainer>
        );
    };
    return (
        <main>
            <Header
                disableBreadcrumbAndUserProfile
            />
            <div>
                {renderPaymentStatus()}
                <ContentContainer>
                    <CustomerDetailsRow
                        title={customerInfoRowtitle}
                        capitalizedTitle
                        customerDetails={customerDetails}
                    />
                    <ProductViewer
                        tableHeaders={productTableHeaders}
                        currency={orderDetails?.currency ?? CurrencyEnum.MalaysianRinggit}
                        viewData={{
                            selectedProducts: NewSelectedProducts(),
                            discountPercent: orderDetails?.discountPercent?.toString(),
                            discountFixed: orderDetails?.discountFixed?.toFixed(2),
                            shippingCost: orderDetails?.shippingFee.toFixed(2) ?? '0.00',
                            taxPrice: orderDetails?.tax.toFixed(2) ?? '0.00',
                        }}
                        taxPercent={Number(taxPercent.toFixed(0))}
                    />
                    {/* {confirmPaymentError && (
                        <Button
                            label='Proceed to Payment'
                            variant={ButtonVariant.secondary}
                            style={{ marginTop: '1rem' }}
                            onClick={onPaymentClicked}
                        />
                    )} */}
                </ContentContainer>
            </div>
            <Footer />
        </main>
    );
};

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.5rem 15rem;
    // justify-content: center;
    align-items: center;

    min-height: calc(100vh - 202px);

    background-color: ${theme.colors.secondary};
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center
`;

const OrderPaymentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    gap: 1rem;

    background-color: ${theme.colors.secondary};
`;

const LogoContainer = styled.div<{ error?: boolean }>`
    width: 5.5rem;
    height: 5.5rem;
    background-color: ${props => (props.error ? props.theme.colors.tertiary : props.theme.colors.primary)};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
`;

const CheckmarkLogo = styled(SVG)<{ color?: string }>`
    color: ${props => (props.color ? props.color : 'black')};
    width: 3rem;
    height: 3rem;
`;

export default PaymentConfirmed;
