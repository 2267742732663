import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import LoadingIndicator from 'components/atoms/LoadingIndicator';
import PageHeader from 'components/atoms/PageHeader';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import DiscardSaveButtons from 'components/molecules/DiscardSaveButtons';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import SupplierSelectors from 'redux/slices/suppliers/Selectors';

import NavActions from 'lib/NavActions';
import { AuthRoleEnum } from 'entities/auth';
import { ISupplierDetails } from 'entities/supplier';

import SupplierForm from './components/SupplierForm';

const UpdateSupplier = (): JSX.Element => {
    const params = useParams();
    const supplierId = params.id;

    const [localError, setLocalError] = useState<string | null>(null);

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);
    const currentSupplier = useAppSelector(SupplierSelectors.getSuppliersCreateEditSupplierParams);

    const loading = useAppSelector(SupplierSelectors.getSupplierDetailsAttempting);
    const error = useAppSelector(SupplierSelectors.getSupplierDetailsError);

    const updateLoading = useAppSelector(SupplierSelectors.getSupplierUpdateAttempting);
    const updateError = useAppSelector(SupplierSelectors.getSupplierUpdateError);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (supplierId) {
            dispatch(Actions.supplierGetSupplierDetailsAttempt({ id: supplierId }));
        } else {
            setLocalError('We are unable to verify this supplier. Please refresh and try again.');
        }

        return () => {
            dispatch(Actions.supplierResetCreateEditSupplierParams());
        };
    }, [supplierId]);

    const onChange = (update: Partial<ISupplierDetails>) => {
        dispatch(Actions.supplierSetCreateEditSupplierParams(update));
    };

    const onSubmit = () => {
        dispatch(Actions.supplierUpdateSupplierAttempt());
    };

    const renderActions = () => {
        if (!currentSupplier) return null;
        if (userInfo?.role === AuthRoleEnum.Executive) return null;
        return (
            <ButtonRow>
                <DiscardSaveButtons
                    onDiscard={NavActions.navBack}
                    onSave={onSubmit}
                    loading={updateLoading}
                    error={updateError}
                />
            </ButtonRow>
        );
    };

    const renderBody = () => {
        if (loading) {
            return (
                <LoadingContainer>
                    <LoadingIndicator />
                </LoadingContainer>
            );
        }

        if (localError || error) {
            return (
                <LoadingContainer>
                    <Text variant={TextVariant.error}>{localError || error}</Text>
                </LoadingContainer>
            );
        }

        return (
            <>
                <SupplierForm
                    error={updateError}
                    supplier={currentSupplier}
                    onChange={onChange}
                    disabledCompanyAccount
                    disableInputs={userInfo?.role === AuthRoleEnum.Executive}
                />

                {renderActions()}
            </>
        );
    };

    return (
        <AdminLayout>
            <PageHeader withBackButton>
                {`Edit ${currentSupplier ? currentSupplier.supplierName : 'Supplier'}`}
            </PageHeader>

            {renderBody()}
        </AdminLayout>
    );
};

const ButtonRow = styled.div`
    margin-top: 18px;
`;

const LoadingContainer = styled.div`
    display: flex;

    background-color: white;
    border-radius: 15px;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 240px;
`;

export default UpdateSupplier;
