import React, { FC, useEffect, useRef, useState } from 'react';

import SVG from 'react-inlinesvg';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Text from 'components/atoms/Text';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';

import NavActions from 'lib/NavActions';
import { AuthRoleEnum } from 'entities/auth';

import icons from 'assets/icons';

import theme from 'styles/theme';

import Breadcrumb, { IBreadCrumb } from './Breadcrumb';

import BackgroundImage from '../../assets/images/background.jpeg';

interface HeaderProps {
    disableNavigate?: boolean;
    disableBreadcrumbAndUserProfile?: boolean;
}

const Header: FC<HeaderProps> = (props: HeaderProps) => {
    const {
        disableNavigate,
        disableBreadcrumbAndUserProfile,
    } = props;

    const dispatch = useAppDispatch();
    const { userInfo } = useAppSelector((state) => state.auth);

    const path = useLocation();
    const [isActive, setActive] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const userName = userInfo?.fullName || 'Admin Name';
    const userRole = AuthRoleEnum[Number(userInfo?.role) ?? AuthRoleEnum.Deactivated] || 'Admin Role';

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setActive(!isActive);
    };
    const handleClickOutside = (event: MouseEvent) => {
        if (!event.target || !ref.current || !ref.current.contains(event.target as Node)) {
            setActive(false);
        }
    };

    const breadcrumbData = (): IBreadCrumb[] => {
        const { pathname } = path;
        const pathnames = pathname.split('/');
        if (pathname === '/') {
            return [];
        }

        const formattedPathnames = (names: string) => {
            const parsedString = names.split('-');
            const uppercasedText = parsedString.map((item) => item.charAt(0).toUpperCase() + item.slice(1));

            return uppercasedText.join(' ');
        };

        if (pathnames.length === 2) {
            return [
                { label: 'Home', onClick: () => NavActions.navToHome() },
                { label: formattedPathnames(pathnames[1]), onClick: () => NavActions.navToMenu(`/${pathnames[1]}`) },
            ];
        }

        return [
            { label: 'Home', onClick: () => NavActions.navToHome() },
            { label: formattedPathnames(pathnames[1]), onClick: () => NavActions.navToMenu(`/${pathnames[1]}`) },
            { label: formattedPathnames(pathnames[2]), onClick: () => NavActions.navToAddNewOrder() },
        ];
    };

    return (
        <HeaderContainer disableBreadcrumbAndUserProfile={disableBreadcrumbAndUserProfile}>
            {!disableBreadcrumbAndUserProfile ? (
                <Content>
                    <Breadcrumb data={breadcrumbData()} disableNavigate={disableNavigate} />
                    <UserProfile onClick={(e) => handleOnClick(e)}>
                        <div id='image'>
                            {userName.charAt(0).toUpperCase() ?? 'SC'}
                        </div>
                        <div id='detail'>
                            <Text id='name'>
                                {userName}
                            </Text>
                            <Text id='role'>
                                {userRole}
                            </Text>
                        </div>
                        <UserSettings active={isActive} ref={ref}>
                            <button type='button' onClick={() => NavActions.navToChangePassword()}>
                                <Text id='password'>
                                    Change password
                                </Text>
                                <IconContainer src={icons.lockIcon} color={theme.fontColor.primary} />
                            </button>
                            <button type='button' onClick={() => dispatch(Actions.authLogout())}>
                                <Text id='logout'>
                                    Logout
                                </Text>
                                <IconContainer src={icons.logoutIcon} color={theme.colors.error} />

                            </button>
                        </UserSettings>
                    </UserProfile>
                </Content>
            ) : (
                <LogoContainer src={icons.acaSaltycustomsLogo} height={90} width={250} />
            )}

        </HeaderContainer>
    );
};

const HeaderContainer = styled.div<{ disableBreadcrumbAndUserProfile? : boolean }>`
    width: 100%;
    height: 90px;

    background-image: ${(props) => (props.disableBreadcrumbAndUserProfile ? `url(${BackgroundImage})` : 'white')};
    padding-left: ${(props) => (props.disableBreadcrumbAndUserProfile ? '0px' : '270px')};

`;

const LogoContainer = styled(SVG)`
    position: absolute;
    padding-left: 5rem;
`;

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;
    padding: 2rem 3rem;

    gap: 1.875rem;
`;

const UserProfile = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-inline: 1rem;
    border-left: 2px solid ${theme.colors.border};
    position: relative;
    cursor: default;
    min-width: 13rem;

    #image {
        font-size: 1.25rem;
        margin-left: 0.5rem;
        display: grid;
        place-items: center;
        width: 3rem;
        height: 3rem;
        background-color: ${theme.colors.tertiary};
        border-radius: 50%;
    }

    #detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #name {
        max-width: 8rem;
        font-weight: 800;
        font-size: 1.25rem;
        color: ${theme.fontColor.primary};
        line-height: 1.25rem;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

    }

    #role {
        font-size: ${theme.fontSize.sm};
        line-height: 1.25rem;
    }
`;

const UserSettings = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== 'active',
}) <{ active?: boolean }>`
    z-index: 10;
    position: absolute;
    top: 65px;
    left: 0;

    width: 14rem;
    border: 1px solid ${theme.colors.border};
    border-radius: 4px;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    color: ${theme.fontColor.primary};
    background-color: #ffffff;
    font-size: 1rem;

    display: ${props => (props.active ? 'flex' : 'none')};
    flex-direction: column;

    button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 3rem;
        border: none;
        background-color: transparent;
        cursor: pointer;

        #password {
            font-weight: 500;
            color: ${theme.fontColor.primary};
        }

        #logout {
            font-weight: 500;
            color: ${theme.colors.error};
        }

        > p{
            font-size: 1rem;
        }
    }

`;

const IconContainer = styled(SVG)`
    width: 1.5rem;
    height: 1.5rem;
`;

export default Header;
