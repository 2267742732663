import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import PageHeader from 'components/atoms/PageHeader';
import Paginator from 'components/atoms/Paginator';
import Table, { IActionsArray, IDataArray, IHeaderArray } from 'components/atoms/Table';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import TableFilters from 'components/molecules/TableFilters';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import UserManagementSelectors from 'redux/slices/userManagement/Selectors';

import NavActions from 'lib/NavActions';
import { AuthRoleEnum } from 'entities/auth';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

const UserManagementScreen = (): JSX.Element => {
    const loading = useAppSelector(UserManagementSelectors.getUserManagementAttempting);
    const error = useAppSelector(UserManagementSelectors.getUserManagementError);
    const { maxIndex, data: userManagement, totalRecords } = useAppSelector(UserManagementSelectors.getUserManagement);

    const [toDelete, setToDelete] = useState<string | null>(null);
    const [userNameToDelete, setUserNameToDelete] = useState<string | null>(null);

    const { currentPage, searchQuery } = useAppSelector(UserManagementSelectors.getUserManagementFilters);

    const confirmationModalIsOpen = useAppSelector(UserManagementSelectors.getUserManagementConfirmationDeleteModalIsOpen);

    const deleteLoading = useAppSelector(UserManagementSelectors.deleteUserManagementAttempting);
    const deleteError = useAppSelector(UserManagementSelectors.deleteUserManagementError);

    const dispatch = useAppDispatch();

    const getUserManagement = () => {
        dispatch(Actions.userManagementGetUserManagementAttempt());
    };

    useEffect(() => {
        dispatch(Actions.userManagementResetUserManagementFilters());
    }, []);

    useEffect(() => {
        if (currentPage <= maxIndex) {
            getUserManagement();
        }
    }, [currentPage]);

    const onSearchChanged = (search: string) => {
        dispatch(Actions.userManagementSetFilters({ searchQuery: search }));
    };

    const onPaginatorPressed = (page: number) => {
        dispatch(Actions.userManagementSetFilters({ currentPage: page }));
    };

    const onCancel = () => {
        dispatch(Actions.userManagementSetConfirmationDeleteModalIsOpen(false));
        setToDelete(null);
        setUserNameToDelete(null);
    };

    const onDeleteUserConfirmed = () => {
        if (toDelete) dispatch(Actions.userManagementDeleteUserManagementAttempt({ id: toDelete }));
    };

    const adminHeaders: IHeaderArray[] = [
        { header: 'id', field: 'id', hidden: true },
        { header: 'User', field: 'fullName' },
        { header: 'Email', field: 'email' },
        { header: 'User Role', field: 'role' },
        { header: 'Actions', field: 'action', width: 150 },
    ];

    const adminData: IDataArray[] = userManagement.map((item) => ({
        id: item.id,
        onClick: () => NavActions.navToEditAdmin(item.id),
        cellData: {
            fullName: item.fullName,
            email: item.email,
            role: AuthRoleEnum[item.role],
        },
    }));

    const adminActions: IActionsArray[] = [
        {
            id: 'Delete',
            icon: <DeleteIcon />,
            onClick: (userManagementId: string) => {
                const selectedUser = userManagement.find((user) => user.id === userManagementId);
                if (selectedUser) {
                    setToDelete(userManagementId);
                    setUserNameToDelete(selectedUser.fullName);

                    dispatch(Actions.userManagementSetConfirmationDeleteModalIsOpen(true));
                }
            },
        },
    ];

    return (
        <AdminLayout>
            <PageHeader subtitle={`${totalRecords} total user(s)`}>
                User Management
            </PageHeader>

            <TableFilters
                searchFilterEnabled
                searchValue={searchQuery}
                onSearchChanged={onSearchChanged}
                onSearch={getUserManagement}
                addNewButtonEnabled
                addNewLabel='Add new admin'
                onAddNew={() => NavActions.navToAddNewAdmin()}
            />

            <Table
                headerArrays={adminHeaders}
                dataArray={adminData}
                actions={adminActions}
                style={{ marginTop: 32, marginBottom: 32 }}
                loading={loading}
                error={error}
            />
            <PaginationContainer>
                <Paginator
                    currentPage={currentPage}
                    onPagePressed={onPaginatorPressed}
                    maxPages={maxIndex}
                    disabled={loading}
                />
            </PaginationContainer>

            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                icon={<ConfirmationIcon />}
                loading={deleteLoading}
                error={deleteError}
                title={`Delete User "${userNameToDelete}" ?`}
                text='Please confirm deleting this user.'
                onConfirm={onDeleteUserConfirmed}
                onCancel={onCancel}
                confirmButtonVariant={ButtonVariant.warning}
                cancelText='Cancel'
                confirmText='Confirm'
            />

        </AdminLayout>
    );
};

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export default UserManagementScreen;
