import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';
import { IPurchaseOrder } from 'entities/purchaseOrders';

import {
    CreatePoApiParams,
    DeletePurchaseOrderApiParams,
    DownloadPurchaseOrderApiParams,
    GetPurchaseOrderDetailsApiParams,
    GetPurchaseOrdersApiParams,
    GetPurchaseOrdersApiResponse,
    IPurchaseOrderGateway,
    SendPurchaseOrderApiParams,
    UpdatePoApiParams,
    UpdatePurchaseOrderApiParams,
    ViewPurchaseOrderEmailParams,
} from './PurchaseOrdersBase';

export default class PurchaseOrderGateway extends IPurchaseOrderGateway {
    async getPurchaseOrders(params: GetPurchaseOrdersApiParams): GatewayResponse<GetPurchaseOrdersApiResponse> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<GetPurchaseOrdersApiResponse> = await this.api.get(
            '/purchaseOrder/viewPurchaseOrder',
            rest,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getPurchaseOrderDetails(params: GetPurchaseOrderDetailsApiParams): GatewayResponse<IPurchaseOrder> {
        const { authToken, purchaseOrderId } = params;
        const response: ApiResponse<IPurchaseOrder> = await this.api.get(
            `/purchaseOrder/viewPurchaseOrder/${purchaseOrderId}`,
            {},
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async updatePurchaseOrderDetailsStatus(params: UpdatePurchaseOrderApiParams): GatewayResponse<string | null> {
        const { authToken, id, status } = params;

        const response: ApiResponse<string | null> = await this.api.put(
            '/purchaseOrder/updatePurchaseOrder',
            { id, status },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async createPurchaseOrder(params: CreatePoApiParams): GatewayResponse<string | null> {
        const { authToken, ...newPo } = params;

        const response: ApiResponse<string | null> = await this.api.post(
            '/purchaseOrder/createPurchaseOrder',
            newPo,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async editPurchaseOrder(params: UpdatePoApiParams): GatewayResponse<string | null> {
        const { authToken, ...updatedPo } = params;

        const response: ApiResponse<string | null> = await this.api.put(
            '/purchaseOrder',
            updatedPo,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deletePurchaseOrder(params: DeletePurchaseOrderApiParams): GatewayResponse<null> {
        const { authToken, id } = params;

        const response: ApiResponse<null> = await this.api.delete(
            '/purchaseOrder',
            { id },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async downloadPurchaseOrder(params: DownloadPurchaseOrderApiParams): GatewayResponse<Blob> {
        const { authToken, id } = params;

        const response: ApiResponse<Blob> = await this.api.get(
            `/purchaseOrder/purchaseOrderPDF/${id}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    Accept: 'application/pdf',
                },
                responseType: 'blob',
            },
        );

        return this.process(response);
    }

    async sendPurchaseOrder(params: SendPurchaseOrderApiParams): GatewayResponse<null> {
        const { authToken, id, supplierEmails } = params;

        const response: ApiResponse<null> = await this.api.post(
            '/purchaseOrder/sendPurchaseOrder',
            { id, supplierEmails },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async viewPurchaseOrderEmail(params: ViewPurchaseOrderEmailParams): GatewayResponse<BlobPart> {
        const { authToken, id } = params;

        const response: ApiResponse<BlobPart> = await this.api.get(
            `/purchaseOrder/emailPreview/${id}`,
            {},
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }
}
