import React from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import LoadingIndicator from 'components/atoms/LoadingIndicator';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import PurchaseOrder from 'redux/slices/purchaseOrder';

import NavActions from 'lib/NavActions';
import { IOrder } from 'entities/order';

import { ReactComponent as EyeIcon } from 'assets/icons/eyeOpen.svg';

import theme from 'styles/theme';

type ComponentProps = {
    orderDetails?: Partial<IOrder> | null;
    loading?: boolean;
    error?: string;
}

const ConvertedOrder: React.FC<ComponentProps> = (props: ComponentProps) => {
    const {
        orderDetails,
        loading,
        error,
    } = props;

    if (!orderDetails?.purchaseOrder || !orderDetails?.purchaseOrder.length) {
        return null;
    }

    const onViewPurchaseOrder = (poId: string) => {
        NavActions.navToPurchaseOrdersDetails(poId);
    };

    const renderConvertedOrder = () => {
        if (!orderDetails?.purchaseOrder) return null;

        return orderDetails.purchaseOrder.map((item, index) => {
            return (
                <PurchaseOrderContainer key={`${item.id} - ${index + 1}`}>
                    <Text variant={TextVariant.h3}>
                        {dayjs(item.poDate).format('DD/MM/YYYY')}
                    </Text>
                    <Text>
                        {item.poNumber}
                        {'  '}
                        <EyeIcon
                            color={theme.colors.info}
                            style={{ cursor: 'pointer' }}
                            onClick={() => onViewPurchaseOrder(item.id)}
                        />
                    </Text>
                </PurchaseOrderContainer>
            );
        });
    };

    if (loading) {
        return (
            <FlexContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
                <LoadingIndicator />
            </FlexContainer>
        );
    }
    if (error) {
        return (
            <FlexContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text variant={TextVariant.error}>{error}</Text>
            </FlexContainer>
        );
    }

    return (
        <FlexContainer>
            <Text variant={TextVariant.h2}>Purchase Orders</Text>
            {renderConvertedOrder()}
        </FlexContainer>
    );
};

const FlexContainer = styled.div<{fitWidth?: boolean}>`
    width: ${props => (props.fitWidth ? 'fit-content' : '100%')};
    display: flex;
    flex-direction: column;
    gap: 2rem;

    background-color: white;
    border-radius: 15px;
    padding: 2rem;
    padding-right: ${props => (props.fitWidth ? '3rem' : '2rem')};
    min-height: 300px;

    > div {
        display: flex;
        flex-direction: column;
    }

    > div > div {
        display: flex;
        gap: 0.5rem;
    }

`;

const PurchaseOrderContainer = styled.div`
        display: flex;
        flex-direction: column;
        width: fit-content;
        gap: 0.5rem;
`;

export default ConvertedOrder;
