import { IAddress } from './address';
import { TaxRateEnum } from './products';
import { CurrencyEnum } from './supplier';

export enum QuotationStatusEnum {
    Pending = 1,
    Converted = 2,
    Deleted = 3,
}

export enum QuotationCompanyAccountEnum {
    SaltyMalaysia = 1,
    SaltySingapore = 2,
}

export enum QuotationShippingMethodEnum {
    InternationalShipping = 1,
    WestMalaysiaShipping = 2,
    EastMalaysiaShipping = 3,
}

export interface IQuotationProductQuantity {
    productPriceId: string;
    size?: string;
    quantity: number;
    pricePerUnit: number;
}

export interface IQuotationProduct {
    productId: string;
    variantId: string;
    productName?: string;
    productVariant: {
        fit?: string;
        type?: string;
        sleeve?: string;
        style?: string;
        color?: string;
    },
    quantities: IQuotationProductQuantity[];
    printMethods: {
        side: number,
        printCustomId: string,
        printVariantId: string,
        pricePerUnit: number,
        printMethod?: string,
        block?: string,
        colorCount?: string,
    }[],
    finalQuantity: number;
    subtotal: number;
    discountPercent?: number;
    discountFixed?: number;
    finalProductPrice: number;
    totalPricePerUnit?: number;
    // weight: number; // todo: might need this in future
    remark?: string;
}

export interface IQuotationCustomLineProduct {
    productName: string;
    finalQuantity: number;
    subtotal: number;
    finalProductPrice: number;
    discountPercent?: number;
    discountFixed?: number;
    totalPricePerUnit?: number;
    weight?: number;
    remark?: string;
}

export interface IQuotationList {
    id: string;
    quotationNumber: string;
    personInCharge: string;
    quotationDate: string;
    companyAccount: QuotationCompanyAccountEnum;
    quotationStatus: QuotationStatusEnum;
    finalPrice: number;
    clientName: string;
    createdAt: string;
    currency: CurrencyEnum;
}

export interface IQuotation {
    id: string;
    quotationNumber: string;
    quotationStatus: QuotationStatusEnum;
    personInCharge: string;
    quotationDate: string;
    companyAccountId: string;
    clientId: string;
    clientName: string;
    clientCompanyName: string;
    clientContact: string;
    clientEmail: string;
    shippingMethod: QuotationShippingMethodEnum;
    shippingAddress: IAddress;
    products: IQuotationProduct[];
    customLineProducts: IQuotationCustomLineProduct[];
    subtotal: number;
    shippingFee: number;
    tax: number;
    discountPercent?: number;
    discountFixed?: number;
    finalPrice: number;
    createdAt: string;
    updatedAt: string;
    companyName: string;
    companyAlias: string;
    companyAddress: IAddress;
    companyNumber: string;
    companyRegistrationNumber: string;
    note?: string;
    remark?: string;
    currency?: CurrencyEnum;
    taxType: TaxRateEnum;
}
