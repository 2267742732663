import React from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import { ICompanyDetails } from 'entities/company';
import { ICustomer } from 'entities/customer';

interface QuotationDetailsProps {
    companyDetails: ICompanyDetails,
    customerDetails: Partial<ICustomer>;
    preparedBy: string;
    quoteNo: string;
    startDate: string;
    endDate: string;
    createdAt: string;
}

const QuotationDetails: React.FC<QuotationDetailsProps> = (props: QuotationDetailsProps) => {
    const {
        companyDetails,
        customerDetails,
        preparedBy,
        quoteNo,
        startDate,
        endDate,
        createdAt,
    } = props;

    return (
        <DetailsContainer>
            <div id='top'>
                <FlexContainer>
                    <Text style={{ fontWeight: 'bold' }}>Saltycustoms</Text>
                    <Text>{`${companyDetails?.name},`}</Text>
                    <Text>{`${companyDetails?.address.street1},`}</Text>
                    {companyDetails?.address.street2 && <Text>{`${companyDetails?.address.street2},`}</Text>}
                    <Text>{`${companyDetails?.address.postcode}, ${companyDetails?.address.city}, ${companyDetails?.address.state},`}</Text>
                    <Text>{companyDetails?.address.country}</Text>
                    <Text>{`Tel: ${companyDetails.phoneNumber}`}</Text>
                </FlexContainer>
                <FlexContainer style={{ gap: '1rem', alignItems: 'flex-end' }}>
                    <div>
                        <Text style={{ fontWeight: 'bold' }}>Quote No:</Text>
                        <Text>{quoteNo}</Text>
                    </div>
                    <div>
                        <Text style={{ fontWeight: 'bold' }}>Quotation Date:</Text>
                        <Text>{dayjs(startDate).format('DD/MM/YYYY')}</Text>
                    </div>
                    <div>
                        <Text style={{ fontWeight: 'bold' }}>Valid Until:</Text>
                        <Text>{dayjs(endDate).format('DD/MM/YYYY')}</Text>
                    </div>
                </FlexContainer>
            </div>
            <div id='bottom'>
                <FlexContainer>
                    <Text style={{ fontWeight: 'normal', fontSize: '2rem', marginBottom: '1rem' }}>Customer</Text>
                    <Text style={{ fontWeight: 'bold' }}>{customerDetails?.contactPerson}</Text>
                    <Text>{`${customerDetails?.companyName},`}</Text>
                    <Text>{`${customerDetails?.address?.[0].street1},`}</Text>
                    {customerDetails?.address?.[0].street2 && <Text>{`${customerDetails?.address?.[0].street2},`}</Text>}
                    <Text>{`${customerDetails?.address?.[0].postcode}, ${customerDetails?.address?.[0].city}, ${customerDetails?.address?.[0].state},`}</Text>
                    <Text>{customerDetails?.address?.[0].country}</Text>
                    <Text>{`Tel: ${customerDetails.contact}`}</Text>
                </FlexContainer>
                <FlexContainer>
                    <Text style={{ fontWeight: 'normal', fontSize: '2rem', marginBottom: '1rem' }}>Prepared by</Text>
                    <Text>{`${preparedBy} (${dayjs(createdAt).format('D MMM YYYY hh:mmA')})`}</Text>
                </FlexContainer>
            </div>
        </DetailsContainer>
    );
};
const DetailsContainer = styled.div`
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin-block: 2rem;

    > div {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 2.5rem 3.25rem;
        gap: 20px;
    }

    #top {
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid ${props => props.theme.colors.border};
    }

    #bottom {
        flex-direction: row;
        justify-content: space-between;
    }

`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;

    > div {
        width: 20rem;
        display: flex;
        justify-content: space-between;
    }
`;

export default QuotationDetails;
