import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAuthUser } from 'entities/auth';

import {
    AuthReduxState,
    ChangePasswordParamsPayload,
    ForgotPasswordParamsPayload,
    LoginActionPayload,
    ResetPasswordParamsPayload,
    StringOrNullPayload,
} from './types';

const initialState: AuthReduxState = {
    actions: {
        startup: true,
        login: false,
        forgotPassword: false,
        resetPassword: false,
        changePassword: false,
    },
    authToken: '',
    requestResetPassword: false,
    userInfo: null,
    error: {
        startup: '',
        login: '',
        forgotPassword: '',
        resetPassword: '',
        changePassword: '',
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authResetActions: (state) => {
            state.actions = initialState.actions;
        },
        authResetErrors: (state) => {
            state.error = initialState.error;
        },
        authStartupAttempt: (state) => {
            state.actions.startup = true;
            state.error.startup = '';
        },
        authStartupSuccess: (state) => {
            state.actions.startup = false;
        },
        authStartupFailure: (state, action: StringOrNullPayload) => {
            state.actions.startup = false;
            if (action.payload) state.error.startup = action.payload;
        },
        authLoginAttempt: (state, _action: LoginActionPayload) => {
            state.actions.login = true;
            state.error.login = '';
        },
        authLoginSuccess: (state, action: PayloadAction<string>) => {
            state.actions.login = false;
            state.authToken = action.payload;
        },
        authLoginFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.login = false;
            if (action.payload) {
                state.error.login = action.payload;
            }
        },
        authSetUserInfo: (state, action: PayloadAction<IAuthUser>) => {
            state.userInfo = action.payload;
        },
        authForgotPasswordAttempt: (state, _action: ForgotPasswordParamsPayload) => {
            state.actions.forgotPassword = true;
            state.error.forgotPassword = '';
            state.requestResetPassword = false;
        },
        authForgotPasswordSuccess: (state) => {
            state.actions.forgotPassword = false;
            state.requestResetPassword = true;
        },
        authForgotPasswordFailure: (state, action: StringOrNullPayload) => {
            state.actions.forgotPassword = false;
            state.requestResetPassword = false;

            if (action.payload) {
                state.error.forgotPassword = action.payload;
            }
        },
        authResetPasswordAttempt: (state, _action: ResetPasswordParamsPayload) => {
            state.actions.resetPassword = true;
            state.error.resetPassword = '';
        },
        authResetPasswordSuccess: (state) => {
            state.actions.resetPassword = false;
        },
        authResetPasswordFailure: (state, action: StringOrNullPayload) => {
            state.actions.resetPassword = false;

            if (action.payload) {
                state.error.resetPassword = action.payload;
            }
        },
        authChangePasswordAttempt: (state, _action: ChangePasswordParamsPayload) => {
            state.actions.changePassword = true;
            state.error.changePassword = '';
        },
        authChangePasswordSuccess: (state) => {
            state.actions.changePassword = false;
        },
        authChangePasswordFailure: (state, action: StringOrNullPayload) => {
            state.actions.changePassword = false;
            if (action.payload) state.error.changePassword = action.payload;
        },
        authLogout: (state) => {
            state.actions.login = false;
            state.authToken = '';
        },
    },
});

export type AuthState = typeof initialState;

export default {
    actions: authSlice.actions,
    reducers: authSlice.reducer,
};
