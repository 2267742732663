import React from 'react';

import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

export type PieChartData = {
    name: string;
    value: number;
}
type ComponentProps = {
    style?: string;
    data: PieChartData[];
};

const AverageTimePieChart: React.FC<ComponentProps> = (props: ComponentProps) => {
    const { style, data } = props;
    // const data = [
    //     { name: 'Group A', value: 5 },
    //     { name: 'Group B', value: 10 },
    //     { name: 'Group D', value: 3 },
    // ];
    const COLORS = ['#DA46FF', '#0088FE', '#FC9736'];
    return (
        <PieChart
            width={400}
            height={400}
        >
            <Pie
                data={data}
                cx={200}
                cy={200}
                innerRadius={150}
                outerRadius={180}
                fill='#8884d8'
                paddingAngle={5}
                dataKey='value'
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index + 1}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
        </PieChart>
    );
};

export default AverageTimePieChart;
