import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import EnquiriesGateway from 'api/Enquiries';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { GetEnquiryDetailsPayload } from 'redux/slices/enquiries/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetEnquiryDetails(api: EnquiriesGateway): SagaWatcherReturnType {
    yield takeEvery('enquiries/enquiriesGetEnquiryDetailsAttempt', handleGetEnquiryDetails, api);
}

function* handleGetEnquiryDetails(api: EnquiriesGateway, data: GetEnquiryDetailsPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const { id, type } = data.payload;

    const response = yield* call([api, api.getEnquiryDetails], {
        authToken,
        id,
        type,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.enquiriesGetEnquiryDetailsFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.enquiriesGetEnquiryDetailsSuccess(response.data));
    }
}
