import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetCustomerOrdersApiResponse, GetCustomersApiResponse } from 'api/CustomerBase';

import { ICustomer } from 'entities/customer';

import { CustomersReduxState, SetCustomersFilters } from './types';

const initialState: CustomersReduxState = {
    actions: {
        getCustomers: false,
        getCustomerDetails: false,
        getCustomerOrders: false,
        createCustomer: false,
        editCustomer: false,
        deleteCustomer: false,
    },
    filters: {
        currentPage: 0,
        searchQuery: undefined,

        ordersCurrentPage: 0,
        ordersSearchQuery: undefined,
    },
    customers: {
        index: 0,
        maxIndex: 0,
        totalRecords: 0,
        data: [],
    },
    customerDetails: null,
    customerOrders: {
        index: 0,
        maxIndex: 0,
        totalRecords: 0,
        data: [],
    },
    ui: {
        confirmationDeleteModalIsOpen: false,
    },
    error: {
        getCustomers: '',
        getCustomerDetails: '',
        getCustomerOrders: '',
        createCustomer: '',
        editCustomer: '',
        deleteCustomer: '',
    },
};

const customersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        customersGetCustomersAttempt: (state) => {
            state.actions.getCustomers = true;
            state.error.getCustomers = '';
        },
        customersGetCustomersSuccess: (state, action: PayloadAction<GetCustomersApiResponse>) => {
            state.actions.getCustomers = false;
            state.customers = action.payload;
        },
        customersGetCustomersFailure: (state, action: PayloadAction<string>) => {
            state.actions.getCustomers = false;
            state.error.getCustomers = action.payload;
        },

        customersSetFilters: (state, action: SetCustomersFilters) => {
            const { currentPage, searchQuery, ordersCurrentPage, ordersSearchQuery } = action.payload;

            if (currentPage !== undefined) state.filters.currentPage = currentPage;
            if (searchQuery !== undefined) {
                if (!searchQuery.length) state.filters.searchQuery = undefined;
                else state.filters.searchQuery = searchQuery;
            }

            if (ordersCurrentPage !== undefined) state.filters.ordersCurrentPage = ordersCurrentPage;
            if (ordersSearchQuery !== undefined) {
                if (!ordersSearchQuery.length) state.filters.ordersSearchQuery = undefined;
                else state.filters.ordersSearchQuery = ordersSearchQuery;
            }
        },
        customersResetCustomerFilters: (state) => {
            state.filters = initialState.filters;
        },
        customersSetConfirmationDeleteModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.confirmationDeleteModalIsOpen = action.payload;
        },

        customersGetCustomerDetailsAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.getCustomerDetails = true;
            state.error.getCustomerDetails = '';
        },
        customersGetCustomerDetailsSuccess: (state, action: PayloadAction<ICustomer>) => {
            state.actions.getCustomerDetails = false;
            state.customerDetails = action.payload;
        },
        customersGetCustomerDetailsFailure: (state, action: PayloadAction<string>) => {
            state.actions.getCustomerDetails = false;
            state.error.getCustomerDetails = action.payload;
        },
        customersClearCustomerDetails: (state) => {
            state.customerDetails = initialState.customerDetails;
            state.actions.getCustomerDetails = false;
            state.error.getCustomerDetails = '';
        },

        customersGetCustomerOrdersAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.getCustomerOrders = true;
            state.error.getCustomerOrders = '';
        },
        customersGetCustomerOrdersSuccess: (state, action: PayloadAction<GetCustomerOrdersApiResponse>) => {
            state.actions.getCustomerOrders = false;
            state.customerOrders = action.payload;
        },
        customersGetCustomerOrdersFailure: (state, action: PayloadAction<string>) => {
            state.actions.getCustomerOrders = false;
            state.error.getCustomerOrders = action.payload;
        },
        customersClearCustomerOrders: (state) => {
            state.customerOrders = initialState.customerOrders;
            state.filters.ordersCurrentPage = 0;
            state.filters.ordersSearchQuery = undefined;
        },

        customersEditCustomerAttempt: (state) => {
            state.actions.editCustomer = true;
            state.error.editCustomer = '';
        },
        customersEditCustomerSuccess: (state) => {
            state.actions.editCustomer = false;
        },
        customersEditCustomerFailure: (state, action: PayloadAction<string>) => {
            state.actions.editCustomer = false;
            state.error.editCustomer = action.payload;
        },
        customersClearEditCustomer: (state) => {
            state.actions.editCustomer = false;
            state.error.editCustomer = '';
            state.customerDetails = initialState.customerDetails;
        },
        customersSetCustomerDetails: (state, action: PayloadAction<Partial<ICustomer>>) => {
            state.customerDetails = {
                ...state.customerDetails,
                ...action.payload,
            };
        },

        customersCreateCustomerAttempt: (state) => {
            state.actions.createCustomer = true;
            state.error.createCustomer = '';
        },
        customersCreateCustomerSuccess: (state) => {
            state.actions.createCustomer = false;
        },
        customersCreateCustomerFailure: (state, action: PayloadAction<string>) => {
            state.actions.createCustomer = false;
            state.error.createCustomer = action.payload;
        },
        customersClearCreateCustomer: (state) => {
            state.actions.createCustomer = false;
            state.error.createCustomer = '';
            state.customerDetails = initialState.customerDetails;
        },

        customersDeleteCustomerAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.deleteCustomer = true;
            state.error.deleteCustomer = '';
        },
        customersDeleteCustomerSuccess: (state) => {
            state.actions.deleteCustomer = false;
            state.ui.confirmationDeleteModalIsOpen = false;
        },
        customersDeleteCustomerFailure: (state, action: PayloadAction<string>) => {
            state.actions.deleteCustomer = false;
            state.error.deleteCustomer = action.payload;
        },
    },
});

export default {
    actions: customersSlice.actions,
    reducers: customersSlice.reducer,
};
