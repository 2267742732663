import React, { useState } from 'react';

import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import Text from 'components/atoms/Text';
import Toggle from 'components/atoms/Toggle';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import theme from 'styles/theme';

interface UploadPaymentProofModalProps {
    isOpen: boolean;

    title: string;
    text: string | React.ReactNode;
    icon?: React.ReactNode;

    cancelText: string;
    confirmText: string;

    onCancel: () => void;
    onUploadPartialPayment: () => void;
    onUploadFullPayment: () => void;

    paymentAmount?: number;
    setPaymentAmount?: (amount: number) => void;

    loading?: boolean;
    error?: string;

    confirmButtonVariant?: ButtonVariant;
}

const UploadPaymentProofModal = (props: UploadPaymentProofModalProps): JSX.Element => {
    const {
        isOpen,
        title,
        text,
        icon,
        cancelText,
        confirmText,
        onCancel,
        onUploadPartialPayment,
        onUploadFullPayment,
        paymentAmount,
        setPaymentAmount,
        loading,
        error,
        confirmButtonVariant,
    } = props;

    const [isFullPayment, setIsFullPayment] = useState(false);

    const handlePaymentAmountChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target as HTMLInputElement;
        const regex = /^[0-9\b]+$/;

        if (target.value === '' || regex.test(target.value)) {
            if (!setPaymentAmount) return;

            if (target.value.trim().length === 0) {
                setPaymentAmount(0);
                return;
            }

            setPaymentAmount(Number(target.value));
        } else {
            target.value = target.value.replace(/[^0-9\b]/g, '');
        }
    };

    return (
        <Modal
            isModalOpen={isOpen}
            title={title}
            onClosed={onCancel}
            icon={icon}
        >
            <Container>
                <ModalText>{text}</ModalText>
                <TabContainer>
                    <TabContent isActive={!isFullPayment} onClick={() => setIsFullPayment(false)}>
                        Partial Payment
                    </TabContent>
                    <TabContent isActive={isFullPayment} onClick={() => setIsFullPayment(true)}>
                        Full Payment
                    </TabContent>
                </TabContainer>
                {!isFullPayment && (
                    <Input
                        header='Partial payment amount'
                        variant='inputForm'
                        value={paymentAmount}
                        onChange={handlePaymentAmountChange}
                        required
                        error={!!error}
                    />
                )}
                {error && <ErrorText variant={TextVariant.error}>{error}</ErrorText>}
            </Container>
            <ButtonRow>
                <Button
                    label={cancelText}
                    onClick={onCancel}
                    variant={ButtonVariant.primary}
                    disabled={loading}
                />
                <Button
                    label={confirmText}
                    onClick={isFullPayment ? onUploadFullPayment : onUploadPartialPayment}
                    variant={confirmButtonVariant ?? ButtonVariant.primary}
                    loading={loading}
                />
            </ButtonRow>
        </Modal>
    );
};

const ModalText = styled(Text)`
    font-size: ${theme.fontSize.xs};
    font-weight: 500;
    font-family: ${theme.fonts.primary};
    color: ${theme.fontColor.tertiary};

    margin-bottom: 6px;

    @media (min-width: 1280px) {
        font-size: ${theme.fontSize.sm};
        line-height: ${theme.lineHeight.sm};
    }

    @media (min-width: 1520px) {
        font-size: ${theme.fontSize.default};
        line-height: ${theme.lineHeight.default};
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const ErrorText = styled(Text)`
    margin-top: 12px;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 12px;

    margin-top: 20px;
`;

const TabContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: 20px;
    background-color: ${theme.colors.secondary};
    padding: 6px;
    transition: all 0.2s ease-in-out;


`;

const TabContent = styled.div<{ isActive?: boolean }>`
    display: flex;
    width: 50%;
    justify-content: center;
    border-radius: 10px;
    background-color: ${props => (props.isActive ? theme.colors.primary : theme.colors.secondary)};
    color: ${props => (props.isActive ? theme.colors.secondary : theme.fontColor.tertiary)};
    padding: 12px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
`;

export default UploadPaymentProofModal;
