import React, { ChangeEvent, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import AddressDisplay from 'components/atoms/AddressDisplay';
import Button from 'components/atoms/Button';
import DateFilterButton from 'components/atoms/DateFilter';
import DropdownButton from 'components/atoms/DropdownButton';
import Input from 'components/atoms/Input';
import { ProductCells, Row } from 'components/atoms/ProductTableRow';
import { TableBody, TableContainer, TableHead, TableHeadCell } from 'components/atoms/Table';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import { GetProductsApiParams, GetProductsApiResponse } from 'api/ProductsBase';
import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/company/Selectors';
import SupplierSelectors from 'redux/slices/suppliers/Selectors';

import Validators from 'lib/Validators';
import { IAddress, ICustomerAddress } from 'entities/address';
import { OrderPaymentMethodEnum, OrderPaymentTermEnum } from 'entities/order';
import { IProduct } from 'entities/products';
import { QuotationCompanyAccountEnum, QuotationShippingMethodEnum } from 'entities/quotations';
import { CurrencyEnum } from 'entities/supplier';
import PaymentMethodLov from 'lov/PaymentMethodsLov';
import PaymentTermLov from 'lov/PaymentTermLov';
import ShippingMethodsLov from 'lov/ShippingMethodsLov';

import BillingAddressInput from './BillingAddressInput';
import CompanyInput from './CompanyInput';
import CustomerInput from './CustomerInput';
import FullAddressInput from './FullAddressInput';
import ShippingAddressInput from './ShippingAddressInput';
import SupplierAddressInput from './SupplierAddressInput';
import SupplierInput from './SupplierInput';

import ProductSelector from '../ProductSelector';

interface FormFieldType {
    enabled: true;
    mandatory?: boolean;
}

export interface FormFields {
    companyAccount?: FormFieldType;
    quotationDate?: FormFieldType;
    poDate?: FormFieldType;
    clientName?: FormFieldType;
    supplierName?: FormFieldType;
    supplierAddress?: FormFieldType;
    shippingAddress?: FormFieldType;
    deliveryCompanyName?: FormFieldType;
    deliveryAddress?: FormFieldType;
    shippingMethod?: FormFieldType;
    remarks?: FormFieldType;
    notes?: FormFieldType;
    productList?: FormFieldType;
    projectName?: FormFieldType;
    projectDueDate?: FormFieldType;
    clientEmail?: FormFieldType;
    clientNumber?: FormFieldType;
    paymentTerms?: FormFieldType;
    paymentDueDate?: FormFieldType;
    billingAddress?: FormFieldType;
    paymentMethod?: FormFieldType;
}

export interface FormValues {
    companyAccountId?: string;
    quotationDate?: string;
    poDate?: string;
    clientId?: string;
    clientName?: string;
    supplierId?: string;
    supplierName?: string;
    supplierAddress?: IAddress;
    deliveryCompanyName?: string;
    deliveryAddress?: Partial<IAddress>; // used for POs
    shippingAddress?: IAddress;
    note?: string;
    remark?: string;
    shippingMethod?: QuotationShippingMethodEnum;
    projectName?: string;
    projectDueDate?: string;
    clientEmail?: string;
    clientNumber?: string;
    paymentTerms?: string;
    paymentDueDate?: string;
    billingAddress?: IAddress;
    paymentMethod?: OrderPaymentMethodEnum;
}

const productTableHeaders = [
    { header: 'Name' },
    { header: 'Quantity' },
    { header: 'Price Per Unit' },
    { header: 'Price After Discount' },
    { header: 'Discount Total' },
    // { header: 'Tax' },
    { header: 'Total' },
    { header: 'Action' },
];

interface NewOrderDetailsFormProps {
    loading?: boolean;
    error?: string;
    disabled?: boolean;
    values: FormValues;
    formFields: FormFields;
    handlers: {
        onCompanyAccountChanged?: (id: string) => void;
        onQuotationDateChanged?: (date: string) => void;
        onPoDateChanged?: (date: string) => void;
        onClientChanged?: (id: string) => void;
        onSupplierChanged?: (id: string) => void;
        onSupplierAddressChanged?: (add: IAddress) => void;
        onDeliveryAddressChanged?: (add: Partial<IAddress>) => void;
        onDeliveryCompanyNameChanged?: (name: string) => void;
        onShippingAddressChanged?: (add: IAddress) => void;
        onEditShippingAddress?: (editedAddress: IAddress) => void;
        onBillingAddressChanged?: (add: IAddress) => void;
        onEditBillingAddress?: (editedAddress: IAddress) => void;
        onNoteChanged?: (note: string) => void;
        onRemarksChanged?: (remarks: string) => void;
        onShippingMethodChanged?: (method: QuotationShippingMethodEnum) => void;
        onPaymentMethodChanged?: (method: OrderPaymentMethodEnum) => void;
        onProjectNameChanged?: (name: string) => void;
        onProjectDueDateChanged?: (date: string) => void;
        onEmailChanged?: (clientEmail: string) => void;
        onContactChanged?: (clientNumber: string) => void;
        onPaymentTermsChanged?: (terms: OrderPaymentTermEnum) => void;
        onPaymentDueDateChanged?: (date: string) => void;
        onSave?: () => void;
    };
    module?: 'order' | 'quotation' | 'purchaseOrder';
    isEdit?: boolean;
    existingShippingCost?: number;
}

const Form = (props: NewOrderDetailsFormProps): JSX.Element => {
    const {
        loading,
        error,
        disabled,
        values,
        formFields,
        handlers,
        module,
        isEdit,
        existingShippingCost,
    } = props;

    const [formError, setFormError] = useState<string | null>(null);

    const dispatch = useAppDispatch();
    const companyDetails = useAppSelector(Selectors.getCompanyDetails);
    const supplierDetails = useAppSelector(SupplierSelectors.getSupplierDetails);

    useEffect(() => {
        return () => {
            dispatch(Actions.uiClearSearchCustomerForm());
        };
    }, []);

    useEffect(() => {
        if (values && values.companyAccountId) {
            dispatch(Actions.companyGetCompanyDetailsAttempt({ id: values.companyAccountId }));
        }
        if (values && values.supplierId) {
            dispatch(Actions.supplierGetSupplierDetailsAttempt({ id: values.supplierId }));
        }
    }, [values.companyAccountId, values.supplierId]);

    useEffect(() => {
        if (companyDetails?.footer) {
            if (handlers.onRemarksChanged) handlers.onRemarksChanged(companyDetails.footer);
        }
    }, [companyDetails]);

    const onCompanyAccountChanged = (acc: string) => {
        if (handlers.onCompanyAccountChanged) handlers.onCompanyAccountChanged(acc);
    };
    const onQuotationDateChanged = (date: string) => {
        if (handlers.onQuotationDateChanged) handlers.onQuotationDateChanged(date);
    };

    const onPoDateChanged = (date: string) => {
        if (handlers.onPoDateChanged) handlers.onPoDateChanged(date);
    };

    const onCustomerSelected = (customerId: string | number) => {
        if (handlers.onClientChanged) handlers.onClientChanged(customerId as string);
    };

    const onSupplierSelected = (supplierId: string | number) => {
        if (handlers.onSupplierChanged) handlers.onSupplierChanged(supplierId as string);
    };

    const onSupplierAddressChanged = (address: IAddress) => {
        if (handlers.onSupplierAddressChanged) handlers.onSupplierAddressChanged(address);
    };

    const onShippingAddressChanged = (address: IAddress) => {
        if (handlers.onShippingAddressChanged) handlers.onShippingAddressChanged(address);
    };

    const onEditShippingAddress = (address: IAddress) => {
        if (handlers.onEditShippingAddress) handlers.onEditShippingAddress(address);
    };

    const onBillingAddressChanged = (address: IAddress) => {
        if (handlers.onBillingAddressChanged) handlers.onBillingAddressChanged(address);
    };

    const onEditBillingAddress = (address: IAddress) => {
        if (handlers.onEditBillingAddress) handlers.onEditBillingAddress(address);
    };

    const onDeliveryCompanyNameChanged = (name: string) => {
        if (handlers.onDeliveryCompanyNameChanged) handlers.onDeliveryCompanyNameChanged(name);
    };

    const onDeliveryAddressChanged = (address: Partial<IAddress>) => {
        if (handlers.onDeliveryAddressChanged) handlers.onDeliveryAddressChanged(address);
    };

    const onShippingMethodChanged = (shippingMethod: QuotationShippingMethodEnum) => {
        if (handlers.onShippingMethodChanged) handlers.onShippingMethodChanged(shippingMethod);
    };

    const onPaymentMethodChanged = (paymentMethod: OrderPaymentMethodEnum) => {
        if (handlers.onPaymentMethodChanged) handlers.onPaymentMethodChanged(paymentMethod);
    };

    const onEmailChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (handlers.onEmailChanged) handlers.onEmailChanged(e.target.value);
    };

    const onContactChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (handlers.onContactChanged) handlers.onContactChanged(e.target.value);
    };

    const onProjectNameChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (handlers.onProjectNameChanged) handlers.onProjectNameChanged(e.target.value);
    };

    const onProjectDueDateChanged = (date: string) => {
        if (handlers.onProjectDueDateChanged) handlers.onProjectDueDateChanged(date);
    };

    const onPaymentTermsChanged = (terms: OrderPaymentTermEnum) => {
        if (handlers.onPaymentTermsChanged) handlers.onPaymentTermsChanged(terms);
    };

    const onPaymentDueDateChanged = (date: string) => {
        if (handlers.onPaymentDueDateChanged) handlers.onPaymentDueDateChanged(date);
    };

    const onRemarksChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (handlers.onRemarksChanged) handlers.onRemarksChanged(e.target.value);
    };

    // const onNoteChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //     if (handlers.onNoteChanged) handlers.onNoteChanged(e.target.value);
    // };

    const onSaveClicked = () => {
        setFormError(null);

        const validated = Validators.ValidateForm(formFields, values);

        if (validated && handlers.onSave) {
            handlers.onSave();
        } else {
            setFormError('Please fill out all mandatory fields.');
        }
    };

    const renderCompanyAccount = () => {
        if (!formFields.companyAccount) return null;

        return (
            <div style={{ flex: 1 }}>
                <CompanyInput
                    error={!!formError && !values.companyAccountId}
                    mandatory={formFields.companyAccount.mandatory}
                    disabled={disabled}
                    value={values.companyAccountId}
                    onCompanyChanged={onCompanyAccountChanged}
                />
            </div>
        );
    };

    const renderQuotationDate = () => {
        if (!formFields.quotationDate) return null;

        return (
            <div style={{ flex: 1 }}>
                <DateFilterButton
                    label='Quotation Date'
                    onChangeDate={(date: Date) => onQuotationDateChanged(dayjs(date).startOf('day').toISOString())}
                    dateFrom={dayjs(values.quotationDate).toDate()}
                />
            </div>
        );
    };

    const renderPoDate = () => {
        if (!formFields.poDate) return null;

        return (
            <div style={{ flex: 1 }}>
                <DateFilterButton
                    label='PO Date'
                    mandatory={formFields.poDate.mandatory}
                    onChangeDate={(date: Date) => onPoDateChanged(dayjs(date).startOf('day').toISOString())}
                    dateFrom={dayjs(values.poDate).toDate()}
                />
            </div>
        );
    };

    const renderClientName = () => {
        if (!formFields.clientName) return null;

        return (
            <CustomerInput
                inputError={(!!formError || !!error) && !values.clientId}
                disabled={!values.companyAccountId}
                value={values.clientId}
                companyId={values.companyAccountId}
                onClientChanged={onCustomerSelected}
            />
        );
    };

    const renderSupplierName = () => {
        if (!formFields.supplierName) return null;

        return (
            <SupplierInput
                inputError={(!!formError || !!error) && !values.supplierId}
                disabled={!values.companyAccountId}
                value={values.supplierId}
                supplierName={values.supplierName}
                mandatory={formFields.supplierName.mandatory}
                onSupplierChanged={onSupplierSelected}
                companyId={values.companyAccountId}
            />
        );
    };

    const renderSupplierAddress = () => {
        if (!formFields.supplierAddress) return null;

        return (
            <SupplierAddressInput
                supplierId={values.supplierId}
                value={values.supplierAddress}
                onChange={onSupplierAddressChanged}
            />
        );
    };

    const renderShippingAddress = () => {
        if (!formFields.shippingAddress) return null;

        return (
            <ShippingAddressInput
                required={formFields.shippingAddress.mandatory}
                inputError={!!formError && !values.shippingAddress}
                clientId={values.clientId}
                onChange={onShippingAddressChanged}
                value={values.shippingAddress}
                onEdit={onEditShippingAddress}
            />
        );
    };

    const renderBillingAddress = () => {
        if (!formFields.billingAddress) return null;

        return (
            <BillingAddressInput
                required={formFields.billingAddress.mandatory}
                inputError={!!formError && !values.billingAddress}
                clientId={values.clientId}
                onChange={onBillingAddressChanged}
                value={values.billingAddress}
                onEdit={onEditBillingAddress}
            />
        );
    };

    const renderShippingMethods = () => {
        if (!formFields.shippingMethod) return null;

        return (
            <div style={{ flex: 1 }}>
                <DropdownButton
                    error={!!formError && !values.shippingMethod}
                    mandatory={formFields.shippingMethod.mandatory}
                    label='Shipping method'
                    disabled={disabled}
                    value={values.shippingMethod}
                    placeholder='Select shipping method'
                    options={ShippingMethodsLov}
                    onSelect={(value) => onShippingMethodChanged(value as number)}
                />
            </div>
        );
    };

    const renderPaymentMethods = () => {
        if (!formFields.paymentMethod) return null;

        return (
            <div style={{ flex: 1 }}>
                <DropdownButton
                    error={!!formError && !values.paymentMethod}
                    mandatory={formFields.paymentMethod.mandatory}
                    label='Payment method'
                    disabled={disabled}
                    value={values.paymentMethod}
                    placeholder='Select Payment method'
                    options={PaymentMethodLov}
                    onSelect={(value) => onPaymentMethodChanged(value as OrderPaymentMethodEnum)}
                />
            </div>
        );
    };

    const renderClientDetails = () => {
        if (!formFields.clientEmail || !formFields.clientNumber) return null;

        return (
            <div id='details'>
                <Input
                    disabled={!!(values.clientEmail && values.clientNumber)}
                    error={!!formError && !values.clientEmail}
                    header='Email'
                    required={formFields.clientEmail.mandatory}
                    name='clientEmail'
                    onChange={onEmailChanged}
                    value={values.clientEmail}
                />
                <Input
                    disabled={!!(values.clientEmail && values.clientNumber)}
                    error={!!formError && !values.clientNumber}
                    header='Contact'
                    name='clientNumber'
                    required={formFields.clientNumber.mandatory}
                    onChange={onContactChanged}
                    value={values.clientNumber}
                />
            </div>
        );
    };

    const renderProjectDetails = () => {
        if (!formFields.projectName || !formFields.projectDueDate) return null;

        return (
            <div id='details'>
                <Input
                    header='Project name'
                    name='projectName'
                    onChange={onProjectNameChanged}
                    value={values.projectName}
                />
                <DateFilterButton
                    label='Project due date'
                    onChangeDate={(date: Date) => onProjectDueDateChanged(dayjs(date).startOf('day').toISOString())}
                    dateFrom={dayjs(values.projectDueDate).toDate()}
                />
            </div>
        );
    };

    const renderPaymentTermsDetails = () => {
        if (!formFields.paymentTerms || !formFields.paymentDueDate) return null;

        return (
            <div id='details'>
                <DropdownButton
                    label='Payment terms'
                    disabled={disabled}
                    value={values.paymentTerms}
                    placeholder='Select payment terms'
                    options={PaymentTermLov}
                    onSelect={(value) => onPaymentTermsChanged(value as OrderPaymentTermEnum)}
                />
                <DateFilterButton
                    label='Payment due date'
                    onChangeDate={(date: Date) => onPaymentDueDateChanged(dayjs(date).startOf('day').toISOString())}
                    dateFrom={dayjs(values.paymentDueDate).toDate()}
                />
            </div>
        );
    };
    const renderRemarks = () => {
        if (!formFields.remarks) return null;

        return (
            <Input
                variant='textarea'
                header='Remarks'
                required={formFields.remarks.mandatory}
                name='remarks'
                value={values.remark}
                onChange={onRemarksChanged}
            />
        );
    };

    // const renderNotes = () => {
    //     if (!formFields.notes) return null;

    //     return (
    //         <Input
    //             variant='textarea'
    //             header='Note'
    //             required={formFields.notes.mandatory}
    //             name='note'
    //             value={values.note}
    //             onChange={onNoteChanged}
    //             style={{ height: '230px' }}
    //         />
    //     );
    // };

    const renderDeliveryAddress = () => {
        if (!formFields.deliveryAddress) return null;

        return (
            <DeliveryAddressContainer>
                <Text variant={TextVariant.h3}>Shipping address</Text>

                {formFields.deliveryCompanyName?.enabled && (
                    <Input
                        error={(!!formError || !!error) && !values.deliveryCompanyName}
                        header='Company name'
                        value={values.deliveryCompanyName}
                        onChange={(e) => onDeliveryCompanyNameChanged(e.target.value)}
                        required
                    />
                )}

                <FullAddressInput
                    error={formError || error}
                    address={values.deliveryAddress}
                    onAddressChanged={onDeliveryAddressChanged}
                    mandatory={formFields.deliveryAddress.mandatory}
                />
            </DeliveryAddressContainer>
        );
    };

    const getFormatedCurrency = (currency: number) => {
        if (currency === 1) {
            return CurrencyEnum.MalaysianRinggit;
        }
        if (currency === 2) {
            return CurrencyEnum.SingaporeDollar;
        }
        return CurrencyEnum.USDollar;
    };

    return (
        <MainContainer>
            <Container>
                <LeftContainer>
                    <ButtonRow>
                        {renderCompanyAccount()}
                        {renderQuotationDate()}
                        {renderPoDate()}
                    </ButtonRow>

                    {renderClientName()}
                    {renderSupplierName()}
                    {renderSupplierAddress()}
                    {renderClientDetails()}
                    {renderBillingAddress()}
                    {renderShippingAddress()}
                    {module !== 'order' && renderShippingMethods()}
                </LeftContainer>

                <RightContainer>
                    {/* {renderNotes()} */}
                    {renderDeliveryAddress()}
                    {module === 'order' && (
                        <div id='address'>
                            {renderProjectDetails()}
                            {renderPaymentTermsDetails()}
                            {renderPaymentMethods()}
                            {renderShippingMethods()}
                        </div>
                    )}
                    {renderRemarks()}

                </RightContainer>
            </Container>

            {formFields.productList && (
                // <BottomContainer>
                <ProductSelector
                    isEdit={isEdit}
                    isCompanyAccountSelected={!!values.companyAccountId}
                    error={formError || error}
                    currency={module === 'purchaseOrder' ? getFormatedCurrency(Number(supplierDetails?.currency)) ?? CurrencyEnum.MalaysianRinggit : companyDetails?.currency ?? CurrencyEnum.MalaysianRinggit}
                    tableHeaders={productTableHeaders}
                    taxPercent={module === 'purchaseOrder' ? 0 : companyDetails?.tax}
                    shippingMethod={values.shippingMethod}
                    disableDiscount={module === 'purchaseOrder'}
                    disableShippingFee={module === 'purchaseOrder'}
                    disableTax={module === 'purchaseOrder'}
                    existingShippingCost={existingShippingCost}
                />
                // </BottomContainer>
            )}

            {(error || formError) && (
                <ErrorContainer>
                    <Text variant={TextVariant.error}>{formError || error}</Text>
                </ErrorContainer>
            )}

            <ButtonContainer>
                <div>
                    <Button
                        label='Save'
                        variant={ButtonVariant.secondary}
                        onClick={onSaveClicked}
                        loading={loading}
                    />
                </div>
            </ButtonContainer>
        </MainContainer>
    );
};

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;

    gap: 20px;
`;

const Container = styled.div`
    background-color: white;
    border-radius: 15px;

    margin-top: 12px;

    padding: 24px 30px;

    display: grid;
    grid-template-columns: 50% 50%;
    gap: 18px;

    min-height: 600px;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;

    flex: 1;

    gap: 20px;

    #details {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;

    flex: 1;

    gap: 20px;

    #address {
        display: flex;
        flex-direction: column;

        gap: 20px;
    }

    #details {
        display: grid;
        // grid-template-columns: repeat(2, 1fr);
        grid-template-columns: 48% 48%;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }
`;

const ButtonRow = styled.div`
    display: grid;
    grid-template-columns: 48% 48%;

    gap: 20px;
`;

const BottomContainer = styled.div`
    display: flex;
    width: 100%;

    padding: 24px 30px;

    border-radius: 15px;

    justify-content: space-between;
    background-color: white;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    > div {
        display: flex;
        justify-content: flex-end;
        width: 150px;
    }
`;

const ErrorContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const DeliveryAddressContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;

    gap: 20px;
`;

export default Form;
