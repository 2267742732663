import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';

import { GetDashboardDetailsParams, GetDashboardDetailsResponse, IHomeGateway } from './HomeBase';

export default class HomeGateway extends IHomeGateway {
    async getDashboardDetails(params: GetDashboardDetailsParams): GatewayResponse<GetDashboardDetailsResponse> {
        const { authToken, dateToday } = params;

        const response: ApiResponse<GetDashboardDetailsResponse> = await this.api.get(
            '/dashboard',
            { dateToday },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }
}
