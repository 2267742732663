import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchDeleteOrder(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderDeleteOrderAttempt', handleDeleteOrder, api);
}
function* handleDeleteOrder(api: OrderGateway, data: PayloadAction<{ id: string }>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.deleteOrder], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderDeleteOrderFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderDeleteOrderSuccess());
        toast.success('Order deleted successfully');
        yield put(Actions.orderSetConfirmDeleteModalIsOpen(false));
        yield put(Actions.orderGetOrderReportAttempt());
    }
}
