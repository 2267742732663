import createSagaMiddleware from '@redux-saga/core';
import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

import authReducer from 'redux/slices/auth';
import companyReducer from 'redux/slices/company';
import customersReducer from 'redux/slices/customers';
import enquiriesReducer from 'redux/slices/enquiries';
import homeReducer from 'redux/slices/home';
import orderReducer from 'redux/slices/order';
import productsReducer from 'redux/slices/products';
import poReducer from 'redux/slices/purchaseOrder';
import quotationsReducer from 'redux/slices/quotations';
import reportReducer from 'redux/slices/report';
import suppliersReducer from 'redux/slices/suppliers';
import uiReducer from 'redux/slices/ui';
import userManagementReducer from 'redux/slices/userManagement';
import rootSaga from 'sagas';

const sagaMiddleware = createSagaMiddleware();
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const rootReducer = combineReducers({
    router: routerReducer,
    auth: authReducer.reducers,
    home: homeReducer.reducers,
    order: orderReducer.reducers,
    quotations: quotationsReducer.reducers,
    suppliers: suppliersReducer.reducers,
    customers: customersReducer.reducers,
    enquiries: enquiriesReducer.reducers,
    po: poReducer.reducers,
    ui: uiReducer.reducers,
    userManagement: userManagementReducer.reducers,
    products: productsReducer.reducers,
    company: companyReducer.reducers,
    report: reportReducer.reducers,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        thunk: false,
    }).concat(sagaMiddleware, routerMiddleware),
    devTools: process.env.REACT_APP_STAGE !== 'prod',
});

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
