import React, { FC, useEffect, useState } from 'react';

import Styles from 'containers/auth/styles/LoginStyles';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AuthLayout from 'components/Layout/AuthLayout';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';

import NavActions from 'lib/NavActions';

import icons from 'assets/icons';

const Login: FC = () => {
    const {
        LogoContainer,
        MainContainer,
        FormContainer,
        InputContainer,
        InputLogo,
        ErrorMessage,
        ForgotPasswordButton,
    } = Styles;

    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.auth.actions.login || false);
    const error = useAppSelector(state => state.auth.error.login || '');

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [inputError, setInputError] = useState<string>('');

    useEffect(() => {
        dispatch(Actions.authResetErrors());
    }, []);

    useEffect(() => {
        if (error) {
            setInputError(error);
        }
    }, [error]);

    const handleOnLogin = () => {
        if (!email || !password) {
            setInputError('Please enter your email and password');
        } else {
            dispatch(Actions.authLoginAttempt({
                email,
                password,
            }));
            setInputError('');
        }
    };

    return (
        <AuthLayout>
            <MainContainer>
                <LogoContainer src={icons.saltyCustomsLogo} />
                <FormContainer>
                    <InputContainer>
                        <InputLogo src={icons.profileIcon} width={30} error={!!inputError} />
                        <Input
                            type='email'
                            placeholder='Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={!!inputError}
                        />
                    </InputContainer>
                    <InputContainer>
                        <InputLogo src={icons.lockIcon} width={30} error={!!inputError} />
                        <Input
                            variant='password'
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={!!inputError}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') handleOnLogin();
                            }}
                        />
                    </InputContainer>
                    <ErrorMessage error={!!inputError}>
                        <Text variant={TextVariant.error}>
                            {inputError}
                        </Text>
                    </ErrorMessage>
                    <Button label='Log In' onClick={handleOnLogin} loading={loading} />
                    <ForgotPasswordButton
                        variant={ButtonVariant.link}
                        label='Forgot Password?'
                        onClick={() => NavActions.navToForgotPassword()}
                    />
                </FormContainer>
            </MainContainer>
        </AuthLayout>
    );
};

export default Login;
