import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import Styles from 'containers/orders/styles/OrderStyles';
import Table, { IActionsArray, IDataArray, TableContainer, TableHeadCell } from 'components/atoms/Table';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import ConfirmationModal from 'components/molecules/ConfirmationModal';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import OrderSelectors from 'redux/slices/order/Selectors';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { AuthRoleEnum } from 'entities/auth';
import { OrderStatusEnum } from 'entities/order';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

const LatestOrdersTable = (): JSX.Element => {
    const userInfo = useAppSelector(AuthSelectors.getUserInfo);

    const loading = useAppSelector(OrderSelectors.getOrderReportAttempting);
    const error = useAppSelector(OrderSelectors.getOrderReportError);
    const { maxIndex, data: orders } = useAppSelector(OrderSelectors.getOrderReportData);
    const confirmationModalIsOpen = useAppSelector(OrderSelectors.deleteConfirmationModalIsOpen);

    const dispatch = useAppDispatch();
    const [search, setSearch] = useState<string>('');

    const [idToDelete, setIdToDelete] = useState<string | null>(null);
    const [orderNumberToDelete, setOrderNumberToDelete] = useState<string | null>(null);

    const getOrders = () => {
        dispatch(Actions.orderGetOrderReportAttempt());
    };
    useEffect(() => {
        getOrders();
    }, []);

    const tableHeaders = [
        { header: 'Order No', field: 'id' },
        { header: 'Date', field: 'createdAt' },
        { header: 'Customer', field: 'clientName' },
        { header: 'Total', field: 'total' },
        { header: 'Project', field: 'projectName' },
        { header: 'Consultant', field: 'consultantName' },
        { header: 'Status', field: 'status' },
        { header: 'Actions', field: 'action' },
    ];

    const tableData = orders.map((item) => ({
        id: item.id,
        onClick: () => NavActions.navToOrderDetails(item.id),
        cellData: {
            id: item.orderNo,
            createdAt: dayjs(item.createdAt).format('DD/MM/YYYY'),
            clientName: item.clientName,
            total: item.finalPrice && `${Utils.Formatter.formatCurrency(item.currency)}${Utils.Formatter.formatPrice(item.finalPrice)}`,
            consultantName: item.personInCharge,
            projectName: item.projectName,
            status: OrderStatusEnum[item.status],
        },
    }));

    const tableActions: IActionsArray[] = [{
        id: 'delete',
        icon: <DeleteIcon />,
        onClick: (orderId: string) => {
            const selectedOrder = orders.find((item) => item.id === orderId);
            if (selectedOrder) {
                setIdToDelete(selectedOrder.id);
                setOrderNumberToDelete(selectedOrder.orderNo);
            }

            dispatch(Actions.orderSetConfirmDeleteModalIsOpen(true));
        },
    }];

    const onDeleteOrderCancelled = () => {
        setIdToDelete(null);
        setOrderNumberToDelete(null);

        dispatch(Actions.orderSetConfirmDeleteModalIsOpen(false));
    };

    const onDeleteOrderConfirmed = () => {
        if (idToDelete) {
            dispatch(Actions.orderDeleteOrderAttempt({ id: idToDelete }));
        } else {
            toast.error('Please select an order to delete');
        }
    };

    return (
        <>
            <Table
                headerArrays={tableHeaders}
                dataArray={tableData}
                actions={tableActions}
                style={{ marginTop: 32, marginBottom: 32 }}
                loading={loading}
                error={error}
                disableActions={userInfo?.role === AuthRoleEnum.Executive}
            />
            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                icon={<ConfirmationIcon />}
                title={`Delete "${orderNumberToDelete}"?`}
                text='Please confirm deleting this order.'
                onCancel={onDeleteOrderCancelled}
                onConfirm={onDeleteOrderConfirmed}
                cancelText='Cancel'
                confirmText='Confirm'
                confirmButtonVariant={ButtonVariant.warning}
            />
        </>
    );
};

export default LatestOrdersTable;
