import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IAddress } from 'entities/address';
import { CurrencyEnum, ISupplier, ISupplierDetails } from 'entities/supplier';

export interface GetSuppliersApiParams {
    authToken: string;
    currentPage: number;

    searchQuery?: string;
}

export interface GetSuppliersApiResponse {
    maxIndex: number;
    index: number;
    totalRecords: number;
    data: ISupplier[];
}

export interface GetSupplierDetailsParams {
    authToken: string;
    supplierId: string;
}

export interface UpdateSupplierApiParams extends CreateSupplierApiParams {
    id: string;
}

export interface CreateSupplierApiParams {
    authToken: string;

    name: string;
    description?: string;
    website?: string;
    address: IAddress;
    contactPersonName: string;
    contactPersonNumber: string;
    contactPersonEmail: string;
    companyAccountId: string;
    currency: CurrencyEnum;
}

export interface DeleteSupplierApiParams {
    authToken: string;
    supplierId: string;
}

export abstract class ISupplierGateway extends Gateway {
    abstract getSuppliers(params: GetSuppliersApiParams): GatewayResponse<GetSuppliersApiResponse>;

    abstract getSupplierDetails(params: GetSupplierDetailsParams): GatewayResponse<ISupplierDetails>;

    abstract updateSupplier(params: UpdateSupplierApiParams): GatewayResponse<string>;

    abstract createSupplier(params: CreateSupplierApiParams): GatewayResponse<string>;

    abstract deleteSupplier(params: DeleteSupplierApiParams): GatewayResponse<string>;
}
