import dayjs from 'dayjs';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import QuotationsGateway from 'api/Quotations';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import Selectors from 'redux/slices/quotations/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetQuotations(api: QuotationsGateway): SagaWatcherReturnType {
    yield takeEvery('quotations/quoteGetQuotationsAttempt', handleGetQuotations, api);
}
function* handleGetQuotations(api: QuotationsGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const quotationFilters = yield* select(Selectors.getQuotationsFilters);

    let response;

    if (quotationFilters.dateStart === '') {
        response = yield* call([api, api.getQuotations], {
            authToken,
            currentPage: quotationFilters.currentPage,
            searchQuery: quotationFilters.searchQuery,
            status: quotationFilters.quoteStatus,
        });
    } else {
        response = yield* call([api, api.getQuotations], {
            authToken,
            ...quotationFilters,
        });
    }

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.quoteGetQuotationsFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.quoteGetQuotationsSuccess(response.data));
    }
}
