import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import DateFilterButton from 'components/atoms/DateFilter';
import DropdownButton from 'components/atoms/DropdownButton';
import Input from 'components/atoms/Input';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';

import { AuthRoleEnum } from 'entities/auth';

import theme from 'styles/theme';

interface ComponentProps {
    projectName: string;
    projectDueDate: string;
    consultantName?: string;
    loading?: boolean;
    error?: string;
    onUpdateProcessOrder: (projectName: string, projectDueDate: string) => void;
    updateLoading: boolean;
}

const ProcessOrder: React.FC<ComponentProps> = (props: ComponentProps) => {
    const {
        projectName,
        projectDueDate,
        consultantName,
        loading,
        error,
        onUpdateProcessOrder,
        updateLoading,
    } = props;

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);

    const [newProjectName, setNewProjectName] = useState<string>(projectName || '');
    const [date, setDate] = useState<Date>(new Date());

    const [isChanged, setIsChanged] = useState<boolean>(false);

    useEffect(() => {
        setIsChanged(false);
        setNewProjectName('');
        setDate(new Date());

        return () => {
            setIsChanged(false);
            setNewProjectName('');
            setDate(new Date());
        };
    }, []);

    useEffect(() => {
        if (projectName) {
            setNewProjectName(projectName);
        } else {
            setNewProjectName('');
        }
    }, [projectName]);

    useEffect(() => {
        if (projectDueDate) {
            setDate(dayjs(projectDueDate).toDate());
        } else {
            setDate(new Date());
        }
    }, [projectDueDate]);

    useEffect(() => {
        if (newProjectName !== projectName || dayjs(date).toISOString() !== projectDueDate) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    }, [newProjectName, date]);

    const onChangeProjectName = (value: string) => {
        setNewProjectName(value);
    };

    const renderSaveButton = () => {
        if (userInfo?.role === AuthRoleEnum.Supplier) return null;

        return (
            <ButtonContainer>
                <Text variant={TextVariant.h4} style={{ color: theme.fontColor.warning }}>
                    {isChanged ? 'you made some changes!' : ''}
                </Text>
                <Button
                    style={{ width: 'fit-content' }}
                    variant={ButtonVariant.secondary}
                    label='Save'
                    loading={updateLoading}
                    onClick={() => onUpdateProcessOrder(newProjectName, dayjs(date).toISOString())}
                />
            </ButtonContainer>
        );
    };

    if (loading) {
        return (
            <FlexContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
                <LoadingIndicator />
            </FlexContainer>
        );
    }
    if (error) {
        return (
            <FlexContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text variant={TextVariant.error}>{error}</Text>
            </FlexContainer>
        );
    }

    return (
        <FlexContainer>
            <Text variant={TextVariant.h2}>Process Orders</Text>
            <Input
                variant='inputForm'
                header='Project Name'
                value={newProjectName}
                onChange={(e) => onChangeProjectName(e.target.value)}
                // disabled={userInfo?.role === AuthRoleEnum.Executive}
            />
            <DateFilterButton
                label='Deadline'
                onChangeDate={(e: Date) => setDate(e)}
                dateFrom={date}
                // disabled={userInfo?.role === AuthRoleEnum.Executive}
            />
            <Text>
                Apparel consultant:
                {' '}
                <span style={{ fontWeight: 'bold' }}>{consultantName}</span>
            </Text>
            {renderSaveButton()}
        </FlexContainer>
    );
};

const FlexContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    background-color: white;
    border-radius: 15px;
    padding: 2rem;
    min-height: 400px;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export default ProcessOrder;
