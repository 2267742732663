import React from 'react';

import styled from 'styled-components';

import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import theme from 'styles/theme';

export interface ICustomerDetails {
    orderId?: string;
    name?: string;
    email?: string;
    phone?: string;
}

interface ICustomerDetailsTitle {
    header: string;
    field: string | number;
}

interface ICustomerDetailsRowProps {
    title?: ICustomerDetailsTitle[];
    capitalizedTitle?: boolean;
    customerDetails?: ICustomerDetails;

}

const CustomerDetailsRow = (props: ICustomerDetailsRowProps): JSX.Element => {
    const { title, capitalizedTitle, customerDetails } = props;

    const renderCustomerDetailsTitle = () => {
        return title?.map((header, index) => {
            return (
                <CustomerDetails>
                    <Text
                        variant={TextVariant.subtitle}
                        key={header.field || index}
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {capitalizedTitle ? String(header?.header).toUpperCase() : String(header)}
                    </Text>
                </CustomerDetails>
            );
        });
    };

    return (
        <Container>
            <CustomerDetailsContainer>
                {renderCustomerDetailsTitle()}
            </CustomerDetailsContainer>
            <CustomerDetailsContainer>
                <CustomerDetails>
                    {customerDetails?.orderId}
                </CustomerDetails>
                <CustomerDetails>
                    {customerDetails?.name}
                </CustomerDetails>
                <CustomerDetails>
                    {customerDetails?.email}
                </CustomerDetails>
                <CustomerDetails>
                    {customerDetails?.phone}
                </CustomerDetails>
            </CustomerDetailsContainer>
        </Container>
    );
};

const Container = styled.div`

    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 0.5rem;

    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid ${theme.colors.tertiary};
`;

const CustomerDetailsContainer = styled.div`

    width: 100%;
    display: flex;
    flex-direction: row;

`;

const CustomerDetails = styled.div`

    width: 22.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: ${theme.fonts.primary};
    font-size: ${theme.fontSize.default};
    font-weight: ${theme.fontWeight.default};
    color: ${theme.fontColor.default};
`;

export default CustomerDetailsRow;
