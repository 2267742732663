import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga';

import ProductsGateway from 'api/Products';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { UploadProductsFilesPayload } from 'redux/slices/products/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchUploadProductsFiles(api: ProductsGateway): SagaWatcherReturnType {
    yield takeEvery('products/uploadProductsFiles', handleUploadProductsFiles, api);
}

function* handleUploadProductsFiles(api: ProductsGateway, data: UploadProductsFilesPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const { file } = data.payload;

    const response = yield* call([api, api.uploadProductsFiles], {
        authToken,
        file,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.productsUploadProductsFilesFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.productsUploadProductsFilesSuccess());
        toast.success('Successfully Uploaded CSV file!');
    }
}
