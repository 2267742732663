import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import ProductsGateway from 'api/Products';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { GetPrintMethodPricingPayload } from 'redux/slices/products/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetPrintMethodPricing(api: ProductsGateway): SagaWatcherReturnType {
    yield takeEvery('products/productsGetPrintMethodPricingAttempt', handleGetPrintMethodPricing, api);
}

function* handleGetPrintMethodPricing(api: ProductsGateway, data: GetPrintMethodPricingPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const { index, ...params } = data.payload;

    const response = yield* call([api, api.getPrintMethodPricing], {
        authToken,
        ...params,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.productsGetPrintMethodPricingFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.productsGetPrintMethodPricingSuccess({
            index: index ?? 0,
            data: response.data,
        }));
    }
}
