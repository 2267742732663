import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { GetPaymentLinkPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

import { AuthRoleEnum } from 'entities/auth';

export default function* watchGetPaymentLink(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderGetPaymentLinkAttempt', handleGetPaymentLink, api);
}
function* handleGetPaymentLink(api: OrderGateway, data: GetPaymentLinkPayload) {
    const authToken = yield* select((state) => state.auth.authToken);
    const userInfo = yield* select((state) => state.auth.userInfo);
    const response = yield* call([api, api.getPaymentLink], {
        authToken,
        orderId: data.payload.orderId,
    });

    if (response.status === GatewayResponseStatus.Error) {
        if (response.error) {
            toast.error(response.error);
        } else {
            if (userInfo?.role === AuthRoleEnum.Executive) {
                return;
            }
            toast.error('failed to get payment link');
        }
        yield put(Actions.orderGetPaymentLinkFailure(response.message || ''));
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderGetPaymentLinkSuccess(response.data));
    }
}
