import { call, put, takeEvery } from 'typed-redux-saga/macro';

import AuthGateway from 'api/Auth';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { ForgotPasswordParamsPayload } from 'redux/slices/auth/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchForgotPassword(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authForgotPasswordAttempt', handleForgotPassword, api);
}

function* handleForgotPassword(api: AuthGateway, data: ForgotPasswordParamsPayload) {
    const { email } = data.payload;

    const response = yield* call([api, api.forgotPassword], { email });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.authForgotPasswordFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            yield put(Actions.authForgotPasswordSuccess());
        } else {
            yield put(Actions.authForgotPasswordFailure('Something went wrong. Please try again.'));
        }
    }
}
