import { fork } from 'redux-saga/effects';

import CustomerGateway from 'api/Customer';
import { RootSagaReturnType } from 'sagas/types';

import watchCreateCustomer from './createCustomer';
import watchDeleteCustomer from './deleteCustomer';
import watchEditCustomer from './editCustomer';
import watchGetCustomerDetails from './getCustomerDetails';
import watchGetCustomerOrders from './getCustomerOrders';
import watchGetCustomers from './getCustomers';

export default (api: CustomerGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetCustomers, api);
        yield fork(watchGetCustomerDetails, api);
        yield fork(watchGetCustomerOrders, api);
        yield fork(watchCreateCustomer, api);
        yield fork(watchEditCustomer, api);
        yield fork(watchDeleteCustomer, api);
    }

    return {
        rootSaga,
    };
};
