import React, { FC, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import Styles from 'containers/auth/styles/ResetPasswordStyles';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AuthLayout from 'components/Layout/AuthLayout';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';

const ResetPassword:FC = () => {
    const {
        MainContainer,
        FormContainer,
        TitleContainer,
        InputContainer,
        ErrorMessage,
    } = Styles;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email') || '';
    const otp = queryParams.get('otp') || '';

    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.auth.actions.resetPassword || false);
    const error = useAppSelector(state => state.auth.error.resetPassword || '');

    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [inputError, setInputError] = useState<string>('');

    useEffect(() => {
        dispatch(Actions.authResetErrors());
    }, []);

    useEffect(() => {
        if (error) {
            setInputError(error);
        }
    }, [error]);

    const handleOnSubmit = () => {
        if (!newPassword && !confirmPassword) {
            setInputError('Please enter your new password');
        } else if (!newPassword || !confirmPassword) {
            setInputError('Please fill in all fields');
        } else if (newPassword !== confirmPassword) {
            setInputError('Passwords do not match');
        } else {
            dispatch(Actions.authResetPasswordAttempt({
                email,
                otp,
                newPassword,
            }));
            setInputError('');
        }
    };

    return (
        <AuthLayout>
            <MainContainer>
                <FormContainer>
                    <TitleContainer>
                        <Text variant={TextVariant.title}>Set a new Password</Text>
                        <Text>
                            Your new password must contain one uppercase letter, one lowercase letter,
                            one number, one special character and atleast 8 characters long.
                        </Text>
                    </TitleContainer>
                    <InputContainer>
                        <Input
                            variant='password'
                            placeholder='New password'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            error={!!inputError}
                        />
                        <Input
                            variant='password'
                            placeholder='Re-enter new password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={!!inputError}
                        />
                    </InputContainer>
                    <ErrorMessage error={!!inputError}>
                        <Text variant={TextVariant.error}>
                            {inputError}
                        </Text>
                    </ErrorMessage>
                    <Button
                        label='Set new password'
                        onClick={handleOnSubmit}
                        loading={loading}
                    />
                </FormContainer>
            </MainContainer>
        </AuthLayout>
    );
};

export default ResetPassword;
