import React, { useEffect } from 'react';

import PageHeader from 'components/atoms/PageHeader';
import AdminLayout from 'components/Layout/AdminLayout';
import Form, { FormFields } from 'components/molecules/Form';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import QuotationSelectors from 'redux/slices/quotations/Selectors';
import UiSelectors from 'redux/slices/ui/Selectors';

import { IAddress } from 'entities/address';
import { QuotationShippingMethodEnum } from 'entities/quotations';

const NewQuotation = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const newQuoteDetailsFromRedux = useAppSelector(QuotationSelectors.getQuotationsNewOrEditQuotation);
    const createQuotationLoading = useAppSelector(QuotationSelectors.createQuotationAttempting);
    const createQuotationError = useAppSelector(QuotationSelectors.createQuotationError);

    const customerAddressList = useAppSelector(UiSelectors.getUiGetCustomerAddresses);

    useEffect(() => {
        return () => {
            dispatch(Actions.quoteResetNewQuotationParams());
            dispatch(Actions.companyGetCompanyListAttempt({ index: 0 }));
            dispatch(Actions.productsGetProductsAttempt({ index: -1 }));
        };
    }, []);

    const onCompanyAccountChanged = (companyAccountId: string) => {
        if (newQuoteDetailsFromRedux.companyAccountId !== companyAccountId) {
            // reset product selector if company account is changed
            dispatch(Actions.productResetProductSelector());
        }
        dispatch(Actions.quoteUpdateNewQuotationParams({ companyAccountId, shippingAddress: undefined, clientId: undefined }));
        dispatch(Actions.uiResetCustomerAddress());
    };

    const onQuotationDateChanged = (quotationDate: string) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ quotationDate }));
    };

    const onClientChanged = (clientId: string) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ clientId, shippingAddress: customerAddressList?.[0] }));
    };

    const onShippingAddressChanged = (shippingAddress: IAddress) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ shippingAddress }));
    };

    const onEditShippingAddress = (address: IAddress) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ shippingAddress: address }));
    };

    const onShippingMethodChanged = (shippingMethod: QuotationShippingMethodEnum) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ shippingMethod }));
    };

    const onRemarksChanged = (remark: string) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ remark }));
    };

    const onNoteChanged = (note: string) => {
        dispatch(Actions.quoteUpdateNewQuotationParams({ note }));
    };

    const onSave = () => {
        dispatch(Actions.quoteCreateQuotationAttempt());
    };

    const formFieldsNeeded: FormFields = {
        companyAccount: {
            enabled: true, mandatory: true,
        },
        quotationDate: {
            enabled: true, mandatory: true,
        },
        clientName: {
            enabled: true, mandatory: true,
        },
        shippingAddress: {
            enabled: true, mandatory: true,
        },
        shippingMethod: {
            enabled: true, mandatory: true,
        },
        remarks: { enabled: true },
        notes: { enabled: true },
        productList: { enabled: true },
    };

    return (
        <AdminLayout>
            <PageHeader withBackButton>
                Add New Quotation
            </PageHeader>

            <Form
                loading={createQuotationLoading}
                error={createQuotationError}
                formFields={formFieldsNeeded}
                values={newQuoteDetailsFromRedux}
                handlers={{
                    onCompanyAccountChanged,
                    onQuotationDateChanged,
                    onClientChanged,
                    onShippingAddressChanged,
                    onEditShippingAddress,
                    onShippingMethodChanged,
                    onRemarksChanged,
                    onNoteChanged,
                    onSave,
                }}
            />
        </AdminLayout>
    );
};

export default NewQuotation;
