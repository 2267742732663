import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import QuotationsGateway from 'api/Quotations';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';

export default function* watchDeleteQuotation(api: QuotationsGateway): SagaWatcherReturnType {
    yield takeEvery('quotations/quoteDeleteQuotationAttempt', handleDeleteQuotation, api);
}
function* handleDeleteQuotation(api: QuotationsGateway, data: PayloadAction<{ id: string }>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.deleteQuotation], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.quoteDeleteQuotationFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.quoteDeleteQuotationSuccess());
        toast.success('Quotation deleted successfully');
        yield put(Actions.quoteSetConfirmDeleteModalIsOpen(false));
        yield put(Actions.quoteGetQuotationsAttempt());
        NavActions.navToMenu('/quotations');
    }
}
