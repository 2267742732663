import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import PageHeader from 'components/atoms/PageHeader';
import Paginator from 'components/atoms/Paginator';
import Table, { IActionsArray, IDataArray, IHeaderArray } from 'components/atoms/Table';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import TableFilters from 'components/molecules/TableFilters';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import poSelectors from 'redux/slices/purchaseOrder/Selectors';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { AuthRoleEnum } from 'entities/auth';
import { PurchaseOrderStatusEnum } from 'entities/purchaseOrders';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

const tableAvailableOptions = [{
    label: 'All statuses',
    value: 0,
}, {
    label: 'Pending',
    value: PurchaseOrderStatusEnum.Pending,
}, {
    label: 'Delivered',
    value: PurchaseOrderStatusEnum.Delivered,
}];

const PurchaseOrders = (): JSX.Element => {
    const [idToDelete, setIdToDelete] = useState<string | null>(null);
    const [poNumberToDelete, setPoNumberToDelete] = useState<string | null>(null);

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);

    const loading = useAppSelector(poSelectors.getPurchaseOrdersAttempting);
    const error = useAppSelector(poSelectors.getPurchaseOrdersError);
    const { data: poData, maxIndex, totalRecords } = useAppSelector(poSelectors.getPurchaseOrders);
    const deleteModalIsOpen = useAppSelector(poSelectors.getPurchaseOrderConfirmationDeleteModalIsOpen);

    const deletePoLoading = useAppSelector(poSelectors.getDeletePurchaseOrderAttempting);
    const deletePoError = useAppSelector(poSelectors.getDeletePurchaseOrderError);

    const { currentPage, searchQuery, dateStart, dateEnd, status } = useAppSelector(poSelectors.getPurchaseOrdersFilters);

    const dispatch = useAppDispatch();

    const getPurchaseOrders = () => {
        dispatch(Actions.poGetPurchaseOrdersAttempt());
    };

    useEffect(() => {
        dispatch(Actions.poResetPurchaseOrderFilters());
    }, []);

    useEffect(() => {
        getPurchaseOrders();
    }, [currentPage, status, dateStart, dateEnd]);

    const onStatusFiltered = (newStatus: PurchaseOrderStatusEnum) => {
        dispatch(Actions.poSetFilters({ status: newStatus }));
    };

    const onPaginatorPressed = (page: number) => {
        dispatch(Actions.poSetFilters({ currentPage: page }));
    };

    const onDateFilterSelected = (selectedStartDate: Date, selectedEndDate: Date) => {
        dispatch(Actions.poSetFilters({
            dateStart: dayjs(selectedStartDate).startOf('day').toISOString(),
            dateEnd: selectedEndDate ? dayjs(selectedEndDate).endOf('day').toISOString() : undefined,
        }));
    };

    const onSearchQueryChanged = (val: string) => {
        dispatch(Actions.poSetFilters({
            searchQuery: val,
        }));
    };

    const onDeletePoCancelled = () => {
        setIdToDelete(null);
        setPoNumberToDelete(null);

        dispatch(Actions.poSetConfirmDeleteModalIsOpen(false));
    };

    const onDeletePoConfirmed = () => {
        if (idToDelete) dispatch(Actions.poDeletePurchaseOrderAttempt({ id: idToDelete }));
    };

    const onNewPoPressed = () => {
        NavActions.navToCreatePurchaseOrder();
    };

    const tableHeaders: IHeaderArray[] = [
        { header: 'Purchase No', field: 'poNumber' },
        { header: 'Date', field: 'poDate' },
        { header: 'Supplier', field: 'supplierName', width: 250 },
        { header: 'User', field: 'personInCharge' },
        { header: 'Total', field: 'finalPrice' },
        { header: 'Status', field: 'status' },
        { header: 'Actions', field: 'action', width: 120 },
    ];

    const tableData: IDataArray[] = poData.map((item) => ({
        id: item.id,
        onClick: () => NavActions.navToPurchaseOrdersDetails(item.id),
        cellData: {
            poNumber: item.poNumber,
            poDate: dayjs(item.poDate).format('DD/MM/YYYY HH:mm'),
            supplierName: item.supplierName,
            personInCharge: item.createdByName,
            finalPrice: item.finalPrice && `${Utils.Formatter.formatCurrency(item.currency)}${Utils.Formatter.formatPrice(item.finalPrice)}`,
            status: PurchaseOrderStatusEnum[item.status],
        },
    }));

    const tableActions: IActionsArray[] = [{
        id: 'edit',
        icon: <EditIcon />,
        onClick: (poId: string) => {
            const selectedPo = poData.find((item) => item.id === poId);
            if (selectedPo?.status === PurchaseOrderStatusEnum.Pending) {
                NavActions.navToEditPurchaseOrder(poId);
            } else {
                toast.error('Cannot edit delivered purchased order');
            }
        },
    }, {
        id: 'delete',
        icon: <DeleteIcon />,
        onClick: (poId: string) => {
            const selectedPo = poData.find((item) => item.id === poId);
            if (selectedPo) {
                setIdToDelete(poId);
                setPoNumberToDelete(selectedPo.poNumber);
            }

            dispatch(Actions.poSetConfirmDeleteModalIsOpen(true));
        },
    }];

    return (
        <AdminLayout>
            <PageHeader subtitle={`${totalRecords} total PO(s)`}>
                Purchase Orders
            </PageHeader>

            <TableFilters
                statusFilterEnabled
                availableStatuses={tableAvailableOptions}
                onStatusSelected={onStatusFiltered}
                selectedStatus={status}
                dateFilterEnabled
                selectedFromDate={dateStart ? dayjs(dateStart).toDate() : undefined}
                selectedToDate={dateEnd ? dayjs(dateEnd).toDate() : undefined}
                onDatesSelected={onDateFilterSelected}
                searchFilterEnabled
                searchValue={searchQuery}
                onSearchChanged={onSearchQueryChanged}
                onSearch={getPurchaseOrders}
                addNewButtonEnabled
                addNewLabel='Add new purchase order'
                onAddNew={onNewPoPressed}
                addButtonVariant={ButtonVariant.secondary}
                disableAddNew={userInfo?.role === AuthRoleEnum.Executive || userInfo?.role === AuthRoleEnum.Supplier}
            />

            <Table
                headerArrays={tableHeaders}
                dataArray={tableData}
                actions={tableActions}
                style={{ marginTop: 32, marginBottom: 32 }}
                loading={loading}
                error={error}
                disableActions={userInfo?.role === AuthRoleEnum.Executive || userInfo?.role === AuthRoleEnum.Supplier}
            />

            <PaginationContainer>
                <Paginator
                    currentPage={currentPage}
                    onPagePressed={onPaginatorPressed}
                    maxPages={maxIndex}
                    disabled={loading}
                />
            </PaginationContainer>

            <ConfirmationModal
                isOpen={deleteModalIsOpen}
                icon={<ConfirmationIcon />}
                title={`Delete "${poNumberToDelete}"?`}
                text='Please confirm deleting this purchase order.'
                onCancel={onDeletePoCancelled}
                onConfirm={onDeletePoConfirmed}
                cancelText='Cancel'
                confirmText='Confirm'
                confirmButtonVariant={ButtonVariant.warning}
                loading={deletePoLoading}
                error={deletePoError}
            />
        </AdminLayout>
    );
};

const PaginationContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

export default PurchaseOrders;
