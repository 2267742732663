import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import LoadingIndicator from 'components/atoms/LoadingIndicator';
import PageHeader from 'components/atoms/PageHeader';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import DiscardSaveButtons from 'components/molecules/DiscardSaveButtons';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import CustomerSelectors from 'redux/slices/customers/Selectors';

import NavActions from 'lib/NavActions';
import { AuthRoleEnum } from 'entities/auth';
import { ICustomer } from 'entities/customer';

import CustomerForm from './components/CustomerForm';
import CustomerOrderTable from './components/CustomerOrderTable';

const EditCustomerPage = (): JSX.Element => {
    const params = useParams();
    const customerId = params.id;

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);

    const loading = useAppSelector(CustomerSelectors.getCustomerDetailsAttempting);
    const error = useAppSelector(CustomerSelectors.getCustomerDetailsError);
    const customer = useAppSelector(CustomerSelectors.getCustomerDetails);

    const updateLoading = useAppSelector(CustomerSelectors.editCustomerAttempting);
    const updateError = useAppSelector(CustomerSelectors.editCustomerError);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (customerId) {
            dispatch(Actions.customersGetCustomerDetailsAttempt({ id: customerId }));
        }

        return () => {
            dispatch(Actions.customersClearEditCustomer());
            dispatch(Actions.customersClearCustomerOrders());
        };
    }, [customerId]);

    const onChange = (update: Partial<ICustomer>) => {
        dispatch(Actions.customersSetCustomerDetails(update));
    };

    const onSubmit = () => {
        dispatch(Actions.customersEditCustomerAttempt());
    };
    const renderActions = () => {
        if (!customer) return null;
        if (userInfo?.role === AuthRoleEnum.Supplier) return null;
        return (
            <ButtonRow>
                <DiscardSaveButtons
                    onDiscard={NavActions.navBack}
                    onSave={onSubmit}
                    loading={updateLoading}
                    error={updateError}
                />
            </ButtonRow>
        );
    };
    const renderBody = () => {
        if (loading) {
            return (
                <LoadingContainer>
                    <LoadingIndicator />
                </LoadingContainer>
            );
        }

        if (error) {
            return (
                <LoadingContainer>
                    <Text variant={TextVariant.error}>{error}</Text>
                </LoadingContainer>
            );
        }

        return (
            <>
                <CustomerForm
                    error={updateError}
                    onChange={onChange}
                    customer={customer}
                    disabledCompanyAccount
                    // disableInputs={userInfo?.role === AuthRoleEnum.Supplier}
                />

                {renderActions()}

                <CustomerOrderTable
                    customerId={customerId}
                    // disableActions={userInfo?.role === AuthRoleEnum.Supplier}
                />
            </>
        );
    };

    return (
        <AdminLayout>
            <PageHeader withBackButton>
                Edit Customer Details
            </PageHeader>

            {renderBody()}
        </AdminLayout>
    );
};

const LoadingContainer = styled.div`
    display: flex;

    background-color: white;
    border-radius: 15px;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 240px;
`;

const ButtonRow = styled.div`
    margin-top: 18px;
`;

const OrderContainer = styled.div`
    margin-top: 28px;
`;

export default EditCustomerPage;
