import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { CreateOrderApiParams } from 'api/OrderBase';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import CompanySelectors from 'redux/slices/company/Selectors';
import OrderSelectors from 'redux/slices/order/Selectors';
import ProductSelectors from 'redux/slices/products/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';
import { IAddress } from 'entities/address';
import { OrderPaymentTermEnum } from 'entities/order';
import { TaxRateEnum } from 'entities/products';

export default function* watchCreateOrder(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderCreateOrderAttempt', handleCreateOrder, api);
}
function* handleCreateOrder(api: OrderGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const orderDetails = yield* select(OrderSelectors.getOrderNewOrEditOrder);
    const companyDetails = yield* select(CompanySelectors.getCompanyDetails);

    const selectedProducts = yield* select(ProductSelectors.getSelectedProducts);
    const discountPrice = yield* select(ProductSelectors.getDiscountPrice);
    const discountPercent = yield* select(ProductSelectors.getDiscountPercent);
    const discountFixed = yield* select(ProductSelectors.getDiscountFixed);
    const shippingPrice = yield* select(ProductSelectors.getShippingPrice);
    const taxPrice = yield* select(ProductSelectors.getTaxPrice);
    const totalWeight = yield* select(ProductSelectors.getTotalWeight);
    const isTaxApplied = yield* select(ProductSelectors.getIsTaxApplied);

    if (!orderDetails || !orderDetails.companyAccountId || !orderDetails.clientName
        || !orderDetails.clientEmail || !orderDetails.clientNumber
        || !orderDetails.shippingAddress || !orderDetails.billingAddress
        || !orderDetails.shippingMethod || !orderDetails.paymentMethod
    ) {
        yield put(Actions.orderCreateOrderFailure('Please enter all fields before submitting.'));
        return;
    }

    if (!selectedProducts) {
        yield put(Actions.orderCreateOrderFailure('Please select at least one product.'));
    }

    const calculateSubTotal = () => {
        let subTotal = 0;
        selectedProducts.forEach((product) => {
            const total = calculateDiscountedPrice(Number(product.total), Number(product.totalQuantity));

            subTotal += (total * product.totalQuantity);
        });
        return subTotal;
    };

    const calculateTotal = () => {
        let finalTotal = 0;

        if (discountPercent) {
            finalTotal = calculateSubTotal() - (calculateSubTotal() * (Number(discountPercent) / 100)) + Number(shippingPrice);
        }

        if (discountFixed) {
            finalTotal = calculateSubTotal() - Number(discountFixed) + Number(shippingPrice);
        }

        if (!discountPercent && !discountFixed) {
            finalTotal = calculateSubTotal() + Number(shippingPrice);
        }

        return Number(finalTotal.toFixed(2));
    };

    const calculateDiscountedPrice = (total: number, quantity: number) => {
        const price = total / quantity;

        return Number(price.toFixed(2));
    };

    const calculateTotalWithTax = () => {
        if (!companyDetails?.tax) return calculateTotal();
        const tax = calculateTotal() * (Number(companyDetails.tax) / 100);
        return calculateTotal() + tax;
    };

    const newProducts = selectedProducts.filter((product) => product.productId)
        .map((product) => ({
            productId: product.productId as string,
            variantId: product.variantId as string,
            productName: product.name,
            productVariant: {
                fit: product.variant?.fit,
                type: product.variant?.type,
                sleeve: product.variant?.sleeve,
                style: product.variant?.style,
                color: product.variant?.color,
            },
            quantities: product.quantities ? product.quantities?.map((quantities) => ({
                quantity: quantities.quantity ?? 0,
                productPriceId: quantities.id,
                size: quantities.size ? quantities.size : undefined,
                pricePerUnit: Number(quantities.pricePerUnit),
            })) : [],
            printMethods: product.printMethods ? product.printMethods.map((printMethod) => ({
                side: printMethod.side,
                printCustomId: printMethod.printCustomId,
                printVariantId: printMethod.printVariantId,
                pricePerUnit: Number(printMethod.pricePerUnit),
                printMethod: printMethod.printMethod,
                block: printMethod.block,
                colorCount: printMethod.colorCount,
            })) : [],
            finalQuantity: product.totalQuantity,
            discountPercent: product.discountPercent ?? undefined,
            discountFixed: product.discountFixed ?? undefined,
            truePricePerUnit: calculateDiscountedPrice(Number(product.total), Number(product.totalQuantity)),
            totalPricePerUnit: Number(product.price),
            subtotal: product.subtotal ?? 0,
            finalProductPrice: calculateDiscountedPrice(Number(product.total), Number(product.totalQuantity)) * product.totalQuantity,
            discountAmountApplied: product.discountAmountApplied ?? 0,
            remark: product.remark,
            // weight: product.weight ?? 0, // todo: might need this in the future
        }));

    const customLineProducts = selectedProducts.filter((product) => !product.productId)
        .map((product) => ({
            productName: product.name,
            finalQuantity: product.totalQuantity,
            finalProductPrice: calculateDiscountedPrice(Number(product.total), Number(product.totalQuantity)) * product.totalQuantity,
            totalPricePerUnit: Number(product.price),
            subtotal: product.subtotal ?? 0,
            discountPercent: product.discountPercent ?? undefined,
            discountFixed: product.discountFixed ?? undefined,
            truePricePerUnit: calculateDiscountedPrice(Number(product.total), Number(product.totalQuantity)),
            discountAmountApplied: product.discountAmountApplied ?? 0,
            weight: product.weight ?? 0,
            remark: product.remark,
        }));

    const newOrder: Omit<CreateOrderApiParams, 'authToken'> = {
        companyAccountId: orderDetails.companyAccountId as string,
        quotationId: orderDetails.quotationId,
        quotationNumber: orderDetails.quotationNumber,
        clientId: orderDetails.clientId as string,
        projectName: orderDetails.projectName as string,
        projectDueDate: orderDetails.projectDueDate as string,
        paymentTerm: orderDetails.paymentTerm as OrderPaymentTermEnum,
        paymentDueDate: orderDetails.paymentDueDate as string,
        shippingAddress: orderDetails.shippingAddress as IAddress,
        shippingMethod: orderDetails.shippingMethod,
        billingAddress: orderDetails.billingAddress as IAddress,
        paymentMethod: orderDetails.paymentMethod,
        remark: orderDetails.remark,
        note: orderDetails.note,
        products: newProducts,
        customLineProducts,
        discountAmountApplied: discountPrice ? Number(discountPrice) : undefined,
        discountPercent: discountPercent ? Number(discountPercent) : undefined,
        discountFixed: discountFixed ? Number(discountFixed) : undefined,
        shippingFee: Number(shippingPrice),
        subtotal: calculateSubTotal(),
        tax: (taxPrice && isTaxApplied) ? Number(taxPrice) : 0,
        finalPrice: isTaxApplied ? calculateTotalWithTax() : calculateTotal(),
        totalWeight,
        taxType: isTaxApplied ? TaxRateEnum.GstTaxRate : TaxRateEnum.OutOfScopeTaxRate,
    };

    if (selectedProducts.length < 1) {
        yield put(Actions.quoteCreateQuotationFailure('Please select at least one product.'));
        return;
    }

    const response = yield* call([api, api.createOrder], {
        authToken,
        ...newOrder,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderCreateOrderFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderCreateOrderSuccess());
        toast.success('Order created successfully');
        yield put(Actions.orderGetOrderReportAttempt());
        if (response.data) NavActions.navToOrderDetails(response.data, true);
    }
}
