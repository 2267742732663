import React from 'react';

import ConfirmationModal from 'components/molecules/ConfirmationModal';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/enquiries/Selectors';

import { EnquiryTypeEnum } from 'entities/enquiries';

interface ConfirmResolveModalProps {
    enquiryId?: string;
    enquiryType?: EnquiryTypeEnum;
    enquirySubject?: string;
}

const ConfirmResolveModal = (props: ConfirmResolveModalProps): JSX.Element | null => {
    const { enquiryId, enquirySubject, enquiryType } = props;

    const isOpen = useAppSelector(Selectors.getResolveConfirmationModalIsOpen);

    const loading = useAppSelector(Selectors.getResolveEnquiryAttempting);
    const error = useAppSelector(Selectors.getResolveEnquiryError);

    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(Actions.enquiriesSetResolveConfirmationModalIsOpen(false));
        dispatch(Actions.enquiriesResolveEnquiryFailure(''));
    };

    if (!enquiryId || !enquirySubject || !enquiryType) return null;

    const onConfirm = () => {
        dispatch(Actions.enquiriesResolveEnquiryAttempt({ id: enquiryId, type: enquiryType }));
    };

    return (
        <ConfirmationModal
            isOpen={isOpen}
            title={`Resolve enquiry "${enquirySubject}"`}
            text='Please confirm that this enquiry is now resolved.'
            onCancel={onCancel}
            onConfirm={onConfirm}
            cancelText='Cancel'
            confirmText='Confirm'
            loading={loading}
            error={error}
        />
    );
};

export default ConfirmResolveModal;
