export enum TextVariant {
    default = 'default',
    title = 'title',
    subtitle = 'subtitle',
    error = 'error',

    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
}

export default {
    TextVariant,
};
