export enum EnquiryTypeEnum {
    HomePage = 1,
    DesignServices = 2,
}

export enum EnquiryStatusEnum {
    Pending = 1,
    Resolved = 2,
}

export interface IEnquiryListItem {
    id: string;
    name: string;
    subject: string;
    message: string;
    status: EnquiryStatusEnum;
    type: EnquiryTypeEnum;
    createdAt: string;
}

export interface IEnquiry {
    id: string;
    email: string;
    name: string;
    phoneNumber: string;
    subject: string;
    message: string;
    status: EnquiryStatusEnum;
    type: EnquiryTypeEnum;
    createdAt: string;
}
