import { fork } from 'redux-saga/effects';

import CompanyGateway from 'api/Company';
import { RootSagaReturnType } from 'sagas/types';

import watchGetCompanyDetails from './getCompanyDetails';
import watchGetCompanyList from './getCompanyList';

export default (api: CompanyGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetCompanyList, api);
        yield fork(watchGetCompanyDetails, api);
    }

    return {
        rootSaga,
    };
};
