import { OrderPaymentMethodEnum } from 'entities/order';

const PaymentMethodLov = [{
    label: 'Online Banking',
    value: OrderPaymentMethodEnum.OnlineBanking,
}, {
    label: 'Credit Card',
    value: OrderPaymentMethodEnum.CreditCard,
}];

export default PaymentMethodLov;
