import React from 'react';

import ReactPaginate from 'react-paginate';

import './styles/Paginator.css';

interface PaginatorProps {
    currentPage: number;
    maxPages: number;

    onPagePressed: (page: number) => void;

    disabled?: boolean;
    style?: React.CSSProperties;
}

const Paginator = (props: PaginatorProps): JSX.Element => {
    const {
        currentPage,
        maxPages,
        onPagePressed,

        disabled = false,
        style,
    } = props;

    const isDisabled = () => {
        if (disabled) return false;
        return undefined;
    };

    return (
        <div style={style}>
            <ReactPaginate
                breakLabel='...'
                nextLabel='Next'
                previousLabel='Previous'
                pageCount={maxPages + 1} // start from 0
                forcePage={currentPage}
                onPageChange={(newPage) => onPagePressed(newPage.selected)}
                className='react-paginate-container'
                previousClassName='react-paginate-previous'
                nextClassName='react-paginate-previous'
                pageClassName='react-paginator-page-button'
                activeClassName='react-paginator-page-button-active'
                breakClassName='react-paginator-break'
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onClick={isDisabled}
            />
        </div>
    );
};

export default Paginator;
