import React from 'react';

import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import ConfirmationModal from 'components/molecules/ConfirmationModal';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/purchaseOrder/Selectors';

import { IPurchaseOrder } from 'entities/purchaseOrders';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';

interface DeleteModalProps {
    poDetails: IPurchaseOrder | null;
}

const DeletePoModal = (props: DeleteModalProps): JSX.Element | null => {
    const { poDetails } = props;

    const deleteModalIsOpen = useAppSelector(Selectors.getPurchaseOrderConfirmationDeleteModalIsOpen);

    const deleteLoading = useAppSelector(Selectors.getDeletePurchaseOrderAttempting);
    const deleteError = useAppSelector(Selectors.getDeletePurchaseOrderError);

    const dispatch = useAppDispatch();

    if (!poDetails) return null;

    const onDeletePoCancelled = () => {
        dispatch(Actions.poSetConfirmDeleteModalIsOpen(false));
    };

    const onDeletePoConfirmed = () => {
        dispatch(Actions.poDeletePurchaseOrderAttempt({ id: poDetails.id }));
    };

    return (
        <ConfirmationModal
            isOpen={deleteModalIsOpen}
            icon={<ConfirmationIcon />}
            title={`Delete "${poDetails.poNumber}"?`}
            text='Please confirm deleting this purchase order.'
            onCancel={onDeletePoCancelled}
            onConfirm={onDeletePoConfirmed}
            cancelText='Cancel'
            confirmText='Confirm'
            confirmButtonVariant={ButtonVariant.warning}
            loading={deleteLoading}
            error={deleteError}
        />
    );
};

export default DeletePoModal;
