import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import DateFilterButton from 'components/atoms/DateFilter';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import StatCard from 'components/molecules/StatCard';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import ReportSelectors from 'redux/slices/report/Selectors';

import Utils from 'lib/Utils';

import icons from 'assets/icons';

import AverageTimePieChart, { PieChartData } from './components/AverageTimePieChart';
import OrderListTable from './components/OrderListTable';
import OverviewChart, { ComposedChartData } from './components/OverviewChart';

const ReportScreen = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(ReportSelectors.getReportDetailsAttempting);
    const error = useAppSelector(ReportSelectors.getReportDetailsError);
    const reportDetails = useAppSelector(ReportSelectors.getReportDetailsData);
    const chartData = useAppSelector(ReportSelectors.getReportChartData);

    const getChartDataLoading = useAppSelector(ReportSelectors.getReportChartDataAttempting);
    const getChartDataError = useAppSelector(ReportSelectors.getReportChartDataError);

    const ordersData = useAppSelector(ReportSelectors.getReportOrderListData);

    const orderDataList = reportDetails.orderOverviewData.data;

    const { startDate, endDate } = useAppSelector(ReportSelectors.getReportFilters);

    const { maxIndex } = ordersData;

    useEffect(() => {
        return () => {
            dispatch(Actions.reportResetReportFilters());
        };
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(Actions.reportGetReportDetailsAttempt());
            dispatch(Actions.reportGetReportChartDataAttempt());
        }
    }, [endDate]);

    // useEffect(() => {
    //     if (index && (index <= maxIndex) && startDate && endDate) {
    //         dispatch(Actions.reportGetReportOrderListAttempt());
    //     }
    // }, [companyAccountId, index, startDate, endDate]);

    const pieChartData: PieChartData[] = [
        {
            name: 'Average Source Time',
            value: chartData.overallAvgSourceTime,
        },
        {
            name: 'Average Production Time',
            value: chartData.overallAvgProductionTime,
        },
        {
            name: 'Average Delivery Time',
            value: chartData.overallAvgDeliveryTime,
        },
    ];

    const composedChartData: ComposedChartData[] = chartData.weeklyData.map((item) => {
        return {
            name: `Week ${item.week}`,
            averageSourceTime: item.avgSourceTime,
            averageProductionTime: item.avgProductionTime,
            averageDeliveryTime: item.avgDeliveryTime,
            averageFulfillmentTime: item.avgOrderFulfillmentTime,
        };
    });

    const orderCardData = [
        {
            label: 'Pending',
            value: reportDetails.orderStatusTotalInfo.Pending,
            icons: <CardIcon src={icons.pendingIcon} />,
            color: '#C0D4F3',
        },
        {
            label: 'Shipped out',
            value: reportDetails.orderStatusTotalInfo.Shipped,
            icons: <CardIcon src={icons.shippedIcon} />,
            color: '#FFDDBC',
        },
        {
            label: 'Paid',
            value: reportDetails.orderStatusTotalInfo.Paid,
            icons: <CardIcon src={icons.paidIcon} />,
            color: '#DDC2FF',
        },
        {
            label: 'Delivered',
            value: reportDetails.orderStatusTotalInfo.Delivered,
            icons: <CardIcon src={icons.deliveredIcon} />,
            color: '#CFE7DC',
        },
        {
            label: 'In production',
            value: reportDetails.orderStatusTotalInfo.InProduction,
            icons: <CardIcon src={icons.manufacturingIcon} />,
            color: '#E8CBBB',
        },
        {
            label: 'Packing',
            value: reportDetails.orderStatusTotalInfo.Packing,
            icons: <CardIcon src={icons.packingIcon} />,
            color: '#9FFBEF',
        },
        {
            label: 'Unpaid',
            value: reportDetails.orderStatusTotalInfo.Unpaid,
            icons: <CardIcon src={icons.unPaidIcon} />,
            color: '#FEF994',
        },
    ];

    const renderOrdersCard = () => {
        return orderCardData.map((item) => {
            return (
                <StatCard
                    key={item.label}
                    label={item.label}
                    value={item.value}
                    icon={item.icons}
                    color={item.color}
                />
            );
        });
    };

    const onDateFilterSelected = (selectedStartDate: Date, selectedEndDate: Date) => {
        dispatch(Actions.reportSetFilters({
            startDate: dayjs(selectedStartDate).startOf('day').toISOString(),
            endDate: selectedEndDate ? dayjs(selectedEndDate).endOf('day').toISOString() : undefined,
        }));

        dispatch(Actions.reportGetReportOrderListAttempt());
    };

    const onEntitiesFiltered = (option: number | string) => {
        dispatch(Actions.reportSetFilters({
            companyAccountId: String(option),
        }));

        dispatch(Actions.reportGetReportOrderListAttempt());
    };

    return (
        <AdminLayout>
            <Text variant={TextVariant.title}>
                Sale Reports
            </Text>
            <SectionsContainer>
                <FilterContainer>
                    <DateFilterButton
                        rangeFilter
                        dateFrom={startDate ? dayjs(startDate).toDate() : undefined}
                        dateTo={endDate ? dayjs(endDate).toDate() : undefined}
                        onChangeDate={onDateFilterSelected}
                    />
                </FilterContainer>
                <SalesStatContainer>
                    <StatCard
                        label='Total Orders'
                        value={reportDetails.totalOrdersInfo.total.toString()}
                        icon={<CardIcon src={icons.cartIcon} color='#0d894f' />}
                        color='#CFE7DC'
                        percent={reportDetails.totalOrdersInfo.percentageChangeFromPrevMonth ?? 0}
                    />
                    <StatCard
                        label='Total Orders Sales'
                        value={`RM${Utils.Formatter.formatPrice(reportDetails.totalOrderSalesInfo.total)}`}
                        icon={<CardIcon src={icons.moneyCheckmarkIcon} color='#0b65ec' />}
                        color='#C0D4F3'
                        percent={reportDetails.totalOrderSalesInfo.percentageChangeFromPrevMonth ?? 0}
                    />
                </SalesStatContainer>
            </SectionsContainer>
            <SectionsContainer>
                <FilterContainer isDateRange>
                    <div>
                        {/* <DropdownButton
                            options={CompanyAccountsOptions}
                            placeholder='SaltyCustoms entities'
                            onSelect={onEntitiesFiltered}
                        /> */}
                    </div>
                    <Text variant={TextVariant.h3}>
                        {`Showing: ${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(endDate).format('DD/MM/YYYY')}`}
                    </Text>
                </FilterContainer>

                <OrderListTable
                    orderDataList={orderDataList}
                />

            </SectionsContainer>
            <SectionsContainer>
                <FilterContainer isDateRange>
                    <Text variant={TextVariant.title}>
                        Number of Orders
                    </Text>
                    <Text variant={TextVariant.h3}>
                        {`Showing: ${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(endDate).format('DD/MM/YYYY')}`}
                    </Text>
                </FilterContainer>
                <OrderStatContainer>
                    {renderOrdersCard()}
                </OrderStatContainer>
            </SectionsContainer>
            <SectionsContainer>
                <FilterContainer isDateRange>
                    <Text variant={TextVariant.title}>
                        Average Time Taken to Fullfill Orders
                    </Text>
                    <Text variant={TextVariant.h3}>
                        {`Showing: ${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(endDate).format('DD/MM/YYYY')}`}
                    </Text>
                </FilterContainer>
                <OrderTimeTakenChartContainer>
                    <div id='pieChart'>
                        <Text style={{
                            position: 'absolute',
                            top: '47%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 10,
                        }}
                        >
                            Average Order Fulfillment Time
                        </Text>
                        <Text
                            variant={TextVariant.title}
                            style={{
                                position: 'absolute',
                                top: '57%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 10,
                            }}
                        >
                            {chartData.overallAvgOrderFulfillmentTime ? chartData.overallAvgOrderFulfillmentTime : 0}
                            {' '}
                            days
                        </Text>
                        <AverageTimePieChart data={pieChartData} />
                    </div>
                    <div id='mixedChart'>
                        <OverviewChartTitle>
                            <Text style={{ padding: '1rem' }}>
                                Overview
                            </Text>
                            <LegendContainer>
                                <div id='averageFulfillmentTime'>
                                    Average Order Fulfillment Time
                                </div>
                                <div id='averageSourceTime'>
                                    Average Source Time
                                </div>
                                <div id='averageProductionTime'>
                                    Average Production Time
                                </div>
                                <div id='averageDeliveryTime'>
                                    Average Delivery Time
                                </div>
                            </LegendContainer>
                        </OverviewChartTitle>
                        <OverviewChartContainer>
                            <OverviewChart data={composedChartData} />
                        </OverviewChartContainer>
                    </div>
                </OrderTimeTakenChartContainer>
            </SectionsContainer>
        </AdminLayout>
    );
};

const SectionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-block: 20px;
`;

const FilterContainer = styled.div<{ isDateRange?: boolean }>`
    width: ${(props) => (props.isDateRange ? '100%' : 'fit-content')};
    display: flex;
    justify-content: ${(props) => (props.isDateRange ? 'space-between' : 'flex-start')};
    align-items: center;
    gap: 1rem;

    > div {
        display: flex;
        gap: 1rem;
        width: fit-content;
    }
`;

const SalesStatContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1.25rem;
    margin-block: 2rem;
`;

const OrderStatContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    margin-block: 2rem;
`;

const OrderTimeTakenChartContainer = styled.div`
    display: flex;
    gap: 1.5rem;
    width: 100%;

    #pieChart {
        border-radius: 15px;
        background-color: white;
        margin-block: 2rem;
        min-width: fit-content;
        width: calc(100%/3 - 1.5rem);
        min-height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-block: 1rem;
    }

    #mixedChart {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border-radius: 15px;
        background-color: white;
        margin-block: 2rem;
        width: calc(100%*(2/3));
        padding: 2rem;
    }

`;

const OverviewChartContainer = styled.div`
    width: 100%;
    height: 400px;
`;

const OverviewChartTitle = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    > p {
        font-weight: 600;
    }
`;

const LegendContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    #averageFulfillmentTime {
        &::before {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            background-color: #33BEAD;
        }

        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    #averageSourceTime {
         &::before {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            background-color: #DA46FF;
        }

        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    #averageProductionTime {
        &::before {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            background-color: #0b65ec;
        }

        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    #averageDeliveryTime {
        &::before {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            background-color: #fc9736;
        }

        display: flex;
        align-items: center;
        gap: 0.5rem;

    }
`;

const CardIcon = styled(SVG)`
    width: 1.5rem;
    height: 1.5rem;

    @media (max-width: 1440px) {
        width: 1rem;
        height: 1rem;
    }
`;

export default ReportScreen;
