import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { UploadPaymentProofPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchUploadPaymentProof(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderUploadPaymentProofAttempt', handleUploadPaymentProof, api);
}
function* handleUploadPaymentProof(api: OrderGateway, data: UploadPaymentProofPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.uploadPaymentProof], {
        authToken,
        orderId: data.payload.orderId,
        paymentProof: data.payload.paymentProof,
        isPartiallyPaid: data.payload.isPartiallyPaid,
        paymentAmount: data.payload.isPartiallyPaid ? data.payload.paymentAmount : undefined,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderUploadPaymentProofFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderSetUploadPaymentProofModalIsOpen(false));
        yield put(Actions.orderUploadPaymentProofSuccess(response.data));
        yield put(Actions.orderGetOrderDetailsAttempt({ id: data.payload.orderId }));
        yield put(Actions.orderGetTimelineAttempt({ id: data.payload.orderId }));
        toast.success('Order\'s payment proof uploaded');
    }
}
