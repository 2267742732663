import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import config from 'config';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import ConfirmationModal from 'components/molecules/ConfirmationModal';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import OrderSelectors from 'redux/slices/order/Selectors';

import { AuthRoleEnum } from 'entities/auth';
import { IAttachment, IOrder } from 'entities/order';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import TabContainer from './TabContainer';
import UploadAttachmentModal from './UploadAttachmentModal';

interface ComponentProps {
    orderDetails: IOrder | null;
    onUploadAttachment: (folderName: string, file: File[]) => void;
    onDeleteAttachment: (folderName: string, file: string) => void;
    onDeleteAttachmentFolder: (folderName: string) => void;
    loading?: boolean;
    error?: string;
}

const AttachmentUpload: React.FC<ComponentProps> = (props: ComponentProps) => {
    const {
        orderDetails,
        onUploadAttachment,
        onDeleteAttachment,
        onDeleteAttachmentFolder,
        loading,
        error,
    } = props;

    const dispatch = useAppDispatch();

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);
    const uploadAttachmentModalIsOpen = useAppSelector(OrderSelectors.uploadAttachmentModalIsOpen);
    const deleteAttachmentFolderModalIsOpen = useAppSelector(OrderSelectors.deleteAttachmentFolderModalIsOpen);

    const [editFolder, setEditFolder] = useState('');
    const [selectedFolder, setSelectedFolder] = useState<IAttachment>();

    useEffect(() => {
        if (!uploadAttachmentModalIsOpen) {
            setEditFolder('');
        }
    }, [uploadAttachmentModalIsOpen]);

    const onEditIconClicked = (data: IAttachment) => {
        setEditFolder(data.folder);
        dispatch(Actions.orderSetUploadAttachmentModalIsOpen(true));
    };

    const onDeleteIconClicked = (data: IAttachment) => {
        dispatch(Actions.orderSetDeleteAttachmentFolderModalIsOpen(true));
        setSelectedFolder(data);
    };

    const onConfirmDeleteFolder = () => {
        if (!selectedFolder) return;

        onDeleteAttachmentFolder(selectedFolder.folder);
    };
    const renderActionButtons = (data: IAttachment) => {
        return (
            <ButtonsContainer>
                <ActionIconsContainer
                    type='button'
                    onClick={() => onEditIconClicked(data)}
                >
                    <EditIcon height={20} />
                </ActionIconsContainer>
                <ActionIconsContainer
                    type='button'
                    onClick={() => onDeleteIconClicked(data)}
                >
                    <DeleteIcon height={20} />
                </ActionIconsContainer>
            </ButtonsContainer>

        );
    };

    const renderAttachmentMedia = (data: string[], folderName: string) => {
        if (!data) return null;

        return data.map((url) => {
            return (
                <li key={url}>
                    <a
                        href={`${config.baseUrl}/${url.split('/').slice(1).join('/')}`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {url.split('/').pop()}
                    </a>
                    <ActionIconsContainer
                        style={{ marginLeft: '20px' }}
                        type='button'
                        onClick={() => onDeleteAttachment(folderName, url)}
                    >
                        <DeleteIcon height={20} />
                    </ActionIconsContainer>
                </li>
            );
        });
    };
    const renderAttachmentDetails = () => {
        if (!orderDetails?.attachment || orderDetails?.attachment.length < 1) {
            return (
                <div>
                    No Attachment yet. Please upload a new attachment.
                </div>
            );
        }

        return (
            orderDetails.attachment.map((data) => {
                return (
                    <div key={data._id}>
                        <Text variant={TextVariant.h3} style={{ fontWeight: 'bold', letterSpacing: '0.025rem' }}>
                            <span style={{ fontWeight: 'normal' }}>
                                Created by
                            </span>
                            {' '}
                            {data.createdBy ?? 'N/A'}
                            {' '}
                            <span style={{ fontWeight: 'normal' }}>
                                at
                            </span>
                            {' '}
                            {dayjs(data.uploadTimestamp ?? new Date()).format('DD MMMM YYYY - hh:mm a')}
                        </Text>
                        <AttachmentTable>
                            <tr>
                                <td width='20%'>
                                    <div>
                                        {data.folder}
                                    </div>
                                </td>
                                <td>
                                    <ul>
                                        {renderAttachmentMedia(data.attachmentUrl, data.folder)}
                                    </ul>
                                </td>
                                <td width='20%'>
                                    <div>
                                        {renderActionButtons(data)}
                                    </div>
                                </td>
                            </tr>
                        </AttachmentTable>
                    </div>
                );
            })
        );
    };

    const renderUploadButton = () => {
        if (userInfo?.role === AuthRoleEnum.Supplier) return null;
        return (
            <Button
                label='Upload Attachment'
                variant={ButtonVariant.secondary}
                onClick={() => dispatch(Actions.orderSetUploadAttachmentModalIsOpen(true))}
                style={{ width: '300px', marginTop: '20px' }}
            />
        );
    };
    const renderAttachment = () => {
        return (
            <>
                <AttachmentContainer>
                    <AttachmentDetailsContainer>
                        {renderAttachmentDetails()}
                    </AttachmentDetailsContainer>
                </AttachmentContainer>
                {renderUploadButton()}
                <UploadAttachmentModal
                    isOpen={uploadAttachmentModalIsOpen}
                    onCloseModal={() => dispatch(Actions.orderSetUploadAttachmentModalIsOpen(false))}
                    onUpload={onUploadAttachment}
                    loading={loading}
                    editedFolder={editFolder}
                />
                <ConfirmationModal
                    isOpen={deleteAttachmentFolderModalIsOpen}
                    icon={<ConfirmationIcon />}
                    onCancel={() => dispatch(Actions.orderSetDeleteAttachmentFolderModalIsOpen(false))}
                    onConfirm={onConfirmDeleteFolder}
                    title='Delete this attachment folder?'
                    text={`"${selectedFolder?.folder}"`}
                    confirmText='Confirm'
                    cancelText='Cancel'
                    confirmButtonVariant={ButtonVariant.warning}
                />
            </>
        );
    };

    const tabs = [
        { label: 'Attachment', content: renderAttachment() },
    ];

    return (
        <TabContainer tabs={tabs} />
    );
};

const AttachmentContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

const AttachmentDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const AttachmentTable = styled.table`
    margin-top: 1rem;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    th, td {
        padding: 1rem;
        border: 1px solid #42564E33;

        > div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
`;

const ActionIconsContainer = styled.button`
    border: none;
    background-color: transparent;
`;

export default AttachmentUpload;
