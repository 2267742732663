export enum BadgeVariant {
    Pending = 'Pending',
    Delivered = 'Delivered',
    Converted = 'Converted',
    Resolved = 'Resolved',
    Deleted = 'Deleted',
    SentToProduction = 'SentToProduction',
    InProduction = 'InProduction',
    Cancelled = 'Cancelled',
    Shipped = 'Shipped',
    Paid = 'Paid',
    PartiallyPaid = 'PartiallyPaid',
    ReadyForCollection = 'ReadyForCollection',
    Processing = 'Processing',
    Failed = 'Failed',
    Refunded = 'Refunded',
    Manufacturing = 'Manufacturing',
    Sampling = 'Sampling',
    Packing = 'Packing',
    OnHold = 'OnHold',
    Unpaid = 'Unpaid',
    POReceived = 'Purchase Order Received',
    // customer address badges
    DefaultAddress = 'Default',
}

export default {
    BadgeVariant,
};
