import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import PageHeader from 'components/atoms/PageHeader';
import Paginator from 'components/atoms/Paginator';
import Table, { IActionsArray, IDataArray, IHeaderArray } from 'components/atoms/Table';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import TableFilters from 'components/molecules/TableFilters';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import CustomerSelectors from 'redux/slices/customers/Selectors';

import NavActions from 'lib/NavActions';
import { AuthRoleEnum } from 'entities/auth';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

const CustomersScreen = (): JSX.Element => {
    const userInfo = useAppSelector(AuthSelectors.getUserInfo);

    const loading = useAppSelector(CustomerSelectors.getCustomersAttempting);
    const error = useAppSelector(CustomerSelectors.getCustomersError);

    const { maxIndex, totalRecords, data: customersData } = useAppSelector(CustomerSelectors.getCustomers);

    const [idToDelete, setIdToDelete] = useState<string | null>(null);
    const [nameToDelete, setNameToDelete] = useState<string | null>(null);

    const { searchQuery, currentPage } = useAppSelector(CustomerSelectors.getCustomerFilters);
    const deleteModalIsOpen = useAppSelector(CustomerSelectors.getCustomerConfirmationDeleteModalIsOpen);

    const deleteLoading = useAppSelector(CustomerSelectors.deleteCustomerAttempting);
    const deleteError = useAppSelector(CustomerSelectors.deleteCustomerError);

    const dispatch = useAppDispatch();

    const getCustomers = () => {
        dispatch(Actions.customersGetCustomersAttempt());
    };

    useEffect(() => {
        dispatch(Actions.customersResetCustomerFilters());
    }, []);

    useEffect(() => {
        if (currentPage <= maxIndex) {
            getCustomers();
        }
    }, [currentPage]);

    const onPaginatorPressed = (page: number) => {
        dispatch(Actions.customersSetFilters({ currentPage: page }));
    };

    const onSearchQueryChanged = (val: string) => {
        dispatch(Actions.customersSetFilters({
            searchQuery: val,
        }));
    };

    const onDeleteCancelled = () => {
        setIdToDelete(null);
        setNameToDelete(null);

        dispatch(Actions.customersSetConfirmationDeleteModalIsOpen(false));
    };

    const onDeleteConfirmed = () => {
        if (idToDelete) dispatch(Actions.customersDeleteCustomerAttempt({ id: idToDelete }));
    };

    const tableHeaders: IHeaderArray[] = [
        { header: 'id', field: 'id', hidden: true },
        { header: 'Company Name', field: 'companyName' },
        { header: 'Contact Person', field: 'contactPerson' },
        { header: 'Contact', field: 'contact' },
        { header: 'Email', field: 'email', width: 360 },
        { header: 'Actions', field: 'action', width: 120 },
    ];

    const tableData: IDataArray[] = customersData.map((item) => ({
        id: item.id,
        onClick: () => NavActions.navToCustomerEdit(item.id),
        cellData: {
            contactPerson: item.contactPerson,
            companyName: item.companyName || '',
            contact: item.contact,
            email: item.email,
        },
    }));

    const tableActions: IActionsArray[] = [{
        id: 'edit',
        icon: <EditIcon />,
        onClick: (customerId: string) => NavActions.navToCustomerEdit(customerId),
    }, {
        id: 'delete',
        icon: <DeleteIcon />,
        onClick: (customerId: string) => {
            dispatch(Actions.customersSetConfirmationDeleteModalIsOpen(true));

            const selectedCustomer = customersData.find((item) => item.id === customerId);
            if (selectedCustomer) {
                setIdToDelete(customerId);
                setNameToDelete(selectedCustomer.contactPerson ?? selectedCustomer.companyName);
            }
        },
    }];

    return (
        <AdminLayout>
            <PageHeader subtitle={`${totalRecords} total customer(s)`}>
                Customers
            </PageHeader>

            <TableFilters
                searchFilterEnabled
                searchValue={searchQuery}
                onSearchChanged={onSearchQueryChanged}
                onSearch={getCustomers}
                addNewButtonEnabled
                addNewLabel='Add new customer'
                onAddNew={NavActions.navToCustomerCreate}
                // disableAddNew={userInfo?.role === AuthRoleEnum.Executive}
            />

            <Table
                headerArrays={tableHeaders}
                dataArray={tableData}
                actions={tableActions}
                style={{ marginTop: 32, marginBottom: 32 }}
                loading={loading}
                error={error}
                // disableActions={userInfo?.role === AuthRoleEnum.Executive}
            />

            <PaginationContainer>
                <Paginator
                    currentPage={currentPage}
                    onPagePressed={onPaginatorPressed}
                    maxPages={maxIndex}
                    disabled={loading}
                />
            </PaginationContainer>

            <ConfirmationModal
                isOpen={deleteModalIsOpen}
                icon={<ConfirmationIcon />}
                title={`Delete customer "${nameToDelete}"?`}
                text="Please confirm deleting this customer. Any documents already associated with this customer won't be affected."
                onCancel={onDeleteCancelled}
                onConfirm={onDeleteConfirmed}
                cancelText='Cancel'
                confirmText='Confirm'
                loading={deleteLoading}
                error={deleteError}
                confirmButtonVariant={ButtonVariant.warning}
            />
        </AdminLayout>
    );
};

const PaginationContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

export default CustomersScreen;
