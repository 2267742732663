import React, { useEffect, useState } from 'react';

import { debounce } from 'lodash';
import Select from 'react-dropdown-select';
import styled from 'styled-components';

import theme from 'styles/theme';
import './styles/DropdownButton.css';

import { TextVariant } from './variants/TextVariant';
import LoadingIndicator from './LoadingIndicator';
import Text from './Text';

interface StatusFilterOption {
    label: string;
    value: number | string;
}

interface DropdownButtonProps {
    disabled?: boolean;
    value?: number | string;

    options: StatusFilterOption[];
    onSelect: (value: number | string) => void;
    onSearch?: (query: string) => void;

    label?: string;
    placeholder?: string;
    searchable?: boolean;
    mandatory?: boolean;

    loading?: boolean;
    error?: string | boolean;
    isOrderStatus?: boolean;
    noDataLabel?: string;
}

const DropdownButton = (props: DropdownButtonProps): JSX.Element => {
    const {
        disabled,
        value,
        options,
        onSelect,
        onSearch,
        label,
        placeholder,
        searchable = false,
        mandatory = false,
        loading,
        error,
        isOrderStatus,
        noDataLabel,
    } = props;

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [onChangeValue, setOnChangeValue] = useState<StatusFilterOption[]>([]);

    useEffect(() => {
        if (value) {
            const selectedValue = options.find((item) => item.value === value);
            if (selectedValue) handleOnChange([selectedValue]);
        }
        if (value && onChangeValue.length) {
            if (onChangeValue[0].value !== value) {
                const originalValue = options.find((item) => item.value === value);

                if (originalValue) handleOnChange([originalValue]);
            }
        }
    }, [loading, value]);

    const handleOnChange = (values: (string | object)[]) => {
        const selectedValue = values as StatusFilterOption[];

        if (selectedValue.length) {
            setOnChangeValue(selectedValue);
            onSelect(selectedValue[0].value);
        }
    };

    // ? if something broke, use this as value again
    const getSelectedValue = () => {
        if (value !== undefined) {
            const selectedValue = options.find((item) => item.value === value);
            if (selectedValue) return [selectedValue];
        }
        return [];
    };

    const debouncedSearch = React.useMemo(() => debounce(
        (query: string) => {
            if (onSearch && query !== searchQuery) {
                setSearchQuery(query);
                onSearch(query);
            }
        },
        500,
        { trailing: true },
    ), [searchQuery]);

    const renderLoading = () => {
        return <LoadingIndicator size={20} color='#295543' />;
    };

    const renderOptions = () => {
        if (loading) {
            return (
                <NoDataContainer>
                    <LoadingIndicator size={20} />
                </NoDataContainer>
            );
        }

        if (error) {
            return (
                <NoDataContainer>
                    <Text variant={TextVariant.error}>{error}</Text>
                </NoDataContainer>
            );
        }
        return (
            <NoDataContainer>
                <Text>{noDataLabel || 'No data'}</Text>
            </NoDataContainer>
        );
    };

    return (
        <Container>
            {label && (
                <Label>
                    {label}
                    {mandatory && (<Text variant={TextVariant.error}>*</Text>)}
                </Label>
            )}
            <StyledSelect
                disabled={disabled}
                options={options}
                onChange={(e) => handleOnChange(e)}
                values={isOrderStatus ? onChangeValue : getSelectedValue()}
                color={theme.colors.primary}
                searchable={searchable}
                style={selectStyles({ error: !!error })}
                placeholder={placeholder}
                dropdownPosition='auto'
                noDataRenderer={renderOptions}
                loading={loading}
                loadingRenderer={renderLoading}
                searchFn={({ state }) => {
                    debouncedSearch(state.search);
                    return options;
                }}
            />
        </Container>
    );
};

const NoDataContainer = styled.div`
    background-color: white;

    padding: 8px 10px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
`;

const Label = styled(Text)`
    display: flex;

    font-weight: 500;
    font-size: ${theme.fontSize.default};

    margin-bottom: 6px;
`;

const StyledSelect = styled(Select) <{ loading?: boolean }>`
    background-color: ${props => props.theme.colors.tertiary};

    height: 47px;

    ::placeholder {
        font-family: ${theme.fonts.primary};
        font-size: 18px;
        color: ${theme.fontColor.primary};

        @media (min-width: 600px) {
            font-size: ${theme.fontSize.xs};
            line-height: ${theme.lineHeight.xs};
        }
    
        @media (min-width: 1280px) {
            font-size: ${theme.fontSize.sm};
            line-height: ${theme.lineHeight.sm};
        }

        @media (min-width: 1520px) {
            font-size: ${theme.fontSize.default};
            line-height: ${theme.lineHeight.default};
        }

    }

    .react-dropdown-select-content,
    .react-dropdown-select-type-single {

        display: block;
        overflow: hidden;
        // text-overflow: ellipsis;
        white-space: nowrap;
    }

    .react-dropdown-select-item,
    .react-dropdown-select-type-single {
        
        color: ${theme.fontColor.primary};
        background-color: ${theme.colors.background};

        @media (min-width: 600px) {
            font-size: ${theme.fontSize.xs};
            line-height: ${theme.lineHeight.xs};
        }

        @media (min-width: 1280px) {
            font-size: ${theme.fontSize.sm};
            line-height: ${theme.lineHeight.sm};
        }

        @media (min-width: 1520px) {
            font-size: ${theme.fontSize.default};
            line-height: ${theme.lineHeight.default};
        }
    }

    .react-dropdown-select-dropdown {
        background-color: ${props => props.theme.colors.tertiary};
        width: 100%;
        top: 3.25rem;
        height: fit-content;
    }

    .react-dropdown-select-content {
        background-color: transparent;
        padding: 0px 20px 0px 0px;

        display: ${props => (props.loading ? 'none' : 'block')};
    }
`;

const selectStyles = (props: { error?: boolean }): React.CSSProperties => ({
    borderRadius: 25,

    border: props.error ? `2px solid ${theme.colors.error}` : `1px solid ${theme.fontColor.primary}`,

    fontFamily: theme.fonts.primary,
    fontSize: 18,
    fontWeight: theme.fontWeight.bold,
    color: theme.fontColor.primary,

    paddingLeft: 12,
    paddingRight: 12,

    cursor: 'pointer',

});

export default DropdownButton;
