import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import AddressDisplayList from 'components/atoms/AddressDisplayList';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import CompanyInput from 'components/molecules/Form/CompanyInput';

import { ICustomer } from 'entities/customer';

interface CustomerFormProps {
    customer: Partial<ICustomer> | null;
    onChange: (update: Partial<ICustomer>) => void;

    error?: string;
    disabledCompanyAccount?: boolean;
    disableInputs?: boolean;
}

const CustomerForm = (props: CustomerFormProps): JSX.Element => {
    const { customer, onChange, error, disabledCompanyAccount, disableInputs } = props;

    return (
        <Container>
            <LeftContainer>
                <Text variant={TextVariant.h3}>
                    Customer Details
                </Text>

                <InputColumn>
                    <Input
                        error={!!error && !customer?.contactPerson}
                        value={customer?.contactPerson}
                        onChange={(e) => onChange({ contactPerson: e.target.value })}
                        header='Customer name'
                        required
                        disabled={disableInputs}
                    />
                    <Input
                        error={!!error && !customer?.companyName}
                        value={customer?.companyName}
                        onChange={(e) => onChange({ companyName: e.target.value })}
                        header='Company'
                        required
                        disabled={disableInputs}
                    />

                    <AddressDisplayList
                        error={error}
                        required
                        label='Shipping Addresses'
                        addresses={customer?.address}
                        onChange={(address) => onChange({ address })}
                        editable={!disableInputs}
                    />
                </InputColumn>
            </LeftContainer>

            <RightContainer>
                <Text variant={TextVariant.h3}>
                    Contact Details
                </Text>

                <InputColumn>
                    <Input
                        // error={!!error && !customer?.contact}
                        value={customer?.contact}
                        onChange={(e) => onChange({ contact: e.target.value })}
                        header='Contact'
                        // required
                        disabled={disableInputs}
                    />
                    <Input
                        error={!!error && !customer?.email}
                        value={customer?.email}
                        onChange={(e) => onChange({ email: e.target.value })}
                        header='Email'
                        required
                        disabled={disableInputs}
                    />
                    {!disabledCompanyAccount && (
                        <div style={{ flex: 1 }}>
                            <CompanyInput
                                mandatory
                                error={!!error && !customer?.companyAccountId}
                                value={customer?.companyAccountId}
                                onCompanyChanged={(companyId) => onChange({ companyAccountId: companyId })}
                            />
                        </div>
                    )}

                </InputColumn>
            </RightContainer>
        </Container>
    );
};

const Container = styled.div`
    border-radius: 15px;
    background-color: white;

    display: flex;
    flex-direction: row;

    width: 100%;

    padding: 32px;
    gap: 20px;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;

    flex: 1;
`;

const InputColumn = styled.div`
    margin-top: 28px;

    display: flex;
    flex-direction: column;

    gap: 20px;
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;

    flex: 1;
`;

export default CustomerForm;
