import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import SupplierGateway from 'api/Supplier';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetSupplierAddress(api: SupplierGateway): SagaWatcherReturnType {
    yield takeEvery('ui/uiGetSupplierAddressAttempt', handleGetSupplierAddress, api);
}

function* handleGetSupplierAddress(api: SupplierGateway, data: PayloadAction<{ supplierId: string}>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getSupplierDetails], {
        authToken,
        supplierId: data.payload.supplierId,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.uiGetSupplierAddressFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.uiGetSupplierAddressSuccess(response.data.supplierAddress));
    }
}
