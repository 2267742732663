import React from 'react';

import styled from 'styled-components';

import theme from 'styles/theme';

import { BadgeVariant } from './variants/BadgeVariant';

type StatusBadgeProps = {
    variant?: BadgeVariant;
}

const StatusBadge: React.FC<StatusBadgeProps> = (props: StatusBadgeProps) => {
    const { variant = BadgeVariant.Pending } = props;

    const capitalSpacing = variant?.replace(/([A-Z])/g, ' $1').toUpperCase() as BadgeVariant;

    return (
        <StyledStatusBadge
            variant={variant}
        >
            {capitalSpacing}
        </StyledStatusBadge>
    );
};

const StyledStatusBadge = styled.div<{ variant?: BadgeVariant }>`
    font-family: ${theme.fonts.primary};
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.5px;
    height: 20px;
    width: fit-content;
    border-radius: 100px;
    padding: 2px 10px;
    display: table-cell;


    ${props => props.variant === BadgeVariant.Pending && `
        background-color: #4169E1;
        color: #E7F4EE;
    `}

    ${props => props.variant === BadgeVariant.Delivered && `
        background-color: #108510;
        color: #E7F4EE;
    `}

    ${props => props.variant === BadgeVariant.Resolved && `
        background-color: #E7F4EE;
        color: ${theme.fontColor.success};
    `}

    ${props => props.variant === BadgeVariant.SentToProduction && `
        background-color: #858796;
        color: #E7F4EE;
    `}
    ${props => props.variant === BadgeVariant.InProduction && `
        background-color: #858796;
        color: #E7F4EE;
    `}
    ${props => props.variant === BadgeVariant.Sampling && `
        background-color: #FAB733;
        color: #E7F4EE;
    `}

    ${props => props.variant === BadgeVariant.Cancelled && `
        background-color: #DC143C;
        color: #E7F4EE;
    `}

    ${props => props.variant === BadgeVariant.Converted && `
        background-color: black;
        color: white;
    `}

    ${props => props.variant === BadgeVariant.Deleted && `
        background-color: #FEEDEC;
        color: ${theme.fontColor.error};
    `}

    ${props => props.variant === BadgeVariant.DefaultAddress && `
        background-color: #D7E2F3;
        color: ${theme.colors.info};
    `}

    ${props => props.variant === BadgeVariant.Shipped && `
        background-color: #8A2BE2;
        color: #E7F4EE;
    `}

    ${props => props.variant === BadgeVariant.Unpaid && `
        background-color: #ee7269;
        color: #E7F4EE;
    `}

    ${props => props.variant === BadgeVariant.Paid && `
        background-color: #5cb68c;
        color: #E7F4EE;
    `}

    ${props => props.variant === BadgeVariant.PartiallyPaid && `
        background-color: #f9e79f;
    `}

    ${props => props.variant === BadgeVariant.Packing && `
        background-color: #3bb0c2;
        color: #E7F4EE;
    `}

    ${props => props.variant === BadgeVariant.POReceived && `
        background-color: #9c30bd;
        color: #E7F4EE;
    `}
`;

export default StatusBadge;
