import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetTimeline(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderGetTimelineAttempt', handleGetTimeline, api);
}
function* handleGetTimeline(api: OrderGateway, data: PayloadAction<{ id: string }>) {
    const authToken = yield* select((state) => state.auth.authToken);

    const response = yield* call([api, api.getTimeline], {
        authToken,
        orderId: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderGetTimelineFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderGetTimelineSuccess(response.data));
    }
}
