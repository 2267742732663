import React from 'react';

import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

interface DiscardSaveButtonsProps {
    onDiscard: () => void;
    onSave: () => void;

    loading?: boolean;
    error?: string;

    discardLabel?: string;
    saveLabel?: string;
}

const DiscardSaveButtons = (props: DiscardSaveButtonsProps): JSX.Element => {
    const {
        onDiscard,
        onSave,
        saveLabel = 'Save',
        discardLabel = 'Discard',
        loading,
        error,
    } = props;

    return (
        <>
            {error && (
                <ErrorContainer>
                    <Text variant={TextVariant.error}>{error}</Text>
                </ErrorContainer>
            )}

            <ButtonRow>
                <ButtonContainer>
                    <Button
                        label={discardLabel}
                        variant={ButtonVariant.primary}
                        onClick={onDiscard}
                        disabled={loading}
                    />
                </ButtonContainer>

                <ButtonContainer>
                    <Button
                        label={saveLabel}
                        onClick={onSave}
                        loading={loading}
                    />
                </ButtonContainer>
            </ButtonRow>
        </>
    );
};

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 20px;
`;

const ErrorContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    margin-bottom: 18px;
`;

const ButtonContainer = styled.div`
    max-width: 175px;
`;

export default DiscardSaveButtons;
