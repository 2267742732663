import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import ErrorMessage from 'components/atoms/ErrorMessage';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import PageHeader from 'components/atoms/PageHeader';
import StatusBadge from 'components/atoms/StatusBadge';
import { BadgeVariant } from 'components/atoms/variants/BadgeVariant';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import ProductViewer from 'components/molecules/ProductViewer';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import QuotationSelectors from 'redux/slices/quotations/Selectors';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { AuthRoleEnum } from 'entities/auth';
import { ICompanyDetails } from 'entities/company';
import { ICustomer } from 'entities/customer';
import { IProductDetails, TaxRateEnum } from 'entities/products';
import { QuotationStatusEnum } from 'entities/quotations';
import { CurrencyEnum } from 'entities/supplier';

import QuotationDetails from './components/QuotationDetails';

const ViewQuotationDetails = (): JSX.Element => {
    const productTableHeaders = [
        { header: 'Name' },
        { header: 'Quantity' },
        { header: 'Price Per Unit' },
        { header: 'Price After Discount' },
        { header: 'Discount Total' },
        // { header: 'Tax' },
        { header: 'Total' },
    ];

    const params = useParams();
    const dispatch = useAppDispatch();

    const {
        formatVariantLabel,
        formatSizeLabel,
        formatPrintMethodLabel,
    } = Utils.Formatter;

    const quotationId = params.id;
    const userInfo = useAppSelector(AuthSelectors.getUserInfo);
    const getQuoteLoading = useAppSelector(QuotationSelectors.getQuotationDetailsAttempting);
    const getQuoteError = useAppSelector(QuotationSelectors.getQuotationDetailsError);
    const quotationDetails = useAppSelector(QuotationSelectors.getQuotationDetails);

    const exportQuotationLoading = useAppSelector(QuotationSelectors.exportQuotationAttempting);
    const exportQuotationError = useAppSelector(QuotationSelectors.exportQuotationError);

    const [taxPercent, setTaxPercent] = useState<number>(0);

    useEffect(() => {
        if (quotationId) {
            dispatch(Actions.quoteGetQuotationDetailsAttempt({ id: quotationId }));
            dispatch(Actions.productsGetPrintMethodAttempt({ index: -1 }));
        } else {
            NavActions.navToQuotation();
        }

        return () => {
            dispatch(Actions.quoteResetQuotationDetails());
        };
    }, [quotationId]);

    useEffect(() => {
        if (quotationDetails) {
            dispatch(Actions.companyGetCompanyDetailsAttempt({ id: quotationDetails.companyAccountId }));
            if (quotationDetails?.taxType) {
                if (quotationDetails.taxType === TaxRateEnum.GstTaxRate) {
                    dispatch(Actions.productSetIsTaxApplied(true));
                } else {
                    dispatch(Actions.productSetIsTaxApplied(false));
                }
            }
        }
    }, [quotationDetails]);

    useEffect(() => {
        const taxPrice = quotationDetails?.tax ?? 0;
        const totalPrice = quotationDetails?.finalPrice ?? 0;

        if (taxPrice === 0) {
            setTaxPercent(0);
            return;
        }

        if (totalPrice === 0) {
            setTaxPercent(0);
            return;
        }
        const priceBeforeTax = totalPrice - taxPrice;

        setTaxPercent((taxPrice / priceBeforeTax) * 100);
    }, [quotationDetails?.tax]);

    const companyDetails: ICompanyDetails = {
        id: quotationDetails?.companyAccountId ?? '',
        name: quotationDetails?.companyName ?? '',
        alias: quotationDetails?.companyAlias ?? '',
        address: {
            street1: quotationDetails?.companyAddress.street1 ?? '',
            street2: quotationDetails?.companyAddress.street2,
            city: quotationDetails?.companyAddress.city ?? '',
            postcode: quotationDetails?.companyAddress.postcode ?? '',
            state: quotationDetails?.companyAddress.state ?? '',
            country: quotationDetails?.companyAddress.country ?? '',
        },
        phoneNumber: quotationDetails?.companyNumber ?? '',
        createdAt: dayjs(quotationDetails?.createdAt).toISOString(),
    };

    const customerDetails: Partial<ICustomer> = {
        id: quotationDetails?.clientId,
        contactPerson: quotationDetails?.clientName,
        companyName: quotationDetails?.clientCompanyName ?? 'Client Company Name Here',
        address: [{
            street1: quotationDetails?.shippingAddress.street1 ?? '',
            street2: quotationDetails?.shippingAddress.street2,
            city: quotationDetails?.shippingAddress.city ?? '',
            postcode: quotationDetails?.shippingAddress.postcode ?? '',
            state: quotationDetails?.shippingAddress.state ?? '',
            country: quotationDetails?.shippingAddress.country ?? '',
        }],
        contact: quotationDetails?.clientContact ?? 'Client Contact Here',
        email: quotationDetails?.clientEmail ?? 'Client Email Here',
    };

    const NewSelectedProducts = (): IProductDetails[] => {
        if (!quotationDetails?.products) return [];
        const selectedProducts = quotationDetails?.products.map(product => {
            let discountAmount = 0;
            if (product.discountPercent) {
                discountAmount = (product.discountPercent / 100) * (Number(product.totalPricePerUnit) * product.finalQuantity);
            }
            if (product.discountFixed) discountAmount = product.discountFixed;
            return {
                productId: product.productId,
                variantId: product.variantId,
                name: product.productName ?? 'Product Name Here',
                variant: {
                    type: product.productVariant ? product.productVariant.type : '',
                    fit: product.productVariant ? product.productVariant.fit : '',
                    sleeve: product.productVariant ? product.productVariant.sleeve : '',
                    style: product.productVariant ? product.productVariant.style : '',
                    color: product.productVariant ? product.productVariant.color : '',
                },
                variantText: formatVariantLabel(product.productVariant ? product.productVariant : null),
                sizeText: formatSizeLabel(product.quantities),
                printMethodText: product.printMethods.map((method) => {
                    return formatPrintMethodLabel({
                        side: method.side,
                        printMethod: method.printMethod ?? '',
                        block: method.block ?? '',
                        colorCount: method.colorCount ?? '',
                    });
                }),
                totalQuantity: product.finalQuantity,
                quantities: product.quantities.map(item => ({
                    id: item.productPriceId,
                    size: item.size,
                    minimumQuantity: item.quantity,
                    quantity: item.quantity,
                    pricePerUnit: item.pricePerUnit,
                })),
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                discountAmountApplied: discountAmount,
                printMethods: product.printMethods,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                disableQuantityEdit: product.quantities.length > 0,
                // weight: product.weight,
                remark: product.remark,
            };
        });

        const customLineProducts = quotationDetails?.customLineProducts.map(product => {
            let discountAmount = 0;
            if (product.discountPercent) {
                discountAmount = (product.discountPercent / 100) * (Number(product.totalPricePerUnit) * product.finalQuantity);
            }
            if (product.discountFixed) discountAmount = product.discountFixed;
            return {
                productId: '',
                variantId: '',
                name: product.productName ?? 'Product Name Here',
                totalQuantity: product.finalQuantity,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                discountAmountApplied: discountAmount,
                disableQuantityEdit: true,
                weight: product.weight,
                remark: product.remark,
            };
        });

        const combinedSelectedProduct = [...selectedProducts, ...customLineProducts];
        return combinedSelectedProduct;
    };

    const quoteStatusVariant = (quoteStatus: QuotationStatusEnum) => {
        if (quoteStatus === QuotationStatusEnum.Pending) {
            return BadgeVariant.Pending;
        }
        if (quoteStatus === QuotationStatusEnum.Converted) {
            return BadgeVariant.Converted;
        }
        return BadgeVariant.Pending;
    };

    const onConvert = () => {
        NavActions.navToAddNewOrder();
    };
    const onDownload = () => {
        dispatch(Actions.quoteExportQuotationAttempt({ id: quotationId as string }));
    };

    const renderPageHeader = () => {
        if (getQuoteLoading || !quotationDetails) {
            return (
                <PageTitle>
                    Quotation
                </PageTitle>
            );
        }

        return (
            <PageTitle>
                {`Quotation ${quotationDetails?.quotationNumber}`}
                <span>
                    <StatusBadge variant={quoteStatusVariant(quotationDetails?.quotationStatus ?? QuotationStatusEnum.Pending)} />
                </span>
            </PageTitle>
        );
    };

    const renderBody = () => {
        if (getQuoteLoading) {
            return (
                <AdminLayout style={{ minHeight: '70vh', justifyContent: 'center' }}>
                    <LoadingIndicator />
                </AdminLayout>
            );
        }

        if (getQuoteError) {
            return (
                <ErrorContainer>
                    <ErrorMessage error='Sorry, we could not find this quotations. It might have been deleted/moved.' />

                    <Button
                        label='Back to all quotations'
                        onClick={() => NavActions.navToMenu('/quotations')}
                        style={{ width: 250 }}
                    />
                </ErrorContainer>
            );
        }

        return (
            <>
                <QuotationDetails
                    companyDetails={companyDetails}
                    customerDetails={customerDetails}
                    quoteNo={quotationDetails?.quotationNumber ?? ''}
                    startDate={quotationDetails?.quotationDate ?? dayjs().toISOString()}
                    endDate={quotationDetails?.updatedAt ?? dayjs().toISOString()}
                    preparedBy={quotationDetails?.personInCharge ?? ''}
                    createdAt={quotationDetails?.createdAt ?? dayjs().toISOString()}
                />
                <BottomContainer>
                    <ProductViewer
                        tableHeaders={productTableHeaders}
                        currency={quotationDetails?.currency ?? CurrencyEnum.MalaysianRinggit}
                        viewData={{
                            selectedProducts: NewSelectedProducts(),
                            discountPercent: quotationDetails?.discountPercent?.toString(),
                            discountFixed: quotationDetails?.discountFixed?.toFixed(2),
                            shippingCost: quotationDetails?.shippingFee.toFixed(2) ?? '0.00',
                            taxPrice: quotationDetails?.tax.toFixed(2) ?? '0.00',
                        }}
                        taxPercent={Number(taxPercent.toFixed(0))}
                    />
                </BottomContainer>
            </>
        );
    };
    const renderActions = () => {
        if (!quotationDetails) return null;
        if (userInfo?.role === AuthRoleEnum.Executive) return null;
        if (quotationDetails?.quotationStatus === QuotationStatusEnum.Converted) return null;
        return (
            <Button
                variant={ButtonVariant.primary}
                label='Convert To Order'
                onClick={onConvert}
            />
        );
    };
    return (
        <AdminLayout>
            <HeaderContainer status={quotationDetails?.quotationStatus ?? QuotationStatusEnum.Pending}>
                <PageHeader withBackButton>
                    {renderPageHeader()}
                </PageHeader>
                <div id='actions'>
                    {renderActions()}
                    <Button
                        variant={ButtonVariant.secondary}
                        label='Download'
                        onClick={onDownload}
                        loading={exportQuotationLoading}
                    />
                </div>
            </HeaderContainer>
            {renderBody()}
        </AdminLayout>
    );
};

const HeaderContainer = styled.div<{ status: QuotationStatusEnum }>`
    display: flex;
    justify-content: space-between;
    align-items: center;

    #actions {
        width: ${props => (props.status === QuotationStatusEnum.Pending ? '30%' : '30%')};
        margin-top: 2rem;
        padding-inline: 1rem;
        display: flex;
        gap: 1rem;
    }
`;

const PageTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const BottomContainer = styled.div`
    display: flex;
    width: 100%;

    padding: 1.5rem 2rem;

    border-radius: 15px;

    background-color: white;
`;

const ErrorContainer = styled.div`
    height: 400px;
    width: 100%;

    padding: 32px;

    background-color: white;
    border-radius: 15px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    text-align: center;
`;

export default ViewQuotationDetails;
