/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Toggle from 'components/atoms/Toggle';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import ProductSelectors from 'redux/slices/products/Selectors';

import Utils from 'lib/Utils';
import { EDiscountType, ShippingVendorEnum } from 'entities/products';
import { QuotationShippingMethodEnum } from 'entities/quotations';
import { CurrencyEnum } from 'entities/supplier';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import theme from 'styles/theme';

import AddCustomItemModal from './AddCustomItemModal';
import AddDiscountModal from './AddDiscountModal';
import AddItemModal from './AddItemModal';
import AddShippingCostModal from './AddShippingCostModal';

interface IProductSelectorProps {
    tableHeaders: { header: string }[];
    currency: CurrencyEnum;
    isCompanyAccountSelected?: boolean;
    error?: string;
    taxPercent?: number;
    shippingMethod?: QuotationShippingMethodEnum;
    existingShippingCost?: number;
    disableDiscount?: boolean;
    disableShippingFee?: boolean;
    disableTax?: boolean;
    isEdit?: boolean;
}

const ProductSelector: React.FC<IProductSelectorProps> = (props: IProductSelectorProps) => {
    const {
        tableHeaders,
        currency,
        isCompanyAccountSelected,
        error,
        taxPercent,
        shippingMethod,
        existingShippingCost,
        disableDiscount,
        disableShippingFee,
        disableTax,
        isEdit,
    } = props;

    const { formatPrice, formatCurrency } = Utils.Formatter;
    const dispatch = useAppDispatch();

    const products = useAppSelector(ProductSelectors.getProducts);
    const selectedProducts = useAppSelector(ProductSelectors.getSelectedProducts);
    const discountPercent = useAppSelector(ProductSelectors.getDiscountPercent);
    const discountFixed = useAppSelector(ProductSelectors.getDiscountFixed);
    const discountPrice = useAppSelector(ProductSelectors.getDiscountPrice);
    const shippingCost = useAppSelector(ProductSelectors.getShippingPrice);
    const taxPrice = useAppSelector(ProductSelectors.getTaxPrice);
    const isShippingCostEdited = useAppSelector(ProductSelectors.getIsShippingCostEdited);
    const isTaxApplied = useAppSelector(ProductSelectors.getIsTaxApplied);

    const [addItemModalIsOpen, setAddItemModalIsOpen] = useState<boolean>(false);
    const [addCustomItemModalIsOpen, setAddCustomItemModalIsOpen] = useState<boolean>(false);
    const [addDiscountModalIsOpen, setAddDiscountModalIsOpen] = useState<boolean>(false);
    const [addShippingModalIsOpen, setAddShippingModalIsOpen] = useState<boolean>(false);

    const [editItem, setEditItem] = useState<boolean>(false);
    const [editedItemIndex, setEditedItemIndex] = useState<number>(-1);

    const [discountType, setDiscountType] = useState<string>(EDiscountType.PERCENTAGE);
    const [discountValue, setDiscountValue] = useState<string>('');

    useEffect(() => {
        dispatch(Actions.productsGetProductsAttempt({ index: -1 }));

        return () => {
            dispatch(Actions.productResetProductSelector());
            setDiscountValue('');
        };
    }, []);

    // useEffect(() => {
    //     if (clearProducts) {
    //         dispatch(Actions.productResetProductSelector());
    //     }
    // }, [clearProducts]);

    useEffect(() => {
        if (!addItemModalIsOpen && !addCustomItemModalIsOpen) {
            setEditItem(false);
            setEditedItemIndex(-1);
        }
    }, [addItemModalIsOpen, addCustomItemModalIsOpen]);

    useEffect(() => {
        if (!addDiscountModalIsOpen) {
            setDiscountType(EDiscountType.PERCENTAGE);
            setDiscountValue('');
        }
    }, [addDiscountModalIsOpen]);

    useEffect(() => {
        if (discountPercent && !discountFixed) {
            dispatch(Actions.productSetDiscountPrice((calculateSubTotal() * (Number(discountPercent) / 100)).toFixed(2)));
        }
        if (discountFixed && !discountPercent) {
            dispatch(Actions.productSetDiscountPrice(discountFixed));
        }
        if (disableDiscount) {
            dispatch(Actions.productSetDiscountPrice('0.00'));
        }
        dispatch(Actions.productReplaceSelectedProducts(selectedProducts || []));
    }, [selectedProducts, discountFixed, discountPercent]);

    useEffect(() => {
        if (taxPercent && !disableTax) {
            dispatch(Actions.productSetTaxPrice((calculateTotal() * (taxPercent / 100)).toFixed(2)));
        } else {
            dispatch(Actions.productSetTaxPrice('0.00'));
        }
    }, [selectedProducts, discountPrice, shippingCost, taxPercent]);

    useEffect(() => {
        if (existingShippingCost) {
            dispatch(Actions.productSetShippingCost(existingShippingCost.toString()));
        }
    }, [existingShippingCost]);

    useEffect(() => {
        if (selectedProducts.length > 0
            && !isEdit
            && !isShippingCostEdited
        ) {
            const totalWeight = selectedProducts.reduce((total, product) => {
                if (product && product.weight !== undefined) {
                    return total + product.weight;
                }
                return total;
            }, 0);

            if (disableShippingFee) {
                dispatch(Actions.productSetTotalWeight(totalWeight));
                return;
            }
            if (shippingMethod === QuotationShippingMethodEnum.InternationalShipping) {
                dispatch(Actions.productsGetShippingCostAttempt({
                    country: shippingMethod ?? QuotationShippingMethodEnum.InternationalShipping,
                    vendor: ShippingVendorEnum.FedEx,
                    weight: totalWeight,
                    currency,
                }));
                dispatch(Actions.productSetTotalWeight(totalWeight));
                return;
            }
            dispatch(Actions.productsGetShippingCostAttempt({
                country: shippingMethod ?? QuotationShippingMethodEnum.EastMalaysiaShipping,
                vendor: ShippingVendorEnum.Ninjavan,
                weight: totalWeight,
                currency,
            }));
            dispatch(Actions.productSetTotalWeight(totalWeight));
        }
    }, [selectedProducts, shippingMethod]);

    const onAddDiscount = () => {
        if (discountType === EDiscountType.PERCENTAGE) {
            dispatch(Actions.productSetDiscountPercent(discountValue));
            dispatch(Actions.productSetDiscountFixed(''));
        } else {
            dispatch(Actions.productSetDiscountFixed(discountValue));
            dispatch(Actions.productSetDiscountPercent(''));
        }
        setAddDiscountModalIsOpen(false);

        toast.success('Discount added');
    };
    const onDiscountValueChange = (value: string) => {
        const newDiscount = value.replace(/[^0-9.]/g, '');

        if (newDiscount === '') {
            setDiscountValue('');
            return;
        }

        if (discountType === EDiscountType.PERCENTAGE) {
            if (!(Number.isNaN(newDiscount)) && Number(newDiscount) <= 100) {
                setDiscountValue(newDiscount);
                return;
            }

            toast.error('Discount value cannot be greater than 100%');
            return;
        }

        setDiscountValue(newDiscount);
    };
    const onAddShippingCost = () => {
        dispatch(Actions.productSetShippingCost(Number(shippingCost).toFixed(2)));
        setAddShippingModalIsOpen(false);

        toast.success('Shipping cost added');
    };
    const updateQuantity = (index: number, quantity: string) => {
        const newQuantity = quantity.replace(/[^0-9\b.]/g, '');

        const updatedItem = {
            ...selectedProducts[index],
            totalQuantity: Number(newQuantity),
            total: Number(newQuantity) * Number(selectedProducts[index].price),
        };

        const newSelectedProducts = selectedProducts.map((item, i) => {
            if (i === index) {
                return updatedItem;
            }
            return item;
        });
        dispatch(Actions.productReplaceSelectedProducts(newSelectedProducts));
    };
    const updatePrice = (index: number, price: string) => {
        const newPrice = price.replace(/[^0-9\b.-]/g, '');

        let total = Number(newPrice) * Number(selectedProducts[index].totalQuantity);
        if (selectedProducts[index].discountPercent) {
            total -= (total * (Number(selectedProducts[index].discountPercent) / 100));
        }
        if (selectedProducts[index].discountFixed) {
            total -= Number(selectedProducts[index].discountFixed);
        }
        const updatedItem = {
            ...selectedProducts[index],
            price: newPrice,
            total,
        };

        const newSelectedProducts = selectedProducts.map((item, i) => {
            if (i === index) {
                return updatedItem;
            }
            return item;
        });

        dispatch(Actions.productReplaceSelectedProducts(newSelectedProducts));
    };
    const onEditItems = (index: number) => {
        const editedProduct = selectedProducts[index];
        setEditItem(true);
        setEditedItemIndex(index);

        if (editedProduct.productId) {
            setAddItemModalIsOpen(true);
        } else {
            setAddCustomItemModalIsOpen(true);
        }
    };
    const onShippingValueChange = (value: string) => {
        const newShipping = value?.replace(/[^0-9\b.]/g, '');
        dispatch(Actions.productSetShippingCost(newShipping));
        dispatch(Actions.productSetIsShippingCostEdited(true));
    };
    const onRemoveSelectedProduct = (name: string, index: number) => {
        toast.success(`${name} removed`);
        dispatch(Actions.productRemoveSelectedProducts({ index }));
    };

    const calculateSubTotal = () => {
        let subTotal = 0;
        selectedProducts.forEach((product) => {
            const finalTotal = calculateDiscountedPrice(product.total, product.totalQuantity) * product.totalQuantity;

            subTotal += finalTotal;
        });
        return subTotal;
    };

    const calculateDiscountedPrice = (total: number, quantity: number) => {
        const price = total / quantity;

        return Number(price.toFixed(2));
    };

    const calculateTotal = () => {
        const allTotal = calculateSubTotal() - Number(discountPrice) + Number(shippingCost);
        return allTotal;
    };
    const calculateTotalWithTax = () => {
        if (!taxPercent) return calculateTotal();
        const tax = calculateTotal() * (Number(taxPercent) / 100);
        return calculateTotal() + tax;
    };
    // ? handler
    const onAddItem = () => {
        if (isCompanyAccountSelected) {
            setAddItemModalIsOpen(true);
        } else {
            toast.error('Please select a company account first');
        }
    };
    const onAddCustomItem = () => {
        if (isCompanyAccountSelected) {
            setAddCustomItemModalIsOpen(true);
        } else {
            toast.error('Please select a company account first');
        }
    };
    // ? render zone
    const renderInputs = (
        type: string,
        value: string,
        onChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void,
        disabled?: boolean,
    ) => {
        return (
            <InputContainer
                value={value}
                disabled={disabled ?? false}
                onChange={onChange}
            />
        );
    };
    const renderProducts = () => {
        if (selectedProducts.length === 0) return null;
        return selectedProducts.map((product, index) => {
            const renderPrintMethod = () => {
                if (!product.printMethodText) return null;
                return product.printMethodText.map((text) => {
                    return (
                        <React.Fragment key={text}>
                            {text}
                            <br />
                        </React.Fragment>
                    );
                });
            };

            const renderWeight = () => {
                if (!product.weight) return null;

                return (
                    <>
                        Total weight:
                        {' '}
                        {product.weight.toFixed(2)}
                        {' '}
                        kg
                    </>
                );
            };

            const renderRemark = () => {
                if (!product.remark) return null;

                return (
                    <>
                        <hr />
                        {product.remark}
                    </>
                );
            };

            const renderProduct = () => {
                return (
                    <ProductNameContainer>
                        <Text variant={TextVariant.h3} id='edit'>
                            {product.name}
                            <EditIcon
                                color={theme.colors.info}
                                style={{ cursor: 'pointer' }}
                                onClick={() => onEditItems(index)}
                            />
                        </Text>
                        <Text variant={TextVariant.h4}>
                            {product.variantText}
                            {product.variantText && <br />}
                            {product.sizeText}
                            {product.sizeText && <br />}
                            {renderPrintMethod()}
                            {renderWeight()}
                            {renderRemark()}
                        </Text>
                    </ProductNameContainer>
                );
            };

            const renderPriceAfterDiscount = () => {
                if (product.discountPercent !== null || product.discountFixed !== null) {
                    if (calculateDiscountedPrice(product.total, product.totalQuantity) !== Number(product.price)) {
                        return (
                            <td>
                                {`${formatCurrency(currency)}`}
                                {calculateDiscountedPrice(product.total, product.totalQuantity).toFixed(2)}
                            </td>
                        );
                    }
                }

                return <td>-</td>;
            };

            const renderProductTotal = () => {
                const finalTotal = calculateDiscountedPrice(product.total, product.totalQuantity) * product.totalQuantity;

                return Number(finalTotal.toFixed(2));
            };

            const getDiscountTotal = () => {
                const price = (Number(product.price) * product.totalQuantity) - renderProductTotal();

                if (product.discountPercent !== null || product.discountFixed !== null) {
                    if (Number(price.toFixed(2)) > 0) {
                        return (
                            <td id='input' aria-label='input'>
                                {`${formatCurrency(currency)}`}
                                {price.toFixed(2)}
                            </td>
                        );
                    }
                }

                return <td id='input' aria-label='input'> - </td>;
            };

            return (
                <tr key={product.name}>
                    <td style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    >
                        {renderProduct()}
                    </td>
                    <td id='input' aria-label='input'>
                        {renderInputs(
                            'quantity',
                            product.totalQuantity.toString(),
                            (e) => updateQuantity(index, e.target.value),
                            product.disableQuantityEdit,
                        )}
                    </td>
                    <td id='input' aria-label='input'>
                        {renderInputs(
                            'price',
                            product.price,
                            (e) => updatePrice(index, e.target.value),
                        )}
                    </td>
                    {renderPriceAfterDiscount()}
                    {getDiscountTotal()}
                    {/* {isOrder ? null : <td>{product.tax ?? '-'}</td>} */}
                    <td id='bold'>{`${formatCurrency(currency)}${product.total ? formatPrice(renderProductTotal()) : '-'}`}</td>
                    <td id='input' aria-label='spacing'>
                        <DeleteIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => onRemoveSelectedProduct(product.name, index)}
                        />
                    </td>
                </tr>
            );
        });
    };
    const renderDiscount = () => {
        // if (selectedProducts.length > 0) {
        //     let totalDiscount = 0;
        //     selectedProducts.forEach((product) => {
        //         let discountText = 0;
        //         if (product.discountPercent) {
        //             const discount = (product.discountPercent / 100) * (Number(product.price) * product.totalQuantity);
        //             discountText = discount;
        //         }
        //         if (product.discountFixed) discountText = product.discountFixed;

        //         totalDiscount += Number(discountText);
        //     });
        //     return (
        //         <>
        //             {`-${formatCurrency(currency)}${formatPrice(Number(totalDiscount))}`}
        //             {disableDiscount ? null : (
        //                 <EditIcon
        //                     style={{ cursor: 'pointer' }}
        //                     color={theme.colors.info}
        //                     onClick={() => setAddDiscountModalIsOpen(true)}
        //                 />
        //             )}
        //         </>
        //     );
        // }
        return (
            <>
                {`-${formatCurrency(currency)}${formatPrice(Number(discountPrice))}`}
                {disableDiscount ? null : (
                    <EditIcon
                        style={{ cursor: 'pointer' }}
                        color={theme.colors.info}
                        onClick={() => setAddDiscountModalIsOpen(true)}
                    />
                )}
            </>
        );
    };
    const renderTax = () => {
        if (!taxPrice || !isTaxApplied) return `${formatCurrency(currency)}0.00`;
        return `${formatCurrency(currency)}${formatPrice(Number(taxPrice))}`;
    };
    const renderShipping = () => {
        return (
            <>
                {`${formatCurrency(currency)}${formatPrice(Number(shippingCost))}`}
                {disableShippingFee ? null : (
                    <EditIcon
                        color={theme.colors.info}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setAddShippingModalIsOpen(true)}
                    />
                )}
            </>
        );
    };
    const renderDiscountAndCharge = () => {
        let discountText = '';
        if (discountPercent) discountText = `(${discountPercent}%)`;

        return (
            <tr>
                <td colSpan={3} aria-label='spacing' />
                <td>
                    <div id='custom'>
                        <Text>Subtotal</Text>
                        <Text style={{ whiteSpace: 'nowrap' }}>
                            {`Discount ${discountText}`}
                        </Text>
                        <Text>Shipping</Text>

                    </div>
                </td>
                <td colSpan={2}>
                    <div id='custom'>
                        <Text>{`${formatCurrency(currency)}${formatPrice(calculateSubTotal())}`}</Text>
                        <Text id='edit'>
                            {renderDiscount()}
                        </Text>
                        <Text id='edit'>
                            {renderShipping()}
                        </Text>
                    </div>
                </td>
            </tr>
        );
    };
    const renderTotal = () => {
        let taxValue = '';
        if (taxPercent) taxValue = `(${taxPercent}%)`;

        return (
            <tr id='total'>
                <td colSpan={3} aria-label='spacing' />
                <td>
                    <div id='custom'>
                        <Text>
                            Subtotal
                        </Text>
                        <Text style={{ whiteSpace: 'nowrap' }}>
                            {`GST ${(disableTax || !isTaxApplied) ? '' : taxValue}`}
                        </Text>
                        <Text id='bold'>
                            Total
                        </Text>
                    </div>
                </td>
                <td>
                    <div id='custom'>
                        <Text>
                            {`${formatCurrency(currency)}${formatPrice(calculateTotal())}`}
                        </Text>
                        <Text>
                            {renderTax()}
                        </Text>
                        <Text id='bold'>
                            {`${formatCurrency(currency)}${formatPrice(isTaxApplied ? calculateTotalWithTax() : calculateTotal())}`}
                        </Text>
                    </div>
                </td>
                {/* {isViewOnly ? null : <td aria-label='spacing' />} */}
            </tr>
        );
    };

    const renderActionButtons = () => {
        return (
            <tr id='button'>
                <td colSpan={2}>
                    <ButtonContainer>
                        <Button
                            label='Add Item'
                            variant={ButtonVariant.primary}
                            onClick={onAddItem}
                        />
                        <Button
                            label='Add Custom Line'
                            variant={ButtonVariant.primary}
                            onClick={onAddCustomItem}
                        />
                    </ButtonContainer>
                </td>
                {(disableTax || isEdit || (currency === CurrencyEnum.MalaysianRinggit)) ? null : (
                    <td colSpan={4}>
                        <ApplyTaxContainer>
                            <Text>
                                Apply GST
                            </Text>
                            <Toggle
                                isActive={isTaxApplied}
                                onClick={() => dispatch(Actions.productSetIsTaxApplied(!isTaxApplied))}
                            />
                        </ApplyTaxContainer>
                    </td>
                )}
            </tr>
        );
    };

    return (
        <>
            <Container>
                <Overlay isCompanyAccountSelected={isCompanyAccountSelected}>
                    <Text
                        style={{ color: 'white', letterSpacing: '0.5px', fontSize: '24px' }}
                    >
                        Please select a company account first.
                    </Text>
                </Overlay>
                <TableContainer>
                    <thead>
                        <tr>
                            {tableHeaders.map((item) => (
                                <th key={item.header} style={{ textAlign: 'center' }}>
                                    {item.header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {renderProducts()}
                        {renderDiscountAndCharge()}

                        {renderTotal()}
                        {renderActionButtons()}
                    </tbody>
                </TableContainer>
            </Container>
            <AddItemModal
                currency={currency}
                isOpen={addItemModalIsOpen}
                onCancel={() => setAddItemModalIsOpen(false)}
                productList={products.data}
                isEdit={editItem}
                editedProductIndex={editedItemIndex}
            />
            <AddCustomItemModal
                error={error}
                isOpen={addCustomItemModalIsOpen}
                onCancel={() => setAddCustomItemModalIsOpen(false)}
                isEdit={editItem}
                editedProductIndex={editedItemIndex}
            />
            <AddDiscountModal
                isOpen={addDiscountModalIsOpen}
                onCancel={() => setAddDiscountModalIsOpen(false)}
                discountValue={discountValue}
                onDiscountValueChange={onDiscountValueChange}
                discountType={discountType}
                onDiscountTypeChange={setDiscountType}
                onSubmit={onAddDiscount}
            />
            <AddShippingCostModal
                isOpen={addShippingModalIsOpen}
                onCancel={() => setAddShippingModalIsOpen(false)}
                shippingCost={shippingCost || ''}
                onShippingCostChange={onShippingValueChange}
                onSubmit={onAddShippingCost}
            />
        </>
    );
};

const Container = styled.div`
    display: flex;
    width: 100%;

    padding: 24px 30px;

    border-radius: 15px;

    background-color: white;
    position: relative;
`;

const Overlay = styled.div<{ isCompanyAccountSelected?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    background-color:  rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    display: ${props => (props.isCompanyAccountSelected ? 'none' : 'flex')};
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    color: white;
`;

const TableContainer = styled.table<{ isViewOnly?: boolean }>`
    width: 100%;
    height: 100%;
    border-radius: 15px;

    table-layout: fixed;
    border-collapse: collapse;

    margin: auto;

    background-color: white;

    th:first-of-type {
        width: 30%;
    }

    tr:not(#total, #button) {
        border-bottom: 1px solid ${props => props.theme.colors.border};
    }
    th {
        font-size: 18px;
        font-weight: 700;
        color: ${props => props.theme.fontColor.primary};
        padding: 1rem 2rem;

        @media (max-width: 1440px) {
            padding: 1rem;
        }
    }
    td {
        padding: 2rem;

        @media (max-width: 1440px) {
            padding: 1rem;
        }
    }

    #bold {
        font-size: 18px;
        font-weight: 700;

        @media (max-width: 1440px) {
            font-size: 16px;
        }
    }

    #input {
        text-align: ${props => (props.isViewOnly ? 'left' : 'center')};
        // padding-left: ${props => (props.isViewOnly ? '2rem' : '1rem')};
        // padding-right: 3rem;
    }

    #custom {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 80%;
    }

    #edit {
        align-items: center;
        gap: 0.5rem;
        white-space: break-spaces;
    }
`;

const ProductNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
`;

const InputContainer = styled(Input)`
    padding: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    appearance: none;
    text-align: center;

    &:disabled {
        cursor: not-allowed;
        background-color: ${props => props.theme.colors.border};
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
    width: 100%;
`;

const ApplyTaxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
`;

export default ProductSelector;
