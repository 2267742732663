import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import Paginator from 'components/atoms/Paginator';
import Table from 'components/atoms/Table';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import CompanySelectors from 'redux/slices/company/Selectors';
import ReportSelectors from 'redux/slices/report/Selectors';

import Utils from 'lib/Utils';
import { AuthRoleEnum } from 'entities/auth';
import { OrderPaymentTermEnum } from 'entities/order';
import { IReportOrderOverviewData } from 'entities/report';
import PaymentTermLov from 'lov/PaymentTermLov';

interface OrderListTableProps {

    orderDataList: IReportOrderOverviewData[];
}

const OrderListTable = (props: OrderListTableProps): JSX.Element => {
    const { orderDataList } = props;
    const dispatch = useAppDispatch();

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);
    const loading = useAppSelector(ReportSelectors.getReportDetailsAttempting);
    const error = useAppSelector(ReportSelectors.getReportDetailsError);

    const companyList = useAppSelector(CompanySelectors.getCompanyList);

    useEffect(() => {
        if (userInfo?.role === AuthRoleEnum.Executive) return;
        dispatch(Actions.companyGetCompanyListAttempt({
            index: 0,
        }));
    }, []);

    // const ordersLoading = useAppSelector(Selectors.getReportOrderListAttempting);
    // const ordersError = useAppSelector(Selectors.getReportOrderListError);

    // const ordersData = useAppSelector(Selectors.getReportOrderListData);

    // const { maxIndex, data: ordersList, totalRecords } = ordersData;

    // const { startDate, endDate, index, companyAccountId } = useAppSelector(Selectors.getReportFilters);

    // useEffect(() => {
    //     if (index && startDate && endDate) {
    //         dispatch(Actions.reportGetReportOrderListAttempt());
    //     }
    // }, [companyAccountId, index, startDate, endDate]);

    const orderListHeader = [
        { header: 'ID', field: 'id', hidden: true },
        { header: 'Order No', field: 'orderNo' },
        { header: 'Company Account', field: 'companyAccountId', width: 220 },
        { header: 'Project Name', field: 'projectName' },
        { header: 'Payment Term', field: 'paymentTerm' },
        { header: 'Quantity', field: 'quantity' },
        { header: 'Amount', field: 'amount' },
        { header: 'Fabric Print Cost', field: 'fabricPrintCost', width: 200 },
        { header: 'Logistic Cost', field: 'logisticCost', width: 200 },
    ];

    const renderCompanyAccount = (companyAccountId: string) => {
        return companyList.find((item) => item.id === companyAccountId)?.name || 'N/A';
    };
    const renderPaymentTerm = (paymentTerm: OrderPaymentTermEnum) => {
        return PaymentTermLov.find((item) => item.value === paymentTerm)?.label || 'N/A';
    };
    const orderListData = orderDataList.map((order) => ({
        id: order.id,
        cellData: {
            id: order.id,
            orderNo: order.orderNo,
            companyAccountId: renderCompanyAccount(order.companyAccountId),
            projectName: order.projectName,
            paymentTerm: renderPaymentTerm(order.paymentTerm),
            quantity: order.quantity,
            amount: `RM ${Utils.Formatter.formatPrice(order.amount)}`,
            fabricPrintCost: `RM ${Utils.Formatter.formatPrice(order.fabricPrintCost)}`,
            logisticCost: `RM ${Utils.Formatter.formatPrice(order.logisticCost)}`,
        },
    }));

    const onPaginatorPressed = (page: number) => {
        dispatch(Actions.reportSetFilters({ index: page }));
    };

    return (
        <>
            <Table
                headerArrays={orderListHeader}
                dataArray={orderListData}
                loading={loading}
                error={error}
                style={{ marginTop: 32, marginBottom: 32 }}
            />
            {/* <PaginationContainer>
                <Paginator
                    currentPage={index as number}
                    onPagePressed={onPaginatorPressed}
                    maxPages={maxIndex}
                    disabled={ordersLoading}
                />
            </PaginationContainer> */}
        </>
    );
};

const PaginationContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

export default OrderListTable;
