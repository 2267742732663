import { GetUserManagementApiResponse } from 'api/UserManagementBase';
import { RootState } from 'redux/store';

import { IUserManagementDetails } from 'entities/userManagement';

import { UserManagementReduxFilters } from './types';

const getUserManagementAttempting = (state: RootState): boolean => state.userManagement.actions.getUserManagement;
const getUserManagement = (state: RootState): GetUserManagementApiResponse => state.userManagement.userManagement;
const getUserManagementError = (state: RootState): string => state.userManagement.errors.getUserManagement;

const getUserManagementDetailsAttempting = (state: RootState): boolean => state.userManagement.actions.getUserManagementDetails;
const getUserManagementDetailsError = (state: RootState): string => state.userManagement.errors.getUserManagementDetails;
const getUserManagementDetails = (state: RootState): Partial<IUserManagementDetails> | null => state.userManagement.userManagementDetails;

const createUserManagementAttempting = (state: RootState): boolean => state.userManagement.actions.createUserManagement;
const createUserManagementError = (state: RootState): string => state.userManagement.errors.createUserManagement;

const editUserManagementAttempting = (state: RootState): boolean => state.userManagement.actions.editUserManagement;
const editUserManagementError = (state: RootState): string => state.userManagement.errors.editUserManagement;

const deleteUserManagementAttempting = (state: RootState): boolean => state.userManagement.actions.deleteUserManagement;
const deleteUserManagementError = (state: RootState): string => state.userManagement.errors.deleteUserManagement;

const getUserManagementFilters = (state: RootState): UserManagementReduxFilters => state.userManagement.filters;
const getUserManagementConfirmationDeleteModalIsOpen = (state: RootState): boolean => state.userManagement.ui.confirmationDeleteModalIsOpen;

export default {
    getUserManagementAttempting,
    getUserManagement,
    getUserManagementError,

    getUserManagementDetailsAttempting,
    getUserManagementDetailsError,
    getUserManagementDetails,

    createUserManagementAttempting,
    createUserManagementError,

    editUserManagementAttempting,
    editUserManagementError,

    deleteUserManagementAttempting,
    deleteUserManagementError,

    getUserManagementFilters,
    getUserManagementConfirmationDeleteModalIsOpen,

};
