import React, { useState } from 'react';

import debounce from 'lodash.debounce';
import Select from 'react-dropdown-select';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import LoadingIndicator from 'components/atoms/LoadingIndicator';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import theme from 'styles/theme';

interface DataType {
    label: string;
    value: string | number;
}

interface InputWithSearchProps {
    onSelect: (val: string | number) => void;

    data: DataType[];

    value?: string | number;
    label?: string;
    placeholder?: string;
    noDataLabel?: string;
    noDataFoundLabel?: string;

    allowCreate?: boolean;
    createLabel?: string;
    emptyLabel?: string;
    isSearchable?: boolean;
    mandatory?: boolean;

    onSearch?: (query: string) => void;
    onCreate?: (input: string) => void;

    disabled?: boolean;
    loading?: boolean;
    error?: string;
    inputError?: boolean;

    requiredCompanyId?: boolean;
    companyId?: string;
    createClient?: boolean;
}

const InputWithSearch = (props: InputWithSearchProps): JSX.Element => {
    const {
        onSelect,
        data,
        value,
        label,
        placeholder = '',
        noDataLabel = '',
        noDataFoundLabel = "We can't seem to find this client. Create it?",
        allowCreate,
        createLabel,
        emptyLabel = 'Start typing to search for customer.',
        isSearchable = true,
        mandatory,
        onSearch,
        onCreate,
        disabled,
        loading,
        error,
        inputError,
        requiredCompanyId,
        companyId,
        createClient,
    } = props;

    // IS NOT USED ANYWHERE TO DISPLAY IN UI - only for use in making sure we are not searching in an infinite loop.
    // The select component we're using performs a search by default when data is first loaded in, leading
    // our search function to be repeatedly called when search results are passed in repeatedly.
    // We'll keep track of the search query here so that we don't call the API if the search query is the same (even if the
    // search results passed in aren't. (equality checks are weird when it comes to arrays))
    const [searchQuery, setSearchQuery] = useState<string>('');

    const handleOnChange = (values: (string | object)[]) => {
        const selectedValue = values as DataType[];
        if (createClient) {
            if (selectedValue.length && selectedValue[0].value !== selectedValue[0].label) onSelect(selectedValue[0].value);
            return;
        }
        if (selectedValue.length) onSelect(selectedValue[0].value);
    };

    const debouncedSearch = React.useMemo(() => debounce(
        (query: string) => {
            if (onSearch && query !== searchQuery) {
                setSearchQuery(query);
                onSearch(query);
            }
        },
        500,
        { trailing: true },
    ), [searchQuery]);

    const handleOnCreate = (prop: string | object) => {
        const { label: input } = prop as DataType;

        if (onCreate) onCreate(input);
    };

    const getSelectedValue = () => {
        if (value) {
            const selectedValue = data.find((item) => item.value === value);
            if (selectedValue) return [selectedValue];
        }
        return [];
    };

    const onSearchCustomerClicked = () => {
        if (!companyId && requiredCompanyId) {
            toast.error('Please select a company first');
        }
    };

    const renderOptions = () => {
        if (loading) {
            return (
                <NoDataContainer>
                    <LoadingIndicator size={20} />
                </NoDataContainer>
            );
        }

        if (error) {
            return (
                <NoDataContainer>
                    <Text variant={TextVariant.error}>{error}</Text>
                </NoDataContainer>
            );
        }

        if (searchQuery && searchQuery.length) {
            if (noDataLabel) {
                return (
                    <NoDataContainer>
                        <Text>{noDataLabel}</Text>
                    </NoDataContainer>
                );
            }
            return (
                <NoDataContainer>
                    <Text>{noDataFoundLabel}</Text>
                </NoDataContainer>
            );
        }

        return (
            <NoDataContainer>
                <Text>{emptyLabel}</Text>
            </NoDataContainer>
        );
    };

    return (
        <Container>
            {label && (
                <Label>
                    {label}
                    {mandatory && (<Text variant={TextVariant.error}>*</Text>)}
                </Label>
            )}
            {/* <DisabledContainer onClick={onSearchCustomerClicked}> */}
            <StyledSelect
                clearOnBlur
                disabled={disabled}
                options={data}
                onChange={handleOnChange}
                values={getSelectedValue()}
                color={theme.colors.primary}
                searchable={isSearchable}
                style={selectStyles({ error: inputError })}
                searchFn={({ state }) => {
                    // if (!state.search) return data;
                    debouncedSearch(state.search);
                    return data;
                }}
                create={allowCreate}
                createNewLabel={createLabel}
                onCreateNew={handleOnCreate}
                placeholder={placeholder}
                noDataRenderer={renderOptions}
            />
            {/* </DisabledContainer> */}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled(Text)`
    display: flex;

    font-weight: 500;
    font-size: 16px;
    margin-bottom: 6px;
`;

const StyledSelect = styled(Select)`
    background-color: ${props => props.theme.colors.grey_light};

    height: 47px;

    ::placeholder {
        font-family: ${theme.fonts.primary};
        font-size: ${theme.fontSize.sm};
        color: ${theme.fontColor.primary};

    }
    .react-dropdown-select-content{
        flex-wrap: nowrap;
        width: 150px;
        overflow: hidden;
        padding-inline: 10px;

        > span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .react-dropdown-select-dropdown {
        background-color: ${props => props.theme.colors.tertiary};
        width: 100%;
        top: 3.25rem;
        text-align: left;
    }

    .react-dropdown-select-item {
        color: ${theme.fontColor.primary};
        background-color: ${theme.colors.background};
    }

    @media (min-width: 1280px) {
        font-size: ${theme.fontSize.sm};
        line-height: ${theme.lineHeight.sm};
    }

    @media (min-width: 1520px) {
        font-size: ${theme.fontSize.default};
        line-height: ${theme.lineHeight.default};
    }
`;

const NoDataContainer = styled.div`
    background-color: white;

    padding: 8px 10px;
`;

const DisabledContainer = styled.button`
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
`;

const selectStyles = (props: { error?: boolean }): React.CSSProperties => ({
    borderRadius: 25,

    border: props.error ? `2px solid ${theme.colors.error}` : 0,

    fontFamily: theme.fonts.primary,
    fontSize: 16,
    color: theme.fontColor.primary,

    width: '100%',

    paddingRight: 12,
    paddingLeft: 12,

    justifyContent: 'center',
    alignItems: 'center',
});

export default InputWithSearch;
