import { GetAllShipmentTrackingResponse, GetOrderCommentResponse, GetOrderReportResponse, GetTimelineResponse } from 'api/OrderBase';
import { RootState } from 'redux/store';

import { IOrder, IShipmentTrackingDetails } from 'entities/order';

import { OrdersReduxFilters } from './types';

const getOrderReportAttempting = (state: RootState): boolean => state.order.actions.getOrderReport || false;
const getOrderReportError = (state: RootState): string => state.order.error.getOrderReport || '';
const getOrderReportData = (state: RootState): GetOrderReportResponse => state.order.orders || '';
const getOrdersFilters = (state: RootState): OrdersReduxFilters => state.order.filters;

const createOrderAttempting = (state: RootState): boolean => state.order.actions.createOrder || false;
const createOrderError = (state: RootState): string => state.order.error.createOrder || '';
const createOrder = (state: RootState): Partial<IOrder> => state.order.createOrEditOrder;

const updateOrderAttempting = (state: RootState): boolean => state.order.actions.updateOrder || false;
const updateOrderError = (state: RootState): string => state.order.error.updateOrder || '';

const deleteOrderAttempting = (state: RootState): boolean => state.order.actions.deleteOrder || false;
const deleteOrderError = (state: RootState): string => state.order.error.deleteOrder || '';

const exportOrderAttempting = (state: RootState): boolean => state.order.actions.exportOrder || false;
const exportOrderError = (state: RootState): string => state.order.error.exportOrder || '';

const getOrderDetailsAttempting = (state: RootState): boolean => state.order.actions.getOrderDetails || false;
const getOrderDetailsError = (state: RootState): string => state.order.error.getOrderDetails || '';
const getOrderDetailsData = (state: RootState): IOrder | null => state.order.orderDetails;

const getTimelineAttempting = (state: RootState): boolean => state.order.actions.getTimeline || false;
const getTimelineError = (state: RootState): string => state.order.error.getTimeline || '';
const getTimelineData = (state: RootState): GetTimelineResponse[] => state.order.timeline || [];

const orderUpdateOrderStatusAttempting = (state: RootState): boolean => state.order.actions.updateOrderStatus || false;
const orderUpdateOrderStatusError = (state: RootState): string => state.order.error.updateOrderStatus || '';

const updateProcessOrderAttempting = (state: RootState): boolean => state.order.actions.updateProcessOrder || false;
const updateProcessOrderError = (state: RootState): string => state.order.error.updateProcessOrder || '';

const updatePaymentTermsAttempting = (state: RootState): boolean => state.order.actions.updatePaymentTerms || false;
const updatePaymentTermsError = (state: RootState): string => state.order.error.updatePaymentTerms || '';

const getOrderNewOrEditOrder = (state: RootState): Partial<IOrder> => state.order.createOrEditOrder;

const getOrderCommentAttempting = (state: RootState): boolean => state.order.actions.getOrderComment || false;
const getOrderCommentError = (state: RootState): string => state.order.error.getOrderComment || '';
const getOrderCommentData = (state: RootState): GetOrderCommentResponse => state.order.orderComment || '';

const createOrderCommentAttempting = (state: RootState): boolean => state.order.actions.createOrderComment || false;
const createOrderCommentError = (state: RootState): string => state.order.error.createOrderComment || '';

const deleteOrderCommentAttempting = (state: RootState): boolean => state.order.actions.deleteOrderComment || false;
const deleteOrderCommentError = (state: RootState): string => state.order.error.deleteOrderComment || '';

const orderUpdateOrderCommentAttempting = (state: RootState): boolean => state.order.actions.updateOrderComment || false;
const orderUpdateOrderCommentError = (state: RootState): string => state.order.error.updateOrderComment || '';

const orderUploadClientPoAttempting = (state: RootState): boolean => state.order.actions.uploadClientPo || false;
const orderUploadClientPoError = (state: RootState): string => state.order.error.uploadClientPo || '';

const orderDeleteClientPoAttempting = (state: RootState): boolean => state.order.actions.deleteClientPo || false;
const orderDeleteClientPoError = (state: RootState): string => state.order.error.deleteClientPo || '';

const orderUploadPaymentProofAttempting = (state: RootState): boolean => state.order.actions.uploadPaymentProof || false;
const orderUploadPaymentProofError = (state: RootState): string => state.order.error.uploadPaymentProof || '';

const orderDeletePaymentProofAttempting = (state: RootState): boolean => state.order.actions.deletePaymentProof || false;
const orderDeletePaymentProofError = (state: RootState): string => state.order.error.deletePaymentProof || '';

const orderUploadOrderAttachmentAttempting = (state: RootState): boolean => state.order.actions.uploadOrderAttachment || false;
const orderUploadOrderAttachmentError = (state: RootState): string => state.order.error.uploadOrderAttachment || '';

const orderDeleteOrderAttachmentAttempting = (state: RootState): boolean => state.order.actions.deleteOrderAttachment || false;
const orderDeleteOrderAttachmentError = (state: RootState): string => state.order.error.deleteOrderAttachment || '';

const orderDeleteOrderAttachmentFolderAttempting = (state: RootState): boolean => state.order.actions.deleteOrderAttachmentFolder || false;
const orderDeleteOrderAttachmentFolderError = (state: RootState): string => state.order.error.deleteOrderAttachmentFolder || '';

const orderGetAllShipmentTrackingAttempting = (state: RootState): boolean => state.order.actions.getAllShipmentTracking || false;
const orderGetAllShipmentTrackingError = (state: RootState): string => state.order.error.getAllShipmentTracking || '';
const orderGetAllShipmentTrackingData = (state: RootState): GetAllShipmentTrackingResponse => state.order.allShipmentTracking || [];

const orderGetShipmentTrackingDetailsAttempting = (state: RootState): boolean => state.order.actions.getShipmentTrackingDetails || false;
const orderGetShipmentTrackingDetailsError = (state: RootState): string => state.order.error.getShipmentTrackingDetails || '';
const orderGetShipmentTrackingDetailsData = (state: RootState): IShipmentTrackingDetails | null => state.order.shipmentTrackingDetails || null;

const orderCreateShipmentTrackingAttempting = (state: RootState): boolean => state.order.actions.createShipmentTracking || false;
const orderCreateShipmentTrackingError = (state: RootState): string => state.order.error.createShipmentTracking || '';

const orderUpdateShipmentTrackingAttempting = (state: RootState): boolean => state.order.actions.updateShipmentTracking || false;
const orderUpdateShipmentTrackingError = (state: RootState): string => state.order.error.updateShipmentTracking || '';

const orderDeleteShipmentTrackingAttempting = (state: RootState): boolean => state.order.actions.deleteShipmentTracking || false;
const orderDeleteShipmentTrackingError = (state: RootState): string => state.order.error.deleteShipmentTracking || '';

const orderGetPaymentLinkAttempting = (state: RootState): boolean => state.order.actions.getPaymentLink || false;
const orderGetPaymentLinkError = (state: RootState): string => state.order.error.getPaymentLink || '';
const orderGetPaymentLinkData = (state: RootState): string | null => state.order.paymentLink || '';

const orderSendPaymentLinkAttempting = (state: RootState): boolean => state.order.actions.sendPaymentLink || false;
const orderSendPaymentLinkError = (state: RootState): string => state.order.error.sendPaymentLink || '';

const orderSetConfirmPaymentAttempting = (state: RootState): boolean => state.order.actions.setConfirmPayment || false;
const orderSetConfirmPaymentError = (state: RootState): string => state.order.error.setConfirmPayment || '';

const orderExportDeliveryOrderAttempting = (state: RootState): boolean => state.order.actions.exportDeliveryOrder || false;
const orderExportDeliveryOrderError = (state: RootState): string => state.order.error.exportDeliveryOrder || '';

const orderExportCommercialInvoiceAttempting = (state: RootState): boolean => state.order.actions.exportCommercialInvoice || false;
const orderExportCommercialInvoiceError = (state: RootState): string => state.order.error.exportCommercialInvoice || '';

const orderConvertOrderStatusToPaidAttempting = (state: RootState): boolean => state.order.actions.convertOrderStatusToPaid || false;
const orderConvertOrderStatusToPaidError = (state: RootState): string => state.order.error.convertOrderStatusToPaid || '';

const orderConvertOrderStatusToUnpaidAttempting = (state: RootState): boolean => state.order.actions.convertOrderStatusToUnpaid || false;
const orderConvertOrderStatusToUnpaidError = (state: RootState): string => state.order.error.convertOrderStatusToUnpaid || '';

const orderGetOrderAttachmentFolderNameAttempting = (state: RootState): boolean => state.order.actions.getOrderAttachmentFolderName || false;
const orderGetOrderAttachmentFolderNameError = (state: RootState): string => state.order.error.getOrderAttachmentFolderName || '';
const orderGetOrderAttachmentFolderNameData = (state: RootState): string[] => state.order.orderAttachmentFolderName || [];

const deleteConfirmationModalIsOpen = (state: RootState): boolean => state.order.ui.confirmationDeleteModalIsOpen;
const editOrderCommentModalIsOpen = (state: RootState): boolean => state.order.ui.editOrderCommentModalIsOpen;
const uploadAttachmentModalIsOpen = (state: RootState): boolean => state.order.ui.uploadAttachmentModalIsOpen;
const uploadPaymentProofModalIsOpen = (state: RootState): boolean => state.order.ui.uploadPaymentProofModalIsOpen;
const deletePaymentProofModalIsOpen = (state: RootState): boolean => state.order.ui.deletePaymentProofModalIsOpen;
const deleteShipmentTrackingModalIsOpen = (state: RootState): boolean => state.order.ui.deleteShipmentTrackingModalIsOpen;
const updateShippingAddressModalIsOpen = (state: RootState): boolean => state.order.ui.updateShippingAddressModalIsOpen;
const updateBillingAddressModalIsOpen = (state: RootState): boolean => state.order.ui.updateBillingAddressModalIsOpen;
const deleteAttachmentFolderModalIsOpen = (state: RootState): boolean => state.order.ui.deleteAttachmentFolderModalIsOpen;
const convertOrderStatusToPaidModalIsOpen = (state: RootState): boolean => state.order.ui.convertOrderStatusToPaidModalIsOpen;
const convertOrderStatusToUnpaidModalIsOpen = (state: RootState): boolean => state.order.ui.convertOrderStatusToUnpaidModalIsOpen;
export default {
    getOrderReportAttempting,
    getOrderReportError,
    getOrderReportData,
    getOrdersFilters,

    createOrderAttempting,
    createOrderError,
    createOrder,

    updateOrderAttempting,
    updateOrderError,

    deleteOrderAttempting,
    deleteOrderError,

    exportOrderAttempting,
    exportOrderError,

    getOrderDetailsAttempting,
    getOrderDetailsError,
    getOrderDetailsData,

    getTimelineAttempting,
    getTimelineError,
    getTimelineData,

    orderUpdateOrderStatusAttempting,
    orderUpdateOrderStatusError,

    updateProcessOrderAttempting,
    updateProcessOrderError,

    updatePaymentTermsAttempting,
    updatePaymentTermsError,

    getOrderNewOrEditOrder,
    getOrderCommentAttempting,
    getOrderCommentError,
    getOrderCommentData,

    createOrderCommentAttempting,
    createOrderCommentError,

    deleteOrderCommentAttempting,
    deleteOrderCommentError,

    orderUpdateOrderCommentAttempting,
    orderUpdateOrderCommentError,

    orderUploadClientPoAttempting,
    orderUploadClientPoError,

    orderDeleteClientPoAttempting,
    orderDeleteClientPoError,

    orderUploadPaymentProofAttempting,
    orderUploadPaymentProofError,

    orderDeletePaymentProofAttempting,
    orderDeletePaymentProofError,

    orderUploadOrderAttachmentAttempting,
    orderUploadOrderAttachmentError,

    orderDeleteOrderAttachmentAttempting,
    orderDeleteOrderAttachmentError,

    orderDeleteOrderAttachmentFolderAttempting,
    orderDeleteOrderAttachmentFolderError,

    orderGetAllShipmentTrackingAttempting,
    orderGetAllShipmentTrackingError,
    orderGetAllShipmentTrackingData,

    orderGetShipmentTrackingDetailsAttempting,
    orderGetShipmentTrackingDetailsError,
    orderGetShipmentTrackingDetailsData,

    orderCreateShipmentTrackingAttempting,
    orderCreateShipmentTrackingError,

    orderUpdateShipmentTrackingAttempting,
    orderUpdateShipmentTrackingError,

    orderDeleteShipmentTrackingAttempting,
    orderDeleteShipmentTrackingError,

    orderGetPaymentLinkAttempting,
    orderGetPaymentLinkError,
    orderGetPaymentLinkData,

    orderSendPaymentLinkAttempting,
    orderSendPaymentLinkError,

    orderSetConfirmPaymentAttempting,
    orderSetConfirmPaymentError,

    orderExportDeliveryOrderAttempting,
    orderExportDeliveryOrderError,

    orderExportCommercialInvoiceAttempting,
    orderExportCommercialInvoiceError,

    orderConvertOrderStatusToPaidAttempting,
    orderConvertOrderStatusToPaidError,

    orderConvertOrderStatusToUnpaidAttempting,
    orderConvertOrderStatusToUnpaidError,

    orderGetOrderAttachmentFolderNameAttempting,
    orderGetOrderAttachmentFolderNameError,
    orderGetOrderAttachmentFolderNameData,

    deleteConfirmationModalIsOpen,
    editOrderCommentModalIsOpen,
    uploadAttachmentModalIsOpen,
    uploadPaymentProofModalIsOpen,
    deletePaymentProofModalIsOpen,
    deleteShipmentTrackingModalIsOpen,
    updateShippingAddressModalIsOpen,
    updateBillingAddressModalIsOpen,
    deleteAttachmentFolderModalIsOpen,
    convertOrderStatusToPaidModalIsOpen,
    convertOrderStatusToUnpaidModalIsOpen,
};
