import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { DeleteOrderAttachmentPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchDeleteOrderAttachment(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderDeleteOrderAttachmentAttempt', handleDeleteOrderAttachment, api);
}
function* handleDeleteOrderAttachment(api: OrderGateway, data: DeleteOrderAttachmentPayload) {
    const authToken = yield* select((state) => state.auth.authToken);

    const response = yield* call([api, api.deleteOrderAttachment], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderDeleteOrderAttachmentFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderDeleteOrderAttachmentSuccess());
        yield put(Actions.orderGetOrderDetailsAttempt({ id: data.payload.orderId }));
        toast.success('file deleted!');
    }
}
