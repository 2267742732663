import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { ICustomerAddress } from 'entities/address';
import { ICustomer } from 'entities/customer';
import { OrderStatusEnum } from 'entities/order';

export interface GetCustomersApiParams {
    authToken: string;
    currentPage: number;

    searchQuery?: string;
}

export interface GetCustomersApiResponse {
    maxIndex: number;
    index: number;
    totalRecords: number;
    data: ICustomer[];
}

export interface GetCustomerDetailsApiParams {
    authToken: string;
    customerId: string;
}

export interface GetCustomerOrdersApiParams {
    authToken: string;
    customerId: string;
    index: number;

    searchQuery?: string;
}

export interface GetCustomerOrdersApiResponse {
    maxIndex: number;
    index: number;
    totalRecords: number;
    data: {
        id: string;
        orderNo: string;
        status: OrderStatusEnum;
        total: number;
        createdAt: string;

        consultantName?: string;
        projectName?: string;
        dateOfProject?: string;
    }[];
}

export interface CreateCustomerApiParams {
    authToken: string;
    companyName?: string;
    contactPerson: string;
    phoneNo: string;
    email: string;
    address: ICustomerAddress[];
    companyAccountId: string;
}

export interface EditCustomerApiParams {
    authToken: string;

    id: string;
    companyName?: string;
    contactPerson?: string;
    phoneNo?: string;
    email?: string;
    address?: ICustomerAddress[];
    companyAccountId: string;
}

export interface DeleteCustomerApiParams {
    authToken: string;
    customerId: string;
}

export abstract class ICustomerGateway extends Gateway {
    abstract getCustomers(params: GetCustomersApiParams): GatewayResponse<GetCustomersApiResponse>;

    abstract getCustomerDetails(params: GetCustomerDetailsApiParams): GatewayResponse<ICustomer>;

    abstract getCustomerOrders(params: GetCustomerOrdersApiParams): GatewayResponse<GetCustomerOrdersApiResponse>;

    abstract createCustomer(params: CreateCustomerApiParams): GatewayResponse<string>;

    abstract editCustomer(params: EditCustomerApiParams): GatewayResponse<void>;

    abstract deleteCustomer(params: DeleteCustomerApiParams): GatewayResponse<void>;
}
