import React, { ChangeEvent, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import DropdownButton from 'components/atoms/DropdownButton';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/products/Selectors';
import { checkDuplicateOrEmpty } from 'sagas/products/getProductVariant';

import {
    EDiscountType,
    EPrintMethod,
    EPrintSide,
    EVariantType,
    IPrintMethod,
    IPrintMethodParams,
    IProduct,
    IProductDetails,
    IProductPricing,
    IProductSize,
    IProductVariant,
} from 'entities/products';
import { CurrencyEnum } from 'entities/supplier';
import {
    DefaultPrintMethodLov,
    DiscountOptionLov,
    SideDataLov,
    SizeTypeDataLov,
} from 'lov/ProductSelectorLov';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

import InputWithSearch from '../InputWithSearch';

interface AddItemModalProps {
    isOpen: boolean;
    onCancel: () => void;

    productList: IProduct[];

    isEdit: boolean;
    editedProductIndex: number;
    currency: CurrencyEnum;
}

export interface IDropdownOption {
    value: string | number;
    label: string;
}

const AddItemModal: React.FC<AddItemModalProps> = (props: AddItemModalProps) => {
    const {
        isOpen,
        onCancel,
        productList,
        isEdit,
        editedProductIndex,
        currency,
    } = props;

    const dispatch = useAppDispatch();

    // ? loading
    const getProductListLoading = useAppSelector(Selectors.getProductsAttempting);
    const getProductVariantLoading = useAppSelector(Selectors.getProductVariantAttempting);
    const getProductPricingLoading = useAppSelector(Selectors.getProductPricingAttempting);
    const getPrintMethodListLoading = useAppSelector(Selectors.getPrintMethodListAttempting);
    const getPrintMethodVariantLoading = useAppSelector(Selectors.getPrintMethodVariantAttempting);
    const getPrintMethodPricingLoading = useAppSelector(Selectors.getPrintMethodPricingAttempting);

    // ? error
    const getProductListError = useAppSelector(Selectors.getProductsError);
    const getProductVariantError = useAppSelector(Selectors.getProductVariantError);
    const getProductPricingError = useAppSelector(Selectors.getProductPricingError);
    const getPrintMethodListError = useAppSelector(Selectors.getPrintMethodListError);
    const getPrintMethodVariantError = useAppSelector(Selectors.getPrintMethodVariantError);
    const getPrintMethodPricingError = useAppSelector(Selectors.getPrintMethodPricingError);

    // ? entities
    const productVariant = useAppSelector(Selectors.getProductVariant);
    const productVariantType = useAppSelector(Selectors.getProductVariantType);
    const productPricing = useAppSelector(Selectors.getProductPricing);
    const printMethodList = useAppSelector(Selectors.getPrintMethodList);
    const printMethodVariant = useAppSelector(Selectors.getPrintMethodVariant);
    const printMethodPricing = useAppSelector(Selectors.getPrintMethodPricing);
    const printMethodVariantType = useAppSelector(Selectors.getPrintMethodVariantType);

    const selectedProductsData = useAppSelector(Selectors.getSelectedProducts);

    // ? massaged options
    const productDropdownOptions = productList.map((product) => {
        return {
            value: product.id,
            label: product.name,
        };
    });
    const printMethodDropdownOptions = printMethodList.data.map((method) => {
        return {
            value: method.id,
            label: method.name,
        };
    });

    const stringDropdownData = (data: string[] | undefined) => {
        if (!data) return [];
        return data.map((item) => {
            return {
                value: item,
                label: item,
            };
        });
    };

    const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
    const [selectedVariant, setSelectedVariant] = useState<IProductVariant | null>(null);
    const [selectedFabric, setSelectedFabric] = useState<string>();
    const [selectedFit, setSelectedFit] = useState<string>();
    const [selectedSleeve, setSelectedSleeve] = useState<string>();
    const [selectedStyle, setSelectedStyle] = useState<string>();
    const [selectedColor, setSelectedColor] = useState<string>();

    const [selectedProductSize, setSelectedProductSize] = useState<IProductSize | null>(null);

    const [selectedPrintMethodDetails, setSelectedPrintMethodDetails] = useState<IPrintMethod[]>([]);

    const [discountType, setDiscountType] = useState<string>(EDiscountType.PERCENTAGE);
    const [discountValue, setDiscountValue] = useState<string>('');

    const [productRemark, setProductRemark] = useState<string>('');

    const [newError, setNewError] = useState<string>('');

    // ! useEffect functions
    const handleEditMode = () => {
        onProductSelected(selectedProductsData[editedProductIndex].productId as string);
        setDiscountBasedOnEditedProduct();
    };
    const setDiscountBasedOnEditedProduct = () => {
        const editedProduct = selectedProductsData[editedProductIndex];
        if (editedProduct.discountPercent) {
            setDiscountType(EDiscountType.PERCENTAGE);
            setDiscountValue(editedProduct.discountPercent.toString());
        } else if (editedProduct.discountFixed) {
            setDiscountType(EDiscountType.FIXED);
            setDiscountValue(editedProduct.discountFixed.toString());
        }
    };
    const resetState = () => {
        setSelectedProduct(null);
        setSelectedFabric(undefined);
        setSelectedFit(undefined);
        setSelectedSleeve(undefined);
        setSelectedStyle(undefined);
        setSelectedColor(undefined);
        setSelectedProductSize(null);
        setSelectedVariant(null);
        setSelectedPrintMethodDetails([]);
        setDiscountType(EDiscountType.PERCENTAGE);
        setDiscountValue('');
        setProductRemark('');
        dispatch(Actions.productResetAddItemInput());
    };
    const setVariantProperties = (variant: IProductVariant) => {
        setSelectedFabric(variant.type);
        setSelectedFit(variant.fit);
        setSelectedSleeve(variant.sleeve);
        setSelectedStyle(variant.style);
        setSelectedColor(variant.color);
    };
    const dispatchProductPricing = () => {
        if (!selectedProduct) return;
        if (!selectedVariant) return;
        dispatch(Actions.productsGetProductPricingAttempt({
            productId: selectedProduct.id,
            variantId: selectedVariant.id,
            currency,
        }));
    };
    const resetVariantSelections = () => {
        setSelectedFabric(undefined);
        setSelectedFit(undefined);
        setSelectedSleeve(undefined);
        setSelectedStyle(undefined);
        setSelectedColor(undefined);
    };

    const setEditModeProductSize = () => {
        const newSelectedProductSize = selectedProductsData[editedProductIndex].quantities?.flatMap((item) => ({
            size: item.size,
            quantity: item.quantity,
        }));

        const newSize = newSelectedProductSize?.reduce((acc, item) => ({
            ...acc,
            [item.size as string]: item.quantity,
        }), {});

        setSelectedProductSize(newSize as IProductSize);
    };
    const setDefaultProductSize = () => {
        setSelectedProductSize(null);
    };
    // ! useEffect zone
    // ? get product print menthod list
    useEffect(() => {
        dispatch(Actions.productsGetPrintMethodAttempt({ index: -1 }));
    }, []);
    // ? check edit product
    useEffect(() => {
        if (isOpen && isEdit && selectedProductsData) {
            handleEditMode();
        }
        if (!isOpen) {
            resetState();
        }
    }, [isOpen]);
    // ? get exact product variant
    useEffect(() => {
        if (productVariant.length > 0) {
            const variant = isEdit
                ? productVariant.find((v) => v.id === selectedProductsData[editedProductIndex].variantId) ?? productVariant[0]
                : productVariant[0];

            setSelectedVariant(variant);
            setVariantProperties(variant);
        }
    }, [productVariant]);
    // ? set selected product variant
    useEffect(() => {
        if (selectedProduct) {
            if (productVariant.length < 2) {
                setSelectedVariant(productVariant[0]);
            }
            setSelectedVariant(
                checkVariantSelected(selectedFabric, selectedFit, selectedSleeve, selectedStyle, selectedColor),
            );
        }
    }, [selectedProduct, selectedFabric, selectedFit, selectedSleeve, selectedStyle, selectedColor]);
    // ? get product pricing listing
    useEffect(() => {
        if (selectedProduct && selectedVariant) {
            dispatchProductPricing();
        }
        if (!selectedProduct) {
            resetVariantSelections();
        }
    }, [selectedProduct, selectedVariant]);
    // ? exact product pricing
    useEffect(() => {
        if (isEdit) {
            setEditModeProductSize();
        } else if (productPricing.length > 0) {
            setDefaultProductSize();
        }
    }, [productPricing]);
    // ? set selected print method
    useEffect(() => {
        if (isEdit && selectedProductsData[editedProductIndex].printMethods) {
            setSelectedPrintMethodDetails(selectedProductsData[editedProductIndex].printMethods as IPrintMethod[]);
        }
    }, [isEdit, selectedProductsData, editedProductIndex]);
    // ? get print method variant and pricing
    useEffect(() => {
        const selectedPrintVariant = checkPrintVariantSelected();

        if (selectedPrintVariant.length > 0) {
            if (isEdit) {
                selectedPrintMethodDetails.forEach((item, index) => {
                    dispatch(Actions.productsGetPrintMethodVariantAttempt({
                        printCustomId: item.printCustomId as string,
                        index,
                    }));
                });
            }
            selectedPrintMethodDetails.forEach((item, index) => {
                if (!item) return;
                const variantNullCheck = selectedPrintVariant.every((variant) => variant !== null);

                if (variantNullCheck) {
                    dispatch(Actions.productsGetPrintMethodPricingAttempt({
                        printCustomId: item.printCustomId as string,
                        variantId: selectedPrintVariant[index]?.id ?? '',
                        index,
                        currency,
                    }));
                }
            });
        }
    }, [isOpen, selectedPrintMethodDetails, printMethodList.data]);
    // ? check remark
    useEffect(() => {
        if (isEdit) {
            setProductRemark(selectedProductsData[editedProductIndex].remark ?? '');
        }
    }, [isEdit]);

    // ! text formatter
    const formatVariantLabel = (variant: IProductVariant) => {
        const { fit, type, sleeve, style, color } = variant;

        const nonNullValues = [fit, type, sleeve, style, color].filter(value => value);

        return nonNullValues.join(', ').trim()
            .replace(/,,+/g, ',');
    };
    const formatSizeLabel = (size: IProductSize) => {
        const { Quantity, XS, S, M, L, XL, ...rest } = size;
        if (Quantity) return undefined;
        // Create an array of formatted size-stock pairs
        const sizeLabels = [
            ...(XS ? [`XS:${XS}`] : []),
            ...(S ? [`S:${S}`] : []),
            ...(M ? [`M:${M}`] : []),
            ...(L ? [`L:${L}`] : []),
            ...(XL ? [`XL:${XL}`] : []),
            // ... Repeat for other sizes (L, XL, etc.)
            ...(Object.entries(rest) // Get size-stock pairs from rest object
                .filter(([key]) => !!key) // Filter out entries with missing keys
                .map(([key, value]) => `${key}:${value}`)),
        ];

        // Join the formatted pairs with ' | ' separator
        return sizeLabels.join(' | ')
            .trim(); // Remove any leading/trailing spaces
    };
    const formatPrintMethodLabel = (printMethodLabel: IPrintMethod) => {
        if (!printMethodLabel) return '';
        const { side, printMethod, block, colorCount } = printMethodLabel;

        const nonNullValues = [EPrintSide[side], printMethod, block, colorCount].filter(value => value);

        return nonNullValues.join(', ').trim()
            .replace(/,,+/g, ',');
    };

    // ! handlers
    // ? product
    const onProductSelected = (productId: string | number) => {
        setSelectedProduct(productList.find((product) => product.id === productId) || null);
        dispatch(Actions.productsGetProductVariantAttempt({
            productId: productId as string,
        }));
    };
    const onProductSearch = (searchQuery: string) => {
        dispatch(Actions.productsGetProductsAttempt({
            index: -1,
            searchQuery,
        }));
    };
    const onSizeChange = (newSize: string, e: ChangeEvent<HTMLInputElement>) => {
        const newSizeValue = e.target.value?.replace(/[^0-9\b.]/g, '');

        if (!Number.isNaN(newSizeValue) && Number(newSizeValue) > 0) {
            setSelectedProductSize({ ...selectedProductSize, [newSize]: Number(newSizeValue) });
            return;
        }

        const newSizeObject = { ...selectedProductSize };
        delete newSizeObject?.[newSize as keyof IProductSize];
        setSelectedProductSize(newSizeObject);
    };
    // ? print method
    const onAddPrintMethod = (size: number) => {
        setSelectedPrintMethodDetails([...selectedPrintMethodDetails, DefaultPrintMethodLov]);
        dispatch(Actions.productsGetPrintMethodVariantAttempt({
            printCustomId: DefaultPrintMethodLov.printCustomId, // get default print method
            index: size,
        }));
        dispatch(Actions.productsSetPrintMethodVariantType({
            data: {
                block: [],
                colorCount: [],
            },
            index: size,
        }));
    };
    const onRemovePrintMethod = (printMethodIndex: number) => {
        const newUpdatedPrintMethod = selectedPrintMethodDetails.filter((item, index) => index !== printMethodIndex);

        setSelectedPrintMethodDetails(newUpdatedPrintMethod);
        dispatch(Actions.productsRemovePrintMethodVariant({ index: printMethodIndex }));
        dispatch(Actions.productRemovePrintMethodPricing({ index: printMethodIndex }));
        dispatch(Actions.productsRemovePrintMethodVariantType({ index: printMethodIndex }));
    };
    const updatePrintMethod = (index: number, type: EPrintMethod, value: string, label?: string) => {
        let updatedItem = {
            ...selectedPrintMethodDetails[index],
            [type]: value,
        };

        if (type === EPrintMethod.PRINTMETHOD) {
            updatedItem = {
                ...selectedPrintMethodDetails[index],
                printCustomId: value,
                printMethod: label as string,
                block: undefined,
                colorCount: undefined,
            };
        }

        const newUpdatedPrintMethod = selectedPrintMethodDetails.map((item, i) => {
            if (i === index) {
                return {
                    ...updatedItem,
                };
            }
            return item;
        });

        setSelectedPrintMethodDetails(newUpdatedPrintMethod);
        if (type === EPrintMethod.PRINTMETHOD) {
            dispatch(Actions.productsGetPrintMethodVariantAttempt({
                printCustomId: newUpdatedPrintMethod[index].printCustomId as string,
                index,
            }));
        }
    };
    // ? discount
    const onDiscountValueChange = (value: string) => {
        const newDiscount = value?.replace(/[^0-9\b.]/g, '');

        if (discountType === EDiscountType.PERCENTAGE) {
            if (!(Number.isNaN(newDiscount)) && Number(newDiscount) <= 100) {
                setDiscountValue(newDiscount);
                return;
            }
            toast.error('Discount value cannot be greater than 100%');
            return;
        }

        setDiscountValue(newDiscount || '');
    };
    // ! calculations functions
    // ? product
    const countTotalSize = (sizeData: IProductSize): number => {
        if (sizeData.Quantity) return sizeData.Quantity;
        return Object.values(sizeData).reduce((acc, value) => acc + (value || 0), 0);
    };
    const getProductPricePerUnit = (): IProductPricing[] => {
        if (!productPricing) return [];
        if (!selectedProductSize) return [productPricing[0]];
        if (selectedProductSize.Quantity) {
            const value = selectedProductSize.Quantity;
            const minQuantity = productPricing[0].minimumQuantity;
            const uniqueSize = productPricing[0].size; // todo: need to set unique size as the size

            if (value < minQuantity) return [{ ...productPricing[0], size: 'Quantity', quantity: value }];

            const productPricePerUnit = productPricing
                .filter((price) => price.minimumQuantity <= value)
                .map((price) => ({ ...price, size: 'Quantity', quantity: value }));

            if (productPricePerUnit.length > 1) {
                return [productPricePerUnit[productPricePerUnit.length - 1]];
            }

            return [productPricePerUnit[0]];
        }
        return Object.entries(selectedProductSize).flatMap(([key, value]) => {
            const minQuantity = productPricing.find((price) => price.size === key)?.minimumQuantity;

            if (!minQuantity) return [];

            if (value < minQuantity) {
                const productPricePerUnit = productPricing.find((price) => price.size === key);
                if (!productPricePerUnit) return [];
                return {
                    id: productPricePerUnit?.id,
                    size: key,
                    minimumQuantity: minQuantity,
                    quantity: value,
                    pricePerUnit: productPricePerUnit?.pricePerUnit,
                };
            }

            const productPricePerUnit = productPricing
                .filter((price) => price.size === key && price.minimumQuantity <= value)
                .map((price) => ({ ...price, size: key, quantity: value })); // need to replace the old minimumQuantity to actual value`

            if (productPricePerUnit.length > 1) {
                return productPricePerUnit[productPricePerUnit.length - 1];
            }
            return productPricePerUnit[0];
        });
    };
    const getTotalProductPrice = () => {
        if (!productPricing) return '0';
        if (!selectedProductSize) return '0';
        // if (selectedProductSize.Quantity) return productPricing[0].pricePerUnit * countTotalSize(selectedProductSize);
        // ! PPU * added quantity (in this case minumumQuantity since its been replace with actual quantity)
        const totalPriceEachSize = getProductPricePerUnit().map((item) => item.pricePerUnit * (item.quantity || 0));
        return totalPriceEachSize.reduce((acc, item) => acc + item, 0).toFixed(2);
    };
    // ? print method
    const getPrintPricePerUnit = () => {
        if (!printMethodPricing) return [];
        if (!selectedProductSize) return [];
        if (selectedPrintMethodDetails.length < 1) return [];

        let printMethodPricePerUnit: IProductPricing[][] = [];

        if (printMethodPricing.length > 0) {
            printMethodPricePerUnit = printMethodPricing.map((item) => {
                if (item[0].minimumQuantity <= countTotalSize(selectedProductSize)) {
                    return item.filter((price) => price.minimumQuantity <= countTotalSize(selectedProductSize))
                        || { id: '', minimumQuantity: 0, pricePerUnit: 0 };
                }
                return [item[0]];
            });
        }

        return printMethodPricePerUnit;
    };
    const getTotalPrintPrice = () => {
        if (!printMethodPricing) return '0.00';
        if (!selectedProductSize) return '0.00';
        if (!selectedPrintMethodDetails) return '0.00';

        // ! (PPU + (all PPU available for other print method)) * total quantity
        const everyPrintMethodPrice = getPrintPricePerUnit().map((price) => price[price.length - 1]?.pricePerUnit || 0);
        const totalPriceEachPrintMethod = everyPrintMethodPrice.reduce((acc, price) => acc + (price || 0), 0);
        return (totalPriceEachPrintMethod * countTotalSize(selectedProductSize)).toFixed(2);
    };
    // ? product + print method
    const calculateTotalPPU = () => {
        if (!selectedProductSize) return 0;

        // ! (total product price + total print method price) / total quantity
        return (Number(getTotalProductPrice()) + Number(getTotalPrintPrice())) / countTotalSize(selectedProductSize);
    };

    const getDiscountedPrice = () => {
        if (!selectedProductSize) return 0;

        let price = (Number(getTotalProductPrice()) + Number(getTotalPrintPrice())) / countTotalSize(selectedProductSize);

        price = (price * Number(discountValue)) / 100;

        return price;
    };

    const calculateNewTotalProductPrice = () => {
        if (!selectedProductSize) return 0;

        return countTotalSize(selectedProductSize) * Number(calculateTotalPPU().toFixed(2));
    };

    const calculateSubtotal = () => {
        if (discountType === EDiscountType.PERCENTAGE) {
            const discountPercent = (100 - Number(discountValue)) / 100;

            return (calculateNewTotalProductPrice() * discountPercent).toFixed(2);
        }
        if (discountType === EDiscountType.FIXED) {
            return (calculateNewTotalProductPrice() - Number(discountValue)).toFixed(2);
        }

        return calculateNewTotalProductPrice().toFixed(2);
    };

    const calculateProductDiscountAmount = () => {
        if (!selectedProductSize) return '0.00';

        if (discountType === EDiscountType.PERCENTAGE) {
            return ((Number(calculateTotalPPU().toFixed(2)) * countTotalSize(selectedProductSize || {})) - Number(calculateSubtotal())).toFixed(2);
        }
        if (discountType === EDiscountType.FIXED) {
            return Number(discountValue).toFixed(2);
        }

        return '0.00';
    };
    const calculateProductWeight = () => {
        if (!selectedVariant) return 0;
        const totalQuantity = countTotalSize(selectedProductSize || {});
        const productWeight = selectedVariant.weight || 0;
        const totalWeight = productWeight * totalQuantity;

        return Number(totalWeight.toFixed(2));
    };

    const onAddItem = () => {
        if (!selectedProduct) {
            toast.error('Please select product first');
            return;
        }
        if (!selectedVariant && newError) {
            toast.error(newError);
            return;
        }
        if (!selectedVariant) {
            toast.error('Please select variant first');
            return;
        }
        if (Object.keys(selectedProductSize || {}).length === 0) {
            toast.error('Please select size first');
            return;
        }
        // if (!productPricing) {
        //     toast.error('Please select price first');
        //     return;
        // }
        if (checkPrintVariantSelected().some(item => !item)) {
            toast.error('Please select print method first');
            return;
        }
        if (calculateTotalPPU() === 0) {
            toast.error('Total price per unit cannot be zero');
            return;
        }
        // ? initialize print method params
        const printMethodParams: IPrintMethodParams[] = checkPrintVariantSelected().map((item, index) => {
            return {
                side: selectedPrintMethodDetails[index].side,
                printCustomId: selectedPrintMethodDetails[index].printCustomId as string,
                printVariantId: item?.id ?? '',
                pricePerUnit: getPrintPricePerUnit().length > 0 ? getPrintPricePerUnit()[index][0].pricePerUnit || 0 : 0,
                colorCount: selectedPrintMethodDetails[index].colorCount ?? undefined,
                block: selectedPrintMethodDetails[index].block ?? undefined,
                printMethod: selectedPrintMethodDetails[index].printMethod ?? undefined,
            };
        });

        if (printMethodParams.length > 0 && printMethodParams.some((item) => item.printVariantId === '')) {
            toast.error('Please select print method first');
            return;
        }

        const addedItem: IProductDetails = {
            productId: selectedProduct.id,
            variantId: selectedVariant.id,
            name: selectedProduct.name,
            variant: {
                type: selectedVariant.type || undefined,
                fit: selectedVariant.fit || undefined,
                sleeve: selectedVariant.sleeve || undefined,
                style: selectedVariant.style || undefined,
                color: selectedVariant.color || undefined,
            },
            variantText: formatVariantLabel(selectedVariant),
            sizeText: formatSizeLabel(selectedProductSize || {}),
            size: selectedProductSize || {},
            printMethodText: selectedPrintMethodDetails.map((item) => formatPrintMethodLabel(item)),
            totalQuantity: countTotalSize(selectedProductSize || {}),
            quantities: getProductPricePerUnit(),
            printMethodDetails: selectedPrintMethodDetails,
            printMethods: printMethodParams,
            price: calculateTotalPPU().toFixed(2), // ppu
            priceAfterDiscount: getDiscountedPrice(), // discounted ppu
            subtotal: Number(calculateTotalPPU().toFixed(2)) * countTotalSize(selectedProductSize || {}),
            total: Number(calculateSubtotal()), // subtotal after discount
            discountPercent: discountType === EDiscountType.PERCENTAGE ? Number(discountValue) : undefined,
            discountFixed: discountType === EDiscountType.FIXED ? Number(discountValue) : undefined,
            disableQuantityEdit: true,
            weight: calculateProductWeight(),
            discountAmountApplied: Number(calculateProductDiscountAmount()),
            remark: productRemark,
        };

        if (isEdit) {
            dispatch(Actions.productEditSelectedProducts({ data: addedItem, index: editedProductIndex }));
            toast.success('Product Updated successfully');
            onCancel();
            return;
        }

        dispatch(Actions.productSetSelectedProducts(addedItem));
        toast.success('Product Added successfully');
        onCancel();
    };

    // ! functions
    const checkVariantSelected = (
        type?: string,
        fit?: string,
        sleeve?: string,
        style?: string,
        color?: string,
    ): IProductVariant | null => {
        const variantSelected = productVariant.filter((variant) => {
            if (type) {
                if (variant.type !== type) return null;
            }
            if (fit) {
                if (variant.fit !== fit) return null;
            }
            if (sleeve) {
                if (variant.sleeve !== sleeve) return null;
            }
            if (style) {
                if (variant.style !== style) return null;
            }
            if (color) {
                if (variant.color !== color) return null;
            }
            return variant;
        });

        if (fit) {
            const fitVariant = productVariant.filter((variant) => variant.fit === fit);
            const availableType = fitVariant.map(item => (item.type ? item.type : ''));
            const availableSleeve = fitVariant.map(item => (item.sleeve ? item.sleeve : ''));
            const availableStyle = fitVariant.map(item => (item.style ? item.style : ''));
            const availableColor = fitVariant.map(item => (item.color ? item.color : ''));

            dispatch(Actions.productSetProductVariantType({
                ...productVariantType,
                type: checkDuplicateOrEmpty(availableType) ?? undefined,
                sleeve: checkDuplicateOrEmpty(availableSleeve) ?? undefined,
                style: checkDuplicateOrEmpty(availableStyle) ?? undefined,
                color: checkDuplicateOrEmpty(availableColor) ?? undefined,
            }));
        }
        if (sleeve) {
            const sleeveVariant = productVariant.filter((variant) => variant.sleeve === sleeve);
            const availableType = sleeveVariant.map(item => (item.type ? item.type : ''));
            const availableFit = sleeveVariant.map(item => (item.fit ? item.fit : ''));
            const availableStyle = sleeveVariant.map(item => (item.style ? item.style : ''));
            const availableColor = sleeveVariant.map(item => (item.color ? item.color : ''));

            dispatch(Actions.productSetProductVariantType({
                ...productVariantType,
                type: checkDuplicateOrEmpty(availableType) ?? undefined,
                fit: checkDuplicateOrEmpty(availableFit) ?? undefined,
                style: checkDuplicateOrEmpty(availableStyle) ?? undefined,
                color: checkDuplicateOrEmpty(availableColor) ?? undefined,
            }));
        }

        // if (style) {
        //     const styleVariant = productVariant.filter((variant) => variant.style === style);
        //     const availableType = styleVariant.map(item => (item.type ? item.type : ''));
        //     const availableFit = styleVariant.map(item => (item.fit ? item.fit : ''));
        //     const availableSleeve = styleVariant.map(item => (item.sleeve ? item.sleeve : ''));
        //     const availableColor = styleVariant.map(item => (item.color ? item.color : ''));

        //     dispatch(Actions.productSetProductVariantType({
        //         ...productVariantType,
        //         type: checkDuplicateOrEmpty(availableType) ?? undefined,
        //         fit: checkDuplicateOrEmpty(availableFit) ?? undefined,
        //         sleeve: checkDuplicateOrEmpty(availableSleeve) ?? undefined,
        //         color: checkDuplicateOrEmpty(availableColor) ?? undefined,
        //     }));
        // }

        // if (color) {
        //     const colorVariant = productVariant.filter((variant) => variant.color === color);
        //     const availableType = colorVariant.map(item => (item.type ? item.type : ''));
        //     const availableFit = colorVariant.map(item => (item.fit ? item.fit : ''));
        //     const availableSleeve = colorVariant.map(item => (item.sleeve ? item.sleeve : ''));
        //     const availableStyle = colorVariant.map(item => (item.style ? item.style : ''));

        //     dispatch(Actions.productSetProductVariantType({
        //         ...productVariantType,
        //         type: checkDuplicateOrEmpty(availableType) ?? undefined,
        //         fit: checkDuplicateOrEmpty(availableFit) ?? undefined,
        //         sleeve: checkDuplicateOrEmpty(availableSleeve) ?? undefined,
        //         style: checkDuplicateOrEmpty(availableStyle) ?? undefined,
        //     }));
        // }

        if (variantSelected.length === 0) setNewError('Variant is not available. Please change your selection');
        if (variantSelected.length > 0) {
            setNewError('');
            return variantSelected[0];
        }
        return null;
    };

    const checkPrintVariantSelected = () => {
        const data = selectedPrintMethodDetails.map((item, index) => {
            if (printMethodVariant[index] && printMethodVariantType[index]) {
                const matchingVariant = printMethodVariant[index].find((variant) => {
                    if (variant.colorCount === null && variant.block === null) {
                        return variant;
                    }
                    if (variant.colorCount === null && item.block === variant.block) {
                        return variant;
                    }
                    if (variant.block === null && item.colorCount === variant.colorCount) {
                        return variant;
                    }
                    return item.block === variant.block && item.colorCount === variant.colorCount;
                });
                return matchingVariant || null;
            }
            return null;
        });
        return data;
    };
    const checkSelectedSize = (newSize: string) => {
        if (newSize === 'XS') {
            return selectedProductSize?.XS ?? '';
        }
        if (newSize === 'S') {
            return selectedProductSize?.S ?? '';
        }
        if (newSize === 'M') {
            return selectedProductSize?.M ?? '';
        }
        if (newSize === 'L') {
            return selectedProductSize?.L ?? '';
        }
        if (newSize === 'XL') {
            return selectedProductSize?.XL ?? '';
        }
        if (newSize === '2XL') {
            return selectedProductSize?.['2XL'] ?? '';
        }
        if (newSize === '3XL') {
            return selectedProductSize?.['3XL'] ?? '';
        }
        if (newSize === '4XL') {
            return selectedProductSize?.['4XL'] ?? '';
        }
        if (newSize === '5XL') {
            return selectedProductSize?.['5XL'] ?? '';
        }
        if (newSize === '6XL') {
            return selectedProductSize?.['6XL'] ?? '';
        }
        if (newSize === '7XL') {
            return selectedProductSize?.['7XL'] ?? '';
        }
        if (newSize === '8XL') {
            return selectedProductSize?.['8XL'] ?? '';
        }
        if (newSize === 'Quantity') {
            return selectedProductSize?.Quantity ?? '';
        }
        return '';
    };

    // ! render
    const renderProductOptions = () => {
        return (
            <InputWithSearch
                value={selectedProduct?.id}
                label='Select product'
                placeholder='Search product name'
                noDataLabel='No product found.'
                data={productDropdownOptions}
                onSelect={onProductSelected}
                onSearch={onProductSearch}
                loading={getProductListLoading}
                error={getProductListError}
            />
        );
    };
    const renderVariantOptions = (
        variantType: EVariantType,
        dropdownOptions: IDropdownOption[] | [],
        onSelect: (val: string | number) => void,
        inputLoading?: boolean,
        inputError?: string,
        value?: string,
    ) => {
        if (dropdownOptions.length === 0) {
            return null;
        }
        return (
            <InputWithSearch
                label={variantType}
                disabled={!selectedProduct}
                data={dropdownOptions}
                onSelect={onSelect}
                isSearchable={false}
                loading={inputLoading}
                error={inputError}
                value={value}
            />
        );
    };
    const renderProductVariant = () => {
        return (
            <>
                {renderVariantOptions(
                    EVariantType.FABRIC,
                    stringDropdownData(productVariantType.type),
                    (e) => setSelectedFabric(e as string),
                    getProductVariantLoading,
                    getProductVariantError,
                    selectedFabric,
                )}
                {renderVariantOptions(
                    EVariantType.FIT,
                    stringDropdownData(productVariantType.fit),
                    (e) => setSelectedFit(e as string),
                    getProductVariantLoading,
                    getProductVariantError,
                    selectedFit,
                )}
                {renderVariantOptions(
                    EVariantType.SLEEVE,
                    stringDropdownData(productVariantType.sleeve),
                    (e) => setSelectedSleeve(e as string),
                    getProductVariantLoading,
                    getProductVariantError,
                    selectedSleeve,
                )}
                {renderVariantOptions(
                    EVariantType.STYLE,
                    stringDropdownData(productVariantType.style),
                    (e) => setSelectedStyle(e as string),
                    getProductVariantLoading,
                    getProductVariantError,
                    selectedStyle,
                )}
                {renderVariantOptions(
                    EVariantType.COLOR,
                    stringDropdownData(productVariantType.color),
                    (e) => setSelectedColor(e as string),
                    getProductVariantLoading,
                    getProductVariantError,
                    selectedColor,
                )}
            </>
        );
    };
    const renderProductSizeInput = () => {
        if (productPricing.length > 0) {
            if (!productPricing[0].size) {
                return (
                    <ProductSizeContainer notSize>
                        <div>
                            <Text variant={TextVariant.h3}>Quantity</Text>
                        </div>
                        <input
                            value={checkSelectedSize('Quantity')}
                            placeholder='0'
                            onChange={(e) => onSizeChange('Quantity', e)}
                        />
                    </ProductSizeContainer>
                );
            }
            if (productPricing[0].size.length > 2) {
                return (
                    <ProductSizeContainer notSize>
                        <div>
                            <Text variant={TextVariant.h3}>{productPricing[0].size}</Text>
                        </div>
                        <input
                            value={checkSelectedSize('Quantity')}
                            placeholder='0'
                            onChange={(e) => onSizeChange('Quantity', e)}
                        />
                    </ProductSizeContainer>
                );
            }
            return SizeTypeDataLov.map((size) => {
                return (
                    <ProductSizeContainer key={size}>
                        <div>
                            <Text variant={TextVariant.h3}>{size}</Text>
                        </div>
                        <SizeInput
                            value={checkSelectedSize(size)}
                            placeholder='0'
                            onChange={(e) => onSizeChange(size, e)}
                        />
                    </ProductSizeContainer>
                );
            });
        }

        return null;
    };
    const renderPrintMethodOptions = (
        printMethod: EPrintMethod | '',
        dropdownOptions: IDropdownOption[] | [],
        onSelect: (val: string | number) => void,
        inputLoading?: boolean,
        inputError?: string,
        value?: string | number,
        disabled?: boolean,
    ) => {
        let newPrintMethod = '';
        if (printMethod === EPrintMethod.SIDE) newPrintMethod = 'Side';
        if (printMethod === EPrintMethod.PRINTMETHOD) newPrintMethod = 'Print Method';
        if (printMethod === EPrintMethod.BLOCK) newPrintMethod = 'Block';
        if (printMethod === EPrintMethod.COLORCOUNT) newPrintMethod = 'Color Count';
        return (
            <InputWithSearch
                label={newPrintMethod}
                disabled={disabled}
                data={dropdownOptions}
                onSelect={onSelect}
                isSearchable={false}
                loading={inputLoading}
                error={inputError}
                value={value}
                placeholder='Select one...'
            />
        );
    };
    const renderPrintMethod = () => {
        if (!selectedPrintMethodDetails) return null;
        return selectedPrintMethodDetails.map((item, index) => {
            return (
                <React.Fragment key={`${item.printMethod}-${index + 1}`}>
                    {renderPrintMethodOptions(
                        index === 0 ? EPrintMethod.SIDE : '',
                        SideDataLov,
                        (e) => updatePrintMethod(index, EPrintMethod.SIDE, e as string),
                        getProductVariantLoading,
                        getProductVariantError,
                        selectedPrintMethodDetails[index].side,
                    )}
                    {renderPrintMethodOptions(
                        index === 0 ? EPrintMethod.PRINTMETHOD : '',
                        printMethodDropdownOptions,
                        (e) => updatePrintMethod(index, EPrintMethod.PRINTMETHOD, e as string, printMethodDropdownOptions.find((option) => option.value === e)?.label),
                        getProductVariantLoading,
                        getProductVariantError,
                        selectedPrintMethodDetails[index].printCustomId,
                    )}
                    {renderPrintMethodOptions(
                        index === 0 ? EPrintMethod.BLOCK : '',
                        stringDropdownData(printMethodVariantType.at(index)?.block ? printMethodVariantType[index].block ?? [] : []),
                        (e) => updatePrintMethod(index, EPrintMethod.BLOCK, e as string),
                        getProductVariantLoading,
                        getProductVariantError,
                        selectedPrintMethodDetails[index].block ?? '',
                        printMethodVariantType.at(index)?.block ? printMethodVariantType[index].block?.length === 0 : false,
                    )}
                    {renderPrintMethodOptions(
                        index === 0 ? EPrintMethod.COLORCOUNT : '',
                        stringDropdownData(printMethodVariantType.at(index)?.colorCount ? printMethodVariantType[index].colorCount ?? [] : []),
                        (e) => updatePrintMethod(index, EPrintMethod.COLORCOUNT, e as string),
                        getProductVariantLoading,
                        getProductVariantError,
                        selectedPrintMethodDetails[index].colorCount ?? '',
                        printMethodVariantType.at(index)?.colorCount ? printMethodVariantType[index].colorCount?.length === 0 : false,
                    )}
                    <DeletePrintMethodIcon onClick={() => onRemovePrintMethod(index)} />
                </React.Fragment>
            );
        });
    };

    return (
        <Modal
            isModalOpen={isOpen}
            onClosed={onCancel}
            title='Add Item'
            loading={
                getProductVariantLoading
                || getProductPricingLoading
                || getPrintMethodListLoading
                || getPrintMethodVariantLoading
                || getPrintMethodPricingLoading
            }
            loadingText='Loading...'
        >
            <Container>
                <ProductContainer>
                    {renderProductOptions()}
                    {renderProductVariant()}
                    <div id='size'>
                        {renderProductSizeInput()}
                    </div>
                </ProductContainer>
                <PrintMethodContainer>
                    {renderPrintMethod()}
                    <div id='addPrint'>
                        <Button
                            label='Add Print Method +'
                            variant={ButtonVariant.primary}
                            onClick={() => onAddPrintMethod(selectedPrintMethodDetails.length)}
                        />
                    </div>
                </PrintMethodContainer>
                <DiscountContainer>
                    <DropdownButton
                        label='Discount type'
                        options={DiscountOptionLov}
                        value={discountType}
                        onSelect={(e) => setDiscountType(e as string)}
                    />
                    <Input
                        header='Discount value'
                        value={discountValue}
                        onChange={(e) => onDiscountValueChange(e.target.value)}
                    />
                </DiscountContainer>
                <RemarkContainer>
                    <Input
                        header='Remark'
                        variant='textarea'
                        value={productRemark}
                        onChange={(e) => setProductRemark(e.target.value)}
                    />
                </RemarkContainer>
                <ButtonContainer>
                    <div>
                        <Button label='Discard' variant={ButtonVariant.primary} onClick={onCancel} />
                        <Button label='Add Item' variant={ButtonVariant.secondary} onClick={onAddItem} />
                    </div>
                </ButtonContainer>
            </Container>
        </Modal>
    );
};

const Container = styled.div`
    width: 56rem;
`;

const ProductContainer = styled.div`
    display: flex;
    /* grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem; */
    gap: 1rem;
    flex-wrap: wrap;
    border-bottom: 1px solid #EDEDED;
    padding-bottom:  2rem;

    > div {
        flex: 1 0 45%;
    }

    #size {
        padding-top:  1rem;
        display: flex;
        gap: 0.4rem;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;

const ProductSizeContainer = styled.div<{ notSize?: boolean }>`
    width: ${props => (props.notSize ? '200px' : '68px')};
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border: 1px solid ${props => props.theme.colors.border};
        border-bottom: none;
        width: 100%;
        padding: 0.5rem;
        text-align: center;
    }
    input {
        width: ${props => (props.notSize ? '200px' : '68px')};
        text-align: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 1px solid ${props => props.theme.colors.border};
        padding-block: 0.5rem;
        font-family: ${props => props.theme.fonts.primary};
        font-size: ${props => props.theme.fontSize.md};
        font-weight: ${props => props.theme.fontWeight.default};
    }

    .input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

`;

const PrintMethodContainer = styled.div`
    display: flex;
    /* grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem; */
    gap: 1rem;
    padding-block: 2.75rem;
    border-bottom: 1px solid #EDEDED;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-end;

    > div {
        flex: 1 0 20%;
    }


    #print {
        display: flex;
        width: 100%;
        gap: 1rem;

        > div {
            flex: 1 0 auto;
        }
    }

    #addPrint {
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }
`;

const DeletePrintMethodIcon = styled(DeleteIcon)`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    color: ${props => props.theme.colors.danger};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-block: 1.5rem;
    width: 100%;

    > div {
        display: flex;
        gap: 1rem;
        width: 40%;
    }
`;

const DiscountContainer = styled.div`
    padding-block: 1rem;
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid #EDEDED;
`;

const RemarkContainer = styled.div`
    padding-block: 1rem;
    display: flex;
    width: 100%;
`;

const SizeInput = styled.input`
    &:focus::placeholder {
            color: transparent;
    }
`;

export default AddItemModal;
