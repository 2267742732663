import React from 'react';

import styled from 'styled-components';

import theme from 'styles/theme';

interface CheckboxProps {
    label?: string;

    isChecked: boolean;
    onChange: (checked: boolean) => void;
    containerStyle?: React.CSSProperties;
    textStyle?: React.CSSProperties;
}

const Checkbox = (props: CheckboxProps): JSX.Element => {
    const { label, isChecked, onChange, containerStyle, textStyle } = props;

    return (
        <Container onClick={() => onChange(!isChecked)} style={containerStyle}>
            <Input
                type='checkbox'
                checked={isChecked}
                onChange={(e) => onChange(e.target.checked)}
            />
            <Label style={textStyle}>{label}</Label>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 6px;

    cursor: pointer;
`;

const Input = styled.input`
    
`;

export const Label = styled.div`
    font-size: 16px;
    font-weight: 500;
    font-family: ${theme.fonts.primary};
    color: ${theme.fontColor.tertiary};
`;

export default Checkbox;
