import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetSuppliersApiResponse } from 'api/SupplierBase';

import { ISupplierDetails } from 'entities/supplier';

import {
    GetSuppliersPayload,
    SuppliersReduxState,
} from './types';

const initialState: SuppliersReduxState = {
    actions: {
        getSuppliers: false,
        getSupplierDetails: false,
        updateSupplier: false,
        createSupplier: false,
        deleteSupplier: false,
    },
    filters: {
        currentPage: 0,
        searchQuery: undefined,
    },
    suppliers: {
        index: 0,
        maxIndex: 0,
        totalRecords: 0,
        data: [],
    },
    supplierDetails: null,
    createEditSupplier: null,
    ui: {
        confirmationDeleteModalIsOpen: false,
    },
    error: {
        getSuppliers: '',
        getSupplierDetails: '',
        updateSupplier: '',
        createSupplier: '',
        deleteSupplier: '',
    },
};

const suppliersSlice = createSlice({
    name: 'suppliers',
    initialState,
    reducers: {
        supplierGetSuppliersAttempt: (state) => {
            state.actions.getSuppliers = true;
            state.error.getSuppliers = '';
        },
        supplierGetSuppliersSuccess: (state, action: PayloadAction<GetSuppliersApiResponse>) => {
            state.actions.getSuppliers = false;
            state.suppliers = action.payload;
        },
        supplierGetSuppliersFailure: (state, action: PayloadAction<string>) => {
            state.actions.getSuppliers = false;
            state.error.getSuppliers = action.payload || 'Something went wrong. Please try again.';
        },
        supplierSetFilters: (state, action: GetSuppliersPayload) => {
            const { currentPage, searchQuery } = action.payload;

            if (currentPage !== undefined) state.filters.currentPage = currentPage;
            if (searchQuery !== undefined) {
                if (!searchQuery.length) state.filters.searchQuery = undefined;
                else state.filters.searchQuery = searchQuery;
            }
        },
        supplierResetSupplierFilters: (state) => {
            state.filters = initialState.filters;
        },
        supplierGetSupplierDetailsAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.getSupplierDetails = true;
            state.error.getSupplierDetails = '';
        },
        supplierGetSupplierDetailsSuccess: (state, action: PayloadAction<ISupplierDetails>) => {
            state.actions.getSupplierDetails = false;
            state.supplierDetails = action.payload;
        },
        supplierGetSupplierDetailsFailure: (state, action: PayloadAction<string>) => {
            state.actions.getSupplierDetails = false;
            state.error.getSupplierDetails = action.payload;
        },
        supplierUpdateSupplierAttempt: (state) => {
            state.actions.updateSupplier = true;
            state.error.updateSupplier = '';
        },
        supplierUpdateSupplierSuccess: (state) => {
            state.actions.updateSupplier = false;
        },
        supplierUpdateSupplierFailure: (state, action: PayloadAction<string>) => {
            state.actions.updateSupplier = false;
            state.error.updateSupplier = action.payload;
        },
        supplierCreateSupplierAttempt: (state) => {
            state.actions.createSupplier = true;
            state.error.createSupplier = '';
        },
        supplierCreateSupplierSuccess: (state) => {
            state.actions.createSupplier = false;
            state.createEditSupplier = null;
        },
        supplierCreateSupplierFailure: (state, action: PayloadAction<string>) => {
            state.actions.createSupplier = false;
            state.error.createSupplier = action.payload;
        },
        supplierDeleteSupplierAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.deleteSupplier = true;
            state.error.deleteSupplier = '';
        },
        supplierDeleteSupplierSuccess: (state) => {
            state.actions.deleteSupplier = false;
            state.ui.confirmationDeleteModalIsOpen = false;
        },
        supplierDeleteSupplierFailure: (state, action: PayloadAction<string>) => {
            state.actions.deleteSupplier = false;
            state.error.deleteSupplier = action.payload;
        },

        supplierSetCreateEditSupplierParams: (state, action: PayloadAction<Partial<ISupplierDetails>>) => {
            state.createEditSupplier = {
                ...state.createEditSupplier,
                ...action.payload,
            };
        },
        supplierResetCreateEditSupplierParams: (state) => {
            state.actions.createSupplier = false;
            state.error.createSupplier = '';
            state.createEditSupplier = null;
        },
        supplierSetDeleteModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.confirmationDeleteModalIsOpen = action.payload;
        },
    },
});

export type SupplierState = typeof initialState;

export default {
    actions: suppliersSlice.actions,
    reducers: suppliersSlice.reducer,
};
