import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import ErrorMessage from 'components/atoms/ErrorMessage';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import PageHeader from 'components/atoms/PageHeader';
import AdminLayout from 'components/Layout/AdminLayout';
import Form, { FormFields } from 'components/molecules/Form';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import PoSelectors from 'redux/slices/purchaseOrder/Selectors';
import UiSelectors from 'redux/slices/ui/Selectors';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { IAddress } from 'entities/address';
import { IProductDetails } from 'entities/products';

const EditPurchaseOrderScreen = (): JSX.Element => {
    const params = useParams();
    const poId = params.id;

    const {
        formatVariantLabel,
        formatSizeLabel,
        formatPrintMethodLabel,
    } = Utils.Formatter;

    const dispatch = useAppDispatch();

    const getPoLoading = useAppSelector(PoSelectors.getPurchaseOrderDetailsAttempting);
    const getPoError = useAppSelector(PoSelectors.getPurchaseOrderDetailsError);
    const poDetails = useAppSelector(PoSelectors.getPurchaseOrderDetails);

    const updatePoLoading = useAppSelector(PoSelectors.getUpdatePurchaseOrderAttempting);
    const updatePoError = useAppSelector(PoSelectors.getUpdatePurchaseOrderError);

    const editPoDetailsFromRedux = useAppSelector(PoSelectors.getPurchaseOrderNewOrEditPurchaseOrder);

    const supplierList = useAppSelector(UiSelectors.getUiSearchSuppliers);

    useEffect(() => {
        if (poId) {
            dispatch(Actions.poGetPurchaseOrderDetailsAttempt({ id: poId }));
        }

        return () => {
            dispatch(Actions.poResetNewPoParams());
        };
    }, [poId]);

    useEffect(() => {
        if (poDetails) {
            dispatch(Actions.poUpdateNewPoParams({ ...poDetails }));
            dispatch(Actions.uiSearchForSuppliersAttempt({ searchQuery: '' }));
        }
        if (poDetails?.products) {
            dispatch(Actions.productReplaceSelectedProducts(newSelectedProducts()));
        }
        if (poDetails?.discountPercent) {
            dispatch(Actions.productSetDiscountPercent(poDetails.discountPercent.toString()));
        }
        if (poDetails?.discountFixed) {
            dispatch(Actions.productSetDiscountFixed(poDetails.discountFixed.toString()));
        }
        if (poDetails?.shippingFee) {
            dispatch(Actions.productSetShippingCost(poDetails.shippingFee.toString()));
        }
    }, [poDetails]);

    // useEffect(() => {
    //     if (poDetails?.supplierId) {
    //         dispatch(Actions.poUpdateNewPoParams({ supplierId: poDetails?.supplierId }));
    //     }
    // }, [editPoDetailsFromRedux.supplierId]);

    const newSelectedProducts = (): IProductDetails[] => {
        if (!poDetails?.products) return [];
        const selectedProductsData = poDetails?.products.map(product => {
            return {
                productId: product.productId,
                variantId: product.variantId,
                name: product.productName ?? 'Product Name Here',
                variantText: formatVariantLabel(product.productVariant),
                variant: product.productVariant,
                sizeText: formatSizeLabel(product.quantities),
                printMethodText: product.printMethods ? product.printMethods.map((method) => {
                    return formatPrintMethodLabel({
                        side: method.side,
                        printMethod: method.printMethod ?? '',
                        block: method.block ?? '',
                        colorCount: method.colorCount ?? '',
                    });
                }) : [],
                totalQuantity: product.finalQuantity,
                quantities: product.quantities.map(item => ({
                    id: item.productPriceId,
                    size: item.size,
                    minimumQuantity: item.quantity,
                    quantity: item.quantity,
                    pricePerUnit: item.pricePerUnit,
                })),
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                printMethods: product.printMethods,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                disableQuantityEdit: true,
            };
        });

        const customLineProducts = poDetails?.customLineProducts.map(product => {
            return {
                productId: '',
                variantId: '',
                name: product.productName ?? 'Product Name Here',
                totalQuantity: product.finalQuantity,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                disableQuantityEdit: true,
                weight: product.weight,
                remark: product.remark ?? '',
            };
        });

        const combinedSelectedProduct = [...selectedProductsData, ...customLineProducts];
        return combinedSelectedProduct;
    };

    const onCompanyAccountChanged = (companyAccountId: string) => {
        if (editPoDetailsFromRedux.companyAccountId !== companyAccountId) {
            // reset product selector if company account is changed
            dispatch(Actions.productResetProductSelector());
            dispatch(Actions.poUpdateNewPoParams({ companyAccountId, supplierAddress: undefined, supplierId: undefined }));
            dispatch(Actions.uiResetSupplierAddress());
        }
        dispatch(Actions.poUpdateNewPoParams({ companyAccountId }));
    };

    const onPoDateChanged = (poDate: string) => {
        dispatch(Actions.poUpdateNewPoParams({ poDate }));
    };

    const onSupplierChanged = (supplierId: string) => {
        const selectedSupplier = supplierList.find((item) => item.id === supplierId);
        const supplierName = selectedSupplier?.supplierName || '';

        dispatch(Actions.poUpdateNewPoParams({ supplierId, supplierName }));
    };

    const onSupplierAddressChanged = (supplierAddress: IAddress) => {
        dispatch(Actions.poUpdateNewPoParams({ supplierAddress }));
    };

    const onDeliveryCompanyNameChanged = (deliveryCompanyName: string) => {
        dispatch(Actions.poUpdateNewPoParams({ deliveryCompanyName }));
    };

    const onDeliveryAddressChanged = (deliveryAddress: Partial<IAddress>) => {
        const newDeliveryAddress: IAddress = {
            ...deliveryAddress,
            street1: deliveryAddress.street1 || '',
            street2: deliveryAddress.street2 || undefined,
            city: deliveryAddress.city || '',
            state: deliveryAddress.state || '',
            postcode: deliveryAddress.postcode || '',
            country: deliveryAddress.country || '',
        };
        dispatch(Actions.poUpdateNewPoParams({ deliveryAddress: newDeliveryAddress }));
    };

    const onNoteChanged = (note: string) => {
        dispatch(Actions.poUpdateNewPoParams({ note }));
    };

    const onSave = () => {
        dispatch(Actions.poEditPurchaseOrderAttempt());
    };

    const renderBody = () => {
        if (getPoLoading) {
            return (
                <ErrorContainer>
                    <LoadingIndicator />
                </ErrorContainer>
            );
        }

        if (getPoError) {
            return (
                <ErrorContainer>
                    <ErrorMessage error='Sorry, we could not find this purchase order. It might have been deleted/moved.' />

                    <Button
                        label='Back to all purchase orders'
                        onClick={NavActions.navToPurchaseOrders}
                        style={{ width: 250 }}
                    />
                </ErrorContainer>
            );
        }

        if (!editPoDetailsFromRedux) {
            return null;
        }

        const formFieldsNeeded: FormFields = {
            companyAccount: {
                enabled: true, mandatory: true,
            },
            poDate: {
                enabled: true, mandatory: true,
            },
            supplierName: {
                enabled: true, mandatory: true,
            },
            supplierAddress: {
                enabled: true, mandatory: true,
            },
            notes: {
                enabled: true,
            },
            deliveryCompanyName: {
                enabled: true,
            },
            deliveryAddress: {
                enabled: true, mandatory: true,
            },
            productList: {
                enabled: true,
            },
        };

        return (
            <Form
                module='purchaseOrder'
                loading={updatePoLoading}
                error={updatePoError}
                formFields={formFieldsNeeded}
                values={editPoDetailsFromRedux}
                handlers={{
                    onCompanyAccountChanged,
                    onPoDateChanged,
                    onSupplierChanged,
                    onSupplierAddressChanged,
                    onNoteChanged,
                    onDeliveryAddressChanged,
                    onDeliveryCompanyNameChanged,
                    onSave,
                }}
            />
        );
    };

    return (
        <AdminLayout>
            <PageHeader withBackButton>
                Edit Purchase Order
                {' '}
                {poDetails?.poNumber}
            </PageHeader>

            {renderBody()}
        </AdminLayout>
    );
};

const ErrorContainer = styled.div`
    height: 400px;
    width: 100%;

    padding: 32px;

    background-color: white;
    border-radius: 15px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    text-align: center;
`;

export default EditPurchaseOrderScreen;
