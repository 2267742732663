import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import SuppliersGateway from 'api/Supplier';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import Selectors from 'redux/slices/suppliers/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetSuppliers(api: SuppliersGateway): SagaWatcherReturnType {
    yield takeEvery('suppliers/supplierGetSuppliersAttempt', handleGetSuppliers, api);
}
function* handleGetSuppliers(api: SuppliersGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const quotationFilters = yield* select(Selectors.getSuppliersFilters);

    const response = yield* call([api, api.getSuppliers], {
        authToken,
        ...quotationFilters,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.supplierGetSuppliersFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.supplierGetSuppliersSuccess(response.data));
    }
}
