/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from 'styled-components';

const device = {
  xs: '400px',
  sm: '600px',
  md: '1024px',
  lg: '1280px',
  xl: '1440px',
  xxl: '1640px',
};

// type MediaFunction = (...args: (CSSObject | TemplateStringsArray)[]) => ReturnType<typeof css>;

const media = {
  xs: (...args: any): ReturnType<typeof css> => css`
        @media (min-width: ${device.xs}) {
            ${css(args)};
        }
    `,
  sm: (...args: any): ReturnType<typeof css> => css`
        @media (min-width: ${device.sm}) {
            ${css(args)};
        }
    `,
  md: (...args: any): ReturnType<typeof css> => css`
        @media (min-width: ${device.md}) {
            ${css(args)};
        }
    `,
  lg: (...args: any): ReturnType<typeof css> => css`
        @media (min-width: ${device.lg}) {
            ${css(args)};
        }
    `,
  xl: (...args: any): ReturnType<typeof css> => css`
        @media (min-width: ${device.xl}) {
            ${css(args)};
        }
    `,
  xxl: (...args: any): ReturnType<typeof css> => css`
        @media (min-width: ${device.xxl}) {
            ${css(args)};
        }
    `,
};

export default media;
