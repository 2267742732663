import React, { useState } from 'react';

import styled from 'styled-components';

import { IAddress } from 'entities/address';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import theme from 'styles/theme';

import { ButtonVariant } from './variants/ButtonVariant';
import { TextVariant } from './variants/TextVariant';
import Button from './Button';
import EditAddressModal from './EditAddressModal';
import Text from './Text';

interface AddressDisplayProps {
    label?: string;
    address?: IAddress;

    editable?: boolean;
    onChange?: (address: IAddress) => void;
    required?: boolean;
    error?: boolean;

    disabledAddAddress?: boolean;

    isCustomer?: boolean;
}

const AddressDisplay = (props: AddressDisplayProps): JSX.Element | null => {
    const { label, editable, address, onChange, required, error, disabledAddAddress, isCustomer } = props;

    const [editAddressModalIsOpen, setEditAddressModalIsOpen] = useState(false);

    const onEditAddressClicked = () => {
        setEditAddressModalIsOpen(true);
    };

    const onAddressChanged = (updatedAddress: IAddress) => {
        if (!onChange) return;

        onChange(updatedAddress);
        setEditAddressModalIsOpen(false);
    };

    const renderAddNewAddress = () => {
        if (!editable) return null;
        if (address) return null;
        if (disabledAddAddress) return null;

        return (
            <AddNewButtonContainer>
                <Button
                    label='Add a new address'
                    variant={ButtonVariant.link}
                    onClick={onEditAddressClicked}
                />
            </AddNewButtonContainer>
        );
    };

    const displayAddressLabel = () => {
        if (disabledAddAddress && isCustomer) {
            return 'Please select a Customer to view the address.';
        } if (disabledAddAddress) {
            return 'Please select a Supplier to view the address.';
        }
        return 'There is currently no address set. Add one below.';
    };

    const renderAddress = () => {
        if (!address) {
            return (
                <AddressText>
                    <Text>
                        {displayAddressLabel()}
                    </Text>
                </AddressText>
            );
        }

        const { street1, street2, city, state, country, postcode } = address;

        let streetAdd = street1;
        if (street2) streetAdd = `${streetAdd}, ${street2}`;

        const fullAddress = `${streetAdd}, ${postcode}, ${city}, ${state}, ${country}`;

        return (
            <AddressContainer>
                <AddressText>
                    <Text>{fullAddress}</Text>
                </AddressText>

                {editable && (
                    <BadgeContainer>
                        <IconContainer
                            onClick={onEditAddressClicked}
                            type='button'
                        >
                            <EditIcon height={18} />
                        </IconContainer>
                    </BadgeContainer>
                )}
            </AddressContainer>
        );
    };

    return (
        <Container>
            {label && (
                <Label>
                    {label}
                    {required && (<Text variant={TextVariant.error}>*</Text>)}
                </Label>
            )}

            <AddressListContainer error={error}>
                {renderAddress()}
            </AddressListContainer>
            {renderAddNewAddress()}
            <EditAddressModal
                isOpen={editAddressModalIsOpen}
                onCancel={() => setEditAddressModalIsOpen(false)}
                onSave={onAddressChanged}
                address={address}
                noAddressName
            />
        </Container>
    );
};

const Container = styled.div`

`;

export const Label = styled.div`
    font-size: 16px;
    font-weight: 500;
    font-family: ${theme.fonts.primary};
    color: ${theme.fontColor.tertiary};

    margin-bottom: 6px;
`;

const AddressListContainer = styled.div<{ error?: boolean}>`
    font-size: ${props => props.theme.fontSize.sm};
    line-height: ${props => props.theme.lineHeight.default};
    font-weight: ${props => props.theme.fontWeight.default};
    font-family: ${props => props.theme.fonts.primary};

    width: 100%;
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.grey_light};
    border: ${props => (props.error ? '2px solid #D15757' : '2px solid white')};
    border-radius: 2rem;
    padding: .75rem 1.5rem;

`;

const AddressContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

`;

const AddNewButtonContainer = styled.div`
    margin-top: 4px;
    width: 50%;
`;

const AddressText = styled.div`
    display: flex;
    flex: 4;
`;

const BadgeContainer = styled.div`
    display: flex;
    flex: 1;

    justify-content: center;
    align-items: center;
`;

const IconContainer = styled.button`
    border: none;
    background-color: transparent;
`;

export default AddressDisplay;
