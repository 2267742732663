import React, { useEffect } from 'react';

import styled from 'styled-components';

import PageHeader from 'components/atoms/PageHeader';
import AdminLayout from 'components/Layout/AdminLayout';
import DiscardSaveButtons from 'components/molecules/DiscardSaveButtons';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/customers/Selectors';

import NavActions from 'lib/NavActions';
import { ICustomer } from 'entities/customer';

import CustomerForm from './components/CustomerForm';

const AddCustomerPage = (): JSX.Element => {
    const newCustomer = useAppSelector(Selectors.getCustomerDetails);

    const loading = useAppSelector(Selectors.createCustomerAttempting);
    const error = useAppSelector(Selectors.createCustomerError);

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(Actions.customersClearCreateCustomer());
        };
    }, []);

    const onChange = (update: Partial<ICustomer>) => {
        dispatch(Actions.customersSetCustomerDetails(update));
    };

    const onSubmit = () => {
        dispatch(Actions.customersCreateCustomerAttempt());
    };

    return (
        <AdminLayout>
            <PageHeader withBackButton>
                Add New Customer
            </PageHeader>
            <CustomerForm
                error={error}
                customer={newCustomer}
                onChange={onChange}
            />
            <ButtonRow>
                <DiscardSaveButtons
                    onDiscard={NavActions.navBack}
                    onSave={onSubmit}
                    loading={loading}
                    error={error}
                />
            </ButtonRow>
        </AdminLayout>
    );
};

const ButtonRow = styled.div`
    margin-top: 18px;
`;

export default AddCustomerPage;
