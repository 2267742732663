import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import QuotationsGateway from 'api/Quotations';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import QuotationSelectors from 'redux/slices/quotations/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetQuotationDetailsExport(api: QuotationsGateway): SagaWatcherReturnType {
    yield takeEvery('quotations/quoteExportQuotationAttempt', handleGetQuotationDetailsExport, api);
}
function* handleGetQuotationDetailsExport(api: QuotationsGateway, data: PayloadAction<{ id: string }>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const QuotationDetails = yield* select(QuotationSelectors.getQuotationDetails);

    const response = yield* call([api, api.exportQuotations], {
        authToken,
        id: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        if (response.code === '9990') {
            yield put(Actions.quoteExportQuotationFailure('We are unable to verify this quotation. Please try again.'));
            return;
        }
        if (response.code === '9104') {
            yield put(Actions.quoteExportQuotationFailure('Token refresh failed. Please try again.'));
            toast.error('quickbook token error');
            return;
        }
        yield put(Actions.quoteExportQuotationFailure(response.message || ''));
        toast.error(response.code);
    }

    if (response.status === GatewayResponseStatus.Success) {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Quotation-${QuotationDetails?.quotationNumber}.pdf`);
        document.body.appendChild(link);
        link.click();

        yield put(Actions.quoteExportQuotationSuccess());
        toast.success('quotation downloaded successfully');
    }
}
