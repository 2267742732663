import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { DeleteClientPoPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchDeleteClientPo(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderDeleteClientPoAttempt', handleDeleteClientPo, api);
}
function* handleDeleteClientPo(api: OrderGateway, data: DeleteClientPoPayload) {
    const authToken = yield* select((state) => state.auth.authToken);

    const response = yield* call([api, api.deleteClientPo], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderDeleteClientPoFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderDeleteClientPoSuccess());
        yield put(Actions.orderGetOrderDetailsAttempt({ id: data.payload.orderId }));
        toast.success('Customer\'s Purchase Order deleted!');
    }
}
