import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import ConfirmationModal from 'components/molecules/ConfirmationModal';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import OrderSelectors from 'redux/slices/order/Selectors';

import { AuthRoleEnum } from 'entities/auth';
import { IOrderComment } from 'entities/order';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';

import theme from 'styles/theme';

import CommentList from './CommentList';
import EditCommentModal from './EditCommentModal';

interface ComponentProps {
    comment: IOrderComment[];
    onAddComment: (comment: string) => void;
    onDeleteComment: (id: string) => void;
    onEditComment: (id: string, text: string) => void;
    loading?: boolean;
    error?: string;
    style?: string;
}

const CommentForm: React.FC<ComponentProps> = (props: ComponentProps) => {
    const {
        comment,
        onAddComment,
        onDeleteComment,
        onEditComment,
        loading,
        error,
        style,
    } = props;

    const dispatch = useAppDispatch();

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);
    const editCommentModalIsOpen = useAppSelector(OrderSelectors.editOrderCommentModalIsOpen);

    const [comments, setComments] = useState<string>('');
    const [isCommentBlank, setIsCommentBlank] = useState(false);

    const [selectedComment, setSelectedComment] = useState<IOrderComment>();
    const [deleteCommentModalIsOpen, setDeleteCommentModalIsOpen] = useState(false);

    const onCommentChange = (value: string) => {
        setComments(value);
        setIsCommentBlank(false);
    };
    const handleAddComment = () => {
        if (comments && !isCommentBlank) {
            setComments('');
            setIsCommentBlank(false);
            dispatch(Actions.orderSetEditOrderCommentModalIsOpen(false));
            onAddComment(comments);
        }

        if (!comments || comments === '' || isCommentBlank) {
            setIsCommentBlank(true);
        }
    };

    const onDeleteIconClicked = (commentData: IOrderComment) => {
        setSelectedComment(commentData);
        setDeleteCommentModalIsOpen(true);
    };

    const onEditIconClicked = (commentData: IOrderComment) => {
        setSelectedComment(commentData);
        dispatch(Actions.orderSetEditOrderCommentModalIsOpen(true));
    };

    const onCancelComment = () => {
        setDeleteCommentModalIsOpen(false);
    };

    const onConfirmDeleteComment = () => {
        setDeleteCommentModalIsOpen(false);
        onDeleteComment(selectedComment?._id as string);
    };

    const renderCommentInput = () => {
        if (userInfo?.role === AuthRoleEnum.Supplier) return null;
        return (
            <InputRowContainer>
                <Input
                    placeholder='Add a comment'
                    style={{ borderRadius: '10px' }}
                    value={comments}
                    error={isCommentBlank}
                    onChange={(e) => onCommentChange(e.target.value)}
                />
                <Button
                    style={{ width: '120px' }}
                    label='Add'
                    onClick={handleAddComment}
                    loading={loading}
                />
            </InputRowContainer>
        );
    };
    return (
        <FlexContainer>
            <AddCommentsSection>
                <Text variant={TextVariant.h2} style={{ marginBottom: '2rem' }}>Comments</Text>
                {renderCommentInput()}
                {isCommentBlank && (
                    <div style={{ color: 'red', padding: '5px' }}>
                        Please enter a comment to add.
                    </div>
                )}
            </AddCommentsSection>

            <CommentList
                comment={comment}
                onDeleteIconClicked={onDeleteIconClicked}
                onEditIconClicked={onEditIconClicked}
            />
            <EditCommentModal
                isOpen={editCommentModalIsOpen}
                selectedComment={selectedComment as IOrderComment}
                loading={loading}
                onCloseModal={() => dispatch(Actions.orderSetEditOrderCommentModalIsOpen(false))}
                onConfirm={onEditComment}
            />

            <ConfirmationModal
                isOpen={deleteCommentModalIsOpen}
                icon={<ConfirmationIcon />}
                onCancel={onCancelComment}
                onConfirm={onConfirmDeleteComment}
                title='Delete this comment?'
                text={`"${selectedComment?.text}"`}
                confirmText='Confirm'
                cancelText='Cancel'
                confirmButtonVariant={ButtonVariant.warning}
            />
        </FlexContainer>
    );
};
const FlexContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    padding: 50px;
`;

const AddCommentsSection = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid ${theme.colors.tertiary};
`;

const InputRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
`;

const InputContainer = styled.div`
    background-color: transparent;
    border-radius: 10px;
    width: 100%;
`;

export default CommentForm;
