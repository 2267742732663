import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import CustomerGateway from 'api/Customer';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { FormSearchCustomersPayload } from 'redux/slices/ui/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchSearchForCustomers(api: CustomerGateway): SagaWatcherReturnType {
    yield takeEvery('ui/uiSearchForCustomersAttempt', handleGetCustomers, api);
}
function* handleGetCustomers(api: CustomerGateway, data: FormSearchCustomersPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getCustomers], {
        authToken,
        currentPage: -1,
        searchQuery: data.payload.searchQuery ? data.payload.searchQuery : undefined,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.uiSearchForCustomersFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.uiSearchForCustomersSuccess(response.data));
    }
}
