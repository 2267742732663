import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import QuotationsGateway from 'api/Quotations';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetQuotationDetails(api: QuotationsGateway): SagaWatcherReturnType {
    yield takeEvery('quotations/quoteGetQuotationDetailsAttempt', handleGetQuotationDetails, api);
}
function* handleGetQuotationDetails(api: QuotationsGateway, data: PayloadAction<{ id: string }>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getQuotationDetails], {
        authToken,
        id: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        if (response.code === '9990') {
            yield put(Actions.quoteGetQuotationDetailsFailure('We are unable to verify this quotation. Please try again.'));
            return;
        }

        yield put(Actions.quoteGetQuotationDetailsFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.quoteGetQuotationDetailsSuccess(response.data));

        // so that users can edit this supplier
        // yield put(Actions.supplierSetCreateEditSupplierParams(response.data));
    }
}
