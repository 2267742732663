import React, { useEffect } from 'react';

import styled from 'styled-components';

import AddressDisplay from 'components/atoms/AddressDisplay';
import DropdownButton from 'components/atoms/DropdownButton';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/ui/Selectors';

import { IAddress } from 'entities/address';

import { StatusFilterOption } from '../TableFilters';

interface OrderBillingAddressInputProps {
    onChange: (address: IAddress) => void;
    onEdit?: (address: IAddress) => void;
    clientId?: string;
    value?: IAddress;
    inputError?: boolean;
    required?: boolean;
}

const BillingAddressInput = (props: OrderBillingAddressInputProps): JSX.Element => {
    const { clientId, onChange, value, onEdit, inputError, required } = props;

    const dispatch = useAppDispatch();

    const loading = useAppSelector(Selectors.getUiGetCustomerAddressesAttempting);
    const error = useAppSelector(Selectors.getUiGetCustomerAddressesError);
    const customerAddresses = useAppSelector(Selectors.getUiGetCustomerAddresses);

    useEffect(() => {
        if (clientId) {
            // if a client is selected, and shipping address is required here, we'll get all addresses for the client.
            dispatch(Actions.uiGetCustomerAddressesAttempt({ customerId: clientId }));
        }
    }, [clientId]);

    useEffect(() => {
        if (customerAddresses && customerAddresses.length) {
            // when a client is first selected, and there isn't an address selected, we select the default one
            const defaultAddressIndex = customerAddresses.findIndex((item) => item.default);

            // if there isn't a default address, we take the first one.
            if (defaultAddressIndex < 0) onBillingAddressChanged(0);
            else onBillingAddressChanged(defaultAddressIndex);
        }
    }, [customerAddresses]);

    const onBillingAddressChanged = (index: number | string) => {
        if (onChange) {
            const {
                default: _isDefault, addressName: _addressName, ...shippingAddress
            } = customerAddresses[index as number];

            onChange(shippingAddress);
        }
    };

    if (loading) {
        return (
            <Container>
                <Label>
                    Billing address
                    {required && <Text variant={TextVariant.error}>*</Text>}
                </Label>
                <LoadingIndicator size={24} />
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Label>
                    Billing address
                    {required && <Text variant={TextVariant.error}>*</Text>}
                </Label>
                <Text variant={TextVariant.error}>{error}</Text>
            </Container>
        );
    }

    let addressOptions: StatusFilterOption[] = [];

    if (customerAddresses) {
        addressOptions = customerAddresses.map((item, index) => ({
            label: `${item.addressName} - ${item.street1}`,
            value: index,
        }));
    }

    const selectedAddress = customerAddresses.findIndex(
        (item) => item.street1 === value?.street1 && item.street2 === value.street2,
    );

    return (
        <Container>
            <DropdownButton
                label='Billing address'
                options={addressOptions}
                value={selectedAddress}
                onSelect={onBillingAddressChanged}
                placeholder='Select billing address'
                disabled={!clientId}
                error={!!inputError && !!selectedAddress}
                mandatory={required}
            />
            <AddressDisplay
                address={value}
                editable
                onChange={onEdit}
                disabledAddAddress
                isCustomer
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Label = styled(Text)`
    display: flex;

    font-weight: 500;
    font-size: 16px;

    margin-bottom: 6px;
`;

export default BillingAddressInput;
