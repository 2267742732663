import React from 'react';

import styled from 'styled-components';

import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

export interface IBreadCrumb {
    label: string;
    onClick?: () => void;
}
interface BreadcrumbProps {
    data: IBreadCrumb[];
    disableNavigate?: boolean;
}

const Breadcrumb: React.FC<BreadcrumbProps> = (props: BreadcrumbProps) => {
    const { data, disableNavigate } = props;

    const renderBreadcrumb = () => {
        if (!data || data.length === 0) {
            return null;
        }

        return data.map((item, index) => {
            if (index === data.length - 1) {
                return (
                    <Text variant={TextVariant.h3} key={item.label}>
                        {item.label}
                    </Text>
                );
            }
            return (
                <React.Fragment key={item.label}>
                    <TextButton onClick={disableNavigate ? undefined : item.onClick}>
                        {item.label}
                    </TextButton>
                    <Text>/</Text>
                </React.Fragment>
            );
        });
    };
    return (
        <BreadcrumbContainer>
            {renderBreadcrumb()}
        </BreadcrumbContainer>
    );
};

const BreadcrumbContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const TextButton = styled(Text)`
    cursor: pointer;
    font-size: 14px;

    &:hover {
        text-decoration: underline;
    }
`;

export default Breadcrumb;
