import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { UploadOrderAttachmentPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchUploadOrderAttachment(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderUploadOrderAttachmentAttempt', handleUploadOrderAttachment, api);
}
function* handleUploadOrderAttachment(api: OrderGateway, data: UploadOrderAttachmentPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.uploadOrderAttachment], {
        authToken,
        orderId: data.payload.orderId,
        folder: data.payload.folder,
        attachments: data.payload.attachments,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderUploadOrderAttachmentFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderUploadOrderAttachmentSuccess(response.data));
        yield put(Actions.orderSetUploadAttachmentModalIsOpen(false));
        yield put(Actions.orderGetOrderDetailsAttempt({ id: data.payload.orderId }));
        toast.success('Attachment Uploaded');
    }
}
