import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { ConvertOrderStatusToPaidPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchConvertOrderStatusToUnpaid(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderConvertOrderStatusToUnpaidAttempt', handleConvertOrderStatusToUnpaid, api);
}
function* handleConvertOrderStatusToUnpaid(api: OrderGateway, data: ConvertOrderStatusToPaidPayload) {
    const authToken = yield* select((state) => state.auth.authToken);

    const response = yield* call([api, api.convertOrderStatusToUnpaid], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        if (response.code === '9105') {
            toast.error('quickbook is not connected. please connect and try again');
        } else {
            toast.error('something went wrong. please try again');
        }

        yield put(Actions.orderConvertOrderStatusToUnpaidFailure(response.message || ''));
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderConvertOrderStatusToUnpaidSuccess());
        yield put(Actions.orderGetOrderDetailsAttempt({ id: data.payload.orderId }));
        yield put(Actions.orderSetConvertOrderStatusToUnpaidModalIsOpen(false));
        yield put(Actions.orderGetTimelineAttempt({ id: data.payload.orderId }));
        yield put(Actions.orderGetPaymentLinkAttempt({ orderId: data.payload.orderId }));
        toast.success('Order Status updated!');
    }
}
