import { IAddress } from './address';
import { IQuotationCustomLineProduct, IQuotationProduct } from './quotations';
import { CurrencyEnum } from './supplier';

export enum PurchaseOrderStatusEnum {
    Pending = 1,
    Delivered = 2,
    Deleted = 3,
}

export interface IPurchaseOrderList {
    id: string;
    poNumber: string;
    poDate: string;
    status: PurchaseOrderStatusEnum;

    supplierName: string;
    createdByName: string;
    finalPrice: number;

    createdAt: string;

    currency: CurrencyEnum;
}

export interface IPurchaseOrder {
    id: string;
    poNumber: string;
    poDate: string;
    orderId?: string;
    orderNumber?: string;
    status: PurchaseOrderStatusEnum;
    deliveryCompanyName?: string;
    deliveryAddress: IAddress;
    supplierId: string;
    supplierName: string;
    supplierAddress: IAddress;
    supplierContactPersonName: string;
    supplierContactPersonNumber: string;
    supplierContactPersonEmail: string;
    products: IQuotationProduct[];
    customLineProducts: IQuotationCustomLineProduct[];

    subtotal: number;
    discountPercent?: number;
    discountFixed?: number;
    shippingFee: number;
    tax: number;
    finalPrice: number;

    companyAccountId: string;
    companyName: string;
    companyAlias: string;
    companyAddress: IAddress;
    companyNumber: string;
    companyRegistrationNumber: string;

    createdAt: string;
    createdBy: string;
    createdByName: string;

    note?: string;
    remark?: string;

    currency: CurrencyEnum;
    emailVariables?: IEmailVariables;
}

export interface IEmailVariables {
    frontPrintSize: string;
    backPrintSize: string;
    brand: string;
    productCode: string;
    color: string;
    attachmentFolder?: string;
}
