import React, { useEffect } from 'react';

import styled from 'styled-components';

import PageHeader from 'components/atoms/PageHeader';
import AdminLayout from 'components/Layout/AdminLayout';
import DiscardSaveButtons from 'components/molecules/DiscardSaveButtons';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/suppliers/Selectors';

import NavActions from 'lib/NavActions';
import { ISupplierDetails } from 'entities/supplier';

import SupplierForm from './components/SupplierForm';

const CreateSupplier = (): JSX.Element => {
    const newSupplier = useAppSelector(Selectors.getSuppliersCreateEditSupplierParams);

    const loading = useAppSelector(Selectors.getSupplierCreateAttempting);
    const error = useAppSelector(Selectors.getSupplierCreateError);

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(Actions.supplierResetCreateEditSupplierParams());
        };
    }, []);

    const onChange = (update: Partial<ISupplierDetails>) => {
        dispatch(Actions.supplierSetCreateEditSupplierParams(update));
    };

    const onSubmit = () => {
        dispatch(Actions.supplierCreateSupplierAttempt());
    };

    return (
        <AdminLayout>
            <PageHeader withBackButton>
                Add New Supplier
            </PageHeader>
            <SupplierForm
                error={error}
                supplier={newSupplier}
                onChange={onChange}
            />
            <ButtonRow>
                <DiscardSaveButtons
                    onDiscard={NavActions.navBack}
                    onSave={onSubmit}
                    loading={loading}
                    error={error}
                />
            </ButtonRow>
        </AdminLayout>
    );
};

const ButtonRow = styled.div`
    margin-top: 18px;
`;

export default CreateSupplier;
