import React, { useEffect } from 'react';

import styled from 'styled-components';

import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import ProductSelectors from 'redux/slices/products/Selectors';

import Utils from 'lib/Utils';
import { IProductDetails } from 'entities/products';
import { CurrencyEnum } from 'entities/supplier';

type ProductViewerProps = {
    tableHeaders: { header: string }[];
    currency: CurrencyEnum;
    viewData: {
        selectedProducts: IProductDetails[];
        discountPercent?: string;
        discountFixed?: string;
        shippingCost: string;
        taxPrice: string;
    };
    isOrder?: boolean;
    taxPercent?: number;
    disableTax?: boolean;
};

const ProductViewer: React.FC<ProductViewerProps> = (props: ProductViewerProps) => {
    const {
        tableHeaders,
        currency,
        viewData,
        isOrder,
        taxPercent,
        disableTax,
    } = props;

    const { formatPrice, formatCurrency } = Utils.Formatter;

    const dispatch = useAppDispatch();

    const selectedProducts = useAppSelector(ProductSelectors.getSelectedProducts);
    const discountPercent = useAppSelector(ProductSelectors.getDiscountPercent);
    const discountFixed = useAppSelector(ProductSelectors.getDiscountFixed);
    const discountPrice = useAppSelector(ProductSelectors.getDiscountPrice);
    const shippingCost = useAppSelector(ProductSelectors.getShippingPrice);
    const taxPrice = useAppSelector(ProductSelectors.getTaxPrice);
    const isTaxApplied = useAppSelector(ProductSelectors.getIsTaxApplied);

    useEffect(() => {
        return () => {
            dispatch(Actions.productResetProductSelector());
        };
    }, []);

    useEffect(() => {
        dispatch(Actions.productReplaceSelectedProducts(viewData?.selectedProducts || []));
        dispatch(Actions.productSetDiscountPercent(viewData?.discountPercent));
        dispatch(Actions.productSetDiscountFixed(viewData?.discountFixed));
        dispatch(Actions.productSetShippingCost(viewData?.shippingCost || '0.00'));
        dispatch(Actions.productSetTaxPrice(viewData?.taxPrice || '0.00'));
    }, [viewData]);

    useEffect(() => {
        if (discountPercent && !discountFixed) {
            dispatch(Actions.productSetDiscountPrice((calculateSubTotal() * (Number(discountPercent) / 100)).toFixed(2)));
        }
        if (discountFixed && !discountPercent) {
            dispatch(Actions.productSetDiscountPrice(discountFixed));
        }
    }, [discountFixed, discountPercent]);
    // ? calculatio

    const calculateDiscountedPrice = (total: number, quantity: number) => {
        const price = total / quantity;

        return Number(price.toFixed(2));
    };

    const calculateSubTotal = () => {
        let subTotal = 0;
        selectedProducts.forEach((product) => {
            const finalTotal = calculateDiscountedPrice(product.total, product.totalQuantity) * product.totalQuantity;

            subTotal += finalTotal;
        });
        return subTotal;
    };

    const calculateTotal = () => {
        const allTotal = calculateSubTotal() - Number(discountPrice) + Number(shippingCost);
        return allTotal;
    };
    const calculateTotalWithTax = () => {
        if (!taxPercent || !isTaxApplied) return calculateTotal();
        const tax = calculateTotal() * (Number(taxPercent) / 100);
        return calculateTotal() + tax;
    };
    // ? render
    const renderProducts = () => {
        if (selectedProducts.length === 0) return null;

        return selectedProducts.map((product, index) => {
            let discountText = '-';

            if (product.discountPercent) {
                const discount = (product.discountPercent / 100) * (Number(product.price) * product.totalQuantity);
                discountText = `${formatCurrency(currency)}${formatPrice(discount)}`;
            }
            if (product.discountFixed) discountText = `${formatCurrency(currency)}${formatPrice(product.discountFixed)}`;
            const renderPrintMethod = () => {
                if (!product.printMethodText) return null;
                return product.printMethodText.map((text) => {
                    return (
                        <React.Fragment key={text}>
                            {text}
                            <br />
                        </React.Fragment>
                    );
                });
            };
            const renderWeight = () => {
                if (!product.weight) return null;

                return (
                    <>
                        Total weight:
                        {' '}
                        {product.weight.toFixed(2)}
                        {' '}
                        kg
                    </>
                );
            };
            const renderRemark = () => {
                if (!product.remark) return null;

                return (
                    <>
                        <hr />
                        {product.remark}
                    </>
                );
            };
            const renderProductName = () => {
                return (
                    <ProductNameContainer>
                        <Text variant={TextVariant.h3} id='edit'>
                            {product.name}
                        </Text>
                        <Text variant={TextVariant.h4}>
                            {product.variantText}
                            {product.variantText && <br />}
                            {product.sizeText}
                            {product.sizeText && <br />}
                            {renderPrintMethod()}
                            {renderWeight()}
                            {renderRemark()}
                        </Text>
                    </ProductNameContainer>
                );
            };

            // correctly Render for all pages except PurchaseOrder
            const renderPriceAfterDiscount = () => {
                if (product.discountPercent !== null || product.discountFixed !== null) {
                    if (calculateDiscountedPrice(product.total, product.totalQuantity) !== Number(product.price)) {
                        return (
                            <td>
                                {`${formatCurrency(currency)}`}
                                {calculateDiscountedPrice(product.total, product.totalQuantity).toFixed(2)}
                            </td>
                        );
                    }
                }

                return <td>-</td>;
            };

            const renderProductTotal = () => {
                const finalTotal = calculateDiscountedPrice(product.total, product.totalQuantity) * product.totalQuantity;

                return Number(finalTotal.toFixed(2));
            };

            const getDiscountTotal = () => {
                const price = (Number(product.price) * product.totalQuantity) - renderProductTotal();

                if (product.discountPercent !== null || product.discountFixed !== null) {
                    if (Number(price.toFixed(2)) > 0) {
                        return (
                            <td id='input' aria-label='input'>
                                {`${formatCurrency(currency)}`}
                                {price.toFixed(2)}
                            </td>
                        );
                    }
                }

                return <td id='input' aria-label='input'> - </td>;
            };

            return (
                <tr key={`${product.name}-${index + 1}`}>
                    <td style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    >
                        {renderProductName()}
                    </td>
                    <td id='input' aria-label='input'>
                        {product.totalQuantity.toString()}
                    </td>
                    <td id='input' aria-label='input'>
                        {`${formatCurrency(currency)}${product.price}`}
                    </td>
                    {renderPriceAfterDiscount()}
                    {getDiscountTotal()}
                    <td id='bold'>
                        {`${formatCurrency(currency)}${product.total ? formatPrice(renderProductTotal()) : '-'}`}
                    </td>
                </tr>
            );
        });
    };
    const renderDiscountAndCharge = () => {
        let discountText = '';
        if (discountPercent) discountText = `(${discountPercent}%)`;

        return (
            <tr>
                <td colSpan={3} aria-label='spacing' />
                <td>
                    <div id='custom'>
                        <Text>Subtotal</Text>
                        <Text style={{ whiteSpace: 'nowrap' }}>
                            {`Discount ${discountText}`}
                        </Text>
                        <Text>Shipping</Text>

                    </div>
                </td>
                <td colSpan={1}>
                    <div id='custom'>
                        <Text>{`${formatCurrency(currency)}${formatPrice(calculateSubTotal())}`}</Text>
                        <Text id='edit'>
                            {`-${formatCurrency(currency)}${formatPrice(Number(discountPrice))}`}
                        </Text>
                        <Text id='edit'>
                            {`${formatCurrency(currency)}${formatPrice(Number(shippingCost))}`}
                        </Text>
                    </div>
                </td>
            </tr>
        );
    };
    const renderTax = () => {
        if (!taxPrice) return `${formatCurrency(currency)}0.00`;
        return `${formatCurrency(currency)}${formatPrice(Number(taxPrice))}`;
    };
    const renderTotal = () => {
        let taxValue = '';
        if (taxPercent) taxValue = `(${taxPercent}%)`;

        return (
            <tr id='total'>
                <td colSpan={3} aria-label='spacing' />
                <td>
                    <div id='custom'>
                        <Text>
                            Subtotal
                        </Text>
                        <Text style={{ whiteSpace: 'nowrap' }}>
                            {`GST ${(disableTax || !isTaxApplied) ? '' : taxValue}`}
                        </Text>
                        <Text id='bold'>
                            Total
                        </Text>
                    </div>
                </td>
                <td>
                    <div id='custom'>
                        <Text>
                            {`${formatCurrency(currency)}${formatPrice(calculateTotal())}`}
                        </Text>
                        <Text>
                            {renderTax()}
                        </Text>
                        <Text id='bold'>
                            {`${formatCurrency(currency)}${formatPrice(calculateTotalWithTax())}`}
                        </Text>
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <TableContainer>
            <thead>
                <tr>
                    {tableHeaders.map((item) => (
                        <th key={item.header} style={{ textAlign: 'center' }}>
                            {item.header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {renderProducts()}
                {renderDiscountAndCharge()}

                {renderTotal()}
            </tbody>
        </TableContainer>
    );
};

const TableContainer = styled.table`
    width: 100%;
    height: 100%;
    border-radius: 15px;

    table-layout: fixed;
    border-collapse: collapse;

    margin: auto;

    background-color: white;

    th:first-of-type {
        width: 30%;
    }

    tr:not(#total, #button) {
        border-bottom: 1px solid ${props => props.theme.colors.border};
    }
    th {
        font-size: 18px;
        font-weight: 700;
        color: ${props => props.theme.fontColor.primary};
        padding: 1rem 2rem;

        @media (max-width: 1440px) {
            padding: 1rem;
        }
    }
    td {
        padding: 2rem;

        @media (max-width: 1440px) {
            padding: 1rem;
        }
    }

    #bold {
        font-size: 18px;
        font-weight: 700;

        @media (max-width: 1440px) {
            font-size: 16px;
        }
    }

    #input {
        text-align: left;
        padding-left: 2rem;
        padding-right: 3rem;
    }

    #custom {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 80%;
    }

    #edit {
        align-items: center;
        gap: 0.5rem;
        white-space: wrap;
    }
`;

const ProductNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
`;

const ProductNameLabel = styled(Text)`
    // white-space: wrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
`;

export default ProductViewer;
