import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import { DeleteShipmentTrackingPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchDeleteShipmentTracking(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderDeleteShipmentTrackingAttempt', handleDeleteShipmentTracking, api);
}
function* handleDeleteShipmentTracking(api: OrderGateway, data: DeleteShipmentTrackingPayload) {
    const authToken = yield* select((state) => state.auth.authToken);

    const response = yield* call([api, api.deleteShipmentTracking], {
        authToken,
        id: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderDeleteShipmentTrackingFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderDeleteShipmentTrackingSuccess());
        yield put(Actions.orderGetAllShipmentTrackingAttempt({ orderId: data.payload.orderId }));
        yield put(Actions.orderSetDeleteShipmentTrackingModalIsOpen(false));
        // NavActions.navToOrderDetails(data.payload.orderId);
        toast.success('Tracking deleted!');
    }
}
