import React from 'react';

import styled from 'styled-components';

import Button from 'components/atoms/Button';
import DropdownButton from 'components/atoms/DropdownButton';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import { DiscountOptionLov } from 'lov/ProductSelectorLov';

interface AddDiscountModalProps {
    isOpen: boolean;
    onCancel: () => void;

    discountType: string;
    onDiscountTypeChange: (value: string) => void;

    discountValue: string;
    onDiscountValueChange: (value: string) => void;

    onSubmit: () => void;
}

const AddDiscountModal: React.FC<AddDiscountModalProps> = (props: AddDiscountModalProps) => {
    const {
        isOpen,
        onCancel,
        discountType,
        onDiscountTypeChange,
        discountValue,
        onDiscountValueChange,
        onSubmit,
    } = props;

    return (
        <Modal
            isModalOpen={isOpen}
            onClosed={onCancel}
            title='Add Discount'
        >
            <Container>
                <DropdownButton
                    label='Discount type'
                    options={DiscountOptionLov}
                    value={discountType}
                    onSelect={(e) => onDiscountTypeChange(e as string)}
                />
                <Input
                    header='Discount value'
                    value={discountValue}
                    onChange={(e) => onDiscountValueChange(e.target.value)}
                />
                <Text variant={TextVariant.h3}>
                    Enter 0 to reset discount value
                </Text>
                <ButtonContainer>
                    <div>
                        <Button label='Discard' variant={ButtonVariant.primary} onClick={onCancel} />
                        <Button label='Add Discount' variant={ButtonVariant.secondary} onClick={onSubmit} />
                    </div>
                </ButtonContainer>
            </Container>
        </Modal>
    );
};

const Container = styled.div`
    width: 30rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-block: 1.5rem;
    width: 100%;

    > div {
        display: flex;
        gap: 1rem;
        width: 100%;
    }
`;

export default AddDiscountModal;
