import React from 'react';

import { RotatingLines } from 'react-loader-spinner';
import styled from 'styled-components';

import theme from 'styles/theme';

interface LoadingIndicatorProps {
    size?: number;
    color?: string;
}

const LoadingIndicator = (props: LoadingIndicatorProps): JSX.Element => {
    const {
        size = 40,
        color = theme.colors.primary,
    } = props;

    return (
        <Container>
            <RotatingLines
                visible
                width={size.toString()}
                strokeColor={color}
                strokeWidth='2'
                animationDuration='0.75'
            />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export default LoadingIndicator;
