import React, { useState } from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import { useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/auth/Selectors';

import { IOrderComment } from 'entities/order';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

interface ComponentProps {
    comment: IOrderComment[];

    onDeleteIconClicked: (comment: IOrderComment) => void;
    onEditIconClicked: (comment: IOrderComment) => void;
}

const CommentList = (props: ComponentProps): JSX.Element => {
    const {
        comment,
        onDeleteIconClicked,
        onEditIconClicked,
    } = props;

    const userInfo = useAppSelector(Selectors.getUserInfo);

    const renderActionButtons = (data: IOrderComment) => {
        if (data.personInChargeId === userInfo?.userId) {
            return (
                <ButtonsContainer>
                    <ActionIconsContainer
                        type='button'
                        onClick={() => onEditIconClicked(data)}
                    >
                        <EditIcon height={20} />
                    </ActionIconsContainer>
                    <ActionIconsContainer
                        type='button'
                        onClick={() => onDeleteIconClicked(data)}
                    >
                        <DeleteIcon height={20} />
                    </ActionIconsContainer>
                </ButtonsContainer>

            );
        }

        return null;
    };

    const renderComments = () => {
        if (comment.length === 0) {
            return (
                <CommentsContainer>
                    <Text variant={TextVariant.default} style={{ fontWeight: 'bold' }}>
                        No comments
                    </Text>
                </CommentsContainer>
            );
        }
        if (comment.length > 0) {
            return comment.map((data) => (
                <CommentsContainer key={data._id}>
                    <NameAndTimeContainer>
                        <Text variant={TextVariant.h3} style={{ fontWeight: 'bold', letterSpacing: '0.025rem' }}>
                            <span style={{ fontWeight: 'normal' }}>
                                Created by
                            </span>
                            {' '}
                            {data.personInCharge}
                            {' '}
                            <span style={{ fontWeight: 'normal' }}>
                                at
                            </span>
                            {' '}
                            {dayjs(data.createdAt).format('DD MMMM YYYY - hh:mm a')}
                        </Text>
                        <Text variant={TextVariant.default} style={{ fontWeight: 'bold' }}>
                            {data.edited ? <span>Edited</span> : null}
                        </Text>
                    </NameAndTimeContainer>
                    <Input
                        actionIcons={renderActionButtons(data)}
                        value={data.text}
                        disabled
                        style={{
                            borderRadius: '10px',
                            paddingRight: '5rem',
                        }}
                    />
                </CommentsContainer>
            ));
        }

        return null;
    };

    return <div>{renderComments()}</div>;
};

const CommentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
`;

const NameAndTimeContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
`;

const ActionIconsContainer = styled.button`
    border: none;
    background-color: transparent;
`;

export default CommentList;
