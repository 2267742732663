import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';

import { GetReportChartDataParams, GetReportChartDataResponse, GetReportDetailsParams, GetReportDetailsResponse, GetReportOrderListParams, IReportGateway, OrderOverviewData } from './ReportBase';

export default class ReportGateway extends IReportGateway {
    async getReportDetails(params: GetReportDetailsParams): GatewayResponse<GetReportDetailsResponse> {
        const { authToken, startDate, endDate } = params;

        const response: ApiResponse<GetReportDetailsResponse> = await this.api.get(
            '/salesReport/',
            { startDate, endDate },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getReportOrderList(params: GetReportOrderListParams): GatewayResponse<OrderOverviewData> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<OrderOverviewData> = await this.api.get(
            '/salesReport/orderlist',
            rest,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getReportChartData(params: GetReportChartDataParams): GatewayResponse<GetReportChartDataResponse> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<GetReportChartDataResponse> = await this.api.get(
            '/salesReport/chartData',
            rest,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }
}
