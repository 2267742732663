import React from 'react';

import styled from 'styled-components';

import theme from 'styles/theme';

import { TextVariant } from './variants/TextVariant';
import Text from './Text';

const Footer = (): JSX.Element => {
    return (
        <FooterContainer>
            <ContentContainer>
                <Text
                    variant={TextVariant.h4}
                    style={{ padding: '1rem', margin: '1rem', color: theme.colors.tertiary }}
                >
                    &copy; 2024 Saltyskins Sdn Bhd (201101011385). All Rights Reserved.
                </Text>
            </ContentContainer>
        </FooterContainer>
    );
};

const FooterContainer = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    background-color: black;
`;

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    // justify-content: left;
    align-items: center;

    margin-right: auto;
    margin-left: 13rem;
`;

export default Footer;
