import React, { useState } from 'react';

import styled from 'styled-components';

import { ICustomerAddress } from 'entities/address';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import theme from 'styles/theme';

import { BadgeVariant } from './variants/BadgeVariant';
import { ButtonVariant } from './variants/ButtonVariant';
import { TextVariant } from './variants/TextVariant';
import Button from './Button';
import EditAddressModal from './EditAddressModal';
import StatusBadge from './StatusBadge';
import Text from './Text';

interface AddressDisplayListProps {
    label: string;
    addresses?: ICustomerAddress[];

    required?: boolean;
    editable?: boolean;
    onChange?: (addresses: ICustomerAddress[]) => void;

    error?: string;
}

const AddressDisplayList = (props: AddressDisplayListProps): JSX.Element | null => {
    const { label, editable, addresses = [], required, onChange, error } = props;

    const [editAddressModalIsOpen, setEditAddressModalIsOpen] = useState(false);
    const [addAddressModalIsOpen, setAddAddressModalIsOpen] = useState(false);
    const [addressToEdit, setAddressToEdit] = useState<ICustomerAddress | undefined>(undefined);

    const onAddAddressClicked = () => {
        setAddressToEdit(undefined);
        setAddAddressModalIsOpen(true);
    };

    const onEditAddressClicked = (editAdd: ICustomerAddress) => {
        setAddressToEdit(editAdd);
        setEditAddressModalIsOpen(true);
    };

    const onAddressRemoved = (addressIndex?: number) => {
        if (!onChange) return;

        let newAddressList = addresses.filter((_item, index) => index !== addressIndex);

        // if the default address is removed, we'll need to make another address the default.
        const defaultAddress = newAddressList.find((item) => item.default);
        if (!defaultAddress) {
            newAddressList = newAddressList.map((item, index) => {
                if (index === 0) {
                    return {
                        ...item,
                        default: true,
                    };
                }
                return item;
            });
        }

        onChange(newAddressList);
    };

    const onAddressChanged = (updatedAddress: ICustomerAddress) => {
        if (!onChange) return;

        onChange(addresses.map((item) => {
            if (item.addressName === addressToEdit?.addressName) {
                return updatedAddress;
            }

            // if this address is set as the default, we'll need to make sure all other addresses are not the default
            if (updatedAddress.default) {
                return {
                    ...item,
                    default: false,
                };
            }

            return item;
        }));

        setEditAddressModalIsOpen(false);
    };

    const onAddAddress = (newAddress: ICustomerAddress) => {
        if (!onChange) return;

        setAddAddressModalIsOpen(false);

        let currentAddresses = addresses;

        if (newAddress.default) {
            currentAddresses = currentAddresses.map((item) => {
                if (item.default) {
                    return {
                        ...item,
                        default: false,
                    };
                }
                return item;
            });
        }
        if (currentAddresses.length === 0) {
            newAddress.default = true;
        }
        onChange(currentAddresses.concat(newAddress));
    };

    const renderAddresses = () => {
        if (addresses.length <= 0) {
            return (
                <AddressText>
                    <Text>There is currently no address set. Add one below.</Text>
                </AddressText>
            );
        }

        return addresses.map(renderAddress);
    };

    const renderAddress = (item: ICustomerAddress, index?: number) => {
        const { addressName, street1, street2, city, state, country, postcode, default: isDefault } = item;

        let streetAdd = street1;
        if (street2) streetAdd = `${streetAdd}, ${street2}`;

        const fullAddress = `${streetAdd}, ${postcode}, ${city}, ${state}, ${country}`;

        return (
            <AddressContainer key={fullAddress}>
                <AddressContent>
                    <AddressText>
                        <AddressNameRow>
                            <AddressNameText>{addressName}</AddressNameText>
                            {isDefault && (
                                <StatusBadge variant={BadgeVariant.DefaultAddress} />
                            )}
                        </AddressNameRow>
                        <Text>{fullAddress}</Text>
                    </AddressText>

                    <BadgeContainer>
                        {renderButtons(index)}
                    </BadgeContainer>
                </AddressContent>

                {index !== (addresses.length - 1) && (
                    <Separator />
                )}
            </AddressContainer>
        );
    };

    const renderButtons = (index?: number) => {
        if (editable) {
            return (
                <ButtonRow>
                    <IconContainer
                        onClick={() => onEditAddressClicked(addresses[index || 0])}
                        type='button'
                    >
                        <EditIcon height={20} />
                    </IconContainer>
                    <IconContainer
                        onClick={() => onAddressRemoved(index)}
                        type='button'
                    >
                        <DeleteIcon height={20} />
                    </IconContainer>
                </ButtonRow>
            );
        }

        return null;
    };

    const renderAddNewAddress = () => {
        if (!editable) return null;

        return (
            <AddNewButtonContainer>
                <Button
                    label='Add a new address'
                    variant={ButtonVariant.link}
                    onClick={onAddAddressClicked}
                />
            </AddNewButtonContainer>
        );
    };

    return (
        <Container>
            <Label>
                {label}
                {label && (
                    required && <Text variant={TextVariant.error}>*</Text>
                )}
            </Label>

            <AddressListContainer error={!!error && addresses.length <= 0}>
                {renderAddresses()}
            </AddressListContainer>

            {renderAddNewAddress()}

            <EditAddressModal
                inputError={error}
                isOpen={editAddressModalIsOpen}
                onCancel={() => {
                    setEditAddressModalIsOpen(false);
                    setAddressToEdit(undefined);
                }}
                onSave={onAddressChanged}
                address={addressToEdit}
            />

            <EditAddressModal
                inputError={error}
                isOpen={addAddressModalIsOpen}
                onCancel={() => setAddAddressModalIsOpen(false)}
                onSave={onAddAddress}
            />
        </Container>
    );
};

const Container = styled.div`

`;

export const Label = styled.div`
    font-size: ${theme.fontSize.default};
    font-weight: 500;
    font-family: ${theme.fonts.primary};
    color: ${theme.fontColor.tertiary};

    margin-bottom: 6px;
`;

const AddressListContainer = styled.div<{ error?: boolean }>`
    font-size: ${props => props.theme.fontSize.sm};
    line-height: ${props => props.theme.lineHeight.default};
    font-weight: ${props => props.theme.fontWeight.default};
    font-family: ${props => props.theme.fonts.primary};

    width: 100%;
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.grey_light};
    border: ${props => (props.error ? '2px solid #D15757' : '2px solid white')};
    border-radius: 2rem;
    padding: .75rem 1.5rem;
`;

const AddressContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const AddressContent = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
`;

const Separator = styled.div`
    height: 1px;
    width: 100%;

    background-color: ${theme.colors.border};

    margin-top: 4px;
    margin-bottom: 8px;
`;

const AddressText = styled.div`
    display: flex;
    flex-direction: column;
    flex: 6;
`;

const AddressNameRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 8px;
`;

const AddressNameText = styled(Text)`
    font-weight: 700;
    color: black;
`;

const BadgeContainer = styled.div`
    display: flex;
    flex: 1;

    justify-content: center;
    align-items: center;
`;

const IconContainer = styled.button`
    border: none;
    background-color: transparent;
`;

const AddNewButtonContainer = styled.div`
    margin-top: 4px;
    width: 50%;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export default AddressDisplayList;
