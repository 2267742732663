import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { ICompanyDetails, ICompanyList } from 'entities/company';

export interface GetCompanyListApiParams{
    authToken: string;
    index: number;
}

export interface GetCompanyListResponse {
    data: ICompanyList[];
}
export interface GetCompanyDetailsApiParams {
    authToken: string;
    id: string;
}

export abstract class ICompanyGateway extends Gateway {
    abstract getCompanyList(params: GetCompanyListApiParams): GatewayResponse<GetCompanyListResponse>

    abstract getCompanyDetails(params: GetCompanyDetailsApiParams): GatewayResponse<ICompanyDetails>
}
