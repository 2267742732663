import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import OrderGateway from 'api/Order';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { GetAllShipmentTrackingPayload } from 'redux/slices/order/types';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetAllShipmentTracking(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderGetAllShipmentTrackingAttempt', handleGetAllShipmentTracking, api);
}
function* handleGetAllShipmentTracking(api: OrderGateway, data: GetAllShipmentTrackingPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getAllShipmentTracking], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderGetAllShipmentTrackingFailure(response.message || ''));
        if (response.code !== 'NETWORK_ERROR') {
            toast.error(response.message);
        }
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderGetAllShipmentTrackingSuccess(response.data));
    }
}
