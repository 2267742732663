import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import CustomersGateway from 'api/Customer';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import Selectors from 'redux/slices/customers/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';

export default function* watchCreateCustomer(api: CustomersGateway): SagaWatcherReturnType {
    yield takeEvery('customers/customersCreateCustomerAttempt', handleCreateCustomer, api);
}

function* handleCreateCustomer(api: CustomersGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const customerParams = yield* select(Selectors.getCustomerDetails);

    if (!customerParams || !customerParams.contactPerson || !customerParams.companyName
        || !customerParams.email || !customerParams.address
        || !customerParams.companyAccountId) {
        yield put(Actions.customersCreateCustomerFailure('Please enter all fields before submitting.'));
        return;
    }

    if (!Utils.Validators.validateEmail(customerParams.email)) {
        yield put(Actions.customersCreateCustomerFailure('Invalid email address. Please enter a valid email address.'));
        return;
    }

    // if (!Utils.Validators.validatePhoneNumber(customerParams.contact)) {
    //     yield put(Actions.customersCreateCustomerFailure('Invalid phone number. Please enter a valid phone number.'));
    //     return;
    // }

    const response = yield* call([api, api.createCustomer], {
        authToken,
        contactPerson: customerParams.contactPerson,
        companyName: customerParams.companyName,
        email: customerParams.email,
        phoneNo: customerParams.contact ?? 'N/A',
        address: customerParams.address,
        companyAccountId: customerParams.companyAccountId,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.customersCreateCustomerFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.customersCreateCustomerSuccess());

        toast.success('Customer created.');

        NavActions.navToCustomers();
    }
}
