import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';
import { ICustomer } from 'entities/customer';

import { CreateCustomerApiParams, DeleteCustomerApiParams, EditCustomerApiParams, GetCustomerDetailsApiParams, GetCustomerOrdersApiParams, GetCustomerOrdersApiResponse, GetCustomersApiParams, GetCustomersApiResponse, ICustomerGateway } from './CustomerBase';

export default class CustomerGateway extends ICustomerGateway {
    async getCustomers(params: GetCustomersApiParams): GatewayResponse<GetCustomersApiResponse> {
        const { authToken, ...searchParams } = params;
        const response: ApiResponse<GetCustomersApiResponse> = await this.api.get(
            '/customers',
            searchParams,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async createCustomer(params: CreateCustomerApiParams): GatewayResponse<string> {
        const { authToken, phoneNo, ...customerDetails } = params;

        let newPhoneNo;
        if (phoneNo === '') {
            newPhoneNo = 'N/A';
        } else {
            newPhoneNo = phoneNo;
        }
        const response: ApiResponse<string> = await this.api.post(
            '/customers',
            {
                ...customerDetails,
                phoneNo: newPhoneNo,
            },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getCustomerDetails(params: GetCustomerDetailsApiParams): GatewayResponse<ICustomer> {
        const { authToken, customerId } = params;

        const response: ApiResponse<ICustomer> = await this.api.get(
            `/customers/${customerId}`,
            {},
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getCustomerOrders(params: GetCustomerOrdersApiParams): GatewayResponse<GetCustomerOrdersApiResponse> {
        const { authToken, customerId, index, searchQuery } = params;

        const response: ApiResponse<GetCustomerOrdersApiResponse> = await this.api.get(
            `/customers/getCustomerOrder/${customerId}`,
            { index, searchQuery },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async editCustomer(params: EditCustomerApiParams): GatewayResponse<void> {
        const { authToken, phoneNo, ...customerParams } = params;
        let newPhoneNo;
        if (phoneNo === '') {
            newPhoneNo = 'N/A';
        } else {
            newPhoneNo = phoneNo;
        }
        const response: ApiResponse<void> = await this.api.put(
            '/customers',
            {
                ...customerParams,
                phoneNo: newPhoneNo,
            },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deleteCustomer(params: DeleteCustomerApiParams): GatewayResponse<void> {
        const { authToken, customerId } = params;

        const response: ApiResponse<void> = await this.api.delete(
            '/customers',
            { id: customerId },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }
}
