import React, { useEffect } from 'react';

import DropdownButton from 'components/atoms/DropdownButton';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import Selectors from 'redux/slices/company/Selectors';

interface OrderCompanyInputProps {
    disabled?: boolean;
    value?: string;
    mandatory?: boolean;

    error?: boolean;

    onCompanyChanged: (companyId: string) => void;
}

const CompanyInput = (props: OrderCompanyInputProps): JSX.Element => {
    const { disabled, value, onCompanyChanged, mandatory = false, error } = props;

    const companyList = useAppSelector(Selectors.getCompanyList);
    const companyListLoading = useAppSelector(Selectors.getCompanyListAttempting);
    const companyListError = useAppSelector(Selectors.getCompanyListError);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!companyList || !companyList.length) {
            dispatch(Actions.companyGetCompanyListAttempt({ index: 0 }));
        }
    }, []);

    const companyAccountOptions = companyList.length > 0 ? companyList.map((company) => ({
        label: company.name,
        value: company.id,
    })) : [];

    return (
        <DropdownButton
            error={error}
            label='Company account'
            disabled={disabled}
            value={value}
            placeholder='Select account'
            mandatory={mandatory}
            options={companyAccountOptions ?? []}
            onSelect={(companyId) => onCompanyChanged(companyId as string)}
        />
    );
};

export default CompanyInput;
