import _ from 'lodash';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import ProductsGateway from 'api/Products';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { GetPrintMethodVariantPayload } from 'redux/slices/products/types';
import { SagaWatcherReturnType } from 'sagas/types';

import { IPrintMethodVariantType } from 'entities/products';

export default function* watchGetPrintMethodVariant(api: ProductsGateway): SagaWatcherReturnType {
    yield takeEvery('products/productsGetPrintMethodVariantAttempt', handleGetPrintMethodVariant, api);
}

export function checkDuplicateOrEmpty(array: string[]): string[] | [] {
    // Early return for empty or array of all empty strings
    if (_.isEmpty(array) || _.every(array, _.isEmpty)) {
        return [];
    }

    // Use Lodash functions for efficient processing
    return _.uniq(_.compact(array));
}

function* handleGetPrintMethodVariant(api: ProductsGateway, data: GetPrintMethodVariantPayload) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const { index, ...params } = data.payload;
    const response = yield* call([api, api.getPrintMethodVariant], {
        authToken,
        ...params,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.productsGetPrintMethodVariantFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.productsGetPrintMethodVariantSuccess({
            index: index ?? 0,
            data: response.data,
        }));

        const colorCount = response.data.map(item => (item.colorCount ? item.colorCount : ''));
        const block = response.data.map(item => (item.block ? item.block : ''));

        const variant:IPrintMethodVariantType = {
            colorCount: checkDuplicateOrEmpty(colorCount),
            block: checkDuplicateOrEmpty(block),
        };

        yield put(Actions.productsSetPrintMethodVariantType({
            index: index ?? 0,
            data: variant,
        }));
    }
}
