import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IAuthUser } from 'entities/auth';

export interface RefreshParam {
    refreshToken: string;
}

export interface LoginParams {
    email: string;
    password: string;
}

export type ForgotPasswordParam = Omit<LoginParams, 'password' | 'rememberMe'>

export type ResetPasswordParams = Pick<LoginParams, 'email'> & { otp: string, newPassword: string }

export type ChangePasswordParams = {
    authToken: string;
    currentPassword: string;
    newPassword: string;
}

// ? Responses
export interface LoginResponse {
    authToken: string;
    authTokenExp: number;
    refreshToken: string;
    refreshTokenExp: number;
    userInfo: IAuthUser;
}

export abstract class IAuthGateway extends Gateway {
    abstract refresh(params: RefreshParam): GatewayResponse<LoginResponse | null>;

    abstract login(params: LoginParams): GatewayResponse<LoginResponse | null>;

    abstract forgotPassword(params: ForgotPasswordParam): GatewayResponse<string | null>;

    abstract resetPassword(params: ResetPasswordParams): GatewayResponse<string | null>;

    abstract changePassword(params: ChangePasswordParams): GatewayResponse<string | null>;
}
