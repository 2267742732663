import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { StatusFilterOption } from 'components/molecules/TableFilters';

import { ICustomerAddress } from 'entities/address';
import CountriesLov from 'lov/CountriesAndStates.json';

import { ButtonVariant } from './variants/ButtonVariant';
import { TextVariant } from './variants/TextVariant';
import Button from './Button';
import Checkbox from './Checkbox';
import DropdownButton from './DropdownButton';
import Input from './Input';
import Modal from './Modal';
import Text from './Text';

interface EditAddressModalProps {
    isOpen: boolean;

    address?: ICustomerAddress;

    onCancel: () => void;
    onSave: (address: ICustomerAddress) => void;
    noAddressName?: boolean;

    inputError?: string;
    loading?: boolean;
}

const EditAddressModal = (props: EditAddressModalProps): JSX.Element => {
    const { isOpen, address, onCancel, onSave, noAddressName, inputError, loading } = props;

    const [addressName, setAddressName] = useState<string | undefined>(address?.addressName);
    const [street1, setStreet1] = useState<string | undefined>(address?.street1);
    const [street2, setStreet2] = useState<string | undefined>(address?.street2);
    const [city, setCity] = useState<string | undefined>(address?.city);
    const [selectedState, setSelectedState] = useState<string | undefined>(address?.state);
    const [postcode, setPostcode] = useState<string | undefined>(address?.postcode);
    const [country, setCountry] = useState<string | undefined>(address?.country);
    const [isAddressDefault, setIsAddressDefault] = useState<boolean>(address?.default || false);

    const [error, setError] = useState('');

    useEffect(() => {
        if (isOpen && address) {
            setAddressName(address?.addressName);
            setStreet1(address?.street1);
            setStreet2(address?.street2);
            setCity(address?.city);
            setSelectedState(address?.state);
            setPostcode(address?.postcode);
            setCountry(address?.country);
            setIsAddressDefault(address?.default || false);
        }

        return () => {
            setAddressName(undefined);
            setStreet1(undefined);
            setStreet2(undefined);
            setCity(undefined);
            setSelectedState(undefined);
            setPostcode(undefined);
            setCountry(undefined);
            setIsAddressDefault(false);
            setError('');
        };
    }, [isOpen, address]);

    const allCountries = CountriesLov.map((item) => ({
        label: item.name,
        value: item.name,
    }));

    let allStates: StatusFilterOption[] = [];
    if (country) {
        const selectedCountry = CountriesLov.find((item) => item.name === country);

        if (selectedCountry) {
            allStates = selectedCountry.states.map((item) => ({
                label: item.name,
                value: item.name,
            }));
        }
    }

    const [filteredCountries, setFilteredCountries] = useState(allCountries);
    const [filteredStates, setFilteredStates] = useState(allStates);

    useEffect(() => {
        if (country) {
            const selectedCountry = CountriesLov.find((item) => item.name === country);

            if (selectedCountry) {
                setFilteredStates(selectedCountry.states.map((item) => ({
                    label: item.name,
                    value: item.name,
                })));
            }
        }
    }, [country]);

    const onSearchCountry = (query: string) => {
        const lowercasedQuery = query.toLowerCase();
        const filtered = allCountries.filter((nation) => nation.label.toLowerCase().includes(lowercasedQuery));
        setFilteredCountries(filtered);
    };

    const onSearchStates = (query: string) => {
        const lowercasedQuery = query.toLowerCase();
        const filtered = allStates.filter((state) => state.label.toLowerCase().includes(lowercasedQuery));
        setFilteredStates(filtered);
    };

    const onSaveClicked = () => {
        if (noAddressName) {
            if (!street1 || !city || !selectedState || !postcode || !country) {
                setError('Please enter all mandatory fields.');
                return;
            }

            onSave({
                street1,
                street2,
                city,
                state: selectedState,
                postcode,
                country,
            });

            return;
        }

        if (!addressName || !street1 || !city || !selectedState || !postcode || !country) {
            setError('Please enter all mandatory fields.');
            return;
        }

        onSave({
            addressName,
            default: isAddressDefault,
            street1,
            street2,
            city,
            state: selectedState,
            postcode,
            country,
        });
    };

    return (
        <Modal
            isModalOpen={isOpen}
            onClosed={onCancel}
            onClick={onCancel}
            title={address ? 'Edit address' : 'Add new address'}
        >
            <Container>
                {noAddressName ? null : (
                    <Input
                        error={!!inputError && !addressName}
                        header='Address name'
                        subtitle='Name for this address (home, office, etc.)'
                        value={(addressName ?? address?.addressName) || ''}
                        onChange={(e) => setAddressName(e.target.value)}
                        tabIndex={0}
                        required
                    />
                )}

                <InputRow>
                    <Input
                        error={!!inputError && !street1}
                        header='Street 1'
                        value={(street1 ?? address?.street1) || ''}
                        onChange={(e) => setStreet1(e.target.value)}
                        required
                    />
                    <Input
                        header='Street 2'
                        value={(street2 ?? address?.street2) || ''}
                        onChange={(e) => setStreet2(e.target.value)}
                    />
                </InputRow>

                <InputRow>
                    <Input
                        error={!!inputError && !city}
                        header='City'
                        value={(city ?? address?.city) || ''}
                        onChange={(e) => setCity(e.target.value)}
                        required
                    />
                    <Input
                        error={!!inputError && !postcode}
                        header='Postcode'
                        value={(postcode ?? address?.postcode) || ''}
                        onChange={(e) => setPostcode(e.target.value)}
                        required
                    />
                </InputRow>

                <InputRow>
                    <DropdownButton
                        error={!!inputError && !country}
                        label='Country'
                        value={(country ?? address?.country) || ''}
                        searchable
                        options={filteredCountries || allCountries}
                        onSelect={(selectedCountry) => setCountry(selectedCountry as string)}
                        mandatory
                        onSearch={onSearchCountry}
                    />
                    <DropdownButton
                        error={!!inputError && !selectedState}
                        label='State'
                        disabled={allStates.length <= 0}
                        value={(selectedState ?? address?.state) || ''}
                        searchable
                        options={filteredStates || allStates}
                        onSelect={(state) => setSelectedState(state as string)}
                        mandatory
                        onSearch={onSearchStates}
                    />
                </InputRow>

                {!noAddressName && (
                    <Checkbox
                        label='Set address as default'
                        isChecked={isAddressDefault}
                        onChange={(checked) => setIsAddressDefault(checked)}
                    />
                )}
            </Container>

            {error && (
                <ErrorContainer>
                    <Text variant={TextVariant.error}>
                        {error}
                    </Text>
                </ErrorContainer>
            )}

            <ButtonRow>
                <Button
                    label='Cancel'
                    variant={ButtonVariant.primary}
                    onClick={onCancel}
                    disabled={loading}
                />

                <Button
                    label='Save'
                    variant={ButtonVariant.default}
                    onClick={onSaveClicked}
                    loading={loading}
                />
            </ButtonRow>
        </Modal>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 600px;
    gap: 20px;
`;

const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    gap: 20px;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 20px;
    margin-top: 32px;
`;

const ErrorContainer = styled.div`
    margin-top: 16px;
`;

export default EditAddressModal;
