import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { AuthRoleEnum } from 'entities/auth';
import { IUserManagement, IUserManagementDetails } from 'entities/userManagement';

export interface GetUserManagementApiParams {
    authToken: string;
    index: number;
    searchQuery?: string;
}

export interface GetUserManagementApiResponse {
    maxIndex: number;
    index: number;
    totalRecords: number;
    data: IUserManagement[];
}

export interface GetUserManagementDetailsApiParams {
    authToken: string;
    userId: string;
}

export interface CreateUsermanagementApiParams {
    authToken: string;

    fullName: string;
    email: string;
    password: string;
    role: AuthRoleEnum;
    supplierId?: string;
    supplierName?: string;
}

export interface EditUserManagementApiParams {
    authToken: string;

    id: string;
    role: AuthRoleEnum;
    supplierId?: string;
    fullName?: string;
    password?: string;
}

export interface DeleteUserManagementParams {
    authToken: string;
    userId: string;
}

export abstract class IUserManagementGateway extends Gateway {
    abstract getUserManagement(params: GetUserManagementApiParams): GatewayResponse<GetUserManagementApiResponse>;

    abstract getUserManagementDetails(params: GetUserManagementDetailsApiParams): GatewayResponse<IUserManagementDetails>;

    abstract createUserManagement(params: CreateUsermanagementApiParams): GatewayResponse<string>;

    abstract editUserManagement(params: EditUserManagementApiParams): GatewayResponse<string>;

    abstract deleteUserManagement(params: DeleteUserManagementParams): GatewayResponse<void>;
}
