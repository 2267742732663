import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { IHomeStatCard } from 'entities/Home';
import { IOrderStatusTotalInfo, IReportOrderOverviewData, IWeeklyReportChartData } from 'entities/report';

export type GetReportDetailsParams = {
    authToken: string;
    startDate: string;
    endDate: string;
}

export type GetReportDetailsResponse = {
    totalOrdersInfo: IHomeStatCard,
    totalOrderSalesInfo: IHomeStatCard,
    orderOverviewData: OrderOverviewData,
    orderStatusTotalInfo: IOrderStatusTotalInfo;
    chartData: number;
}

export type OrderOverviewData = {
    maxIndex: number,
    index: number,
    totalRecords: number,
    data: IReportOrderOverviewData[]
}

export type GetReportOrderListParams = {
    authToken: string;
    index: number;
    startDate: string;
    endDate: string;
    companyAccountId?: string;
}

export type GetReportChartDataParams = {
    authToken: string;
    startDate: string;
    endDate: string;
}

export type GetReportChartDataResponse = {
    weeklyData: IWeeklyReportChartData[],
    overallAvgOrderFulfillmentTime: number;
    overallAvgSourceTime: number;
    overallAvgProductionTime: number;
    overallAvgDeliveryTime: number;
}

export abstract class IReportGateway extends Gateway {
    abstract getReportDetails(params: GetReportDetailsParams): GatewayResponse<GetReportDetailsResponse>;

    abstract getReportOrderList(params: GetReportOrderListParams): GatewayResponse<OrderOverviewData>;

    abstract getReportChartData(params: GetReportChartDataParams): GatewayResponse<GetReportChartDataResponse>;
}
