import { FormFields, FormValues } from 'components/molecules/Form';

const ValidateForm = (fields: FormFields, data: FormValues): boolean => {
    if (fields.companyAccount?.mandatory && !data.companyAccountId) return false;
    if (fields.quotationDate?.mandatory && !data.quotationDate) return false;
    if (fields.poDate?.mandatory && !data.poDate) return false;
    if (fields.clientName?.mandatory && !data.clientId) return false;
    if (fields.supplierName?.mandatory && !data.supplierId) return false;
    if (fields.supplierAddress?.mandatory && !data.supplierAddress) return false;
    if (fields.shippingAddress?.mandatory && !data.shippingAddress) return false;
    if (fields.billingAddress?.mandatory && !data.billingAddress) return false;
    if (fields.deliveryAddress?.mandatory && !data.deliveryAddress) return false;
    if (fields.shippingMethod?.mandatory && !data.shippingMethod) return false;
    if (fields.remarks?.mandatory && !data.remark) return false;
    if (fields.notes?.mandatory && !data.note) return false;
    if (fields.projectName?.mandatory && !data.projectName) return false;
    if (fields.projectDueDate?.mandatory && !data.projectDueDate) return false;
    if (fields.clientEmail?.mandatory && !data.clientEmail) return false;
    if (fields.clientNumber?.mandatory && !data.clientNumber) return false;
    if (fields.paymentTerms?.mandatory && !data.paymentTerms) return false;
    if (fields.paymentDueDate?.mandatory && !data.paymentDueDate) return false;
    if (fields.paymentMethod?.mandatory && !data.paymentMethod) return false;
    // ADD PRODUCT VALIDATION HERE
    // if (fields.productList?.mandatory && !data.productList) return false;

    return true;
};

export default {
    ValidateForm,
};
