import { QuotationShippingMethodEnum } from 'entities/quotations';

const ShippingMethodsLov = [{
    label: 'East Malaysia Shipping',
    value: QuotationShippingMethodEnum.EastMalaysiaShipping,
}, {
    label: 'West Malaysia Shipping',
    value: QuotationShippingMethodEnum.WestMalaysiaShipping,
}, {
    label: 'International Shipping',
    value: QuotationShippingMethodEnum.InternationalShipping,
}];

export default ShippingMethodsLov;
