import React, { FC } from 'react';

import styled from 'styled-components';

import BackgroundImage from '../../assets/images/background.jpeg';

type AuthLayoutProps = {
  children: React.ReactNode;
}

const AuthLayout:FC<AuthLayoutProps> = (props: AuthLayoutProps) => {
    const { children } = props;

    return (
        <MainContainer>
            {children}
        </MainContainer>
    );
};

const MainContainer = styled.main`
    background-image: url(${BackgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default AuthLayout;
