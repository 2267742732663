import React from 'react';

import styled from 'styled-components';

import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import theme from 'styles/theme';

interface EnquirySubjectAndMessageProps {
    subject: string;
    message: string;
}

const EnquirySubjectAndMessage = (props: EnquirySubjectAndMessageProps): JSX.Element => {
    const { subject, message } = props;

    let messageToRender = message;

    if (message.length >= 200) {
        messageToRender = messageToRender.slice(0, 200).concat('...');
    }

    return (
        <Container>
            <Title variant={TextVariant.h3}>{subject}</Title>

            <Subtitle>{messageToRender}</Subtitle>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Title = styled(Text)`
    color: ${theme.fontColor.primary};
`;

const Subtitle = styled(Text)`
    width: 100%;
    margin-top: 12px;
`;

export default EnquirySubjectAndMessage;
