import { GetReportChartDataResponse, GetReportDetailsResponse, OrderOverviewData } from 'api/ReportBase';
import { RootState } from 'redux/store';

import { ReportReduxFilters } from './type';

const getReportDetailsAttempting = (state: RootState): boolean => state.report.actions.getReportDetails;
const getReportDetailsError = (state: RootState): string => state.report.error.getReportDetails;
const getReportDetailsData = (state: RootState): GetReportDetailsResponse => state.report.reportDetails;

const getReportFilters = (state: RootState): ReportReduxFilters => state.report.filters;

const getReportOrderListAttempting = (state: RootState): boolean => state.report.actions.getReportOrderList;
const getReportOrderListError = (state: RootState): string => state.report.error.getReportOrderList;
const getReportOrderListData = (state: RootState): OrderOverviewData => state.report.reportOrderList;

const getReportChartDataAttempting = (state: RootState): boolean => state.report.actions.getReportChartData;
const getReportChartDataError = (state: RootState): string => state.report.error.getReportChartData;
const getReportChartData = (state: RootState): GetReportChartDataResponse => state.report.reportChartData;

export default {
    getReportDetailsAttempting,
    getReportDetailsError,
    getReportDetailsData,

    getReportFilters,

    getReportOrderListAttempting,
    getReportOrderListError,
    getReportOrderListData,

    getReportChartDataAttempting,
    getReportChartDataError,
    getReportChartData,
};
