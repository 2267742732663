import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import PageHeader from 'components/atoms/PageHeader';
import Paginator from 'components/atoms/Paginator';
import Table, { IActionsArray, IHeaderArray } from 'components/atoms/Table';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import TableFilters from 'components/molecules/TableFilters';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import SupplierSelectors from 'redux/slices/suppliers/Selectors';

import NavActions from 'lib/NavActions';
import { AuthRoleEnum } from 'entities/auth';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

const SuppliersPage = (): JSX.Element => {
    const [idToDelete, setIdToDelete] = useState<string | null>(null);
    const [supplierNameToDelete, setSupplierNameToDelete] = useState<string | null>(null);

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);

    const loading = useAppSelector(SupplierSelectors.getSuppliersAttempting);
    const error = useAppSelector(SupplierSelectors.getSuppliersError);
    const { maxIndex, data: suppliers, totalRecords } = useAppSelector(SupplierSelectors.getSuppliers);

    const { currentPage, searchQuery } = useAppSelector(SupplierSelectors.getSuppliersFilters);

    const confirmationModalIsOpen = useAppSelector(SupplierSelectors.getSupplierConfirmationDeleteModalIsOpen);

    const deleteLoading = useAppSelector(SupplierSelectors.getSupplierDeleteAttempting);
    const deleteError = useAppSelector(SupplierSelectors.getSupplierDeleteError);

    const dispatch = useAppDispatch();

    const getSuppliers = () => {
        dispatch(Actions.supplierGetSuppliersAttempt());
    };

    useEffect(() => {
        dispatch(Actions.supplierResetSupplierFilters());
    }, []);

    useEffect(() => {
        if (currentPage <= maxIndex) {
            getSuppliers();
        }
    }, [currentPage]);

    const onNewSupplier = () => {
        NavActions.navToCreateSupplier();
    };

    const onDeleteSupplierCancelled = () => {
        setIdToDelete(null);
        setSupplierNameToDelete(null);

        dispatch(Actions.supplierSetDeleteModalIsOpen(false));
    };

    const onDeleteSupplierConfirmed = () => {
        if (idToDelete) dispatch(Actions.supplierDeleteSupplierAttempt({ id: idToDelete }));
    };

    const onPaginatorPressed = (page: number) => {
        dispatch(Actions.supplierSetFilters({ currentPage: page }));
    };

    const onSearchQueryChanged = (text: string) => {
        dispatch(Actions.supplierSetFilters({ searchQuery: text }));
    };

    const tableHeaders: IHeaderArray[] = [
        { header: 'id', field: 'id', hidden: true },
        { header: 'Name', field: 'supplierName', width: 300 },
        { header: 'Contact Person', field: 'contactPerson' },
        { header: 'Contact', field: 'contactNumber' },
        { header: 'Email', field: 'email' },
        { header: 'Actions', field: 'action', width: 130 },
    ];

    const tableData = suppliers.map((item) => ({
        id: item.id,
        onClick: () => NavActions.navToEditSupplier(item.id),
        cellData: {
            supplierName: item.supplierName,
            contactPerson: item.contactPersonName,
            contactNumber: item.contactPersonNumber,
            email: item.contactPersonEmail,
        },
    }));

    const tableActions: IActionsArray[] = [{
        id: 'edit',
        icon: <EditIcon />,
        onClick: (supplierId: string) => NavActions.navToEditSupplier(supplierId),
    }, {
        id: 'delete',
        icon: <DeleteIcon />,
        onClick: (supplierId: string) => {
            const selectedSupplier = suppliers.find((item) => item.id === supplierId);

            if (selectedSupplier) {
                setIdToDelete(supplierId);
                setSupplierNameToDelete(selectedSupplier.supplierName);

                dispatch(Actions.supplierSetDeleteModalIsOpen(true));
            }
        },
    }];

    return (
        <AdminLayout>
            <PageHeader subtitle={`${totalRecords} total supplier(s)`}>
                Suppliers
            </PageHeader>

            <TableFilters
                searchFilterEnabled
                searchValue={searchQuery}
                onSearchChanged={onSearchQueryChanged}
                onSearch={getSuppliers}
                addNewButtonEnabled
                addNewLabel='Add new supplier'
                onAddNew={onNewSupplier}
                addButtonVariant={ButtonVariant.secondary}
                disableAddNew={userInfo?.role === AuthRoleEnum.Executive}
            />

            <Table
                headerArrays={tableHeaders}
                dataArray={tableData}
                actions={tableActions}
                style={{ marginTop: 32, marginBottom: 32 }}
                loading={loading}
                error={error}
                disableActions={userInfo?.role === AuthRoleEnum.Executive}
            />

            <PaginationContainer>
                <Paginator
                    currentPage={currentPage}
                    onPagePressed={onPaginatorPressed}
                    maxPages={maxIndex}
                    disabled={loading}
                />
            </PaginationContainer>

            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                icon={<ConfirmationIcon />}
                title={`Delete supplier "${supplierNameToDelete}"?`}
                text="Please confirm deleting this supplier. Any documents already associated with this supplier won't be affected."
                onCancel={onDeleteSupplierCancelled}
                onConfirm={onDeleteSupplierConfirmed}
                cancelText='Cancel'
                confirmText='Confirm'
                loading={deleteLoading}
                error={deleteError}
                confirmButtonVariant={ButtonVariant.warning}
            />
        </AdminLayout>
    );
};

const PaginationContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

export default SuppliersPage;
