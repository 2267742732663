import React from 'react';

import styled from 'styled-components';

import Text from 'components/atoms/Text';

interface StatCardProps {
    label: string;
    value: string | number;
    icon: React.ReactNode;
    color: string;
    percent?: number;
    mtd?: boolean;
}

const StatCard: React.FC<StatCardProps> = (props: StatCardProps) => {
    const {
        label,
        value,
        icon,
        color,
        percent,
        mtd,
    } = props;

    function rgbToHex(r: number, g: number, b: number): string {
        const componentToHex = (c: number) => {
            const hex = c.toString(16);
            return hex.length === 1 ? `0${hex}` : hex;
        };
        return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
    }

    function brightenHex(colorCode: string, factor: number): string {
        if (!colorCode.startsWith('#') || colorCode.length !== 7) {
            throw new Error('Invalid hex color code format.');
        }

        const hex = colorCode.slice(1); // Remove the hash symbol

        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        const l = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        const adjustedFactor = 1 + factor * (1 - (l / 1000)); // Consider luminance (l)

        const newR = Math.min(255, Math.max(0, Math.round(r * adjustedFactor)));
        const newG = Math.min(255, Math.max(0, Math.round(g * adjustedFactor)));
        const newb = Math.min(255, Math.max(0, Math.round(b * adjustedFactor)));

        return rgbToHex(newR, newG, newb);
    }

    const renderPercent = () => {
        if (!percent) return null;

        const newPercent = Math.abs(percent * 100);
        return (
            <PercentContainer positivePercent={percent > 0}>
                {`${percent > 0 ? '+' : '-'} ${newPercent.toString().includes('.') ? newPercent.toFixed(0) : newPercent}%`}
            </PercentContainer>
        );
    };
    return (
        <StatCardContainer>
            <IconContainer color={color} borderColor={brightenHex(color, 0.1)}>
                {icon}
            </IconContainer>
            <ContentContainer>
                <Text id='label'>
                    {label}
                    {mtd && (
                        <span>
                            (Month to date)
                        </span>
                    )}

                </Text>
                <div id='value-container'>
                    <Text id='value'>
                        {value}
                    </Text>
                    {renderPercent()}
                </div>
            </ContentContainer>
        </StatCardContainer>
    );
};

const StatCardContainer = styled.div`
    border-radius: 15px;
    background-color: white;

    display: flex;

    padding: 1.5rem 1.75rem;
`;

const IconContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== 'color' && prop !== 'borderColor',
}) <{ color: string, borderColor: string }>`
    width: 3.5rem;
    height: 3.5rem;

    background-color: ${props => props.color};
    border: 4px solid ${props => props.borderColor};
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.5rem;

    @media (max-width: 1440px){
        width: 2.5rem;
        height: 2.5rem;

        margin-right: 1rem;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    #label {
        font-size: 1rem;

        @media (max-width: 1440px){
            font-size: 0.875rem;
        }
        > span{
            padding-left: 0.25rem;
            font-size: 0.7rem;

            @media (max-width: 1440px){
                font-size: 0.625rem;
            }
        }
    }

    #value {
        font-size: 2.5rem;
        font-weight: 600;

        @media (max-width: 1440px){
            font-size: 2rem;
        }
    }

    #value-container {
        display: flex;
        align-items: end;
        gap: 1.5rem;
    }
`;

const PercentContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== 'positivePercent',
}) <{ positivePercent: boolean }>`

    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
    white-space: nowrap;
    background-color: ${props => (props.positivePercent ? '#E7F4EE' : '#FEEDEC')};
    color: ${props => (props.positivePercent ? '#0D894F' : '#F2594F')};

    border-radius: 100px;
    width: fit-content;
    height: fit-content;
    padding: 0.25rem 0.75rem;

    @media (max-width: 1440px){
        font-size: 0.625rem;
        line-height: 0.75rem;
    }
`;

export default StatCard;
