import React from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

import theme from 'styles/theme';

interface DropdownOptions {
    label: string;
    onClick: () => void;
    isHidden?: boolean;
}

interface DropdownButtonForActionsProps {
    label?: string;

    options: DropdownOptions[];
}

const DropdownButtonForActions = (props: DropdownButtonForActionsProps): JSX.Element => {
    const { label = 'Actions', options } = props;

    return (
        <Container>
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <ActionButton type='button'>
                        {label}
                    </ActionButton>
                </DropdownMenu.Trigger>

                <StyledContent sideOffset={4} align='end'>
                    {options.map((item, index) => {
                        if (item.isHidden) return null;
                        return (
                            <React.Fragment key={item.label}>
                                <StyledItem onSelect={item.onClick}>
                                    {item.label}
                                </StyledItem>
                                {(index !== options.length - 1) && (
                                    <Separator />
                                )}
                            </React.Fragment>
                        );
                    })}
                </StyledContent>
            </DropdownMenu.Root>
        </Container>
    );
};

const Container = styled.div`

`;

const StyledContent = styled(DropdownMenu.Content)`
    min-width: 220px;
    background-color: white;
    border-radius: 6px;
    padding: 5px;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
`;

const StyledItem = styled(DropdownMenu.Item)`
    position: relative;

    border-radius: 3px;
    display: flex;
    position: relative;
    padding: 4px 16px;
    user-select: none;
    outline: none;

    font-size: ${theme.fontSize.default};
    line-height: ${theme.lineHeight.default};
    font-weight: ${theme.fontWeight.default};

    &[data-highlighted] {
        background-color: ${theme.colors.primary};
        color: white;
    }
`;

const ActionButton = styled.button`
    all: unset;

    display: flex;
    align-items: center;
    box-sizing: border-box;

    width: 100%;
    box-sizing: border-box;

    border: 1px solid ${theme.fontColor.primary};
    border-radius: 2rem;
    padding: 0 1.5rem;
    height: 47px;

    font-size: ${theme.fontSize.xs};
    line-height: ${theme.lineHeight.xs};
    font-weight: ${theme.fontWeight.default};
    text-align: center;

    @media (min-width: 1280px) {
        font-size: ${theme.fontSize.sm};
        line-height: ${theme.lineHeight.sm};
    }

    @media (min-width: 1520px) {
        font-size: ${theme.fontSize.default};
        line-height: ${theme.lineHeight.default};
    }

    background-color: ${theme.colors.tertiary};
    color: ${theme.fontColor.primary};
`;

const Separator = styled(DropdownMenu.Separator)`
    height: 1px;
    background-color: ${theme.colors.border};
`;

export default DropdownButtonForActions;
