import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

type ComponentProps = {
    isOpen: boolean;
    onCloseModal: () => void;
    onUpload: (folderName: string, file: File[]) => void;
    loading?: boolean;
    editedFolder?: string;
}

const UploadAttachmentModal: React.FC<ComponentProps> = (props: ComponentProps) => {
    const {
        isOpen,
        onCloseModal,
        onUpload,
        loading,
        editedFolder,
    } = props;

    const [folderName, setFolderName] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File[]>([]);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        return () => {
            setFolderName('');
            setSelectedFile([]);
        };
    }, [isOpen]);

    useEffect(() => {
        if (editedFolder) {
            setFolderName(editedFolder);
        }
    }, [editedFolder]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target as HTMLInputElement;
        if (target.files === null || target.files.length === 0) {
            setSelectedFile([]);
            return;
        }
        setSelectedFile(Array.from(target.files) as File[]);
    };

    const onUploadClick = () => {
        if (folderName.trim().length === 0) {
            setError('Please enter a folder name');
            return;
        }
        if (selectedFile.length === 0) {
            setError('Please select a file');
            return;
        }
        onUpload(folderName, selectedFile);
    };

    return (
        <Modal
            isModalOpen={isOpen}
            onClosed={onCloseModal}
            title='Upload Attachment'
            loading={loading}
        >
            <Container>
                <Input
                    header='Folder Name'
                    variant='inputForm'
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    disabled={!!editedFolder}
                />
                <Input
                    header='Upload File'
                    variant='file'
                    uploadMultiple
                    onChange={handleFileChange}
                />

                {error && <ErrorText variant={TextVariant.error}>{error}</ErrorText>}

            </Container>
            <ButtonRow>
                <Button
                    label='Cancel'
                    onClick={onCloseModal}
                    variant={ButtonVariant.primary}
                    disabled={loading}
                />
                <Button
                    label='Upload'
                    onClick={onUploadClick}
                    loading={loading}
                />
            </ButtonRow>
        </Modal>
    );
};
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 500px;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 12px;

    margin-top: 30px;
`;

const ErrorText = styled(Text)`
    margin-top: 12px;
`;

export default UploadAttachmentModal;
