import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import Button from 'components/atoms/Button';

import media from 'lib/Breakpoints';

const MainContainer = styled.div`
    width: 35.5rem;
    height: 24rem;
    padding: 1rem;
    gap: 1.5rem;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    background-color: white;
    border-radius: 2rem;

    ${media.lg`
        width: 40.5rem;
        height: 29rem;
        padding: 1.6rem;
        gap: 1.8rem;
    `}

    ${media.xxl`
        width: 50.5rem;
        height: 34rem;
        padding: 2rem;
        gap: 3.5625rem;
    `}
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    width: 26.25rem;

    button {
        margin-top: 0.25rem;
    }

    ${media.lg`
        width: 30rem;
        button {
            margin-top: 1.25rem;
        }
    `}

    ${media.xxl`
        width: 36.25rem;
        button {
            margin-top: 2.25rem;
        }
    `}

`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;

    ${media.lg`
        gap: 0.75rem;
    `}

    ${media.xxl`
        gap: 1rem;
    `}
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
`;

const ErrorMessage = styled.div.withConfig({
    shouldForwardProp: prop => prop !== 'error',
}) <{ error?: boolean }>`
    visibility: ${props => (props.error ? 'visible' : 'hidden')};
    height: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -0.75rem;
`;

export default {
    MainContainer,
    FormContainer,
    TitleContainer,
    InputContainer,
    ErrorMessage,
};
