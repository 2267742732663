import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetDashboardDetailsResponse } from 'api/HomeBase';

import {
    HomeReduxState,
} from './types';

const initialState:HomeReduxState = {
    actions: {
        getDashBoardDetails: false,
    },
    dashboardDetails: {
        totalOrdersInfo: {
            total: 0,
            percentageChangeFromPrevMonth: 0,
        },
        totalOrderSalesInfo: {
            total: 0,
            percentageChangeFromPrevMonth: 0,
        },
        totalMembersInfo: {
            total: 0,
            percentageChangeFromPrevMonth: 0,
        },
        chartData: {
            totalOrdersData: [],
            totalOrderSalesData: [],
            totalMembersData: [],
        },
    },
    error: {
        getDashBoardDetails: '',
    },
};

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        homeGetDashboardDetailsAttempt: (state) => {
            state.actions.getDashBoardDetails = true;
            state.error.getDashBoardDetails = '';
        },
        homeGetDashboardDetailsSuccess: (state, action: PayloadAction<GetDashboardDetailsResponse>) => {
            state.dashboardDetails = action.payload;
            state.actions.getDashBoardDetails = false;
        },
        homeGetDashboardDetailsFailure: (state, action: PayloadAction<string>) => {
            state.error.getDashBoardDetails = action.payload;
            state.actions.getDashBoardDetails = false;
        },
    },
});

export type HomeState = typeof initialState;

export default {
    actions: homeSlice.actions,
    reducers: homeSlice.reducer,
};
