import { EDiscountType, EPrintSide } from 'entities/products';

export const SideDataLov = [
    {
        label: 'Front',
        value: EPrintSide.Front,
    },
    {
        label: 'Back',
        value: EPrintSide.Back,
    },
    {
        label: 'Left',
        value: EPrintSide.Left,
    },
    {
        label: 'Right',
        value: EPrintSide.Right,
    },
];

export const SizeTypeDataLov = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL', '6XL', '7XL', '8XL'];

export const DefaultPrintMethodLov = {
    side: EPrintSide.Front,
    printCustomId: '',
    printMethod: '',
    block: '',
    colorCount: '',
};

export const DiscountOptionLov = [
    {
        label: 'Percentage',
        value: EDiscountType.PERCENTAGE,
    },
    {
        label: 'Fixed',
        value: EDiscountType.FIXED,
    },
];
