import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';
import { IQuotation } from 'entities/quotations';

import { CreateQuotationApiParams, DeleteQuotationApiParams, GetQuotationDetailsApiParams, GetQuotationsApiParams, GetQuotationsApiResponse, IQuotationsGateway, UpdateQuotationApiParams } from './QuotationsBase';

export default class QuotationsGateway extends IQuotationsGateway {
    async getQuotations(params: GetQuotationsApiParams): GatewayResponse<GetQuotationsApiResponse> {
        const { authToken, ...quotationParams } = params;
        const response: ApiResponse<GetQuotationsApiResponse> = await this.api.get(
            '/quotes/viewQuotation',
            quotationParams,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getQuotationDetails(params: GetQuotationDetailsApiParams): GatewayResponse<IQuotation> {
        const { authToken, id } = params;

        const response: ApiResponse<IQuotation> = await this.api.get(
            `/quotes/viewQuotation/${id}`,
            {},
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async createQuotation(params: CreateQuotationApiParams): GatewayResponse<string> {
        const { authToken, ...quotationParams } = params;

        const response: ApiResponse<string> = await this.api.post(
            '/quotes/createQuotation',
            quotationParams,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async updateQuotation(params: UpdateQuotationApiParams): GatewayResponse<void> {
        const { authToken, ...quotationParams } = params;

        const response: ApiResponse<void> = await this.api.put(
            '/quotes/updateQuotation',
            quotationParams,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deleteQuotation(params: DeleteQuotationApiParams): GatewayResponse<void> {
        const { authToken, ...quotationParams } = params;

        const response: ApiResponse<void> = await this.api.delete(
            '/quotes/deleteQuotation',
            quotationParams,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async exportQuotations(params: GetQuotationDetailsApiParams): GatewayResponse<Blob> {
        const { authToken, ...quotationParams } = params;

        const response: ApiResponse<Blob> = await this.api.get(
            `/quotes/quotationPDF/${quotationParams.id}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    Accept: 'application/pdf',
                },
                responseType: 'blob',
            },
        );

        return this.process(response);
    }

    async convertQuotationsToOrders(params: GetQuotationDetailsApiParams): GatewayResponse<void> {
        const { authToken, ...quotationParams } = params;

        const response: ApiResponse<void> = await this.api.post(
            'quotes/convert',
            quotationParams,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }
}
