import React from 'react';

import PageHeader from 'components/atoms/PageHeader';
import AdminLayout from 'components/Layout/AdminLayout';

const EditOrder = (): JSX.Element => {
    return (
        <AdminLayout>
            <PageHeader withBackButton>
                Edit Order
            </PageHeader>
        </AdminLayout>
    );
};

export default EditOrder;
