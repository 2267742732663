import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import { EnquiryStatusEnum, EnquiryTypeEnum, IEnquiry, IEnquiryListItem } from 'entities/enquiries';

export interface GetEnquiriesApiParams {
    authToken: string;
    currentPage: number;

    searchQuery?: string;
    dateStart?: string;
    dateEnd?: string;
    status?: EnquiryStatusEnum;
    type?: EnquiryTypeEnum;
}

export interface GetEnquiriesApiResponse {
    maxIndex: number;
    index: number;
    data: IEnquiryListItem[];
}

export interface GetEnquiryDetailsApiParams {
    authToken: string;

    id: string;
    type: EnquiryTypeEnum;
}

export interface ResolveEnquiryApiParams {
    authToken: string;

    id: string;
    type: EnquiryTypeEnum;
}

export abstract class IEnquiriesGateway extends Gateway {
    abstract getEnquiries(params: GetEnquiriesApiParams): GatewayResponse<GetEnquiriesApiResponse>;

    abstract getEnquiryDetails(params: GetEnquiryDetailsApiParams): GatewayResponse<IEnquiry>;

    abstract resolveEnquiry(params: ResolveEnquiryApiParams): GatewayResponse<null>;

    abstract deleteEnquiry(params: ResolveEnquiryApiParams): GatewayResponse<null>;
}
