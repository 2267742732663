import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import Button from 'components/atoms/Button';

import media from 'lib/Breakpoints';

const LogoContainer = styled(SVG)`
    width: 17rem;

    ${media.lg`
        width: 19rem;
    `}

    ${media.xxl`
        width: max-content;
    `}
`;
const MainContainer = styled.div`
    width: 35.5rem;
    height: 24rem;
    padding: 1rem;
    gap: 1.5rem;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    background-color: white;
    border-radius: 2rem;

    ${media.lg`
        width: 40.5rem;
        height: 29rem;
        padding: 1.6rem;
        gap: 1.8rem;
    `}

    ${media.xxl`
        width: 50.5rem;
        height: 34rem;
        padding: 2rem;
        gap: 3.5625rem;
    `}
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    width: 18rem;

    ${media.lg`
        width: 20rem;
    `}

    ${media.xxl`
        width: 25rem;
    `}

`;

const InputContainer = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .75rem;
`;

const InputLogo = styled(SVG).withConfig({
    shouldForwardProp: prop => prop !== 'error',
}) <{ error?: boolean }>`
    position: absolute;
    left: -2.5rem;
    color: ${props => (props.error ? props.theme.colors.error : props.theme.fontColor.primary)};
`;

const ErrorMessage = styled.div.withConfig({
    shouldForwardProp: prop => prop !== 'error',
}) <{ error?: boolean }>`
    visibility: ${props => (props.error ? 'visible' : 'hidden')};
    height: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -0.75rem;
`;

const ForgotPasswordButton = styled(Button)`
    width: fit-content;
    text-align: center;

    margin-top: -1.25rem;

    ${media.lg`
        margin-top: -1rem;
    `}

    ${media.xxl`
        margin-top: -0.5rem;
    `}
`;

export default {
    LogoContainer,
    MainContainer,
    FormContainer,
    InputContainer,
    InputLogo,
    ErrorMessage,
    ForgotPasswordButton,
};
