import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import CustomersGateway from 'api/Customer';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import Selectors from 'redux/slices/customers/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

import Utils from 'lib/Utils';

export default function* watchEditCustomer(api: CustomersGateway): SagaWatcherReturnType {
    yield takeEvery('customers/customersEditCustomerAttempt', handleEditCustomer, api);
}

function* handleEditCustomer(api: CustomersGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const customerParams = yield* select(Selectors.getCustomerDetails);

    if (!customerParams || !customerParams.id || !customerParams.contactPerson
        || !customerParams.companyName || !customerParams.email
        || !customerParams.address
        || !customerParams.companyAccountId) {
        yield put(Actions.customersEditCustomerFailure('We are unable to verify this customer. Please try again later.'));
        return;
    }

    if (!Utils.Validators.validateEmail(customerParams.email)) {
        yield put(Actions.customersEditCustomerFailure('Invalid email address. Please enter a valid email address.'));
        return;
    }
    // if (!Utils.Validators.validatePhoneNumber(customerParams.contact)) {
    //     yield put(Actions.customersEditCustomerFailure('Invalid phone number. Please enter a valid phone number.'));
    //     return;
    // }

    const response = yield* call([api, api.editCustomer], {
        authToken,
        id: customerParams.id,
        companyName: customerParams.companyName,
        contactPerson: customerParams.contactPerson,
        phoneNo: customerParams.contact ?? 'N/A',
        email: customerParams.email,
        address: customerParams.address,
        companyAccountId: customerParams.companyAccountId,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.customersEditCustomerFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.customersEditCustomerSuccess());

        toast.success('Customer updated.');

        yield put(Actions.customersGetCustomerDetailsAttempt({ id: customerParams.id }));
    }
}
