import React, { ButtonHTMLAttributes, FC } from 'react';

import SVG from 'react-inlinesvg';
import { ThreeDots } from 'react-loader-spinner';
import styled from 'styled-components';

import { ButtonVariant } from './variants/ButtonVariant';
import LoadingIndicator from './LoadingIndicator';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    label: string | JSX.Element;
    variant?: ButtonVariant;
    loading?: boolean;
    active?: boolean;
    icon?: string;
}

const Button: FC<ButtonProps> = (props: ButtonProps) => {
    const {
        label,
        variant = ButtonVariant.default,
        loading,
        active,
        icon,
        disabled,
        ...restOfProps
    } = props;

    const renderButtonContent = () => {
        if (loading) {
            return (
                <LoadingContainer>
                    <LoadingIndicator
                        size={25}
                        color={(variant === ButtonVariant.default || variant === ButtonVariant.secondary) ? 'white' : 'black'}
                    />
                </LoadingContainer>
            );
        }

        if (variant === ButtonVariant.menu) {
            return (
                <>
                    <ButtonLogo active={active} src={icon} />
                    {label}
                </>
            );
        }

        if (variant === ButtonVariant.primary) {
            return (
                <>
                    {label}
                    <ButtonLogo src={icon} />
                </>
            );
        }

        return label;
    };

    return (
        <CustomButton
            variant={variant}
            active={active}
            loading={loading}
            disabled={disabled}
            {...restOfProps}
        >
            {renderButtonContent()}
        </CustomButton>
    );
};

const CustomButton = styled.button.withConfig({
    shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'active',
}) <{ variant?: ButtonVariant, loading?: boolean, active?: boolean, disabled?: boolean }>`
    all: unset;

    font-size: ${props => props.theme.fontSize.xs};
    line-height: ${props => props.theme.lineHeight.xs};
    font-weight: ${props => props.theme.fontWeight.default};
    text-align: center;

    width: 100%;
    box-sizing: border-box;
    color: white;
    background-color: black;
    border-radius: 2rem;
    padding: 0 1.5rem;
    height: 47px;

    @media (min-width: 1280px) {
        font-size: ${props => props.theme.fontSize.sm};
        line-height: ${props => props.theme.lineHeight.sm};
    }

    @media (min-width: 1520px) {
        font-size: ${props => props.theme.fontSize.default};
        line-height: ${props => props.theme.lineHeight.default};
    }

    ${props => props.disabled && `
        opacity: 0.5;
        cursor: not-allowed;
    `}

    ${props => props.variant === ButtonVariant.link && `
        all: unset;

        font-size: ${props.theme.fontSize.xs};
        line-height: ${props.theme.lineHeight.xs};
        font-weight: ${props.theme.fontWeight.default};

        color: black;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: ${props.theme.colors.primary};
        }
    `}

    ${props => props.variant === ButtonVariant.menu && `
        all: unset;

        font-size: ${props.theme.fontSize.xs};
        line-height: ${props.theme.lineHeight.xs};
        font-weight: ${props.theme.fontWeight.default};

        @media (min-width: 1280px) {
            font-size: ${props.theme.fontSize.sm};
            line-height: ${props.theme.lineHeight.sm};
        }

        @media (min-width: 1520px) {
            font-size: ${props.theme.fontSize.sm};
            line-height: ${props.theme.lineHeight.sm};
        }

        background-color: ${props.active ? props.theme.colors.primary : 'transparent'};
        color: ${props.active ? 'white' : props.theme.fontColor.default};

        height: 3rem;
        width: 100%;

        display: flex;
        align-items: center;
        box-sizing: border-box;

        padding-inline: 1rem;
        gap: 1rem;
        border-radius: 5px;
    `}

    ${props => props.variant === ButtonVariant.primary && `
        background-color: ${props.theme.colors.tertiary};
        color: ${props.theme.fontColor.primary};
        font-family: ${props.theme.fonts.primary};

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        height: 47px;
        width: 100%;

        white-space: nowrap;
    `}

    ${props => props.variant === ButtonVariant.secondary && `
        background-color: black;
        color: white;
        font-family: ${props.theme.fonts.primary};

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 47px;
        white-space: nowrap;
    `}

    ${props => props.variant === ButtonVariant.warning && `
        background-color: ${props.theme.colors.error};
        color: ${props.theme.colors.warning};
        font-family: ${props.theme.fonts.primary};

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 47px;
    `}
`;

const LoadingContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const ButtonLogo = styled(SVG).withConfig({
    shouldForwardProp: (prop) => prop !== 'active',
}) <{ active?: boolean }>`
    width: 1.5rem;
    height: 1.5rem;

    color: ${props => (props.active ? 'white' : props.theme.colors.tertiary)};
`;

export default Button;
