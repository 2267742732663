import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import PurchaseOrderGateway from 'api/PurchaseOrders';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

export default function* watchGetPurchaseOrderDetails(api: PurchaseOrderGateway): SagaWatcherReturnType {
    yield takeEvery('po/poGetPurchaseOrderDetailsAttempt', handleGetPurchaseOrderDetails, api);
}

function* handleGetPurchaseOrderDetails(api: PurchaseOrderGateway, data: PayloadAction<{ id: string }>) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const response = yield* call([api, api.getPurchaseOrderDetails], {
        authToken,
        purchaseOrderId: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.poGetPurchaseOrderDetailsFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.poGetPurchaseOrderDetailsSuccess(response.data));
    }
}
