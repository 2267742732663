import React, { FunctionComponent } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import PaymentOrder from 'containers/orders/PaymentOrder';
import Header from 'components/molecules/Header';
import SideMenu from 'components/molecules/SideMenu';

import { useAppSelector } from 'redux/Hooks';

import Utils from 'lib/Utils';
import { ModulesEnum } from 'entities/auth';

import theme from 'styles/theme';

const PrivateRoute: FunctionComponent = () => {
    const Location = useLocation();
    const isAuthenticated = Utils.Auth.getAuthToken();
    const { userInfo } = useAppSelector(state => state.auth);

    if (!isAuthenticated) return <Navigate replace to='/login' />;

    if (Location.pathname.split('/')[2] === 'payment') {
        return (
            <PaymentOrder />
        );
    }

    return (
        <main>
            <Header
                disableNavigate={!userInfo?.rolePermission.find((permission) => permission === ModulesEnum.Dashboard)}
            />
            <div>
                <SideMenu />
                <MainContainer>
                    <Outlet />
                </MainContainer>
            </div>
        </main>
    );
};

const MainContainer = styled.div`
    margin-left: 270px;
    min-height: calc(100vh - 70px);

    padding: 40px;

    background-color: ${theme.colors.background};
    overflow: hidden;
`;

export default PrivateRoute;
