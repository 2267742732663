import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';

import AddressDisplayList from 'components/atoms/AddressDisplayList';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';

import Actions from 'redux/Actions';
import { useAppDispatch } from 'redux/Hooks';

import { ICustomerAddress } from 'entities/address';
import { ICustomer } from 'entities/customer';

import theme from 'styles/theme';

interface CreateCustomerModalProps {
    isOpen: boolean;
    onModalClosed: () => void;

    loading: boolean;
    error: string;

    onCreate: (customer: Partial<ICustomer>) => void;

    defaultCustomerName?: string;
    updateDefaultCustomerName?: (name: string) => void;
    companyId?: string;
}

const CreateCustomerModal = (props: CreateCustomerModalProps): JSX.Element => {
    const {
        isOpen,
        onModalClosed,
        loading,
        error,
        onCreate,
        defaultCustomerName,
        companyId,
        updateDefaultCustomerName,
    } = props;

    const dispatch = useAppDispatch();

    const [customerName, setCustomerName] = useState<string | undefined>(defaultCustomerName);
    const [customerEmail, setCustomerEmail] = useState<string>('');
    const [customerPhone, setCustomerPhone] = useState<string>('');
    const [customerCompany, setCustomerCompany] = useState<string>('');
    const [address, setAddress] = useState<ICustomerAddress[]>();

    useEffect(() => {
        if (defaultCustomerName) setCustomerName(defaultCustomerName);

        return () => {
            setCustomerName(undefined);
            dispatch(Actions.uiCreateCustomerFailure(''));
            setCustomerEmail('');
            setCustomerPhone('');
            setCustomerCompany('');
            setAddress(undefined);
        };
    }, [defaultCustomerName]);

    const onCreatePressed = () => {
        const customer: Partial<ICustomer> = {
            companyAccountId: companyId,
            contactPerson: customerName,
            email: customerEmail,
            contact: customerPhone,
            companyName: customerCompany,
            address,
        };

        if (!customerName || !customerEmail || !customerCompany
            || !address) {
            dispatch(Actions.uiCreateCustomerFailure('Please fill in all required fields'));
            return;
        }

        onCreate(customer);
    };

    const onCustomerNameChanged = (name: string) => {
        setCustomerName(name);
        if (updateDefaultCustomerName) {
            updateDefaultCustomerName(name);
        }
    };
    return (
        <Modal
            isModalOpen={isOpen}
            onClosed={onModalClosed}
            title='Create new customer'
        >
            <Container>
                <Input
                    error={!!error && !customerName}
                    value={customerName}
                    onChange={(e) => onCustomerNameChanged(e.target.value)}
                    header='Customer name'
                    required
                />
                <Input
                    // error={!!error && !customerPhone}
                    value={customerPhone}
                    onChange={(e) => setCustomerPhone(e.target.value)}
                    header='Phone number'
                // required
                />
                <Input
                    error={!!error && !customerEmail}
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    header='Email'
                    required
                />
                <Input
                    error={!!error && !customerCompany}
                    value={customerCompany}
                    onChange={(e) => setCustomerCompany(e.target.value)}
                    header='Company'
                    required
                />
                <AddressDisplayList
                    error={error}
                    editable
                    label='Shipping Addresses'
                    addresses={address}
                    onChange={(addressList) => setAddress(addressList)}
                    required
                />
                {error && <ErrorText variant={TextVariant.error}>{error}</ErrorText>}
            </Container>

            <ButtonRow>
                <Button label='Cancel' onClick={onModalClosed} variant={ButtonVariant.primary} disabled={loading} />
                <Button
                    label='Create'
                    onClick={onCreatePressed}
                    variant={ButtonVariant.secondary}
                    disabled={loading}
                    loading={loading}
                />
            </ButtonRow>
        </Modal>
    );
};

const ModalText = styled(Text)`
    font-size: 16px;
    color: ${theme.fontColor.primary};
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    width: 800px;

    > div:nth-child(5) {
        grid-column: span 2;
        margin-bottom: 1rem;;
    }
`;

const ErrorText = styled(Text)`
    margin-top: 12px;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 12px;

    margin-top: 20px;
`;

export default CreateCustomerModal;
