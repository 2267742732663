import { toast } from 'react-toastify';
import { call, put, select, takeEvery } from 'typed-redux-saga/macro';

import SuppliersGateway from 'api/Supplier';
import { GatewayResponseStatus } from 'api/types/types';
import Actions from 'redux/Actions';
import AuthSelectors from 'redux/slices/auth/Selectors';
import Selectors from 'redux/slices/suppliers/Selectors';
import { SagaWatcherReturnType } from 'sagas/types';

import Utils from 'lib/Utils';

export default function* watchUpdateSupplier(api: SuppliersGateway): SagaWatcherReturnType {
    yield takeEvery('suppliers/supplierUpdateSupplierAttempt', handleUpdateSupplier, api);
}

function* handleUpdateSupplier(api: SuppliersGateway) {
    const authToken = yield* select(AuthSelectors.getAuthToken);

    const supplierDetails = yield* select(Selectors.getSuppliersCreateEditSupplierParams);

    if (!supplierDetails || !supplierDetails.contactPersonEmail
        || !supplierDetails.contactPersonName
        || !supplierDetails.supplierAddress || !supplierDetails.supplierName
        || !supplierDetails.companyAccountId || !supplierDetails.currency
    ) {
        yield put(Actions.supplierUpdateSupplierFailure('Please enter all fields before submitting.'));
        return;
    }

    if (!supplierDetails.id) {
        yield put(Actions.supplierUpdateSupplierFailure("We're unable to verify this supplier. Please refresh the page and try again."));
        return;
    }

    if (!Utils.Validators.validateEmail(supplierDetails.contactPersonEmail)) {
        yield put(Actions.supplierUpdateSupplierFailure('Invalid email address. Please enter a valid email address.'));
        return;
    }

    // if (!Utils.Validators.validatePhoneNumber(supplierDetails.contactPersonNumber)) {
    //     yield put(Actions.supplierUpdateSupplierFailure('Invalid phone number. Please enter a valid phone number.'));
    //     return;
    // }

    const response = yield* call([api, api.updateSupplier], {
        authToken,
        id: supplierDetails.id,
        name: supplierDetails.supplierName,
        description: supplierDetails.supplierDescription,
        website: supplierDetails.supplierWebsite,
        address: supplierDetails.supplierAddress,
        contactPersonName: supplierDetails.contactPersonName,
        contactPersonNumber: supplierDetails.contactPersonNumber ?? 'N/A',
        contactPersonEmail: supplierDetails.contactPersonEmail,
        companyAccountId: supplierDetails.companyAccountId,
        currency: supplierDetails.currency,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.supplierUpdateSupplierFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.supplierUpdateSupplierSuccess());
        yield put(Actions.supplierGetSupplierDetailsAttempt({ id: supplierDetails.id }));
        toast.success('Supplier updated.');
    }
}
