import { ApiResponse } from 'apisauce';

import Utils from 'lib/Utils';
import { ICompanyDetails, ICompanyList } from 'entities/company';

import { GatewayResponse } from './types/types';
import { GetCompanyDetailsApiParams, GetCompanyListApiParams, GetCompanyListResponse, ICompanyGateway } from './CompanyBase';

export default class CompanyGateway extends ICompanyGateway {
    async getCompanyList(params: GetCompanyListApiParams): GatewayResponse<GetCompanyListResponse> {
        const { authToken, index } = params;
        const response: ApiResponse<GetCompanyListResponse> = await this.api.get(
            '/settings/account',
            { index },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getCompanyDetails(params: GetCompanyDetailsApiParams): GatewayResponse<ICompanyDetails> {
        const { authToken, id } = params;
        const response: ApiResponse<ICompanyDetails> = await this.api.get(
            `/settings/account/${id}`,
            {},
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }
}
