import React, { FC, useEffect, useState } from 'react';

import Styles from 'containers/auth/styles/ForgotPasswordStyles';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AuthLayout from 'components/Layout/AuthLayout';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';

import NavActions from 'lib/NavActions';

import icons from 'assets/icons';

const ForgotPassword: FC = () => {
    const {
        MainContainer,
        FormContainer,
        TitleContainer,
        ErrorMessage,
        ButtonContainer,
        SuccessContainer,
        LogoContainer,
        MailLogo,
    } = Styles;

    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.auth.actions.forgotPassword || false);
    const error = useAppSelector(state => state.auth.error.forgotPassword || '');
    const requestSuccess = useAppSelector(state => state.auth.requestResetPassword || false);

    const [email, setEmail] = useState<string>('');
    const [inputError, setInputError] = useState<string>('');

    useEffect(() => {
        dispatch(Actions.authResetErrors());
    }, []);

    useEffect(() => {
        if (error) {
            setInputError(error);
        }
    }, [error]);

    const handleOnRequest = () => {
        if (!email) {
            setInputError('Please enter your email');
        } else {
            dispatch(Actions.authForgotPasswordAttempt({ email }));
            setInputError('');
        }
    };

    const renderContent = () => {
        if (requestSuccess) {
            return (
                <SuccessContainer>
                    <LogoContainer>
                        <MailLogo src={icons.mailIcon} />
                    </LogoContainer>
                    <Text
                        variant={TextVariant.title}
                        style={{ textAlign: 'center' }}
                    >
                        Email sent! Check your email to reset your password.
                    </Text>
                    <Button
                        label='Back to Login'
                        onClick={() => NavActions.navResetToLogin()}
                        loading={loading}
                        style={{ width: '70%' }}
                    />
                </SuccessContainer>
            );
        }
        return (
            <FormContainer>
                <TitleContainer>
                    <Text variant={TextVariant.title}>Reset Password</Text>
                    <Text>
                        Enter the email address you used when you joined and
                        we&apos;ll send you instructions to reset your password.
                    </Text>
                </TitleContainer>
                <Input
                    type='email'
                    placeholder='Enter email here'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!inputError}
                />
                <ErrorMessage error={!!inputError}>
                    <Text variant={TextVariant.error}>
                        {inputError}
                    </Text>
                </ErrorMessage>
                <ButtonContainer>
                    <div>
                        <Text>You remember your password? </Text>
                        <Button
                            label='Login here.'
                            variant={ButtonVariant.link}
                            onClick={() => NavActions.navResetToLogin()}
                        />
                    </div>
                    <Button
                        label='Request Password Reset'
                        onClick={handleOnRequest}
                        loading={loading}
                    />
                </ButtonContainer>
            </FormContainer>
        );
    };

    return (
        <AuthLayout>
            <MainContainer>
                {renderContent()}
            </MainContainer>
        </AuthLayout>
    );
};

export default ForgotPassword;
